import {
  addToQueue,
  getQueue
} from 'helpers/QueueHelper'
import {
  getLocalStorage
} from '../../helpers/LocalStorage'

const initialState = {
  showQueueOverlay: false,
  track:null,
  trackUrl:null,
  trackType: null,
  queue: [],
  loading:false,
  loaded:false,
  loopQueue: false,
  error:null
}

export default function reducer(state = initialState, action){
  switch(action.type){
    case 'FETCH_TRACK_PENDING':{
      state = {...state,loading:true}
      break
    }
    case 'FETCH_TRACK_REJECTED':{
      state = {...state,error:action.payload}
      break
    }
    case 'FETCH_TRACK_FULFILLED':{
      const track = {
        ...state.track,
        isPlaying:true
      }

      const playback_option = getLocalStorage('playback_option')
      let filetype = 'file_small'
      if(playback_option){
        filetype = playback_option === 3 ? 'file_stereo' : playback_option === 2 ? 'file_ios' : 'file_small'
      }
      
      state = {
        ...state,
        trackUrl:action.payload.data[filetype].filename.src,
        track: track
      }
      break
    }
    case 'FETCH_TRACK_INFO':{
      const track = {
        ...state.track,
        id:action.meta.track.id,
        title:action.meta.track.title,
        artist:action.meta.track.artist,
        album:action.meta.track.album,
        energy:action.meta.track.energy,
        isPlaying:false
      }

      state = {
        ...state,
        track: track,
        trackType: action.meta.trackType
      }
      break
    }
    case 'PLAY_PAUSE_TRACK':{
      state = {
        ...state,
        track:{
          ...state.track,
          isPlaying:!state.track.isPlaying
        }
      }
      break
    }
    case 'APP_BODY_CLICK':{
      state = {...state,showQueueOverlay:false, error: null }
      break
    }
    case 'TOGGLE_QUEUE_OVERLAY':{
      state = {...state,showQueueOverlay:action.payload}
      break
    }
    case 'TOGGLE_QUEUE_LOOP':{
      state = {...state,loopQueue:action.payload}
      break
    }
    case 'FETCH_QUEUE':{
      if (action.payload) {
        const tracks = action.payload.map((track)=>{
          track.isPlaying = track.id === action.meta
          return track
        })
        state = {
          ...state,
          queue: tracks
        }
      } else {
        state = {
          ...state,
          queue: []
        }
      }
      break
    }
    case 'ADD_TRACK_TO_QUEUE':{
      if (state.queue.find(track => track.id === action.payload.id)) {
        state = {
          ...state,
          error: 'Track already in queue'
        }
      } else {
        action.payload.isPlaying = false
        state = {
          ...state,
          queue: state.queue.concat(action.payload),
          error: null
        }
        addToQueue(action.payload)
      }
      break
    }
    case 'DELETE_FROM_QUEUE':{
      state = {
        ...state,
        queue: getQueue()
      }
      break
    }
    case 'DELETE_ALL_FROM_QUEUE':{
      state = {
        ...state,
        queue: []
      }
      break
    }
    case 'ADD_ALL_TO_PLAYLIST':{
      console.log('ADD_ALL_TO_PLAYLIST', action.payload)
      break
    }
    default: //no default case
  }
  return state
}
