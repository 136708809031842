import axios from 'axios'

export function login(username,password){
  return (dispatch) => {
    dispatch({type:'FETCH_AUTH_PENDING'})
    axios.post('/login',{
        username:username,
        password:password
      })
      .then((res)=>{
        if(res.data.authToken){
          localStorage.setItem('Authentication',res.data.authToken)
          axios.defaults.headers.common['Authentication'] = res.data.authToken
          dispatch({type:'FETCH_AUTH_FULFILLED',payload:res.data})
        } else if(!res.data.authToken && res.data.msg){
          dispatch({type:'FETCH_AUTH_WRONG',payload:res.data.msg})
        }

      })
      .catch((err)=>{
        dispatch({type:'FETCH_AUTH_REJECTED',payload:err ? err : '500'})
      })
  }
}

export function activate(hash){
  return (dispatch) => {
    dispatch({type:'FETCH_AUTH_PENDING'})
    axios.post('/login/activate', {
      activate: hash
    })
      .then((res)=>{
        if(res.data.authToken){
          localStorage.setItem('Authentication',res.data.authToken)
          axios.defaults.headers.common['Authentication'] = res.data.authToken
          dispatch({type:'FETCH_AUTH_FULFILLED',payload:res.data})
        } else if(!res.data.authToken && res.data.msg){
          dispatch({type:'FETCH_AUTH_WRONG',payload:res.data.msg})
        }

      })
      .catch((err)=>{
        dispatch({type:'FETCH_AUTH_REJECTED',payload:err ? err : '500'})
      })
  }
}

export function logout(){
  return (dispatch) => {
    localStorage.removeItem('Authentication')
    dispatch({type:'LOGOUT'})
  }
}

export function isAuthenticated(){

  return {
    type:'IS_AUTH',
    payload:axios.post('/login/check-login',{
      authToken:localStorage.getItem('Authentication')
    })
  }
}

export function resetPassword(email){
  return{
    type:'RESET_PASSWORD',
    payload:axios({
      method:'post',
      url:'/public/login/reset-password',
      //baseURL:'https://api.systems.openearmusic.com',
      baseURL:'http://localhost:8001',
      data:{email:email}
    })
  }
}

export function checkResetToken(token,email){
  return{
    type:'CHECK_RESET_TOKEN',
    payload:axios.post('/login/check-reset-token',{
      token:token,
      email:email
    })
  }
}

export function changePassword(data){
  return{
    type:'CHANGE_PASSWORD',
    payload:axios.post('/login/change-password',data)
  }
}

export function resetPwInterface(){
  return{
    type:'RESET_PW_INTERFACE',
    payload:null
  }
}