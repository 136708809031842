import React, {Component} from 'react'
import { connect } from 'react-redux'

import {
  updateVenueDetails
} from 'store/actions/venueAction'

import Container from 'ui/Container'
import Loader from 'ui/Loader'
import Form from 'ui/Form'

const classname = 'venueDetails'

class VenueDetails extends Component {
  constructor(props){
    super(props)
    this.state = {
      displayName:null
    }
  }

  componentDidUpdate(prevProps){
    if(this.props.venue !== prevProps.venue){
      this.props.setHeader(`${this.props.venue.name}`)
    }
  }

  onSubmit(form){
    this.props.dispatch(updateVenueDetails(this.props.id,form))
  }

  render(){
    if(this.props.loading){
      return <Loader/>
    } else {
      return (
        <Container classname={classname} height="100%" maxWidth>
          <Form
            classname={classname}
            submit={(form)=>this.onSubmit(form)}
            messages={this.state.message}
            data={this.props.venue}
            scroll/>
        </Container>
      )
    }
  }
}

function mapStateToProps(store){
  return {
    venue:store.venue.details,
    loading:store.playlist.loading
  }
}

export default connect(mapStateToProps)(VenueDetails)
