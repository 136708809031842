import React from 'react'

import FormLabel from 'ui/FormLabel'

const FormTextarea = (props) => {
  const {
    name,
    value,
    change,
    label,
    classname,
    size,
    autocomplete
  } = props

  const textareaClasses = `texarea texarea-${classname} ${size ? size : ''}`
  const wrapperClasses = `form-texarea-wrapper texarea-wrapper-${classname}`
  const labelClasses = `texarea-label texarea-label-${classname}`

  return (
    <div className={wrapperClasses}>
      <FormLabel className={labelClasses} label={label}/>
      <textarea
        name={name}
        value={value}
        className={textareaClasses}
        onChange={(e)=>change(e)}
        autoComplete={autocomplete}
        />
        <span className="focus-border"></span>
    </div>
  )
}

export default FormTextarea
