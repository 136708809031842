import React, {Fragment} from 'react'

import Icon from 'ui/Icon'
import Loader from 'ui/Loader'

const OverlayPlaylistsLibraryTableRowActions = (props) => {
  const {
    addPlaylist,
    removePlaylist,
    venuePlaylists,
    classname,
    venueID,
    playlist,
    updatingVenueIDs,
  } = props

  const isPlaylistAssigned = venuePlaylists.some(venuePlaylist => venuePlaylist.id === playlist.id)

  return (
    <div className={`${classname}`}>
      {updatingVenueIDs.includes(venueID) ? (
        <Loader />
      ) : (
        <Fragment>
          {isPlaylistAssigned ?
              <Icon
                name="remove-circle"
                action={()=>removePlaylist(venueID, playlist)}
              />
              :
              <Icon
                name="add-circle"
                action={()=>addPlaylist(venueID, playlist)}
              />
          }
        </Fragment>
      )}
    </div>
  )
}

export default OverlayPlaylistsLibraryTableRowActions
