import React, { Component, useEffect, useState } from 'react'
import { CSSTransition } from 'react-transition-group'

const AnimateOnLoad = ({
  children
}) => {

  const [inProp, setInProp] = useState(false)
  useEffect(()=>{
    setInProp(true)
  },[])
  return (
    <CSSTransition
      className='animate-on-load'
      timeout={200}
      in={inProp}
    >
      <div>
        {children}
      </div>
    </CSSTransition>
  )
}

export default AnimateOnLoad
