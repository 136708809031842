import React, { Component } from 'react'
import {
  Cell,
  PieChart,
  Pie,
  ResponsiveContainer
} from 'recharts';

const colours = ['#4ba0b3', '#22aaae', '#12b39c', '#3fb980', '#6cbc5d', '#9bbb37', '#ccb408', '#ffa600'];

class DashboardPieChart extends Component {

  render() {
    const {
      data,
      animate
    } = this.props;

    let pieData = [];

    Object.keys(data).forEach((key, index) => {
      if (key !== 'keys' && index < 8) {
        pieData.push({name: key, value: data[key]})
      }
    });

    return (
      <ResponsiveContainer width="99%" height={300}>
        <PieChart>
          <Pie
            data={pieData}
            dataKey="value"
            fill="#8884d8"
            label={(entry)=>`${entry.name}`}
            labelLine
            outerRadius="50%"
            isAnimationActive={animate}
          >
            {
              pieData.map(
                (entry, index) =>
                  <Cell
                    key={`cell-${index}`}
                    fill={colours[index % colours.length]}
                    style={{ fontFamily: 'Walsheim, sans-serif', fontSize: '11px' }}
                  />
              )
            }
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    )
  }
}

export default DashboardPieChart
