import React, {PureComponent, Fragment} from 'react'
import InterleaveCreator from "./InterleaveCreator";

class ScheduleCreator extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      timesFrom: [
        '04', '05', '06', '07', '08', '09',
        '10', '11', '12', '13', '14', '15',
        '16', '17', '18', '19', '20', '21',
        '22', '23', '00', '01', '02', '03'
      ],
      timesTill: [
        '04', '05', '06', '07', '08', '09',
        '10', '11', '12', '13', '14', '15',
        '16', '17', '18', '19', '20', '21',
        '22', '23', '00', '01', '02', '03',
        '04'
      ]
    }
  }

  componentDidMount() {
    const {
      resetTimeError,
      setFillGaps
    } = this.props;

    resetTimeError()
    setFillGaps(false)
  }

  render() {
    const {
      hideScheduleCreator,
      editArray,
      editMulti,
      fillGaps,
      isInterleave,
      playlists,
      repeatTypes,
      resetTimeError,
      showRepeatTypes,
      setFillGaps,
      selectedFrom,
      selectedTill,
      setSelectedPlaylist,
      setSelectedRepeatType,
      setSelectedFrom,
      setSelectedTill,
      timeError,
      addSchedule,
      addGapSchedule,
      addMultiSchedule,
      editMultiSchedule
    } = this.props;

    const {
      timesFrom,
      timesTill
    } = this.state;

    return (
      <div className='schedule-creator__overlay'>
        <div
          className='schedule-creator'
          onClick={(e)=>{e.stopPropagation()}}
        >
          {isInterleave || (
            <h3>{editMulti ? 'Edit Selected' : 'Create Schedule'}</h3>
          )}
          <p
            className='schedule-creator__close-btn'
            onClick={() => {
              hideScheduleCreator()
            }}
          >
            CLOSE
          </p>
          {editMulti && editArray.length === 0 ? (
            <p>Hold the command (cmd) key and click on playlists to add for editing</p>
          ) : (
            <Fragment>
              {isInterleave ? (
                <InterleaveCreator
                  {...this.props}
                />
              ): (
                <Fragment>
                  <h4>{editMulti ? 'Edit Playlist' : 'Select Playlist'}</h4>
                  <select
                    className='schedule-creator__playlist-select'
                    onChange={(event) => {
                      setSelectedPlaylist(event.nativeEvent.target[event.nativeEvent.target.selectedIndex].text, event.target.value)
                    }}
                  >
                    {playlists && playlists.map((playlist) => {
                      return (
                        <option
                          key={playlist.id}
                          value={playlist.id}
                        >
                          {playlist.display_name || playlist.name}
                        </option>
                      )
                    })}
                  </select>
                </Fragment>
              )}
            </Fragment>
          )}
          {!editMulti && !isInterleave && (
            <Fragment>
              {showRepeatTypes ? (
                <Fragment>
                  <h4>Repeat Type</h4>
                  <select
                    className='schedule-creator__repeat-select'
                    onChange={(event) => {
                      setSelectedRepeatType(event.target.value)
                    }}
                  >
                    {repeatTypes && repeatTypes.map((type) => {
                      return (
                        <option
                          key={type.value}
                          value={type.value}
                        >
                          {type.display}
                        </option>
                      )
                    })}
                  </select>
                </Fragment>
              ) : (
                <Fragment>
                  <h4>
                    Fill gap
                    <input
                      className='schedule-creator__fill-gap'
                      type='checkbox'
                      onChange={(event) => {
                        setFillGaps(event.target.checked);
                        resetTimeError();
                      }}
                    />
                  </h4>
                </Fragment>
              )}
              {!fillGaps && (
                <Fragment>
                  {timeError && (
                    <p style={{color: '#e74c3c'}}>
                      Selected time frame is already in use. Please select another.
                    </p>
                  )}
                  <div className='schedule-creator__time'>
                    <div className='schedule-creator__time-from'>
                      <h4>From</h4>
                      <select
                        className='schedule-creator__time-select'
                        onChange={(event) => {
                          setSelectedFrom(parseInt(event.target.value))
                        }}
                        value={selectedFrom % 2 === 0 ? selectedFrom : selectedFrom - 1}
                      >
                        {timesFrom.map((time, index) => {
                          return (
                            <option
                              key={index}
                              value={index * 2}
                            >
                              {time}
                            </option>
                          )
                        })}
                      </select>
                      <span className='schedule-creator__time-select-separator'>:</span>
                      <select
                        className='schedule-creator__time-select'
                        onChange={(event) => {
                          setSelectedFrom(parseInt(selectedFrom) + (parseInt(event.target.value) === 1 ? 1 : -1))
                        }}
                        value={selectedFrom % 2 === 0 ? 0 : 1}
                      >
                        <option
                          key={0}
                          value={0}
                        >
                          00
                        </option>
                        <option
                          key={1}
                          value={1}
                        >
                          30
                        </option>
                      </select>
                    </div>
                    <div className='schedule-creator__time-till'>
                      <h4>Till</h4>
                      <select
                        className='schedule-creator__time-select'
                        onChange={(event) => {
                          setSelectedTill(parseInt(event.target.value))
                        }}
                        value={selectedTill % 2 === 0 ? selectedTill : selectedTill - 1}
                      >
                        {timesTill.map((time, index) => {
                          return (
                            <option
                              key={index}
                              value={index * 2}
                            >
                              {time}
                            </option>
                          )
                        })}
                      </select>
                      <span className='schedule-creator__time-select-separator'>:</span>
                      <select
                        className='schedule-creator__time-select'
                        onChange={(event) => {
                          setSelectedTill(parseInt(selectedTill) + (parseInt(event.target.value) === 1 ? 1 : -1))
                        }}
                        value={selectedTill % 2 === 0 ? 0 : 1}
                      >
                        {selectedTill !== 0 && (
                          <option
                            key={0}
                            value={0}
                          >
                            00
                          </option>
                        )}
                        {selectedTill !== 48 && (
                          <option
                            key={1}
                            value={1}
                          >
                            30
                          </option>
                        )}
                      </select>
                    </div>
                  </div>
                </Fragment>
              )}
            </Fragment>
          )}
          {(editMulti && (editArray.length === 0)) || isInterleave || (
            <div className='schedule-creator__submit-btn-container'>
              <button
                type='button'
                className='schedule-creator__submit-btn'
                onClick={() => {
                  editMulti ? editMultiSchedule() : fillGaps ? addGapSchedule() : showRepeatTypes ? addMultiSchedule() : addSchedule();
                }}
              >
                Save
              </button>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default ScheduleCreator
