import React from 'react'

import LibraryTypeahead from './LibraryTypeahead'
import Icon from 'ui/Icon'
import Tooltip from 'ui/Tooltip'
import Badge from 'ui/Badge'

const LibraryTableAction = (props) => {
  const {
    classname,
    searchAction,
    typeaheadAction,
    typeaheadLoading,
    disableTypeahead,
    searchData,
    placeholder,
    showFilter,
    clickTypeahead,
    active,
    numSelected,
    changeInfo,
    addToQueue,
    addTracksToPlaylist,
    closeTypeahead,
    filterLength,
    deselectTracks
  } = props

  return (
    <div className={`table-actions table-actions-${classname}`}>
      <div className="table-actions-left">
        <Icon name="funnel" action={()=>showFilter()}>
          <Tooltip pos="right" text="Show filter"/>
        </Icon>
        <Badge data={filterLength}/>
        <LibraryTypeahead
          classname={classname}
          placeholder={placeholder}
          searchAction={(e)=>searchAction(e)}
          typeaheadAction={(e)=>typeaheadAction(e)}
          typeaheadLoading={typeaheadLoading}
          disableTypeahead={disableTypeahead}
          data={searchData}
          clickTypeahead={(type,term)=>clickTypeahead(type,term)}
          closeTypeahead={()=>closeTypeahead()}
        />
      </div>
      <div className={`table-actions-right ${active ? 'active' : ''}`}>
        {
          numSelected > 0 &&
          <div className="selected-indicator">{numSelected} selected</div>
        }
        <Icon name="close-circle-outline" action={()=>deselectTracks()}>
          <Tooltip text="Deselect tracks" pos="left"/>
        </Icon>
        <Icon name="ios-list-box" action={()=>addToQueue()}/>
        <Icon name="add" action={()=>addTracksToPlaylist()}>
          <Tooltip pos="left" text="Add tracks to playlist"/>
        </Icon>
      </div>
    </div>
  )
}

export default LibraryTableAction
