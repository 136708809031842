import React from 'react'

import Icon from 'ui/Icon'

const PlaylistOrderTableActions = (props) => {
  const { index } = props

  return (
    <div>
      {index + 1}
      <Icon name='ios-hand' />
    </div>
  )
}

export default PlaylistOrderTableActions
