import axios from 'axios'

export function getSpotifyPlaylists(spToken){
  return (dispatch) => {
    let instance = axios.create();
    instance.defaults.headers.common['Authorization'] = 'Bearer '+spToken;
    instance.defaults.baseURL = 'https://api.spotify.com/'
    return dispatch({
      type:'FETCH_SPOTIFY_PLAYLISTS',
      payload:instance.get('v1/me/playlists?limit=50',{
        transformRequest: [(data, headers) => {
          delete headers.common.Authentication
          return data
        }]
      })
    })
  }
}

export function getSpotifyPlaylistTracks(spToken, playlist, offset){
  return (dispatch) => {
    let instance = axios.create();
    instance.defaults.headers.common['Authorization'] = 'Bearer '+spToken;
    instance.defaults.baseURL = 'https://api.spotify.com/'
    return dispatch({
      type:'FETCH_SPOTIFY_PLAYLIST_TRACKS',
      payload:instance.get(`https://api.spotify.com/v1/playlists/${playlist.id}/tracks?offset=${offset}`,{
        transformRequest: [(data, headers) => {
          delete headers.common.Authentication
          return data
        }]
      })
    })
  }
}

export function getSpotifyPlaylistTracksNext(spToken, href){
  return (dispatch) => {
    let instance = axios.create();
    instance.defaults.headers.common['Authorization'] = 'Bearer '+spToken;
    instance.defaults.baseURL = 'https://api.spotify.com/'
    return dispatch({
      type:'FETCH_SPOTIFY_PLAYLIST_TRACKS_NEXT',
      payload:instance.get(href,{
        transformRequest: [(data, headers) => {
          delete headers.common.Authentication
          return data
        }]
      })
    })
  }
}

export function selectPlaylist(playlist){
  return (dispatch) => {
    return dispatch({
      type:'SELECT_SPOTIFY_PLAYLIST',
      payload:playlist
    })
  }
}

export function getImporterMatches(data){
  return (dispatch) => {
    return dispatch({
      type: 'FETCH_IMPORTER_MATCHES',
      payload: axios.post('importer/match', data),
      meta: data
    })
  }
}

export function addToFinalTracklist(track){
  return (dispatch) => {
    return dispatch({
      type: 'ADD_TO_FINAL_TRACKLIST',
      payload: null,
      meta: track
    })
  }
}

export function removeFromFinalTracklist(track){
  return (dispatch) => {
    return dispatch({
      type: 'REMOVE_FROM_FINAL_TRACKLIST',
      payload: null,
      meta: track
    })
  }
}

export function createImporterPlaylist(data){
  return (dispatch) => {
    return dispatch({
      type: 'CREATE_IMPORTER_PLAYLIST',
      payload: axios.post('importer/create',data)
    })
  }
}

export function createPlaylistAddVenues(data, playlistID){
  const formData = {
    ...data,
    id: playlistID
  }
  return (dispatch) => {
    return dispatch({
      type: 'CREATE_PLAYLIST_ADD_VENUES',
      payload: axios.post('importer/create/venues',formData)
    })
  }
}

export function resetCreatePlaylist(){
  return (dispatch) => {
    return dispatch({
      type: 'RESET_CREATE_PLAYLIST'
    })
  }
}