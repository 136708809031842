import React from 'react'

const Image = (props) => {
  const baseUrl = 'https://openearmusic.imgix.net/'
  const {
    name,width,height
  } = props
  const src = name ? `${baseUrl}${name}?w=${width ? width : 200}&h=${height ? height : 200}` : 'albumCovers/default.png'
  return (
    <div className="image-wrapper">
      <img
        src={src}
        alt={name}
      />
    </div>
  )
}

export default Image
