import React, {Fragment} from 'react'

import Icon from 'ui/Icon'
import Loader from 'ui/Loader'
import Tooltip from 'ui/Tooltip'

const PlaylistTracksOwnerTableRowActions = (props) => {
  const {
    removeTrack,
    track,
    addTrackToOwnedPlaylistLoading,
    openPlaylistOverlay
  } = props
  const isSubscribed = addTrackToOwnedPlaylistLoading === track.id

  return (
    <div>
      {isSubscribed ? (
        <Loader/>
      ): (
        <Fragment>
          <Icon name="add-circle" action={()=>openPlaylistOverlay({track: track})}>
            <Tooltip pos="left" text="Add track to another playlist."/>
          </Icon>
          <Icon
            name="trash"
            action={()=>removeTrack(track)}
          >
            <Tooltip
              pos="left"
              text="Remove track from playlist"
            />
          </Icon>
        </Fragment>
      )}
    </div>
  )
}

export default PlaylistTracksOwnerTableRowActions
