import React from 'react'

const Container = (props) => {
  const classes = `
    container container-${props.classname} 
    ${props.height ? 'container-height-full' : 'container-height-auto'}
    ${props.column ? 'column' : ''}
    ${props.maxWidth ? 'maxWidth':''}
    ${props.maxHeight ? 'maxHeight':''}`

  if(props.action){
    return <div onClick={(e)=>props.action(e)}className={classes}>{props.children}</div>
  } else {
    return <div className={classes}>{props.children}</div>
  }

}

export default Container
