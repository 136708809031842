import React, {Component} from 'react'

import Container from 'ui/Container'
import Button from 'ui/Button'

class OverlayAnnouncements extends Component{
  copyVenue(){

  }
  render(){
    return (
      <Container classname="overlay-venues" maxWidth height="100%" column>
        <h2>Venue Actions for {this.props.data.name}</h2>
        <Button action={()=>this.copyVenue()} name={`Copy venue`}/>
        <Button action={()=>this.deleteVenue()} name={`Delete venue`}/>
      </Container>
    )
  }
}

export default OverlayAnnouncements