import React, { Component } from 'react'
import Slider from 'react-slick'

import Icon from 'ui/Icon'
import PlaylistPreview from './PlaylistPreview'

function SampleNextArrow (props) {
  const {currentSlide, slideCount, ...arrowProps} = props

  return (
      <div {...arrowProps}>
        <Icon name="ios-arrow-dropright-circle" />
      </div>
  );
}

function SamplePrevArrow (props) {
  const {currentSlide, slideCount, ...arrowProps} = props

  return (
      <div {...arrowProps}>
        <Icon name="ios-arrow-dropleft-circle" />
      </div>
  );
}

class PlaylistsCarousel extends Component{
  constructor(props){
    super(props);

    this.state = {
      playlistID: null,
      carouselDataArray: []
    };

    this.overlayFunc = this.overlayFunc.bind(this);
  }

  componentDidMount() {
    const {
      carouselData
    } = this.props

    this.setState({
      carouselDataArray: carouselData
    })
  }

  componentDidUpdate(prevProps) {
    const {
      carouselData
    } = this.props

    if (prevProps.carouselData !== carouselData) {
      this.setState({
        carouselDataArray: carouselData
      })
    }
  }

  overlayFunc(playlist) {
    const {
      playPlaylist
    } = this.props

    playPlaylist(playlist.id)
  }

  render() {
    const {
      currentlyPlaying,
      showPlaylistPreview,
      activePreview
    } = this.props;

    const {
      carouselDataArray
    } = this.state;

    const carouselSettings = {
      accessibility: false,
      arrows: true,
      infinite: true,
      slidesToShow: 6,
      prevArrow: <SamplePrevArrow />,
      nextArrow: <SampleNextArrow />,

      responsive: [
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 5
          }
        },
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 4
          }
        },
        {
          breakpoint: 720,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 460,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 0,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    };

    return (
      <div>
        <Slider {...carouselSettings}>
          {carouselDataArray.map((playlist,index) => {
            const isPlaying = currentlyPlaying === playlist.id
            return (
              <div
                className='playlists-carousel__playlist'
                key={index}
              >
                <div
                  className='playlists-carousel__playlist-image'
                >
                  <img
                      src={playlist.playlistImage && playlist.playlistImage.name ? `https://openearmusic.imgix.net/${playlist.playlistImage.name}?w=402&h=402` : 'albumCovers/default.png'}
                      alt={playlist.playlistImage && playlist.playlistImage.name ? playlist.playlistImage.name : 'Open Ear'}
                  />
                  <div
                    className={`playlists-carousel__playlist-overlay ${isPlaying ? 'playlists-carousel__playlist-overlay--active' : ''}`}
                  >
                    <h4>Curated by the Open Ear Playlist team</h4>
                    <div
                      className='playlists-carousel__playlist-overlay-arrow'
                      onClick={() => {this.overlayFunc(playlist)}}
                    >
                      <Icon name={isPlaying ? 'ios-volume-high' : 'play-circle'} />
                    </div>
                    <div
                        className='playlists-carousel__more-info-link'
                        onClick={() => {showPlaylistPreview(playlist.id)}}
                    >
                      More info
                    </div>
                  </div>
                </div>
                <div
                  className='playlists-carousel__playlist-name'
                  onClick={() => {showPlaylistPreview(playlist.id)}}
                >
                  {playlist.name}
                </div>
              </div>
            )
          })}
        </Slider>
        {activePreview && carouselDataArray.find(playlist=> playlist.id === activePreview)  && (
          <div className='playlists-carousel__overlay'>
            <div className='playlists-carousel__overlay-inner'>
              <PlaylistPreview
                id={activePreview}
                closeFunc={() => {showPlaylistPreview(null)}}
              />
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default PlaylistsCarousel
