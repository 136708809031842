import React, { Component, Fragment } from 'react'

import moment from 'moment'
import Calendar from 'react-calendar'

import Icon from 'ui/Icon'
import Button from 'ui/Button'

class LibrarySearchFilter extends Component {
  constructor(props){
    super(props)
    this.state = {
      genres:[],
      genreFilterType: 'OR',
      energies:[],
      openPanel:{
        genres:true,
        energies:true,
        dates:true
      },
      startCalendar:{
        show:false,
        date:null
      },
      endCalendar:{
        show:false,
        date:null
      }
    }
  }

  componentDidMount(){

  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.genres !== this.props.genres){
      this.setStateGenres()
      this.setStateEnergies()
    }
  }

  setStateGenres(){
    const genres = this.props.genres
      .map(genre => {
        genre.selected = false
        return genre
      })

    this.setState({
      genres:genres
    })
  }

  setStateEnergies(){
    const energies = [1,2,3,4,5,6,7,8,9,10]
      .map(num => {
        const energy = {
          number:num,
          selected:false
        }
        return energy
      })

    this.setState({
      energies:energies
    })
  }

  toggleSelectGenres(index){
    let genres = [...this.state.genres]

    if (genres[index].selected) {
      // second click
      genres[index].selected = false
      genres[index].deselected = true
    } else if (genres[index].deselected) {
      // third click
      genres[index].selected = false
      genres[index].deselected = false
    } else {
      // first click
      genres[index].selected = true
      genres[index].deselected = false
    }

    this.setState({
      genres:genres
    })
  }

  toggleSelectEnergies(index){
    let energies = [...this.state.energies]
    energies[index].selected = !energies[index].selected
    this.setState({
      energies:energies
    })
  }

  getGenres(){
    return this.state.genres.map((genre,index)=>{
      return(
        <div
          className={`genre selector ${genre.selected ? 'selected' : ''} ${genre.deselected ? 'deselected' : ''}`}
          key={index}
          onClick={()=>this.toggleSelectGenres(index)}>
          {genre.genre} {!genre.selected || <Icon name="checkmark-circle"/>} {!genre.deselected || <Icon name="remove-circle"/>}
        </div>
      )
    })
  }

  getEnergies(){
    return this.state.energies.map((energy,index) => {
      return (
        <div
          className={`energy selector ${energy.selected ? 'selected' : ''}`}
          key={index}
          onClick={()=>this.toggleSelectEnergies(index)}>
          {energy.number} {!energy.selected || <Icon name="checkmark-circle"/>}
        </div>
      )
    })
  }

  getDates(){

  }

  openPanel(panel){
    this.setState({
      openPanel: {
        ...this.state.openPanel,
        [panel]: !this.state.openPanel[panel]
      }
    })
  }

  openCalendar(type){
    const {startCalendar, endCalendar} = this.state

    if(type === 'start'){
      this.setState(
        {
          startCalendar:{
            show:true,
            date:startCalendar.date
          },
          endCalendar:{
            show:false,
            date:endCalendar.date
          }
        }
      )
    } else {
      this.setState(
        {
          startCalendar:{
            show:false,
            date:startCalendar.date
          },
          endCalendar:{
            show:true,
            date:endCalendar.date
          }
        }
      )
    }
  }

  setDate(type,date){
    const {startCalendar, endCalendar} = this.state
    if(type === 'start'){
      const start = moment(date)
      const end = moment(endCalendar.date)
      const diff = end.diff(start)
      let endDate
      if(diff <= 0){
        endDate = start.add(1,'days')
      } else {
        endDate = endCalendar.date
      }
      this.setState({
        startCalendar:{
          date:date,
          show:false
        },
        endCalendar:{
          date:endDate,
          show:false
        }
      })
    } else {
      const start = moment(startCalendar.date)
      const end = moment(date)
      const diff = end.diff(start)
      let startDate
      if(diff <= 0){
        startDate = end.subtract(1,'days')
      } else {
        startDate = startCalendar.date
      }
      this.setState({
        startCalendar:{
          date:startDate,
          show:false
        },
        endCalendar:{
          date:date,
          show:false
        }
      })
    }
  }

  resetCalendar() {
    this.setState(
      {
        startCalendar:{
          show:false,
          date:null
        },
        endCalendar:{
          show:false,
          date:null
        }
      }
    )
  }

  closeCalendars(e) {
    const { startCalendar, endCalendar } = this.state

    e.stopPropagation()

    this.setState(
      {
        startCalendar:{
          show:false,
          date: startCalendar.date || false
        },
        endCalendar:{
          show:false,
          date: endCalendar.date || false
        }
      }
    )
  }

  clearFilter(){
    this.setStateGenres()
    this.setStateEnergies()
    this.resetCalendar()
    setTimeout(()=>{
      this.setFilterState()
    },300)
  }

  setFilterState(){
    const {
      genres,
      genreFilterType,
      energies,
      startCalendar,
      endCalendar
    } = this.state

    const filter = {
      genres:genres.filter(genre => genre.selected).map(genre => genre.id),
      excludedGenres:genres.filter(genre => genre.deselected).map(genre => genre.id),
      genreFilterType: genreFilterType,
      energies:energies.filter(energy => energy.selected).map(energy => energy.number),
      startDate:startCalendar.date,
      endDate:endCalendar.date
    }
    this.props.setFilter(filter)
  }

  setFilter(){
    this.setFilterState()
    this.props.closeFilter()
  }

  setGenreFilterType(e) {
    this.setState({
      genreFilterType: e.target.value
    })
  }

  renderFilterCalendar(type) {
    return (
      <Fragment>
        <div
          className='library-search-filter__calendar-underlay'
          onClick={(e)=>this.closeCalendars(e)}
        />
        <Calendar
          value={new Date()}
          onChange={(date) => this.setDate(type, date)}
        />
      </Fragment>
    )
  }

  render(){
    const {
      startCalendar,
      endCalendar
    } = this.state

    const genresClass = `panel genres ${this.state.openPanel.genres ? 'active' : null}`
    const energiesClass = `panel energies ${this.state.openPanel.energies ? 'active' : null}`
    const datesClass = `panel dates ${this.state.openPanel.dates ? 'active' : null}`

    return (
      <Fragment>
        {this.props.showFilter && (
          <div
            className='library-search-filter__underlay'
            onClick={()=>this.props.closeFilter()}
          />
        )}
        <div className={`library-search-filter-wrapper ${this.props.showFilter ? 'active' : ''}`}>
          <div className="library-search-filter">
            <div className="header-wrapper">
              <h1>Library Filter</h1>
              <Icon name="close" action={()=>this.props.closeFilter()}/>
            </div>
            <span className="dropdown-selector" onClick={()=>this.openPanel('dates')}>
              Select upload date <Icon name={this.state.openPanel.dates ? 'arrow-dropdown-circle' : 'arrow-dropright-circle'}/>
            </span>
            <div className={datesClass}>
              <div
                className="start-date"
                onClick={()=>this.openCalendar('start')}
              >
                <Icon
                  name="calendar"
                  stopPropagation
                />
                <span className="date">
                {startCalendar.date ? moment(startCalendar.date).format("MMM Do YYYY") : 'Select start date'}
              </span>
                {startCalendar.show && (
                  this.renderFilterCalendar('start')
                )}
              </div>
              <span className="divider">TO</span>
              <div
                className="end-date"
                onClick={()=>this.openCalendar('end')}
              >
                <Icon
                  name="calendar"
                  stopPropagation
                />
                <span className="date">
                {endCalendar.date ? moment(endCalendar.date).format("MMM Do YYYY") : 'Select end date'}
              </span>
                {endCalendar.show && (
                  this.renderFilterCalendar('end')
                )}
              </div>
              <Icon
                name="trash"
                action={()=>this.resetCalendar()}/>
            </div>
            <span className="dropdown-selector" onClick={()=>this.openPanel('genres')}>
            Select genres <Icon name={this.state.openPanel.genres ? 'arrow-dropdown-circle' : 'arrow-dropright-circle'}/>
          </span>
            <div
              className='library-search-filter__genre-filter-type'
              onChange={(e)=>this.setGenreFilterType(e)}
            >
              <span>Filter type:</span>
              <label htmlFor='or-state'>
                <input
                  type='radio'
                  value='OR'
                  id='or-state'
                  name='genreFilterType'
                  defaultChecked
                />
                <span>OR</span>
              </label>
              <label htmlFor='and-state'>
                <input
                  type='radio'
                  value='AND'
                  id='and-state'
                  name='genreFilterType'
                />
                <span>AND</span>
              </label>
              <label htmlFor='only-state'>
                <input
                  type='radio'
                  value='ONLY'
                  id='only-state'
                  name='genreFilterType'
                />
                <span>ONLY</span>
              </label>
            </div>
            <div className={genresClass}>
              {this.getGenres()}
            </div>
            <span className="dropdown-selector" onClick={()=>this.openPanel('energies')}>
              Select energies <Icon name={this.state.openPanel.energies ? 'arrow-dropdown-circle' : 'arrow-dropright-circle'}/>
            </span>
            <div className={energiesClass}>
              {this.getEnergies()}
            </div>
          </div>
          <div className="actions-wrapper">
            <Button
              action={()=>this.clearFilter()}
              name="Clear Filter"/>
            <Button
              action={()=>this.setFilter()}
              name="Set Filter"
              main/>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default LibrarySearchFilter
