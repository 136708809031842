import React, {Component, Fragment} from 'react'
import { connect } from 'react-redux'
import { SortablePane, Pane } from '../../lib/react-sortable-pane'
import { getFitnessClass, clearFitnessClass, updateFitnessClass } from 'store/actions/fitnessAction'
import { toggleOverlay } from 'store/actions/overlayAction'

import FitnessTimelineHeading from './fitness-timeline/FitnessTimelineHeading'
import FitnessTimelinePlaylist from './fitness-timeline/FitnessTimelinePlaylist'
import Container from 'ui/Container'
import Loader from 'ui/Loader'

import { debounce } from 'helpers/Debounce'

const classname = 'fitnessTimeline'

let dragTimer

class FitnessTimeline extends Component {

  constructor(props){
    super(props)
    // defines the width in pixels of one minute on the timeline
    this.unitWidth = 20

    this.state = {
      list: null,
      maxDuration: 90 * this.unitWidth,
      order: null,
      playlistInfo: {},
      remainingTime: 90 * this.unitWidth,
      showPlaylistInfo: false,
      wasDrag: false,
      hasMissingPlaylists: false
    }

    this.addPlaylist = this.addPlaylist.bind(this)
    this.editDuration = this.editDuration.bind(this)
    this.debounceExportClassData = debounce(this.debounceExportClassData,800)
  }

  componentDidMount(){
    const {
      dispatch,
      id
    } = this.props

    dispatch(clearFitnessClass())
    dispatch(getFitnessClass(id))
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      fitnessClass
    } = this.props

    const {
      list,
      wasDrag,
    } = this.state

    if (prevProps.fitnessClass !== fitnessClass) {
      if (fitnessClass.sections && fitnessClass.sections.some(section => section.playlist === null)) {
        this.setState({
          hasMissingPlaylists: true
        })
      }
      this.importClassData()
    }

    if ((prevState.list === null) && (prevState.list !== list) && (list.length > 0)) {
      this.calculateRemainingTime()
    }

    if ((wasDrag === false) && (prevState.wasDrag !== wasDrag)) {
      this.exportClassData()
    }
  }

  importClassData() {
    const {
      fitnessClass
    } = this.props

    let listArray = []
    let orderArray = []

    if (fitnessClass.sections) {
      fitnessClass.sections.sort((a, b) => {
        return a.order - b.order
      })

      fitnessClass.sections.forEach((playlist) => {
        const playlistStart = playlist.start.split(':')
        const playlistEnd = playlist.end.split(':')
        const duration = (
          (
            (parseInt(playlistEnd[0]) * 60) +
            (parseInt(playlistEnd[1])) +
            (parseInt(playlistEnd[2]) / 60)
          ) -
          (
            (parseInt(playlistStart[0]) * 60) +
            (parseInt(playlistStart[1])) +
            (parseInt(playlistStart[2]) / 60)
          )
        ) * this.unitWidth

        let item = {}
        item.name = playlist.playlist ? playlist.playlist.name : 'Error: missing playlist'
        item.missingPlaylist = !playlist.playlist
        item.id = `${playlist.order}`
        item.playlistId = playlist.playlist_id
        item.classId = playlist.class_id
        item.displayWidth = duration
        item.width = duration
        listArray.push(item)
        orderArray.push(`${playlist.order}`)
      })

      this.setState({
        list: listArray,
        order: orderArray
      })
    }
  }

  exportClassData() {
    const {
      dispatch,
      fitnessClass,
      updating
    } = this.props

    const {
      list,
      order
    } = this.state

    if (updating) {
      return false
    }

    const classData = []
    let classDuration = 0

    order.forEach((orderId, index) => {
      let listArray = [...list]
      let item = {}

      // get the item from the list that matches the order item
      listArray = listArray.filter((listItem) => {
        return listItem.id === orderId
      })

      item.order = index + 1
      item.playlist_id = listArray[0].playlistId
      item.name = listArray[0].name
      item.start = this.minutesToHHMMSS(classDuration)
      classDuration = classDuration + (listArray[0].width / this.unitWidth)
      item.end = this.minutesToHHMMSS(classDuration)
      classData.push(item)
    })

    const data = {
      classID: fitnessClass.id,
      sections: classData
    }
    dispatch(updateFitnessClass(data))
  }

  debounceExportClassData() {
    this.exportClassData()
  }

  formatNumberToTwoDigits(number) {
    return number.toLocaleString('en-GB', {
      minimumIntegerDigits: 2,
      useGrouping: false
    })
  }

  minutesToHHMMSS(mins) {
    const floorMins = Math.floor(mins)
    const mm = this.formatNumberToTwoDigits(floorMins % 60)
    const hh = this.formatNumberToTwoDigits((floorMins - (floorMins % 60)) / 60)
    const ss = mins % 1 > 0 ? '30' : '00'
    return `${hh}:${mm}:${ss}`
  }

  overlayProps() {
    const {
      list,
      maxDuration
    } = this.state

    return {
      list: list,
      maxDuration: maxDuration,
      addPlaylist: this.addPlaylist,
      totalDuration: this.getPlaylistDuration(),
      unitWidth: this.unitWidth
    }
  }

  openPlaylistOverlay() {
    const { panesWrapper } = this.panesRef
    const rzBase = panesWrapper.querySelector('.__resizable_base__')

    if (rzBase) {
      panesWrapper.removeChild(rzBase)
    }

    this.props.dispatch(toggleOverlay(true, this.overlayProps(),'fitnessTimeline'))
  }

  addPlaylist (selectedPlaylist, duration) {
    const {
      dispatch
    } = this.props

    const {
      list,
      order
    } = this.state

    let listArray = [...list]
    let orderArray = [...order]

    const orderId = `${orderArray.length === 0 ? 1 : parseFloat(listArray[listArray.length-1].id) + 1}`

    orderArray.push(orderId)

    listArray.push(
      {
        name: selectedPlaylist.name,
        id: orderId,
        playlistId: selectedPlaylist.id,
        displayWidth: duration,
        width: duration
      }
    )

    this.setState({
      list: listArray,
      order: orderArray,
      showPlaylistInfo: false
    }, () => {
      this.calculateRemainingTime()
      this.exportClassData()
      dispatch(toggleOverlay(false))
    })
  }

  removePlaylist(playlistInfo) {
    const {
      list,
      order
    } = this.state

    let listArray = [...list]
    let orderArray = [...order]

    clearTimeout(dragTimer)

    listArray = listArray.filter((obj) => {
      return obj.id !== playlistInfo.id
    })

    orderArray = orderArray.filter((value) => {
      return value !== playlistInfo.id
    })

    this.setState({
      list: listArray,
      order: orderArray,
      showPlaylistInfo: false
    }, () => {
      this.calculateRemainingTime()
      this.exportClassData()
    })
  }

  updateDisplayWidth(key, width) {
    const {
      list
    } = this.state

    let listArray = [...list]

    const activeItem = listArray.find(pl => pl.id === key)
    activeItem.displayWidth = width

    this.setState({
      list: listArray,
      showPlaylistInfo: false
    })
  }

  updateWidth(key, width) {
    const {
      list
    } = this.state

    let listArray = [...list]

    const activeItem = listArray.find(pl => pl.id === key)
    activeItem.width = width
    activeItem.displayWidth = width

    this.setState({
      list: listArray
    })
  }

  getPlaylistDuration() {
    const {
      list
    } = this.state

    let total = 0

    list.forEach(function (item) {
      total += item.displayWidth
    })

    return total
  }

  dragStart() {
    dragTimer = setTimeout(() => {
      this.setState({
        wasDrag: true,
        showPlaylistInfo: false
      })
    }, 200)
  }

  dragStop() {
    clearTimeout(dragTimer)
    setTimeout(() => {
      this.setState({
        wasDrag: false
      })
    }, 100)
  }

  clickPane(details, index) {
    const {
      wasDrag,
      showPlaylistInfo,
      playlistInfo
    } = this.state

    if (wasDrag) {
      this.setState({
        wasDrag: false
      })
      return false
    }

    details.index = index

    this.setState({
      showPlaylistInfo: !(showPlaylistInfo && (details.id === playlistInfo.id)),
      playlistInfo: details
    })
  }

  hidePlaylist() {
    clearTimeout(dragTimer)
    this.setState({
      showPlaylistInfo: false
    })
  }

  editDuration(id, add) {
    const {
      list,
      maxDuration
    } = this.state

    let listArray = [...list]

    const activeItem = listArray.find(pl => pl.id === id)

    if (add && (this.getPlaylistDuration() < maxDuration)) {
      activeItem.width = activeItem.width + this.unitWidth/2
      activeItem.displayWidth = activeItem.displayWidth + this.unitWidth/2
    } else if (!add && (activeItem.width >= (this.unitWidth))) {
      activeItem.width = activeItem.width - this.unitWidth/2
      activeItem.displayWidth = activeItem.displayWidth - this.unitWidth/2
    }

    this.setState({
      list: listArray
    }, () => {
      this.calculateRemainingTime()
      this.debounceExportClassData()
    })
  }

  calculateRemainingTime () {
    const {
      maxDuration
    } = this.state

    const remainingTime = maxDuration - this.getPlaylistDuration()

    this.setState({
      remainingTime: remainingTime
    })
  }

  removeMissingPlaylistSections() {
    const {
      list,
      order
    } = this.state

    let listArray = [...list]
    let orderArray = [...order]

    list.forEach((section) => {
      if (section.missingPlaylist) {
        listArray = listArray.filter((obj) => {
          return obj.id !== section.id
        })

        orderArray = orderArray.filter((value) => {
          return value !== section.id
        })
      }
    })

    this.setState({
      list: listArray,
      order: orderArray,
      hasMissingPlaylists: false
    }, () => {
      this.calculateRemainingTime()
      this.exportClassData()
    })
  }

  render(){
    const {
      loading,
      updating
    } = this.props

    const {
      list,
      maxDuration,
      order,
      showPlaylistInfo,
      playlistInfo,
      remainingTime,
      hasMissingPlaylists
    } = this.state

    let panes

    if(list) {
      panes = list.map((item, index) => {
        const isActive = showPlaylistInfo && (playlistInfo.id === item.id)
        const maxWidth = item.width + remainingTime
        return (
          <Pane
            key={item.id}
            className={`${item.width < 20 ? 'fitness-timeline__pane fitness-timeline__pane--no-drag-handle' : 'fitness-timeline__pane'}`}
            size={{
              height: '98px',
              width: item.width
            }}
            style={{
              backgroundColor: isActive ? '#193c52' : '#112938'
            }}
            grid={[this.unitWidth/2, 0]}
            minWidth={this.unitWidth/2}
            maxWidth={maxWidth}
          >
            <FitnessTimelinePlaylist
              item={item}
              key={index}
              index={index}
              isActive={isActive}
              showPlaylistInfo={showPlaylistInfo}
              hidePlaylist={() => {this.hidePlaylist()}}
              removePlaylist={() => {this.removePlaylist(playlistInfo)}}
              playlistInfo={playlistInfo}
              clickPane={() => {this.clickPane(item, index)}}
              editDuration={this.editDuration}
              unitWidth={this.unitWidth}
              updating={updating}
            />
          </Pane>
        )
      })
    }

    return (
      <Fragment>
        {loading ? (
          <Loader/>
        ) : (
          <Container
            classname={classname}
            height="100%"
            maxWidth
            column
          >
            {hasMissingPlaylists ? (
              <div>
                <p>This class has sections that use a deleted playlist. These sections must be removed before the class can be updated.</p>
                <button
                  className='button'
                  onClick={() => { this.removeMissingPlaylistSections() }}
                  type='button'
                >
                  Remove sections?
                </button>
              </div>
            ) : (
              <div className='fitness__button'>
                <button
                  className='button'
                  onClick={() => { this.openPlaylistOverlay() }}
                  type='button'
                >
                  Add playlist
                </button>
              </div>
            )}
            {panes && (
              <div
                className={`
                  fitness-timeline__wrapper
                  ${(hasMissingPlaylists || updating) && 'fitness-timeline__wrapper--disabled'}
                `}
              >
                <FitnessTimelineHeading
                  duration={this.getPlaylistDuration()}
                  maxDuration={maxDuration}
                  unitWidth={this.unitWidth}
                />
                <SortablePane
                  className='fitness-timeline'
                  ref={ref => this.panesRef = ref}
                  direction='horizontal'
                  disableEffect
                  margin={0}
                  order={order}
                  style={{
                    backgroundSize: `100px 100px, 100px 100px, ${this.unitWidth}px 10px, ${this.unitWidth}px 10px`,
                    width: maxDuration,
                    maxWidth: maxDuration
                  }}
                  onOrderChange={order => {
                    this.setState({ order })
                  }}
                  onResize={(e, key, dir, ref, d) => {
                    const activeItem = list.find(pl => pl.id === key)
                    const width = activeItem.width + d.width
                    this.updateDisplayWidth(key, width)
                  }}
                  onResizeStop={(e, key, dir, ref, d) => {
                    const activeItem = list.find(pl => pl.id === key)
                    const width = activeItem.width + d.width
                    this.updateWidth(key, width)
                    this.calculateRemainingTime()
                  }}
                  onDragStart={() => {
                    this.dragStart()
                  }}
                  onDragStop={() => {
                    this.dragStop()
                  }}
                >
                  {panes}
                </SortablePane>
                <p>Total duration: {this.getPlaylistDuration() / this.unitWidth} mins</p>
              </div>
            )}
          </Container>
        )}
      </Fragment>
    )
  }
}

function mapStateToProps(store){
  return {
    fitnessClass:store.fitness.class,
    loading:store.fitness.loading,
    updating:store.fitness.updating
  }
}

export default connect(mapStateToProps)(FitnessTimeline)
