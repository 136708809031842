import Button from 'ui/Button'

const ImporterPlaylists = ({
  getSpotifyPlaylists,
  importPlaylist,
  disabled
}) => {
  return(
    <div className='importer-playlists-container'>
      <p>Select a playlist from your Spotify account.</p>
      <div className='playlists-wrapper'>
        {getSpotifyPlaylists()}
      </div>
      <Button 
        action={()=>importPlaylist()} 
        name='Import playlist' 
        size='block' 
        disabled={disabled}
      />
    </div>
  )
}

export default ImporterPlaylists