import React from 'react'
import { Droppable } from 'react-beautiful-dnd'

const TableBody = (props) => {
  const {
    classname,
    children,
    sortable
  } = props

  if (sortable) {
    return (
        <Droppable droppableId='droppable'>
          {(provided) => {
            return(
              <tbody
                className={`table-body table-body-${classname}`}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
              {children}
              {provided.placeholder}
              </tbody>
            )
          }}
        </Droppable>
    )
  }

  return (
    <tbody className={`table-body table-body-${classname}`}>{children}</tbody>
  )
}

export default TableBody
