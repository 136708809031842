import React, { Component } from 'react'
import { connect } from 'react-redux'

import { copyPlaylist } from 'store/actions/playlistAction'

import Container from 'ui/Container'
import Button from 'ui/Button'
import FormInput from 'ui/FormInput'

class OverlayCopyPlaylist extends Component{

  constructor(props){
    super(props)
    this.state = {
      copyType:[
        {
          name:'copyType',
          display:'Clone',
          value:'clone',
          info:'Receive updates from the Open Ear Team as they update the main playlist.'
        },
        {
          name:'copyType',
          display:'Copy',
          value:'copy',
          info:'A straight copy of the playlist that requires you to update the playlist.'
        }
      ],
      addType:[
        {
          name:'autoAdd',
          display:'Auto',
          value:'auto'
        },
        {
          name:'autoAdd',
          display:'Manual',
          value:'manual'
        }
      ],
      deleteType:[
        {
          name:'autoDelete',
          display:'Auto',
          value:'auto'
        },
        {
          name:'autoDelete',
          display:'Manual',
          value:'manual'
        }
      ],
      selected:{
        copyType:'clone',
        addType:'auto',
        deleteType:'auto'
      },
      playlistName:''
    }
  }

  componentDidMount(){
    this.setState({
      playlistName:`${this.props.data.name} (COPY)`
    })
  }

  copyPlaylist(){
    const {
      playlistName
    } = this.state
    const data = {
      playlist_id:this.props.data.id,
      playlist_type:this.props.data.type,
      playlist_name:playlistName
    }
    this.props.dispatch(copyPlaylist(data))
  }

  selectCopyType(e){
    this.setState({
      selected:{
        ...this.state.selected,
        copyType:e.target.value
      }
    })
  }

  selectAddType(e){
    this.setState({
      selected:{
        ...this.state.selected,
        addType:e.target.value
      }
    })
  }

  selectDeleteType(e){
    this.setState({
      selected:{
        ...this.state.selected,
        addType:e.target.value
      }
    })
  }

  playlistName(e){
    this.setState({
      playlistName:e.target.value
    })
  }

  render(){
    return (
      <Container classname="overlay-copy-playlist" maxWidth height="100%" column>
        <h2>Copy playlist {this.props.data.name}</h2>
        <FormInput
          type="text"
          value={this.state.playlistName}
          change={(e) => this.playlistName(e)}
          label="Rename playlist"
          autocomplete="false"
        />
        {/*
          this.props.data.type !== 6 && <RadioGroup
            action={(e) => this.selectCopyType(e)}
            data={this.state.copyType}
            title="Choose copy type"
            default={this.state.selected.copyType}/>
        */}
        {/*
          this.state.selected.copyType === 'clone' && <RadioGroup
            action={(e) => this.selectAddType(e)}
            data={this.state.addType}
            title="Do you want to automatically add tracks when updated from the parent playlist, or do you want to manually add?"
            default={this.state.selected.addType}/>
        */}
        {/*
          this.state.selected.copyType === 'clone' && <RadioGroup
            action={(e) => this.selectDeleteType(e)}
            data={this.state.deleteType}
            title="Do you want to automatically delete tracks when updated from the parent playlist, or do you want to manually delete?"
            default={this.state.selected.deleteType}/>
        */}
        <Button name="Copy playlist" action={() => this.copyPlaylist()} type="block"/>
      </Container>
    )
  }
}

export default connect()(OverlayCopyPlaylist)
