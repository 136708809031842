const initialState = {
  details:{
    id:null
  },
  user:{},
  settings:{},
  tracks:[],
  blockedTracks: [],
  blockedTracksLoading: [],
  playlists:[],
  schedules:[],
  schedule_template: [],
  announcements:[],
  loading:false,
  loaded:false,
  scheduleLoading:false,
  error:null,
  newPlaylistID: null,
  newInterleaveID: null,
  newInterleaveIDs: [],
  showScheduleCreator: false,
  activePlaylistInfo: null,
  businessID: null,
  venueID: null
}
export default function reducer(state = initialState, action){
  switch (action.type){
    case 'APP_BODY_CLICK':{
      state = {...state,showScheduleCreator:false,activePlaylistInfo:null}
      break
    }
    case 'TOGGLE_SCHEDULE_CREATOR':{
      state = {...state, showScheduleCreator:action.payload,activePlaylistInfo:null}
      break
    }
    case 'TOGGLE_VENUE_INTERLEAVE_RATIO_CHANGER':{
      state = {
        ...state,
        showInterleaveRatioChanger:action.payload
      }
      break
    }
    case 'SET_PLAYLIST_INFO':{
      state = {...state, activePlaylistInfo:action.payload,showScheduleCreator:false}
      break
    }
    case 'CLEAR_VENUE':{
      state = {...state,details:{},tracks:[],playlists:[],schedules:[],announcements:[]}
      break
    }
    case 'FETCH_VENUE_TRACKS_PENDING':{
      state = {...state,loading:true}
      break
    }
    case 'FETCH_VENUE_TRACKS_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_VENUE_TRACKS_FULFILLED':{
      state =  {
        ...state,
        loading:false,
        loaded:true,
        tracks:state.tracks.concat(action.payload.data.tracks)
      }
      break
    }
    case 'FETCH_VENUE_VENUES_PENDING':{
      state = {...state,loading:true}
      break
    }
    case 'FETCH_VENUE_VENUES_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_VENUE_VENUES_FULFILLED':{
      state =  {
        ...state,
        loading:false,
        loaded:true,
        venues:state.venues.concat(action.payload.data.venues)
      }
      break
    }
    case 'FETCH_VENUE_SCHEDULE_PENDING':{
      state = {
        ...state,
        scheduleLoading:true,
        newInterleaveIDs: []
      }
      break
    }
    case 'FETCH_VENUE_SCHEDULE_REJECTED':{
      state = {...state,scheduleLoading:false,error:action.payload}
      break
    }
    case 'FETCH_VENUE_SCHEDULE_FULFILLED':{
      state =  {
        ...state,
        scheduleLoading:false,
        loaded:true,
        schedules:action.payload.data.schedule_template,
        playlists:action.payload.data.playlists,
        businessID:action.payload.data.business_id,
        venueID:action.payload.data.id
      }
      break
    }
    case 'CREATE_VENUE_SCHEDULE_FULFILLED':{
        state = {
        ...state,
        newPlaylistID: {
          index: action.meta,
          id: action.payload.data.res.data.id
        }
      }
      break
    }
    case 'UPDATE_VENUE_SCHEDULE_FULFILLED':{
      state = {
        ...state
      }
      break
    }
    case 'DELETE_VENUE_SCHEDULE_FULFILLED':{
      state = {
        ...state
      }
      break
    }
    case 'CREATE_VENUE_INTERLEAVE_FULFILLED':{
      state = {
        ...state,
        newInterleaveID: {
          index: `${action.meta}` ,
          id: action.payload.data.id
        }
      }
      break
    }
    case 'CREATE_VENUE_INTERLEAVES_FULFILLED':{
      state = {
        ...state,
        newInterleaveIDs: action.payload.data
      }
      break
    }
    case 'UPDATE_VENUE_INTERLEAVE_RATIOS_FULFILLED':{
      state = {
        ...state,
        newInterleaveIDs: action.payload.data
      }
      break
    }
    case 'DELETE_VENUE_INTERLEAVE_FULFILLED':{
      state = {
        ...state
      }
      break
    }
    case 'FETCH_VENUE_CALENDAR_PENDING':{
      state = {...state,scheduleLoading:true}
      break
    }
    case 'FETCH_VENUE_CALENDAR_REJECTED':{
      state = {...state,scheduleLoading:false,error:action.payload}
      break
    }
    case 'FETCH_VENUE_CALENDAR_FULFILLED':{
      // if the request has been cancelled by another request,
      // set the loading state to true
      if (action.payload === 'cancelled') {
        state = {
          ...state,
          scheduleLoading:true
        }
        break
      }

      state =  {
        ...state,
        scheduleLoading: false,
        loaded: true,
        schedules: action.payload.data.schedule_calendar,
        schedule_template: action.payload.data.schedule_template,
        playlists: action.payload.data.playlists,
        businessID: action.payload.data.business_id
      }
      break
    }
    case 'CREATE_VENUE_CALENDAR_FULFILLED':{
      state = {
        ...state,
        newPlaylistID: {
          index: action.meta,
          id: action.payload.data.id
        }
      }
      break
    }
    case 'CREATE_CALENDAR_INTERLEAVE_FULFILLED':{
      state = {
        ...state,
        newInterleaveID: {
          index: `${action.meta}` ,
          id: action.payload.data.id
        }
      }
      break
    }
    case 'CREATE_CALENDAR_INTERLEAVES_FULFILLED':{
      state = {
        ...state,
        newInterleaveIDs: action.payload.data,
      }
      break
    }
    case 'UPDATE_CALENDAR_INTERLEAVE_RATIOS_FULFILLED':{
      state = {
        ...state,
        newInterleaveIDs: action.payload.data
      }
      break
    }

    case 'FETCH_VENUE_ANNOUNCEMENTS_PENDING':{
      state = {...state,loading:true}
      break
    }
    case 'FETCH_VENUE_ANNOUNCEMENTS_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_VENUE_ANNOUNCEMENTS_FULFILLED':{
      state =  {
        ...state,
        loading:false,
        loaded:true,
        announcements:state.schedules.concat(action.payload.data.schedule_template),
        playlists:state.playlists.concat(action.payload.data.playlists),
      }
      break
    }
    case 'FETCH_VENUE_DETAILS_REJECTED':{
      state = {...state,error:action.payload}
      break
    }
    case 'FETCH_VENUE_DETAILS_FULFILLED':{
      const {data} = action.payload
      state =  {
        ...state,
        details:{
          ...state.details,
          id:data.id,
          name:data.name,
          contact_name:data.contact_name,
          contact_number:data.contact_number,
          address_line_1:data.address_line_1,
          address_line_2:data.address_line_2,
          town:data.town,
          postcode:data.postcode
        },
        venueID: data.id
      }
      break
    }
    case 'FETCH_VENUE_USER_PENDING':{
      state = {...state,loading:true}
      break
    }
    case 'FETCH_VENUE_USER_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_VENUE_USER_FULFILLED':{
      const {data} = action.payload
      state =  {
        ...state,
        loading:false,
        loaded:true,
        user:{
          ...state.user,
          id:data.id,
          name:data.name,
          username:data.user[0].username,
          loginHash:data.user[0].login_hash
        }
      }
      break
    }
    case 'FETCH_VENUE_SETTINGS_PENDING':{
      state = {...state,loading:true}
      break
    }
    case 'FETCH_VENUE_SETTINGS_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_VENUE_SETTINGS_FULFILLED':{
      const {settings} = action.payload.data
      state =  {
        ...state,
        loading:false,
        loaded:true,
        settings:{
          ...state.settings,
          file_size:settings.file_size
        }
      }
      break
    }
    case 'UPDATE_VENUE_USER_PENDING':{
      state = {...state,loading:true}
      break
    }
    case 'UPDATE_VENUE_USER_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'UPDATE_VENUE_USER_FULFILLED':{
      state =  {
        ...state,
        loading:false,
        loaded:true
      }
      break
    }
    case 'UPDATE_VENUE_DETAILS_PENDING':{
      state = {...state,loading:true}
      break
    }
    case 'UPDATE_VENUE_DETAILS_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'UPDATE_VENUE_DETAILS_FULFILLED':{
      const data = action.meta
      state =  {
        ...state,
        loading:false,
        loaded:true,
        details:{
          ...state.details,
          name:data.name,
          contact_name:data.contact_name,
          contact_number:data.contact_number,
          address_line_1:data.address_line_1,
          address_line_2:data.address_line_2,
          town:data.town,
          postcode:data.postcode
        },
        venueID: data.id
      }
      break
    }
    case 'FETCH_VENUE_PLAYLISTS_PENDING':{
      state = {...state,loading:true,playlists:[]}
      break
    }
    case 'FETCH_VENUE_PLAYLISTS_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_VENUE_PLAYLISTS_FULFILLED':{
      const playlists = action.payload.data.map((playlist)=>{
        const data = {
          id:playlist.id,
          name:playlist.name,
          tracks_count:playlist.tracks_count
        }
        return data
      })
      state =  {
        ...state,
        loading:false,
        loaded:true,
        playlists:playlists
      }
      break
    }
    case 'ADD_PLAYLIST_TO_VENUE_FULFILLED':{
      const playlist = action.meta.playlist
      playlist.tracks_count = action.meta.playlist.tracksCount
      state = {
        ...state,
        playlists:state.playlists.concat(playlist)
      }
      break
    }
    case 'REMOVE_PLAYLIST_FROM_VENUE_FULFILLED':{
      state = {
        ...state,
        playlists:state.playlists.filter(playlist => playlist.id !== action.meta.playlist.id)
      }
      break
    }
    case 'FETCH_VENUE_CONTROLS_PENDING':{
      state = {...state,loading:true,loaded:false}
      break
    }
    case 'FETCH_VENUE_CONTROLS_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_VENUE_CONTROLS_FULFILLED':{
      state =  {...state,loading:false,loaded:true,data:{...action.payload.data}}
      break
    }
    case 'BLOCK_TRACK_PENDING':{
      state = {
        ...state,
        blockedTracksLoading: state.blockedTracksLoading.concat(action.meta)
      }
      break
    }
    case 'BLOCK_TRACK_REJECTED':{
      state = {
        ...state,
        blockedTracksLoading: state.blockedTracksLoading.filter(trackID => trackID !== action.meta),
        error:action.payload
      }
      break
    }
    case 'BLOCK_TRACK_FULFILLED':{
      state =  {
        ...state,
        blockedTracksLoading: state.blockedTracksLoading.filter(trackID => trackID !== action.meta),
        blockedTracks: state.blockedTracks.concat({track_id: parseInt(action.payload.data.blocked_track_id)})
      }
      break
    }
    case 'DELETE_BLOCKED_TRACKS_PENDING':{
      state = {
        ...state
      }
      break
    }
    case 'DELETE_BLOCKED_TRACKS_REJECTED':{
      state = {
        ...state,
        error:action.payload
      }
      break
    }
    case 'DELETE_BLOCKED_TRACKS_FULFILLED':{
      state =  {
        ...state,
        blockedTracks:state.blockedTracks.filter(track => track.blocked_id !== action.meta)
      }
      break
    }
    case 'FETCH_BLOCKED_TRACKS_PENDING':{
      state = {
        ...state,
        blockedTracks:[]
      }
      break
    }
    case 'FETCH_BLOCKED_TRACKS_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_BLOCKED_TRACKS_FULFILLED':{

      const tracks = action.payload.data.map((blocked)=>{
        const data = {
          blocked_id:blocked.id,
          id:blocked.track ? blocked.track.id : null,
          title:blocked.track ? blocked.track.title : 'TRACK REMOVED FROM LIBRARY',
          artist:blocked.track ? blocked.track.artist : '',
          album:blocked.track ? blocked.track.album : '',
          genres:blocked.track ? blocked.track.genres : '',
          total_length:blocked.track ? blocked.track.total_length : '',
          energy:blocked.track ? blocked.track.energy : '',
          bpm:blocked.track ? blocked.track.bpm : '',
          added:blocked.created_at.standard,
          addedMysql:blocked.created_at.mysql,
        }
        return data
      })

      state =  {
        ...state,
        blockedTracks: tracks
      }
      break
    }
    case 'LOGOUT':{
      state = {
        ...state,
        details:{
          name:null
        },
        tracks:[],
        blockedTracks:[],
        blockedTracksLoading:[],
        playlists:[],
        schedules: [],
        loading:false,
        loaded:false,
        error:null
      }
      break
    }
    default: //no default case
  }
  return state
}
