import React from 'react'

import {Link} from 'react-router-dom'

import Icon from 'ui/Icon'
import Tooltip from 'ui/Tooltip'

const PlaylistTableRowActions = (props) => {
  const {id, removePlaylist, playlist} = props
  return (
    <div>
      <Link to={`/playlist/${id}?tab=details`}>
        <Icon name="eye">
          <Tooltip pos="left" text="View playlist"/>
        </Icon>
      </Link>
      <Icon name="remove-circle" action={()=>removePlaylist(playlist)}>
        <Tooltip pos="left" text="Remove playlist from venue"/>
      </Icon>
    </div>
  )
}

export default PlaylistTableRowActions
