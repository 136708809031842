import React from 'react'

import Filter from 'ui/Filter'
import Icon from 'ui/Icon'
import Badge from 'ui/Badge'
import Tooltip from "ui/Tooltip"

const PlaylistTracksTableAction = (props) => {

  const {
    classname,
    action,
    placeholder,
    removeTracks,
    addToQueue,
    metadata,
    active,
    getBreakdown,
    count,
    addTracksToPlaylist,
    deselectTracks,
    numSelected,
    removeDuplicates
  } = props

  return (
    <div className={`table-actions table-actions-${classname}`}>
      <div className="table-actions-left">
        <Filter placeholder={placeholder} action={(e)=>action(e)}/>
      </div>
      <div className={`table-actions-right ${active ? 'active' : ''}`}>
        {
          numSelected > 0 &&
          <div className="selected-indicator">{numSelected} selected</div>
        }
        <Icon name="close-circle-outline" action={()=>deselectTracks()}>
          <Tooltip text="Deselect tracks" pos="left"/>
        </Icon>
        <Icon name="add-circle" action={()=>addTracksToPlaylist()}>
          <Tooltip text="Add tracks to playlist" pos="left"/>
        </Icon>
        <Icon name="ios-list-box" action={()=>addToQueue()}/>
        <Icon name="ios-arrow-dropright-circle" action={()=>removeTracks()}>
          <Tooltip text="Remove selected tracks" pos="left"/>
        </Icon>
      </div>
      <div className={`metadata`}>
        {count ? <Badge data={`Total ${count}`}/> : ''}
        {metadata && metadata.lowestEnergy ? <Badge data={`Lowest energy ${metadata.lowestEnergy}`}/> : ''}
        {metadata && metadata.highestEnergy ? <Badge data={`Highest energy ${metadata.highestEnergy}`}/> : ''}
        {metadata && metadata.energy ? <Badge data={`Avg. energy ${metadata.energy}`}/> : ''}
        {metadata && metadata.total_length ? <Badge data={`Length ${metadata.total_length}`}/> : ''}
        {metadata && metadata.new ? <Badge data={`${metadata.new} New`}/> : ''}
        <Badge action={()=>getBreakdown(metadata)} data="Get breakdown"/>
        { metadata && 
          metadata.duplicates ? 
          <Badge action={()=>removeDuplicates()} type="error" data={`Remove ${metadata.duplicates} duplicates`}/> : 
          null
        }
      </div>
    </div>
  )
}

export default PlaylistTracksTableAction