import React, { Component } from 'react';

import {
  forceCollide,
  forceSimulation,
} from 'd3-force'

import {
  select,
  selectAll
} from 'd3-selection'

import boundary from 'd3-force-boundary/src/boundary';

class BubbleChart extends Component {
  constructor(props){
    super(props);

    this.renderBubbles = this.renderBubbles.bind(this);
  }

  componentDidMount() {
    this.renderBubbles();
  }

  renderBubbles() {
    const {
      bubbleFunc,
      bubbleScale,
      data,
      height,
      padding,
      width
    } = this.props

    const bubbleChart = select('#bubble-chart g')
      .selectAll('g')
      .data(data);

    bubbleChart.exit().remove();

    const node = bubbleChart.enter()
      .append('g')
      .attr('class', 'bubble-chart__bubble')
      .merge(bubbleChart)

    node.append('circle')
      .attr('r', (d) => {
        return d.value * bubbleScale;
      })

    node.append('text')
      .attr('class', 'bubble-chart__bubble-text')
      .style('font-size', (d) => {
        return d.value < 40 ? '11px' : `16px`
      })
      .text((d) => {
        return d.label;
      });

    selectAll('.bubble-chart__bubble-text')
      .attr('x', function(d) {
        const self = select(this);
        const width = self.node().getBBox().width;
        return -(width/2);
      })
      .attr('y', (d) => {
        return d.value < 40 ? 11/2 : 16/2
      })

    selectAll('.bubble-chart__bubble')
      .on('click', function (d) {
        const selNode = select(this)
        bubbleFunc(selNode, d.label, d.id, d.type)
      });

    node.exit().remove();

    forceSimulation(data)
      .force('collision', forceCollide().radius((d) => {
        return d.value * (bubbleScale + (padding/100));
      }))
      .force('boundary', boundary(0,0, width,height).hardBoundary(true))
      .on('tick', () => {
        node.attr('transform', (d) => { return 'translate(' + d.x + ',' + d.y + ')'; })
      });
  }

  render() {
    const {
      height,
      width
    } = this.props

    return (
      <svg
        id='bubble-chart'
        viewBox={`0 0 ${width} ${height}`}
      >
        <g transform='translate(0, 75)'/>
      </svg>
    )
  }
}

export default BubbleChart
