import React, { Component } from 'react'
import { connect } from 'react-redux'

import { toggleOverlay } from 'store/actions/overlayAction'

import OverlayDefault from './overlay/OverlayDefault'
import OverlayVenues from './overlay/OverlayVenues'
import OverlayVenueCopy from './overlay/OverlayVenueCopy'
import OverlayVenueInterleaveCopy from './overlay/OverlayVenueInterleaveCopy'
import OverlayVenueScheduleCopy from './overlay/OverlayVenueScheduleCopy'
import OverlayVenueCalendarCopy from './overlay/OverlayVenueCalendarCopy'
import OverlayFitnessTimeline from './overlay/OverlayFitnessTimeline'
import OverlayPlaylistsMore from './overlay/OverlayPlaylistsMore'
import OverlayPlaylistsAddTrack from './overlay/OverlayPlaylistsAddTrack'
import OverlayMultiPlaylistsLibrary from './overlay/OverlayMultiPlaylistsLibrary'
import OverlayAnnouncements from './overlay/OverlayAnnouncements'
import OverlayPlaylistBreakdown from './overlay/OverlayPlaylistBreakdown'
import OverlayPlaylistTracksMore from './overlay/OverlayPlaylistTracksMore'
import OverlaySubscribedPlaylistToVenues from './overlay/OverlaySubscribedPlaylistToVenues'
import OverlayFitnessVenues from './overlay/OverlayFitnessVenues'
import OverlayCopyPlaylist from './overlay/OverlayCopyPlaylist'
import OverlayImporter from './overlay/OverlayImporter'
import OverlayImporterCreatePlaylist from './overlay/OverlayImporterCreatePlaylist'

import Container from 'ui/Container'
import Loader from 'ui/Loader'
import Icon from 'ui/Icon'

class Overlay extends Component{

  constructor(props){
    super(props)
    this.overlayComps = {
      default:{comp:OverlayDefault,size:'normal'},
      venues:{comp:OverlayVenues,size:'normal'},
      venueCopy:{comp:OverlayVenueCopy,size:'tall'},
      venueInterleaveCopy:{comp:OverlayVenueInterleaveCopy,size:'tall'},
      venueScheduleCopy:{comp:OverlayVenueScheduleCopy,size:'tall'},
      venueCalendarCopy:{comp:OverlayVenueCalendarCopy,size:'tall'},
      fitnessTimeline:{comp:OverlayFitnessTimeline,size:'normal'},
      playlistsMore:{comp:OverlayPlaylistsMore,size:'normal'},
      announcements:{comp:OverlayAnnouncements,size:'normal'},
      breakdown:{comp:OverlayPlaylistBreakdown,size:'normal'},
      playlistsAddTrack:{comp:OverlayPlaylistsAddTrack,size:'normal'},
      multiPlaylistsAdd:{comp:OverlayMultiPlaylistsLibrary,size:'normal'},
      playlistTracksMore:{comp:OverlayPlaylistTracksMore,size:'normal'},
      subscribedPlaylistToVenues:{comp:OverlaySubscribedPlaylistToVenues,size:'normal'},
      fitnessVenues:{comp:OverlayFitnessVenues,size:'normal'},
      copyPlaylist:{comp:OverlayCopyPlaylist,size:'auto-height'},
      importer:{comp:OverlayImporter,size:'importer'},
      importerCreatePlaylist:{comp:OverlayImporterCreatePlaylist,size:'large'},
    }
  }

  componentDidMount(){

  }


  componentDidUpdate(prevProps){
    //set close overlay if already active
    /*if(nextProps.data.isActive){
      setTimeout(()=>{
        this.props.dispatch(toggleOverlay(false))
      },nextProps.data.time);
    }*/
  }

  closeOverlay(){
    this.props.dispatch(toggleOverlay(false))
  }

  stopPropogation(e){
    e.stopPropagation()
  }

  getOverlay(){
    if(this.props.loading){
      return <div className="overlay-loader-wrapper"><Loader/></div>
    } else {
      //create default comp for first load
      const Comp = this.overlayComps[this.props.overlay.type || 'default'].comp
      return <Comp data={this.props.overlay.data}/>
    }
  }

  render(){
    const {isActive} = this.props.overlay
    const className = `overlay-wrapper ${isActive ? 'active' : ''} ${this.overlayComps[this.props.overlay.type || 'default'].size}`
    return (
      <div className={className} onClick={()=>this.closeOverlay()}>
        <Container classname="overlay" action={(e)=>this.stopPropogation(e)}>
          <span className="overlay-close" onClick={()=>this.closeOverlay()}>
            CLOSE <Icon name="close"/>
          </span>
          {this.getOverlay()}
        </Container>
      </div>
    )
  }

}

function mapStateToProps(store){
  return {
    overlay:store.overlay,
    loading:store.overlay.loading
  }
}

export default connect(mapStateToProps)(Overlay)
