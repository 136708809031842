import React from 'react'
import {Link} from 'react-router-dom'

import Icon from 'ui/Icon'
import Tooltip from 'ui/Tooltip'
import TableModel from 'models/TableModel'

const PlaylistsAssignedTableRowActions = (props) => {
  const {
    classname,
    playlist,
    addPlaylistToVenues,
    copyPlaylist
  } = props

  return (
    <div>
      <Link to={`${TableModel[classname].viewLink}/${playlist.id}?tab=tracks`}>
        <Icon name="eye">
          <Tooltip pos="left" text="View playlist."/>
        </Icon>
      </Link>
      <Icon name="copy" action={() => copyPlaylist(playlist)}>
        <Tooltip pos="left" text="Copy playlist."/>
      </Icon>
      <Icon
        name='add-circle-outline'
        action={()=>addPlaylistToVenues(playlist)}
        stopPropagation>
        <Tooltip pos="left" text='Add/remove playlist to/from venues' />
      </Icon>
    </div>
  )
}

export default PlaylistsAssignedTableRowActions
