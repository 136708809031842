import React, { Component } from 'react'

import Icon from 'ui/Icon'
import Container from 'ui/Container'
import Tooltip from 'ui/Tooltip'
import Loader from 'ui/Loader'

export default class VenueLastPlayed extends Component{
  likeTrack(track){
    this.props.likeTrack(track)
  }
  blockTrack(track){
    this.props.blockTrack(track)
  }
  render(){
    const {
      data,
      blockedTracks,
      blockedTracksLoading
    } = this.props

    const mappedTracks = data.map(
      (track,index)=>{
        const isBlocked = blockedTracks && blockedTracks.some(blockedTrack => blockedTrack.track_id === track.id)
        const isBlockedLoading = blockedTracksLoading && blockedTracksLoading.some(blockedTrackID => blockedTrackID === track.id)

        return (
          <div
            className={`list-item track-item ${isBlocked ? 'list-item--blocked' : ''}`}
            key={index}
          >
            {/*<div className="track-actions">
              <span className="icon ion-thumbsup" onClick={()=>this.likeTrack(track)}></span>
            </div>*/}
            <div className="track">{track.title} <i>by</i> {track.artist} @{track.time}</div>
            {isBlocked || (
              <div className="track-actions">
                {isBlockedLoading ? (
                  <div className='list-item__loader'>
                    <Loader />
                  </div>
                ) : (
                <Icon name="close-circle" action={()=>this.blockTrack(track)}>
                  <Tooltip text="Block track" pos="left"/>
                </Icon>
                )}
              </div>
            )}
          </div>
        )
      }
    )
    return (
      <Container classname="last-played" column maxHeight>
        <h2>Last played</h2>
        {mappedTracks}
      </Container>
    )
  }
}
