import React, { Component } from 'react'

import Icon from 'ui/Icon'
import Loader from 'ui/Loader'
import { debounce } from 'helpers/Debounce'

import TypeaheadModel from 'models/TypeaheadModel'

class ScheduleTypeahead extends Component{
  constructor(props){
    super(props)
    //https://blog.revathskumar.com/2016/02/reactjs-using-debounce-in-react-components.html
    this.sendResponse = debounce(this.sendResponse,TypeaheadModel[this.props.classname].debounce)
    this.typeaheadInput = React.createRef()
    this.typeaheadResultsRef = []
    this.state = {
      typeaheadInput:'',
      term:'',
      results:[],
      cursor:-1,
      cursorSelected:null
    }
  }

  componentDidMount(){
    this.typeaheadInput.current.focus()
  }

  componentDidUpdate(prevProps,prevState){
    const {
      data,
      interleaveValue,
      isInterleave
    } = this.props

    if(prevProps.data !== data){
      this.displayResults()
    }
    if(prevState.cursor !== this.state.cursor){
      //scroll results to show selected result. Not compatible with all browsers
      //https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoViewIfNeeded
      this.typeaheadResultsRef[this.state.cursorSelected.id].current.scrollIntoViewIfNeeded(false)
      this.displayResults()
    }

    if(isInterleave && (prevProps.interleaveValue !== interleaveValue)) {
      this.setState({
        term: interleaveValue,
        results: []
      })
    }
  }

  handleOnChange(e){
    const { value } = e.target
    this.setState({term:value},()=>{
      this.sendResponse(value)
    })
  }

  handleKeyDown(e){
    const {
      data,
      search
    } = this.props

    const { cursor, cursorSelected } = this.state

    // arrow up/down button should select next/previous list element
    // up arrow
    if (e.key === 'ArrowUp' && cursor > 0) {
      this.setState( prevState => ({
        cursor: prevState.cursor - 1,
        cursorSelected: data[prevState.cursor - 1]
      }))
    //down arrow
    } else if (e.key === 'ArrowDown' && cursor < data.length - 1) {
      this.setState( prevState => ({
        cursor: prevState.cursor + 1,
        cursorSelected: data[prevState.cursor + 1]
      }))
    //on enter if selected via cursor
    } else if(e.key === 'Enter' && cursorSelected){
      this.sendAction(cursorSelected)
    } else if(e.key === 'Enter' && this.typeaheadInput.current.value.length > 0){
      search(this.typeaheadInput.current.value)
    }

  }

  clearTypeahead(){
    const {
      action,
      isInterleave
    } = this.props

    this.setState({results:[]},()=>{
      this.typeaheadInput.current.value = ''
      if (isInterleave) {
        const nullData = {
          id: '',
          name: ''
        }

        this.setState({
          term: ''
        })
        action(nullData)
      }
    })
  }

  sendResponse(value){
    const {
      getData
    } = this.props

    if(this.typeaheadInput.current.value.length > 0){
      getData(value)
    } else {
      this.clearTypeahead()
    }
  }

  sendAction(datum){
    const {
      action,
      isInterleave
    } = this.props

    if (isInterleave) {
      this.setState({results:[]})
    } else {
      this.clearTypeahead()
    }

    action(datum)
  }

  displayResults(){
    const {
      classname,
      data
    } = this.props

    const {
      cursor
    } = this.state

    let results = []

    if(data && data.length > 0){
      results = data.map((datum,index)=>{
        this.typeaheadResultsRef[datum.id] = React.createRef()
        return <div
          ref={this.typeaheadResultsRef[datum.id]}
          onClick={() => this.sendAction(datum)}
          key={index}
          className={`typeahead-result ${index === cursor ? 'typeahead-active' : ''}`}>
          {datum[TypeaheadModel[classname].field]}
        </div>
      })
      this.setState({results:results})
    }
  }

  render(){
    const {
      classname,
      loading
    } = this.props

    const {
      results,
      term
    } = this.state

    return (
      <div className={`typeahead-wrapper typeahead-wrapper-${classname}`}>
        <Icon name="ios-search" classname="typeahead"/>
        <input
          ref={this.typeaheadInput}
          className={`typeahead-input typeahead-input-${classname}`}
          onChange={(e)=>this.handleOnChange(e)}
          onKeyDown={(e)=>this.handleKeyDown(e)}
          placeholder={TypeaheadModel[classname].placeholder}
          value={term}
        />
        <Icon
          name="ios-close"
          action={()=>this.clearTypeahead()}
          classname={`typeahead-clear ${this.typeaheadInput.current && this.typeaheadInput.current.value.length > 0 ? 'active' : ''}`}
        />
        <div className={`
          typeahead-results
          typeahead-results-${classname}
          ${results.length > 0 ? 'typeahead-results-active' : ''}`}>
          {loading ? (
            <div className='typeahead-result'>
              <Loader/>
              Loading
            </div>
          ) :
            results
          }
        </div>
      </div>
    )
  }
}

export default ScheduleTypeahead
