const initalState = {
  type:null,
  isActive:false,
  message:null
}

export default function reducer(state = initalState,action){
  switch (action.type){
    case 'OPEN_MESSAGE_OVERLAY':{
      state = {
        ...state,
        isActive:true,
        type:action.payload.type,
        message:action.payload.message
      }
      break
    }
    case 'DELETE_PLAYLIST_TRACK_PENDING':{
      state = {...state,loading:true}
      break
    }
    case 'CREATE_PLAYLIST_FULFILLED':{
      state = {
        ...state,
        type:'saved',
        isActive:true
      }
      break
    }
    case 'CREATE_VENUE_FULFILLED':{
      state = {
        ...state,
        type:'saved',
        isActive:true
      }
      break
    }
    case 'ADD_PLAYLIST_TO_VENUE_FULFILLED':
    case 'REMOVE_PLAYLIST_FROM_VENUE_FULFILLED':{
      state = {
        ...state,
        type:'saved',
        isActive:true
      }
      break
    }
    case 'CLOSE_MESSAGE_OVERLAY':{
      state = {
        ...state,
        isActive:false
      }
      break
    }
    case 'ADD_PLAYLIST_TRACK_OWNED_FULFILLED':
    case 'ADD_PLAYLIST_TRACK_FULFILLED':
    case 'DELETE_PLAYLIST_TRACK_LIBRARY_FULFILLED':{
      state = {
        ...state,
        type:'saved',
        isActive:true
      }
      break
    }
    default: //no default case
  }
  return state
}
