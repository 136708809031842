const TabsModel = {
  playlists:[
    {name:'Dashboard',link:'dashboard'},
    {name:'Open Ear playlists',link:'main'},
    {name:'My playlists',link:'owned'},
    {name:'Subscribed',link:'subscribed'},
    {name:'Assigned',link:'assigned'},
    //{name:'Importer',link:'importer'},
    {name:'Create',link:'create'}
  ],
  fitnessClasses:[
    {name:'My classes',link:'classes'},
    {name:'Create',link:'create'}
  ],
  fitnessClass:[
    {name:'Timeline',link:'timeline'},
    {name:'Venues',link:'venues'}
  ],
  overlayPlaylistsLibrary:[
    {name:'Assigned',link:'assigned'},
    {name:'Pinned',link:'pinned'},
    {name:'Recent',link:'recent'},
    {name:'Owned',link:'owned'},
    {name:'Curating',link:'curating'},
    {name:'Admin',link:'admim'},
    {name:'Special',link:'special'},
    {name:'Child',link:'child'},
    {name:'Importer',link:'importer'},
    {name:'Search',link:'search'}
  ],
  playlist:[
    {name:'Details',link:'details'},
    //{name:'Settings',link:'settings'},
    {name:'Tracks',link:'tracks'},
    {name:'Library',link:'library'},
    {name:'Order',link:'order'},
    {name:'Venues',link:'venues'},
    //{name:'Children',link:'children'},
    //{name:'Curators',link:'curators'},
    //{name:'History',link:'history'}
  ],
  venues:[
    {name:'All',link:'all'},
    {name:'Create',link:'create'}
  ],
  venue:[
    {name:'Details',link:'details'},
    {name:'Playlists',link:'playlists'},
    {name:'Schedule',link:'schedule'},
    {name:'Blocked tracks',link:'blocked'},
    {name:'Control',link:'control'},
    {name:'User',link:'user'},
  ],
  library:[
    {name:'All',link:'all'},
    // {name:'Advanced search',link:'advsearch'},
    //{name:'Favourites',link:'favourites'},
    //{name:'Queue',link:'queue'}
  ],
  businesses:[
    {name:'All',link:'all'},
    {name:'Create',link:'create'}
  ],
  queue:[
    {name:'Queue',link:'queue'},
    {name:'History',link:'history'},
    {name:'Recommendations',link:'recommendations'}
  ]
}
export default TabsModel
