import React, { Fragment } from 'react'
import { Draggable } from 'react-beautiful-dnd'

import TableCell from './TableCell'
import TableCellLink from './TableCellLink'
import TableCellEnergy from './TableCellEnergy'
import LibraryTableCell from 'modules/library/LibraryTableCell'
import Icon from 'ui/Icon'

import TableModel from 'models/TableModel'

const TableRow = (props) => {

  const {
    data,
    classname,
    play,
    size,
    checkboxAction,
    selected,
    index,
    libraryAction,
    playingTrack,
    sortable,
    restricted
  } = props

  const isSelected = playingTrack && (playingTrack.id === data.id)
  const currentlyPlaying = isSelected && playingTrack.isPlaying

  const classes = `table-row table-row-${classname} table ${isSelected ? 'isPlaying' : ''}`
  let isPlaying = false

  const playTrack = (track) => {
    isPlaying = !isPlaying
    play(track)
  }

  let cells = TableModel[classname]['headers'].map((header,index)=>{
    if(header.field === 'play'){
      return (
        <td key="play" className={`table-cell table-cell-play table-cell-${classname}`}>
          <Icon name={`ios-${currentlyPlaying ? 'volume-high' : 'play'}`} action={()=>playTrack(data)}/>
        </td>
      )
    } else if(header.field === 'energy') {
      return <TableCellEnergy key={index} datum={data[header.field]} classname={classname} size={header.size}/>
    } else if(header.link) {
      return <TableCellLink
        key={index}
        datum={data[header.field]}
        field={header.field}
        classname={classname}
        size={header.size}/>

    } else if(header.libraryAction){

      return restricted ?
        <TableCell
        key={index}
        datum={data[header.field]}
        classname={classname}
        size={header.size}/>
        : <LibraryTableCell
        key={index}
        datum={data[header.field]}
        field={header.field}
        classname={classname}
        size={header.size}
        libraryAction={(data) => libraryAction(data)}
        />

    } else {
      return <TableCell key={index} datum={data[header.field]} classname={classname} size={header.size}/>
    }
  })
  //if has play
  if(TableModel[classname].play){
    cells.unshift(
      <td key="play" className={`table-cell table-cell-play table-cell-${classname}`}>
        <Icon name={`ios-${currentlyPlaying ? 'volume-high' : 'play'}`} action={()=>playTrack(data)}/>
      </td>
    )
  }
  //if the table has a checkbox
  if(TableModel[classname].checkbox){
    //what a fuckabout this was. selected state held in parent component and passed down to the table wrapper
    //value and name set to stop connected/unconnected problem
    cells.unshift(
      <td key="checkbox" className={`table-cell table-cell-checkbox table-cell-${classname}`}>
        <input value={index || ''} name={index || ''} type="checkbox" onChange={(e)=>checkboxAction(e,data)} checked={selected || false}/>
      </td>
    )
  }

  const tableRowContent = (isDragging) => {
    return (
      <Fragment>
        {cells}
        <td className={`table-cell table-cell-actions table-cell-actions-${classname} table-cell-actions-${size}`}>
          {props.children || props.action}
        </td>
      </Fragment>
    )
  }


  const getItemStyle = (isDragging, draggableStyle) => ({
    // styles we need to apply on draggables
    ...draggableStyle
  })

  if (sortable) {
    return (
      <Draggable
        key={index}
        index={index}
        draggableId={`${index}--draggable`}
        style={getItemStyle()}
      >
        {(provided, snapshot) => (
          <tr
            className={`${classes} ${snapshot.isDragging ? 'table-row--is-dragging' : ''}`}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            {tableRowContent()}
          </tr>
        )}
      </Draggable>
    )
  }

  return (
    <tr className={classes} onDoubleClick={typeof play === 'function' ? ()=>playTrack(data) : null}>
      {tableRowContent()}
    </tr>
  )
}

export default TableRow
