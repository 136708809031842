import axios from 'axios'
import {
  getLocalStorage
} from '../../helpers/LocalStorage'
import {
  deleteQueue,
  getQueue,
  removeFromQueue
} from 'helpers/QueueHelper'

export const getTrackInfo = (track, trackType) => {
  return (dispatch) => {
    return dispatch({
      type:'FETCH_TRACK_INFO',
      meta: {
        track: track,
        trackType: trackType
      }
    })
  }
}
export const getTrackUrl = (track, trackType) => {

  const playback_option = getLocalStorage('playback_option')

  let source = 'small'
  if(playback_option){
    source = playback_option === 3 ? 'stereo' : playback_option === 2 ? 'standard' : 'small'
  }

  return (dispatch) => {
    return dispatch({
      type:'FETCH_TRACK',
      payload:axios.get(`track/source/${source}/${track.id}`),
      meta: {
        trackId: track.id,
        trackType: trackType
      }
    })
  }
}
export const pauseTrack = (track) => {
  return (dispatch) => {
    return dispatch({
      type:'PAUSE_TRACK',
      payload:track
    })
  }
}
export const loadTrack = (track) => {
  return (dispatch) => {
    return dispatch({
      type:'LOAD_TRACK',
      payload:track
    })
  }
}

export const playPauseTrack = (isPlaying) => {
  return (dispatch) => {
    return dispatch({
      type:'PLAY_PAUSE_TRACK',
      payload:isPlaying
    })
  }
}

export function toggleQueueOverlay(isOpen) {
  return (dispatch) => {
    dispatch({
      type: 'TOGGLE_QUEUE_OVERLAY',
      payload: isOpen
    })
  }
}

export function toggleQueueLoop(loop) {
  return (dispatch) => {
    dispatch({
      type: 'TOGGLE_QUEUE_LOOP',
      payload: loop
    })
  }
}

export function fetchQueue() {
  return (dispatch) => {
    dispatch({
      type: 'FETCH_QUEUE',
      payload: getQueue()
    })
  }
}

export function deleteFromQueue(track) {
  return (dispatch) => {
    dispatch({
      type: 'DELETE_FROM_QUEUE',
      payload: removeFromQueue(track),
      meta: track
    })
  }
}

export function deleteAllFromQueue() {
  return (dispatch) => {
    dispatch({
      type: 'DELETE_ALL_FROM_QUEUE',
      payload: deleteQueue()
    })
  }
}

export const addAllToPlaylist = (queue) => {
  return (dispatch) => {
    return dispatch({
      type:'ADD_ALL_TO_PLAYLIST',
      payload: queue
    })
  }
}
