import React, {Component} from 'react'

import Icon from 'ui/Icon'

class Filter extends Component{
  constructor(props){
    super(props)
    this.filterInput = React.createRef()
  }

  componentDidMount(){
    this.filterInput.current.focus()
  }

  render (){
    const { placeholder, action } = this.props
    return (
      <div className="filter-wrapper">
        <Icon name="ios-search" classname="typeahead"/>
        <input
          ref={this.filterInput}
          type="text"
          className="filter"
          placeholder={placeholder}
          onChange={(e)=>action(e)}
        />
      </div>
    )
  }
}

export default Filter
