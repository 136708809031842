import React, {Component, Fragment} from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { getTrackInfo, getTrackUrl } from 'store/actions/playerAction'
import { getPlaylist, subscribePlaylistDetails, unsubscribePlaylistDetails } from 'store/actions/playlistAction'
import { toggleOverlay } from 'store/actions/overlayAction'
import { toggleConfirm } from 'store/actions/confirmAction'

import DashboardPieChart from 'components/dashboard/DashboardPieChart'
import Icon from 'ui/Icon'
import Tooltip from 'ui/Tooltip'
import Button from 'ui/Button'
import Energy from 'ui/Energy'
import Badge from 'ui/Badge'
import Confirm from 'ui/Confirm'
import Loader from 'ui/Loader'

class PlaylistPreview extends Component {
  constructor(props){
    super(props);

    this.state = {
      activeTab: 'tracklist'
    };

    this.toggleTabs = this.toggleTabs.bind(this);
    this.playTrack = this.playTrack.bind(this);
  }

  componentDidMount(){
    this.props.dispatch(getPlaylist(this.props.id))
  }

  toggleTabs(whatTab) {
    this.setState({
      activeTab: whatTab
    });
  }

  playTrack(track) {
    const {
      dispatch
    } = this.props

    dispatch(getTrackInfo(track, 'playlist'))
    dispatch(getTrackUrl(track, 'playlist'))
  }

  subscribe(playlist){
    const {
      dispatch
    } = this.props

    dispatch(subscribePlaylistDetails(playlist.id))
    dispatch(toggleOverlay(true, playlist ,'subscribedPlaylistToVenues'))
  }

  unsubscribe(playlist){
    const {
      dispatch
    } = this.props

    const confirmData = {
      action: () => {
        dispatch(unsubscribePlaylistDetails(playlist))
      },
      question: 'Unsubscribing from a playlist will remove it from your schedules for all your venues. Are you sure?'
    }

    dispatch(toggleConfirm(true, confirmData))
  }

  getTrackList(){
    const {
      tracks,
      playingTrack
    } = this.props

    return tracks.map((track,index)=>{
      const isSelected = playingTrack && (playingTrack.id === track.id)
      const isPlaying = isSelected && playingTrack.isPlaying

      return (
        <li
          className={`tracklist-item  ${isSelected ? ' tracklist-item--playing' : ''}`}
          key={index}
        >
          <Icon
            classname="play"
            name={`ios-${isPlaying ? 'volume-high' : 'play'}`}
            action={() => this.playTrack(track)}
          />
          <div className='tracklist-track'>
            <div className='tracklist-track-name'>{track.title}</div>
            <div className='tracklist-artist'>{track.artist}</div>
          </div>
          <div className="tracklist-meta">
            <div className='tracklist-new'>{track.new ? <Badge data='new'/> : ''}</div>
            <div className='tracklist-energy'><Energy energy={track.energy}/></div>
            <div className='tracklist-duration'>{track.length}</div>
          </div>
        </li>
      )
    })
  }

  getSubscribeButton(){
    const {
      details,
      subscriptionLoading,
      userGroup
    } = this.props

    if(details.subscribed){
      return(
        <Button
          action={()=>this.unsubscribe(details)}
          name={userGroup === 9 ? 'Subscribed' : 'Unsubscribe from playlist'}
          loading={subscriptionLoading}
          warning
          disabled={userGroup === 9 && true}
        />
      )
    } else {
      return(
        <Button
          action={()=>this.subscribe(details)}
          name="Subscribe to playlist"
          loading={subscriptionLoading}
        />
      )
    }
  }

  viewPlaylist(playlist_id){
    const {
      history
    } = this.props

    history.push(`/playlist/${playlist_id}?tab=details`)
  }

  copyPlaylist(playlist){
    this.props.dispatch(toggleOverlay(true, playlist ,'copyPlaylist'))
  }

  render(){
    const {
      closeFunc,
      details,
      loading,
      metadata
    } = this.props;

    const {
      activeTab
    } = this.state;

    return (
      <div className='playlist-preview'>
        {loading ? (
          <Loader />
        ) : (
          <Fragment>
            <div className="preview-header">
              <img
                className='playlist-preview__image'
                src={details.playlistImage && details.playlistImage.name ? `https://openearmusic.imgix.net/${details.playlistImage.name}?w=402&h=402` : 'albumCovers/default.png'}
                alt={details.playlistImage && details.playlistImage.name ? details.playlistImage.name : 'Open Ear'}
              />
              <div className="playlist-preview__details">
                <h3>{details.name}</h3>
                <p>{details.style}</p>
                <div className="playlist-preview__actions">
                  <div className="playlist-preview__actions_left">
                    {this.getSubscribeButton()}
                  </div>
                  <div className="playlist-preview__actions_right">
                    <Icon name="eye" action={() => this.viewPlaylist(details.id)}>
                      <Tooltip pos="left" text="View playlist."/>
                    </Icon>
                    <Icon name="copy" action={() => this.copyPlaylist(details)}>
                      <Tooltip pos="left" text="Copy playlist."/>
                    </Icon>
                  </div>
                </div>
              </div>
              <p
                className='playlist-preview__close'
                onClick={closeFunc}
              >
                <span className='playlist-preview__close-icon'>
                  <Icon name="close" />
                </span>
                CLOSE
              </p>
            </div>
            <div className='playlist-preview__tabs'>
              <div
                className={`playlist-preview__tab ${activeTab === 'tracklist' ? 'playlist-preview__tab--active' : ''}`}
                onClick={() => {this.toggleTabs('tracklist')}}
              >
                Tracks
              </div>
              <div
                className={`playlist-preview__tab ${activeTab === 'data' ? 'playlist-preview__tab--active' : ''}`}
                onClick={() => {this.toggleTabs('data')}}
              >
                Data
              </div>
            </div>
            {activeTab === 'tracklist' && (
              <div className="tracks-wrapper">
                <ul className='tracklist'>
                  {this.getTrackList()}
                </ul>
              </div>
            )}
            {activeTab === 'data' && (
              <div className='playlist-preview__data'>
                <div className='playlist-preview__data-details'>
                  <p>
                  <span className='playlist-preview__data-icon'>
                    <Icon name="ios-flash" />
                  </span>
                    Energy Level: {metadata.energy}
                  </p>
                  <p>
                  <span className='playlist-preview__data-icon'>
                    <Icon name="ios-musical-notes" />
                  </span>
                    Total tracks: {metadata.count}</p>
                  <p>
                  <span className='playlist-preview__data-icon'>
                    <Icon name="time" />
                  </span>
                    Running time: {metadata.total_length}
                  </p>
                  <ul className='playlist-preview__data-tags'>
                    {details.genres.map((genre,index)=>{
                      return (
                        <li key={index}>{genre}</li>
                      )
                    })}
                    {details.tags.map((tag,index)=>{
                      return (
                        <li key={index}>{tag}</li>
                      )
                    })}
                  </ul>
                </div>
                <div className='playlist-preview__data-genres'>
                  <DashboardPieChart
                    data={metadata.genres}
                  />
                </div>
              </div>
            )}
          </Fragment>
        )}
        <Confirm />
      </div>
    )
  }
}

function mapStateToProps(store){
  return {
    details:store.playlist.details,
    playingTrack: store.player.track,
    tracks:store.playlist.tracks,
    metadata:store.playlist.metadata,
    loading:store.playlist.loading,
    subscriptionLoading:store.playlist.subscriptionLoading,
    userGroup:store.auth.userGroup
  }
}

export default withRouter(connect(mapStateToProps)(PlaylistPreview))
