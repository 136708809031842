import React, { Component } from 'react'
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Legend, Tooltip,
} from 'recharts';

const colours = ['#4ba0b3', '#ffa600', '#56bb6f'];

class DashboardBarChart extends Component {

  render(){
    const {
      data
    } = this.props;

    return (
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 20,
              right: 20,
              left: 20,
              bottom: 20
            }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            style={{ fontFamily: 'Walsheim, sans-serif', fontSize: '14px' }}
          />
          <YAxis style={{ fontFamily: 'Walsheim, sans-serif', fontSize: '14px' }} />
          <Tooltip contentStyle={{ background: '#112938' }} />
          <Legend />
          {Object.keys(data[0]).map((key, index) => {
            if (key !== 'name') {
              return (
                  <Bar
                      key={key}
                      dataKey={key}
                      stackId="a"
                      fill={colours[index-1]}
                  />
              )
            }
            return false
          })}
        </BarChart>
      </ResponsiveContainer>
    )
  }
}

export default DashboardBarChart
