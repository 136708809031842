import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  getPlaylistTracks,
  filterPlaylistTracks,
  removeTrackFromPlaylist,
  removeTrackFromPlaylistOwnedUsingPivot,
  removeDuplicates
} from 'store/actions/playlistAction'
import { getTrackInfo, getTrackUrl } from 'store/actions/playerAction'
import { toggleOverlay } from 'store/actions/overlayAction'

import Container from 'ui/Container'
import Loader from 'ui/Loader'
import PlaylistTracksTableAction from './playlist-tracks/PlaylistTracksTableAction'

import TableWrapper from 'ui/TableWrapper'
import PlaylistTracksTableRowActions from './playlist-tracks/PlaylistTracksTableRowActions'
import PlaylistTracksOwnerTableRowActions from './playlist-tracks/PlaylistTracksOwnerTableRowActions'

import { debounce } from 'helpers/Debounce'
import { tableSorter } from 'helpers/TableHelper'
import PlaylistTracksRow from "./playlist-tracks/PlaylistTracksRow"

const classname = 'playlistTracks'

class PlaylistTracks extends Component {

  constructor(props){
    super(props)
    this.state = {
      filter:'',
      actionActive:false,
      sorter:{
        field:'added',
        direction:'desc'
      }
    }
    this.filterTracksDispatch = debounce(this.filterTracksDispatch,300)
    this.selectedTracks = []
  }

  componentDidMount() {
    const {
      dispatch,
      id
    } = this.props

    dispatch(getPlaylistTracks(id))
  }

  componentDidUpdate(prevProps){
    const {
      id,
      dispatch,
      removeDuplicatesLoading
    } = this.props
    if(prevProps.removeDuplicatesLoading !== removeDuplicatesLoading && !removeDuplicatesLoading){
      dispatch(getPlaylistTracks(id))
    }
  }

  playTrack(track){
    const {
      dispatch
    } = this.props

    dispatch(getTrackInfo(track, 'playlist'))
    dispatch(getTrackUrl(track, 'playlist'))
  }

  filterTracks(e){
    const filter = e.target.value
    this.filterTracksDispatch(filter)
  }

  filterTracksDispatch(term){
    this.props.dispatch(filterPlaylistTracks(term))
  }

  moreActions(data){
    this.props.dispatch(toggleOverlay(true,data,'playlistTracksMore'))
  }

  sortTable(head){
    this.setState({
      sorter:head
    })
  }

  sortType(x,y){
    const {field, direction} = this.state.sorter

    // if no direction is provided, due to the sorting icon being reset, use default values
    if (direction === null) {
      return tableSorter(x,y,'added','desc')
    }

    return tableSorter(x,y,field,direction)
  }

  getTracks(){
    const {
      filter,
      tracks
    } = this.props

    // create new array, so that we do not mutate the props data
    // allows proper reset of sorting order
    const sortedTracks = [...tracks]

    //sort tracks by date added to playlist and filter
    return sortedTracks
      .sort((x, y) => this.sortType(x, y))
      .filter(track => {
        //console.log(track.filter)
        if (track.filter && track.filter.toLowerCase().includes(filter.toLowerCase())) {
          return track
        }
        return false
      })
  }

  removeTrackFromPlaylistOwned(track,playlist_id){
    this.props.dispatch(removeTrackFromPlaylistOwnedUsingPivot(track,playlist_id))
  }

  checkboxAction(e,data){
    if(e.target.checked){
      this.selectedTracks.push(data)
    } else {
      this.selectedTracks = this.selectedTracks.filter(track => track.id !== data.id)
    }
    if(this.selectedTracks.length > 0){
      this.setState({actionActive:true})
    } else {
      this.setState({actionActive:false})
    }
  }

  clickRow(e,data,isChecked,index){
    e.stopPropagation()
    //cmd + click
    if(e.metaKey){
      if(!isChecked){
        data.index = index
        this.selectedTracks.push(data)
      } else {
        this.selectedTracks = this.selectedTracks.filter(track => track.id !== data.id)
      }
      if(this.selectedTracks.length > 0){
        this.setState({actionActive:true})
      } else {
        this.setState({actionActive:false})
      }
    }
    /*if(e.shiftKey){
      if(this.selectedTracks.length === 1){
        for (let i = this.selectedTracks[0].index + 1; i <= index; i++) {
          this.selectedTracks.push(this.props.tracks[i])
        }
        this.setState({actionActive:true})
      }
    }*/
  }

  deselectTracks(){
    this.selectedTracks = []
    this.setState({actionActive:false})
  }

  addTracksToPlaylist(){
    this.props.dispatch(toggleOverlay(true,this.selectedTracks,'multiPlaylistsAdd'))
  }

  getBreakdown(data){
    this.props.dispatch(toggleOverlay(true,data,'breakdown'))
  }

  removeTracks(){
    for(let track of this.selectedTracks){
      this.props.dispatch(removeTrackFromPlaylist(track,this.props.id))
    }
    this.selectedTracks = []
    this.setState({
      actionActive:false
    })
  }

  openPlaylistOverlay(data){
    const {
      dispatch,
    } = this.props

    dispatch(toggleOverlay(true, data, 'playlistsAddTrack'))
  }

  getRowActions(){
    const {
      addTrackToOwnedPlaylistLoading,
      id,
      isOwned
    } = this.props

    //if owner of playlist
    if(isOwned){
      return (
        <PlaylistTracksOwnerTableRowActions
          //favouriteTrack={(data)=>this.favouriteTrack(data)}
          //moreActions={(data)=>this.moreActions(data)}
          removeTrack={(data)=>this.removeTrackFromPlaylistOwned(data,id)}
          openPlaylistOverlay={(data)=>this.openPlaylistOverlay(data)}
          //changeDesignation={(data)=>{this.changeDesignation(data)}}
          classname={classname}
          addTrackToOwnedPlaylistLoading={addTrackToOwnedPlaylistLoading}
        />
      )
    } else{
      return (
        <PlaylistTracksTableRowActions
          favouriteTrack={(data)=>this.favouriteTrack(data)}
          moreActions={(data)=>this.moreActions(data)}
          removeTrack={(data)=>this.removeTrack(data)}
          openPlaylistOverlay={(data)=>this.openPlaylistOverlay(data)}
          changeDesignation={(data)=>{this.changeDesignation(data)}}
          classname={classname}
        />
      )
    }
  }

  selectAll(selected){
    const {
      filter,
      tracks
    } = this.props

    // create new array, so that we do not mutate the props data
    // allows proper reset of sorting order
    const sortedTracks = [...tracks]

    //sort tracks by date added to playlist and filter
    if(selected){
      sortedTracks
        .sort((x, y) => this.sortType(x, y))
        .filter(track => {
          //console.log(track.filter)
          if (track.filter && track.filter.toLowerCase().includes(filter.toLowerCase())) {
            this.selectedTracks.push(track)
          }
          return false
        })
        this.setState({actionActive:true})
    } else {
      this.selectedTracks = []
      this.setState({actionActive:false})
    }
    
  }

  removeDuplicates(){
    this.props.dispatch(removeDuplicates(this.props.id))
  }

  getTable(){
    return <TableWrapper
      play={(track)=>this.playTrack(track)}
      checkboxAction={(e,id)=>this.checkboxAction(e,id)}
      checkboxSelected={this.selectedTracks}
      classname={classname}
      data={this.getTracks()}
      count={this.props.count}
      clearData={()=>this.clearPlaylists()}
      getData={(limit,offset)=>this.getPlaylists(limit,offset)}
      loading={this.props.loading}
      sortTable={(head)=>this.sortTable(head)}
      selectAll={(selected)=>this.selectAll(selected)}
      playingTrack={this.props.playingTrack}
      rowActions={
        this.getRowActions()
      }
      rowComp={
        <PlaylistTracksRow
          play={(track)=>this.playTrack(track)}
          playingTrack={this.props.playingTrack}
          checkboxSelected={this.selectedTracks}
          checkboxAction={(e,data,index)=>this.checkboxAction(e,data,index)}
          clickRow={(e,data,isChecked,index)=>this.clickRow(e,data,isChecked,index)}
          libraryAction={(data,e) => this.libraryAction(data,e)}
          />
      }
      tableAction={<PlaylistTracksTableAction
        removeDuplicates={()=>this.removeDuplicates()}
        active={this.state.actionActive}
        classname={classname}
        metadata={this.props.metadata}
        count={this.props.tracks.length}
        numSelected={this.selectedTracks.length}
        placeholder="Filter tracks..."
        action={(e)=>this.filterTracks(e)}
        addToQueue={()=>this.addToQueue()}
        removeTracks={()=>this.removeTracks()}
        getBreakdown={(data)=>this.getBreakdown(data)}
        addTracksToPlaylist={()=>this.addTracksToPlaylist()}
        deselectTracks={()=>this.deselectTracks()}/>
      }
      />
  }

  render(){
    if(this.props.loading){
      return <Loader/>
    } else {
      return (
        <Container classname="playlist-tracks" height="100%">
          {this.getTable()}
        </Container>
      )
    }
  }
}
function mapStateToProps(store){
  return {
    tracks:store.playlist.tracks,
    metadata:store.playlist.metadata,
    isOwned:store.playlist.isOwned,
    filter:store.playlist.filter,
    loading:store.playlist.playlistTracksLoading,
    addTrackToOwnedPlaylistLoading: store.playlist.addTrackToOwnedPlaylistLoading,
    removeDuplicatesLoading:store.playlist.removeDuplicatesLoading
  }
}

export default connect(mapStateToProps)(PlaylistTracks)
