import React, { Component } from 'react'
import { connect } from 'react-redux'

import { getFitnessClassVenues, removeFitnessClassFromVenue } from 'store/actions/fitnessAction'
import { toggleOverlay } from 'store/actions/overlayAction';

import Button from 'ui/Button'
import Container from 'ui/Container'
import Loader from 'ui/Loader'
import TableWrapper from 'ui/TableWrapper'

import FitnessVenuesTableActions from "../fitness/FitnessVenuesTableActions";

const classname = 'fitnessClassVenues'

class FitnessClassVenues extends Component {

  componentDidMount(){
    this.props.dispatch(getFitnessClassVenues(this.props.id, true))
  }

  openFitnessVenuesOverlay() {
    const {
      dispatch,
      id
    } = this.props

    dispatch(
      toggleOverlay(
        true,
        { id: id },
        'fitnessVenues'
      )
    );
  }

  removeFitnessClassFromVenue(venue_id) {
    const {
      dispatch,
      id
    } = this.props

    dispatch(removeFitnessClassFromVenue({
      class_id: id,
      venue_id: venue_id
    }))
  }

  render(){
    const {
      loading,
      removingVenueIDs,
      venues
    } = this.props

    if(loading){
      return <Loader/>
    } else {
      return (
        <Container classname={classname} height="100%" maxWidth column>
          <div className='fitness__button'>
            <Button
              className='button'
              name='Add to venues'
              action={()=>this.openFitnessVenuesOverlay()}
              type='button'
            />
          </div>
          <TableWrapper
            classname={classname}
            data={venues}
            count={this.props.count}
            clearData={()=>this.clearVenues()}
            loading={loading}
            rowActions={<FitnessVenuesTableActions
              removeFitnessClassFromVenue={(venue_id)=>this.removeFitnessClassFromVenue(venue_id)}
              removingVenueIDs={removingVenueIDs}
              classname={classname}
            />
            }
          />
        </Container>
      )
    }
  }
}

function mapStateToProps(store){
  return {
    loading:store.fitness.loading,
    removingVenueIDs:store.fitness.removingVenueIDs,
    venues:store.fitness.venues
  }
}

export default connect(mapStateToProps)(FitnessClassVenues)
