import React, {Fragment} from 'react'

const WaveformScrubber = (props) =>{
  const {
    scrubberPos,
    scrubberSelectPos
  } = props
  return (
    <Fragment>
      <div className="waveform-scrubber" style={{left:`${scrubberPos}%`}}></div>
      <div 
        className={`waveform-scrubber-select ${scrubberSelectPos ? 'active' : ''}`} 
        style={{left:`${scrubberSelectPos}%`}}>
      </div>
    </Fragment>
  )
}

export default WaveformScrubber