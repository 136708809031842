import TableModel from 'models/TableModel'
import { getLocalStorage, setLocalStorage } from './LocalStorage'
import { minSecsStringToSecs } from './TimeHelper'

export function getRowLimit(table){
  const ls = getLocalStorage(table)
  if(ls){
    return ls.results.limit
  } else {
    return TableModel[table].results.limit
  }
}
export function getRowOptions(table){
  const ls = getLocalStorage(table)
  if(ls){
    return ls.results.options
  } else {
    return TableModel[table].results.options
  }
}
export function setRowLimit(table, value){
  const ls = getLocalStorage(table)
  TableModel[table].results.limit = value
  if(!ls){
    setLocalStorage(table, TableModel[table])
  } else {
    ls.results.limit = value
    setLocalStorage(table, ls)
  }
}
export function getOffset(limit,page){
  return limit * (page - 1)
}
export function tableSorter(x,y,field,direction){
  switch(field){
    case 'added':
    case 'createdAt':
      const dateA = new Date(x.addedMysql)
      const dateB = new Date(y.addedMysql)
      if(direction === 'asc'){
        if(dateA > dateB){
          return 1
        }
        if(dateA < dateB){
          return -1
        }
        return 0
      } else {
        if(dateA > dateB){
          return -1
        }
        if(dateA < dateB){
          return 1
        }
        return 0
      }
    case 'length':{
      const timeA = minSecsStringToSecs(x.length)
      const timeB = minSecsStringToSecs(y.length)
      if(direction === 'asc'){
        if(timeA > timeB){
          return 1
        }
        if(timeA < timeB){
          return -1
        }
        return 0
      } else {
        if(timeA > timeB){
          return -1
        }
        if(timeA < timeB){
          return 1
        }
        return 0
      }
    }
    case 'energy':
    case 'bpm':
    case 'venuesCount':
    case 'tracksCount':
    case 'tracks_count':{
      const intA = x[field] ? parseInt(x[field]) : 0
      const intB = y[field] ? parseInt(y[field]) : 0
      if(direction === 'asc'){
        if(intA > intB){
          return 1
        }
        if(intA < intB){
          return -1
        }
        return 0
      } else {
        if(intA > intB){
          return -1
        }
        if(intA < intB){
          return 1
        }
        return 0
      }
    }
    case 'title':
    case 'artist':
    case 'album':
    case 'name':
    case 'designation':
      const stringA = x[field].toUpperCase().trim()
      const stringB = y[field].toUpperCase().trim()
      if(direction === 'asc'){
        if(stringA > stringB){
          return 1
        }
        if(stringA < stringB){
          return -1
        }
        return 0
      } else {
        if(stringA > stringB){
          return -1
        }
        if(stringA < stringB){
          return 1
        }
        return 0
      }
    default: //no default case
  }
}
