const initialState = {
  data:[],
  typeahead:[],
  count:null,
  loading:false,
  loaded:false,
  venueCreated:false,
  storedVenueData: {},
  createdVenueID:null,
  copiedVenueID:null,
  updatingVenueIDs: [],
  updating: false,
  error:null
}
export default function reducer(state = initialState, action){
  switch (action.type){
    //playlists
    case 'CLEAR_VENUES':{
      state = {...state,data:[]}
      break
    }
    case 'FETCH_VENUES_PENDING':{
      state = {...state,loading:true,data:[]}
      break
    }
    case 'FETCH_VENUES_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_VENUES_FULFILLED':{
      const venues = action.payload.data.venues.map((venue)=>{
        const data = {
          id:venue.id,
          name:venue.name,
          venue_user:venue.user[0] ? venue.user[0].username : '',
          business:venue.business ? venue.business.name : '',
          playlists_count:venue.playlists.length,
          playlists:venue.playlists.map((playlist) => {
            const playlists = {
              id: playlist.id
            }
            return playlists
          }),
          blocked_count:venue.blocked_count,
          total_tracks:venue.total_tracks,
          created_at:venue.created_at.standard,
          last_login:venue.user[0] ? venue.user[0].last_login.standard : '',
          authToken:venue.authToken
        }
        return data
      })
      state =  {
        ...state,
        loading:false,
        loaded:true,
        data:state.data.concat(venues),
        count:action.payload.data.count,
        venueCreated:false
      }
      break
    }
    case 'FETCH_VENUES_TYPEAHEAD_PENDING':{
      state = {
        ...state,
        loading:true,
        typeahead:[],
        typeaheadLoading: true
      }
      break
    }
    case 'FETCH_VENUES_TYPEAHEAD_REJECTED':{
      state = {
        ...state,
        loading:false,
        typeaheadLoading: false,
        error:action.payload
      }
      break
    }
    case 'FETCH_VENUES_TYPEAHEAD_FULFILLED':{
      state = {...state,
        loading: false,
        error: null,
        typeahead:action.payload.data.venues,
        typeaheadLoading: false
      }
      break
    }
    case 'CLEAR_TYPEAHEAD':{
      state = {
        ...state,
        typeahead:[]
      }
      break
    }
    case 'CREATE_VENUE_PENDING':{
      state = {...state,loading:true,createdVenueID:null}
      break
    }
    case 'CREATE_VENUE_REJECTED':{
      state = {
        ...state,
        loading:false,
        error:action.payload,
        storedVenueData: action.meta
      }
      break
    }
    case 'CREATE_VENUE_FULFILLED':{
      state = {...state,
        loading: false,
        error: null,
        venueCreated:true,
        storedVenueData: {},
        createdVenueID:action.payload.data.res.data.id
      }
      break
    }
    case 'COPY_VENUE_PENDING':{
      state = {
        ...state,
        loading: true,
        copiedVenueID: null
      }
      break
    }
    case 'COPY_VENUE_REJECTED':{
      state = {
        ...state,
        loading: false,
        error: action.payload,
        storedVenueData: action.meta
      }
      break
    }
    case 'COPY_VENUE_FULFILLED':{
      state = {...state,
        loading: false,
        error: null,
        venueCreated: true,
        storedVenueData: {},
        copiedVenueID: action.payload.data.res.data.id
      }
      break
    }
    case 'DELETE_VENUE_FULFILLED':{
      const venues = state.data.filter(venue => venue.id !== action.meta)
      state = {...state,data:venues}
      break
    }
    case 'SEARCH_VENUES_PENDING':{
      state = {...state,typeahead:[],loading:true}
      break
    }
    case 'SEARCH_VENUES_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'SEARCH_VENUES_FULFILLED':{
      state =  {
        ...state,
        loading:false,
        loaded:true,
        typeahead:state.typeahead.concat(action.payload.data),
      }
      break
    }
    case 'ADD_PLAYLIST_TO_VENUE_PENDING':{
      state = {
        ...state,
        updatingVenueIDs: state.updatingVenueIDs.concat(action.meta.venueID),
        updating: true
      }
      break
    }
    case 'ADD_PLAYLIST_TO_VENUE_REJECTED':{
      state = {
        ...state,
        updatingVenueIDs: state.updatingVenueIDs.filter(venueId=>venueId !== action.meta.venueID),
        updating: false,
        error:action.payload,
      }
      break
    }
    case 'ADD_PLAYLIST_TO_VENUE_FULFILLED':{
      const venues = state.data
      const venueIndex = venues.findIndex((venue) => {
        return venue.id === action.meta.venueID
      })


      if (venueIndex >= 0) {
        const venue = venues[venueIndex]
        const playlist = action.meta.playlist
        const playlistTrackCount = playlist.tracksCount || playlist.trackCount

        venue.playlists.push({
          id: playlist.id
        })
        venue.total_tracks = venue.total_tracks + playlistTrackCount
        venue.playlists_count = venue.playlists_count + 1
      }

      state = {
        ...state,
        data: venues,
        updatingVenueIDs: state.updatingVenueIDs.filter(venueId=>venueId !== action.meta.venueID),
        updating: false
      }
      break
    }
    case 'REMOVE_PLAYLIST_FROM_VENUE_PENDING':{
      state = {
        ...state,
        updatingVenueIDs: state.updatingVenueIDs.concat(action.meta.venueID),
        updating: true
      }
      break
    }
    case 'REMOVE_PLAYLIST_FROM_VENUE_REJECTED':{
      state = {
        ...state,
        updatingVenueIDs: state.updatingVenueIDs.filter(venueId=>venueId !== action.meta.venueID),
        updating: false,
        error: action.payload
      }
      break
    }
    case 'REMOVE_PLAYLIST_FROM_VENUE_FULFILLED':{
      const venues = state.data
      const venueIndex = venues.findIndex((venue) => {
        return venue.id === action.meta.venueID
      })

      if (venueIndex >= 0) {
        const venue = venues[venueIndex]
        const playlist = action.meta.playlist
        const playlistTrackCount = playlist.tracksCount || playlist.trackCount

        venue.playlists = venue.playlists.filter((venuePlaylist) => {
          return venuePlaylist.id !== playlist.id
        })
        venue.total_tracks = venue.total_tracks - playlistTrackCount
        venue.playlists_count = venue.playlists_count - 1
      }

      state = {
        ...state,
        data: venues,
        updatingVenueIDs: state.updatingVenueIDs.filter(venueId=>venueId !== action.meta.venueID),
        updating: false
      }
      break
    }
    //check player online
    case 'WS_PLAYER_ONLINE':{
      state =  {...state,loading:false,loaded:true}
      //state.data = state.data.concat(action.payload.data)
      const venues = state.data.map(venue => {
        const id = parseInt(action.payload.vid)
        if(venue.id === id){
          venue.online = action.payload.online
        }
        return venue
      })
      state = {...state,data:venues}
      break
    }
    case 'LOGOUT':{
      state = {
        data:[],
        count:null,
        loading:false,
        loaded:false,
        updatingVenueIDs: [],
        error:null
      }
      break
    }
    default: //no default case
  }
  return state
}
