import React, {Component} from 'react'
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  LabelList
} from 'recharts'

import { connect } from 'react-redux'

class OverlayPlaylistBreakdown extends Component{

  constructor(props){
    super(props)
    this.state = {
      data:[]
    }
  }

  componentDidMount(){
    this.createData()
  }

  createData(){
    const data = this.props.data.genres.keys.map(key => {
      const res = {name:key,value:this.props.data.genres[key]}
      return res
    })
    this.setState({data:data})
  }

  colours = [
    '#F44336','#E91E63','#9C27B0','#673AB7','#3F51B5','#2196F3','#03A9F4','#00BCD4','#009688','#4CAF50','#8BC34A','#CDDC39','#FFEB3B','#FFC107','#FF9800',
    '#FF5722','#795548','#9E9E9E','#607D8B','#FFC312','#C4E538','#12CBC4','#FDA7DF','#ED4C67','#F79F1F','#A3CB38','#1289A7','#D980FA','#D980FA','#B53471',
    '#F44336','#E91E63','#9C27B0','#673AB7','#3F51B5','#2196F3','#03A9F4','#00BCD4','#009688','#4CAF50','#8BC34A','#CDDC39','#FFEB3B','#FFC107','#FF9800',
    '#FF5722','#795548','#9E9E9E','#607D8B','#FFC312','#C4E538','#12CBC4','#FDA7DF','#ED4C67','#F79F1F','#A3CB38','#1289A7','#D980FA','#D980FA','#B53471',
  ]

  customLabel(props){
    const RADIAN = Math.PI / 180;
    const { viewBox, value, fill, stroke } = props
    const radius = viewBox.innerRadius + (viewBox.outerRadius - viewBox.innerRadius) * 1.17
    const midAngle = (viewBox.startAngle + viewBox.endAngle)/2
    const totalAngle = viewBox.endAngle - viewBox.startAngle
    const x  = viewBox.cx + radius * Math.cos(-midAngle * RADIAN)
    const y = viewBox.cy  + radius * Math.sin(-midAngle * RADIAN)
    if(totalAngle > 2){
      return <text
        x={x}
        y={y}
        fill={fill}
        stroke={stroke}
        fontSize={12}
        fontWeight="lighter"
        textAnchor={viewBox.endAngle > 270 || viewBox.endAngle < 90 ? 'start' : 'end'}>
          {value}
        </text>
    } else {
      return null
    }
  }

  render(){
    return (
      <ResponsiveContainer width="100%" height={400}>
        <PieChart>
          <Pie dataKey="value" data={this.state.data} outerRadius={150} cx="50%" cy="50%" isAnimationActive={false}>
            {this.state.data.map((entry,index) => <Cell key={index} fill={this.colours[index % this.colours.length]}/>)}
            <LabelList dataKey="name" position="outside" offset={30} content={this.customLabel}/>
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    )
  }
}

export default connect()(OverlayPlaylistBreakdown)
