import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {generateRandomString, serialize} from 'helpers/StringHelper'

import Container from 'ui/Container'
import Button from 'ui/Button'

const classname = 'playlistsImporter'

class PlaylistsImporter extends Component {

  constructor(props){
    super(props)
    this.state = {
      screen:'init'
    }
  }

  componentDidMount(){
    const accessToken = this.getHashParams()
    console.log(accessToken)
  }

  getHashParams() {
    var hashParams = {};
    console.log(window.location.hash.substring(1))
    var e, r = /([^&;=]+)=?([^&;]*)/g,
        q = window.location.hash.substring(1);
    e = r.exec(q)
    while (e) {
       hashParams[e[1]] = decodeURIComponent(e[2]);
       e = r.exec(q);
    }
    return hashParams;
  }

  goToSpotify(){
    const state = generateRandomString(16)
		const href = 'https://accounts.spotify.com/authorize?'
		const scopes = ['playlist-read-private','playlist-read-collaborative']
		const options = {
      response_type: 'token',
      client_id: '2dd750d1c05a492bbb147ceb0386c094',
      scope:scopes.join(' '),
      redirect_uri: 'http://localhost:3000/playlists',
      state: state
		};

    const serializedUrl = href+serialize(options)
    window.location = serializedUrl
  }

  getScreen(){
    switch(this.state.screen){
      case 'init':
        return (
          <div className="importer-login">
            <p>Welcome to the Open Ear playlist importer. Login to your Spotify account to contunue.</p>
            <Button action={()=>this.goToSpotify()} name="Login to Spotify"/>
          </div>
        )
      default: //no default case
    }
  }

  render(){
    return (
      <Container classname={classname}>
        {this.getScreen()}
      </Container>
    )
  }
}

function mapStateToProps(store,myProps){
  return {
    playlists:store.playlists.data,
    playlist:store.playlist.details,
    count:store.playlists.count,
    loading:store.playlists.loading,
    tab:myProps.location.search
  }
}

export default withRouter(connect(mapStateToProps)(PlaylistsImporter))
