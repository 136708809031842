import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import Calendar from 'react-calendar'

import {
  copyCalendarToVenues,
  getVenues
} from 'store/actions/venueAction'

import Container from 'ui/Container'
import Icon from 'ui/Icon'
import Button from 'ui/Button'
import Loader from 'ui/Loader'

const classname = 'overlayVenueCalendarCopy'

class OverlayVenueCalendarCopy extends Component{

  constructor(props){
    super(props)
    this.state = {
      venues:[],
      allSelected:false,
      startDate: moment(this.props.data.currentWeek).toDate(),
      endDate: moment(this.props.data.currentWeek).add(6, 'd').toDate(),
      showStartDateCalendar: false,
      showEndDateCalendar: false
    }
  }

  componentDidMount(){
    this.props.dispatch(getVenues())
  }

  componentDidUpdate(prevProps, prevState){
    const {
      venues
    } = this.props

    const {
      startDate,
      endDate
    } = this.state

    if(prevProps.venues !==   venues){
      this.setStateVenues()
    }

    // if the start date is set to be after the end date,
    // set the end date to the day after the new start date
    if (prevState.startDate !== startDate && moment(endDate).isBefore(startDate)) {
      this.setState({
        endDate: moment(startDate).add(1, 'd').toDate()
      })
    }

    // if the end date is set to be before the start date,
    // set the start date to the day before the new end date
    if (prevState.endDate !== endDate && moment(endDate).isBefore(startDate)) {
      this.setState({
        startDate: moment(endDate).subtract(1, 'd').toDate()
      })
    }
  }

  setStateVenues(){
    const venues = this.props.venues
      .map(venue => {
        venue.selected = false
        return venue
      })
      //remove current venue from selection
      .filter(venue => venue.id !== this.props.data.venueID)

    this.setState({
      venues:venues
    })
  }

  toggleSelect(index){
    let venues = [...this.state.venues]
    venues[index].selected = !venues[index].selected

    this.setState({
      venues:venues
    })
  }

  getVenues(){
    return this.state.venues.map((venue,index) => {
      return <div
        className={`venue-overlay ${venue.selected ? 'selected' : ''}`}
        key={index}
        onClick={()=>this.toggleSelect(index)}>
          {venue.name} {venue.selected ? <Icon name="checkmark-circle"/>: ''}
      </div>
    })
  }

  selectDeselectAll(){
    const allSelected = !this.state.allSelected
    if(allSelected){
      const venues = this.state.venues.map(venue => {
        venue.selected = true
        return venue
      })
      this.setState({
        venues:venues,
        allSelected:allSelected
      })
    } else {
      const venues = this.state.venues.map(venue => {
        venue.selected = false
        return venue
      })
      this.setState({
        venues:venues,
        allSelected:allSelected
      })
    }
  }

  toggleCalendar(calendar) {
    const {
      showStartDateCalendar,
      showEndDateCalendar
    } = this.state

    if (calendar === 'startDate') {
      this.setState({
        showStartDateCalendar: !showStartDateCalendar,
        showEndDateCalendar: false
      })
    } else {
      this.setState({
        showStartDateCalendar: false,
        showEndDateCalendar: !showEndDateCalendar
      })
    }
  }

  copyCalendarToVenues(){
    const {
      data,
      dispatch,
    } = this.props

    const {
      startDate,
      endDate
    } = this.state

    const venues = [...this.state.venues]
    let selectedVenues = []
    for(let venue of venues){
      if(venue.selected){
        selectedVenues.push(venue.id)
      }
    }

    dispatch(copyCalendarToVenues({
      businessID: data.businessID,
      venueID: data.venueID,
      selectedVenues:selectedVenues,
      saved_id: data.savedID,
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD')
    }))
  }

  render(){
    const {
      data,
      venuesLoading
    } = this.props

    const {
      allSelected,
      startDate,
      endDate,
      showStartDateCalendar,
      showEndDateCalendar,
      venues
    } = this.state

    return (
      <Container classname={classname} maxWidth height="100%" column>
        {venues.length === 0 ? (
          <Loader />
        ) : (
          <Fragment>
            <div className="header">
              <span>Select venues to copy to.</span>
              <div className="select-deselect" onClick={() => this.selectDeselectAll()}>
                {allSelected ? 'deselect all' : 'select all'}
              </div>
            </div>
            <div className="venues-overlay-wrapper">
              {venuesLoading ? (
                <Loader />
              ) : (
                this.getVenues()
              )}
            </div>
            <div className='overlay-venue-calendar-copy__date-section'>
              <div className='overlay-venue-calendar-copy__date-select'>
                <h4>Start date</h4>
                <input
                  name='startDateCalendar'
                  type='text'
                  onClick={()=>this.toggleCalendar('startDate')}
                  value={moment(startDate).format('ddd Do MMM YYYY')}
                  readOnly
                />
                {showStartDateCalendar && (
                  <Calendar
                    value={startDate}
                    onChange={(date) => {
                      this.setState({
                        startDate: date,
                        showStartDateCalendar: false
                      })
                    }}
                  />
                )}
              </div>
              <div className='overlay-venue-calendar-copy__date-select'>
                <h4>End date</h4>
                <input
                  name='endDateCalendar'
                  type='text'
                  onClick={()=>this.toggleCalendar('endDate')}
                  value={moment(endDate).format('ddd Do MMM YYYY')}
                  readOnly
                />
                {showEndDateCalendar && (
                  <Calendar
                    value={endDate}
                    onChange={(date) => {
                      this.setState({
                        endDate: date,
                        showEndDateCalendar: false
                      })
                    }}
                  />
                )}
              </div>
            </div>
            <Button
              action={()=>this.copyCalendarToVenues(data)}
              name={`Add schedule to calendar`}
              disabled={venues.filter(venue => venue.selected === true).length === 0}
            />
          </Fragment>
        )}
      </Container>
    )
  }
}
function mapStateToProps(store){
  return {
    venues:store.venues.data,
    venuesLoading:store.venues.loading,
  }
}
export default connect(mapStateToProps)(OverlayVenueCalendarCopy)
