const initialState = {
  business: null,
  importerMatches: [],
  importerMatchesLoaded: [],
  importerMatchPerformed: false,
  importerFinalTracklist: [],
  playlists:[],
  playlist: null,
  playlistCreated: false,
  playlistCreatedVenuesAdded: false,
  playlistTracks:[],
  playlistTotal:null,
  playlistTracksNext:null,
  spotifyTokenValid: null
}

export default function reducer(state = initialState,action){
  switch(action.type){
    case 'FETCH_SPOTIFY_PLAYLISTS_PENDING':{
      state = {
        ...state,
        playlists: [],
        spotifyTokenValid: true
      }
      break
    }
    case 'FETCH_SPOTIFY_PLAYLISTS_REJECTED':{
      state = {
        ...state,
        playlists: [],
        spotifyTokenValid: false
      }
      break
    }
    case 'FETCH_SPOTIFY_PLAYLISTS_FULFILLED':{
      state = {
        ...state,
        playlists: action.payload.data.items,
        spotifyTokenValid: true
      }
      break
    }
    case 'FETCH_SPOTIFY_PLAYLIST_TRACKS_PENDING':{
      state = {
        ...state,
        playlistTracks: [],
        playlistTotal: null,
        playlistTracksNext: null
      }
      break
    }
    case 'FETCH_SPOTIFY_PLAYLIST_TRACKS_REJECTED':{
      state = {
        ...state,
        playlistTracks: [],
        playlistTotal: null,
        playlistTracksNext: null,
        spotifyTokenValid: false
      }
      break
    }
    case 'FETCH_SPOTIFY_PLAYLIST_TRACKS_FULFILLED':{
      const {
        items,
        total,
        next
      } = action.payload.data

      const tracks = items.map(item => {
        const track = {
          title: item.track.name,
          artist: item.track.artists && item.track.artists[0].name,
          album: item.track.album && item.track.album.name,
          id: item.track.id,
          total_length: item.track.duration_ms
        }
        return track
      })
      state = {
        ...state,
        playlistTracks: state.playlistTracks.concat(tracks),
        playlistTotal: total,
        playlistTracksNext: next ? next : null,
        spotifyTokenValid: true
      }
      break
    }
    case 'FETCH_SPOTIFY_PLAYLIST_TRACKS_NEXT_FULFILLED':{

      const {
        items,
        next
      } = action.payload.data

      const tracks = items.map(item => {
        const track = {
          title: item.track.name,
          artist: item.track.artists && item.track.artists[0].name,
          album: item.track.album && item.track.album.name,
          id: item.track.id
        }
        return track
      })
      state = {
        ...state,
        playlistTracks: state.playlistTracks.concat(tracks),
        playlistTracksNext: next ? next : null
      }
      break
    }
    case 'FETCH_IMPORTER_MATCHES_PENDING':{
      state = {
        ...state
      }
      break
    }
    case 'FETCH_IMPORTER_MATCHES_REJECTED':{
      state = {
        ...state,
        importerMatchPerformed: false
      }
      break
    }
    case 'FETCH_IMPORTER_MATCHES_FULFILLED':{
      // add the spotifyID of the searched track to each track returned
      const importerMatchesData = {
        ...action.payload.data,
        tracks: action.payload.data.tracks ? action.payload.data.tracks.map((track) => {
          return ({
            ...track,
            spotifyID: action.payload.data.spotifyID
          })
        }) : []
      }

      state = {
        ...state,
        importerMatches: state.importerMatches.concat(importerMatchesData),
        importerMatchesLoaded: state.importerMatchesLoaded.concat(action.meta.spotifyID),
        importerMatchPerformed: true
      }
      break
    }
    case 'ADD_TO_FINAL_TRACKLIST':{
      state = {
        ...state,
        importerFinalTracklist: state.importerFinalTracklist.concat(action.meta)
      }
      break
    }
    case 'REMOVE_FROM_FINAL_TRACKLIST':{
      state = {
        ...state,
        importerFinalTracklist: state.importerFinalTracklist.filter(track => track !== action.meta)
      }
      break
    }
    case 'TRACK_UPLOAD_RESET':{
      state = {
        ...state,
        importerMatches: [],
        importerMatchesLoaded: [],
        importerMatchPerformed: false,
        importerFinalTracklist: [],
        error:null
      }
      break
    }
    case 'CREATE_IMPORTER_PLAYLIST_PENDING':{
      state = {
        ...state,
        loading: true
      }
      break
    }
    case 'CREATE_IMPORTER_PLAYLIST_REJECTED':{
      state = {
        ...state,
        loading: false,
        error: action.payload
      }
      break
    }
    case 'CREATE_IMPORTER_PLAYLIST_FULFILLED':{
      const businessData = action.payload.data.business
      state =  {
        ...state,
        details:{
          ...state.details,
          id: action.payload.data.id
        },
        business: businessData || {},
        playlistCreated: true,
        loading: false
      }
      break
    }
    case 'CREATE_PLAYLIST_ADD_VENUES_FULFILLED':{
      state =  {
        ...state,
        details:{
          ...state.details,
          id: action.payload.data.id
        },
        playlistCreatedVenuesAdded: true
      }
      break
    }
    default: //no default case
  }
  return state
}
