import { useState } from "react"
import { Link } from "react-router-dom"
import Button from "ui/Button"
import Input from "ui/Input"
import Logo from "ui/Logo"

const PasswordReset = ({
  changePassword,
  loading,
  isPwValid,
  message,
  resetInterface
}) => {

  const [password,setPassword] = useState('')
  const [confPassword,setConfPassword] = useState('')
  const [isValid,setIsValid] = useState(false)
  const [isDirty,setIsDirty] = useState(false)

  const _changePassword = () => {
    setIsDirty(true)
    if(password === confPassword){
      setIsValid(true)
      changePassword(password)
    }
  }

  const _resetInterface = () => {
    resetInterface()
  }

  if(message){
    return(
      <div className="password-reset-container">
        <Logo/>
        <div>{message}</div>
        <div>
          <Button 
            name={isPwValid ? 'Login' : 'Retry'}
            action={()=>_resetInterface()}/>
        </div>
      </div>
    )
  }

  return (
    <div className="password-reset-container">
      <Logo/>
      <Input 
        type="password"
        value={password}
        change={(e)=>setPassword(e.target.value)}
        placeholder="Enter new password."/>
      <Input
        type="password"
        value={confPassword}
        change={(e)=>setConfPassword(e.target.value)}
        placeholder="Confirm password."/>
      {
        isDirty && !isValid && <div className="message">Passwords do not match.</div>
      }
      <Button 
        name="Change Password" 
        loading={loading}
        action={()=>_changePassword()}/>
    </div>
  )
}

export default PasswordReset