import React from 'react'

import Loader from 'ui/Loader'

const Button = (props) => {
  const {action, size, type, name, disabled, loading, main, stopPropagation, warning, bottom} = props
  const classes = `button button-${type} button-${size} ${main ? 'button-main' : ''} ${loading && 'button--loading'}  ${warning && 'button--warning'} ${bottom && 'button-bottom'}`

  return (
    <button
      className={classes}
      disabled={disabled ? true : false}
      onClick={stopPropagation ? (e)=>{action(e); e.stopPropagation()} : (e)=>action(e)}
    >
      {loading ? <Loader/> : name}
    </button>
  )
}

export default Button
