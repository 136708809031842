import React from 'react'

const Logo = (props) => {
  
  return (
    <svg width="251px" height="359px" viewBox="0 0 251 359" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient x1="49.9870968%" y1="0.0020844446%" x2="49.9870968%" y2="99.9976055%" id="linearGradient-1">
          <stop stopColor="#269CB7" offset="0%"></stop>
          <stop stopColor="#4761B9" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="open-ear-logo-(1)" fill="url(#linearGradient-1)">
          <path d="M0.680129032,105.407019 C0.230607839,109.594391 0.00358187759,113.802479 0,118.013694 C0,165.474115 29.9580645,198.619029 69.4055484,219.866595 C55.3669436,233.602245 47.1789171,252.191081 46.5402581,271.776431 C46.5402581,273.130601 46.5402581,274.484771 46.5402581,275.806698 C46.5402581,320.945686 80.3847742,345.159528 100.432387,352.478493 C109.954194,356.476517 158.437677,373.113459 221.009548,330.23142 L186.776387,280.965439 C149.207355,306.759147 124.334065,297.666865 123.297677,297.247717 L124.107355,297.602381 C123.362452,297.279959 121.645935,296.602874 120.868645,296.344937 C111.922502,293.388944 105.760911,284.25431 106.456387,274.32356 C107.395613,252.495635 148.36529,246.885504 156.559226,245.982724 C171.748904,244.764919 183.619166,232.422715 184.181347,217.262395 C184.743527,202.102074 173.819686,188.92216 158.761548,186.592713 C148.041419,184.948364 54.2483871,169.02075 60.3371613,111.661993 C62.1184516,94.9283259 68.7901935,81.9347458 80.1904516,72.9714326 C94.1169032,62.1380755 115.46,57.5274503 137.256516,60.5904531 C164.008258,64.3627828 180.752387,76.3890988 201.221032,106.503252 L251,73.0359168 C221.10671,29.0254038 189.594065,7.7133532 145.677161,1.52286346 C107.525161,-3.8293308 70.1828387,5.06949821 43.3015484,25.9624011 C18.9140645,44.9207759 4.17793548,72.3910741 0.680129032,105.407019 Z" id="Shape"></path>
        </g>
      </g>
  </svg>
  )
}
export default Logo