import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Waveform from 'components/player/Waveform'
import WaveformScrubber from 'components/player/WaveformScrubber'
import WaveformDisplay from 'components/player/WaveformDisplay'
import Container from 'ui/Container'
import Icon from 'ui/Icon'
import Badge from 'ui/Badge'

import { secondsToTime } from 'helpers/TimeHelper'
import { debounce } from 'helpers/Debounce'

import {
  getTrackInfo,
  getTrackUrl,
  playPauseTrack,
  toggleQueueOverlay
} from 'store/actions/playerAction'

import QueueOverlay from '../modules/queue/QueueOverlay'

class Player extends Component{

  constructor(props){
    super(props)
    this.state = {
      isPlaying:false,
      audio:null,
      scrubberPos:0,
      scrubberSelectPos:null,
      position:'0:00',
      total:'0:00',
      trackArray: []
    }

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.playPause = this.playPause.bind(this);
    this.playPrevious = this.playPrevious.bind(this);
    this.playNext = this.playNext.bind(this);
    this.getTrack = this.getTrack.bind(this);
    this.getTrackUrlDebounce = debounce(this.getTrackUrlDebounce, 500)
    // https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
    this.playPromise = null
  }

  componentDidMount(){
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount(){
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  componentDidUpdate(prevProps, prevState){
    const {
      track,
      trackUrl,
      trackType,
      library,
      location,
      loopQueue,
      playlist,
      queue
    } = this.props

    const {
      audio,
      scrubberPos,
      trackArray
    } = this.state

    if (prevProps.trackUrl !== trackUrl) {
      if (audio && this.playPromise && (this.playPromise !== undefined)) {
        this.playPromise.then(() => {
          audio.src = null
        }).catch(error => {
          console.log(error)
        });
      }

      this.setState({
        isPlaying:true,
        audio:new Audio(trackUrl),
        scrubberPos:0,
        position:'0:00',
        total:'0:00'
      },()=>{
        const {
          audio
        } = this.state

        this.playPromise = audio.play()
        audio.addEventListener('timeupdate',()=>this.timeUpdate())
      })
    }

    // if the track type (queue, playlist or library) has changed,
    // update the trackArray state
    if ((prevProps.trackType !== trackType) || (prevProps.playlist !== playlist)) {
      this.setState({
        trackArray:  trackType === 'queue' ? queue : trackType === 'library' || trackType === 'playlist-library' ? library : trackType === 'playlist' ? playlist : []
      })
    }

    // if the order of the playlist has been changed, update the track array to reflect this
    if ((prevProps.trackType === 'playlist') && (trackType === 'playlist') && (prevProps.playlist !== playlist)) {
      this.setState({
        trackArray: playlist
      })
    }

    // check if current track has ended and move onto next track
    // if it's a queue track, the queue is set to loop and we're at the end of the queue, play the first queue track
    // if it's a library track and we're not on the library page, pause the player
    // if we're at the end of the current track array, pause the player
    // otherwise, play the next track
    if ((prevState.scrubberPos !== scrubberPos) && scrubberPos === 100) {
      const isLibraryView = location.pathname === '/library'
      const isPlaylistView = location.pathname.match(/playlist/g)
      const isPlaylistLibraryView = location.pathname.match(/playlist|playlists/g) && location.search.match(/library/)
      const trackIndex = trackArray.findIndex((currentTrack) => {
        return currentTrack.id === track.id
      })

      if ((trackType === 'queue') && loopQueue && (trackIndex === trackArray.length - 1)) {
        this.playNext()
      } else if ((trackIndex === trackArray.length - 1) || (!isLibraryView && trackType === 'library') || (!isPlaylistView && trackType === 'playlist') || (!isPlaylistLibraryView && trackType === 'playlist-library')) {
        this.playPause()
      } else {
        this.playNext()
      }
    }
  }

  timeUpdate(){
    const position = (this.state.audio.currentTime/this.state.audio.duration) * 100
    this.setState({
      scrubberPos:position,
      position:secondsToTime(Math.round(this.state.audio.currentTime)),
      total:secondsToTime(Math.round(this.state.audio.duration))
    })
  }

  setScrubberSelectPos(pos){
    if(this.state.audio){
      this.setState({
        scrubberSelectPos:pos
      })
    }
  }

  selectScrubberPos(clientWidth){
    if(this.state.audio){
      const {
        audio,
        scrubberSelectPos
      } = this.state
      const time = (scrubberSelectPos/100) * audio.duration
      audio.currentTime = time
    }
  }

  getTrackUrlDebounce(track, trackType) {
    const {
      dispatch
    } = this.props

    dispatch(getTrackUrl(track, trackType))
  }

  getTrack(track, trackType) {
    const {
      dispatch
    } = this.props

    dispatch(getTrackInfo(track, trackType))
    this.getTrackUrlDebounce(track, trackType)
  }

  playPause(){
    const {
      dispatch,
      library,
      location,
      playlist,
      queue
    } = this.props

    const {
      audio,
      isPlaying
    } = this.state

    const isLibraryView = location.pathname === '/library'
    const isPlaylistView = location.pathname.match(/playlist|playlists/g) && !location.search.match(/library/)
    const isPlaylistLibraryView = location.pathname.match(/playlist|playlists/g) && location.search.match(/library/)

    // if audio is loaded, play or pause as appropriate
    // if not and we are on the library page, play the first track in the library
    // otherwise, if there are tracks in the queue, play the first track in the queue
    if (audio) {
      this.setState({ isPlaying: !isPlaying},() => {
        if(!isPlaying) {
          this.playPromise = audio.play()
        } else if (this.playPromise && (this.playPromise !== undefined)) {
          this.playPromise.then(() => {
            audio.pause()
          }).catch(error => {
            console.log(error)
          });
        }
      })
      dispatch(playPauseTrack(!isPlaying))
    } else if (!isLibraryView && !isPlaylistView && !isPlaylistLibraryView && queue.length > 0) {
      this.getTrack(queue[0], 'queue')
    } else if (isLibraryView && library.length > 0) {
      this.getTrack(library[0], 'library')
    } else if (isPlaylistLibraryView && library.length > 0) {
      this.getTrack(library[0], 'playlist-library')
    } else if (isPlaylistView && playlist.length > 0) {
      this.getTrack(playlist[0], 'playlist')
    }

  }

  playPrevious() {
    const {
      location,
      loopQueue,
      track,
      trackType
    } = this.props

    const {
      audio,
      trackArray
    } = this.state

    // if the track time is over 5 seconds, restart the track, otherwise play the previous track in the queue
    // if the queue is playing, it is set to loop and you are on the first track, play the last track in the queue
    // if a library track is playing but we're no longer on the library page, do nothing
    if (audio) {
      if (audio.currentTime > 5) {
        audio.currentTime = 0
      } else {
        const isLibraryView = location.pathname === '/library'
        const isPlaylistView = location.pathname.match(/playlist|playlists/g) && !location.search.match(/library/)
        const isPlaylistLibraryView = location.pathname.match(/playlist|playlists/g) && location.search.match(/library/)

        const currentTrackIndex = trackArray.findIndex((currentTrack) => {
          return currentTrack.id === track.id
        })

        const prevTrackIndex = currentTrackIndex - 1

        if (trackType === 'queue' && loopQueue && prevTrackIndex === -1) {
          this.getTrack(trackArray[trackArray.length - 1], trackType)
        } else if ((currentTrackIndex > 0) && (prevTrackIndex < trackArray.length)) {
          if ((!isLibraryView && trackType === 'library') || (!isPlaylistView && trackType === 'playlist') || (!isPlaylistLibraryView && trackType === 'playlist-library')) return
          this.getTrack(trackArray[prevTrackIndex], trackType)
        }
      }
    }
  }

  playNext() {
    const {
      location,
      loopQueue,
      track,
      trackType
    } = this.props

    const {
      audio,
      trackArray
    } = this.state

    // if the queue is playing, it is set to loop and you are on the last track, play the first track in the queue
    // if a library track is playing but we're no longer on the library page, do nothing
    if (audio) {
      const isLibraryView = location.pathname === '/library'
      const isPlaylistView = location.pathname.match(/playlist|playlists/g) && !location.search.match(/library/)
      const isPlaylistLibraryView = location.pathname.match(/playlist|playlists/g) && location.search.match(/library/)

      const currentTrackIndex = trackArray.findIndex((currentTrack) => {
        return currentTrack.id === track.id
      })

      const nextTrackIndex = currentTrackIndex + 1

      if ((trackType === 'queue') && loopQueue && (nextTrackIndex === trackArray.length)) {
        this.getTrack(trackArray[0], trackType)
      } else if (nextTrackIndex < trackArray.length) {
        if ((!isLibraryView && trackType === 'library') || (!isPlaylistView && trackType === 'playlist') || (!isPlaylistLibraryView && trackType === 'playlist-library')) return
        this.getTrack(trackArray[nextTrackIndex], trackType)
      }
    }
  }

  getDetails(){
    if(this.props.track){
      const {title,artist,album} = this.props.track
      return `${title} - ${artist} - ${album}`
    } else {
      return null
    }
  }

  handleKeyDown(event) {
    if (document.activeElement.tagName !== 'INPUT' && document.activeElement.tagName !== 'TEXTAREA') {
      switch (event.keyCode) {
        case 32: {
          event.preventDefault()
          this.playPause()
          break
        }
        case 37: {
          this.playPrevious()
          break
        }
        case 39: {
          this.playNext()
          break
        }
        default: //no default case
      }
    }
  }

  render(){
    const {
      dispatch,
      queue,
      queueError,
      showQueueOverlay,
      trackUrl
    } = this.props

    const {
      position,
      scrubberPos,
      scrubberSelectPos,
      total
    } = this.state

    return (
      <Container classname="player">
        <Container classname="controls">
          <Icon
            name="ios-rewind"
            classname="rewind"
            action={()=>this.playPrevious()}
          />
          <Icon
            name={`ios-${this.state.isPlaying ? 'pause' : 'play'}`}
            classname="play"
            action={()=>this.playPause()}
          />
          <Icon
            name="ios-fastforward"
            classname="fastforward"
            action={()=>this.playNext()}
          />
        </Container>
        <Container
          classname="waveform"
          height="100%"
        >
          <Waveform
            src={trackUrl}
            setScrubberSelectPos={(pos)=>this.setScrubberSelectPos(pos)}
            selectScrubberPos={(clientWidth)=>this.selectScrubberPos(clientWidth)}
          />
          <WaveformScrubber
            scrubberPos={scrubberPos}
            scrubberSelectPos={scrubberSelectPos}
          />
          <WaveformDisplay
            position={position}
            total={total}
            details={this.getDetails()}
          />
        </Container>
        {/* Temp removal for initial launch */}
        {/*<Icon name="ios-add" classname="add" />*/}
        <div
          className='player__queue-overlay-btn'
          onClick={(e)=>{
            dispatch(toggleQueueOverlay(!showQueueOverlay));
            e.stopPropagation()
          }}
        >
          <Icon name="ios-list" classname="list" />
          {queue && queue.length > 0 && (
            <Badge data={queue.length} />
          )}
        </div>
        <QueueOverlay />
        {queueError && (
          <div className='schedule-creator__overlay'>
            <div className='schedule-creator'>
              {queueError}
            </div>
          </div>
        )}
      </Container>
    )
  }
}

function mapStateToProps(store){
  return {
    library: store.library.tracks,
    loopQueue: store.player.loopQueue,
    queue: store.player.queue,
    queueError: store.player.error,
    showQueueOverlay: store.player.showQueueOverlay,
    trackUrl: store.player.trackUrl,
    track:  store.player.track,
    trackType:  store.player.trackType,
    playlist: store.playlist.tracks
  }
}

export default withRouter(connect(mapStateToProps)(Player))

