import React, { Component, Fragment } from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

import {
  getDashboard,
  getDashboardPlaylist,
  getDashboardPlaylistTracks
} from 'store/actions/dashboardAction';

import DashboardUnit from 'components/dashboard/DashboardUnit'
import DashboardPieChart from 'components/dashboard/DashboardPieChart'
import DashboardBarChart from 'components/dashboard/DashboardBarChart'
import FormSelect from 'ui/FormSelect'
import Container from 'ui/Container'
import Loader from 'ui/Loader'

const classname = 'dashboard';

class DashboardContainer extends Component {

  constructor(props){
    super(props)

    this.state = {
      pdfMode: false
    };

    this.createPdf = this.createPdf.bind(this);
  }

  componentDidMount() {
    const {
      dispatch,
      dashboard
    } = this.props;

    // Check if we have already loaded the dashboard into the store
    // before making the request again
    if (dashboard.loaded) {
      console.log("Loading dashboard from store")
    } else {
      dispatch(getDashboard())
    }
  }

  componentDidUpdate(prevProps) {
    const {
      dispatch,
      dashboard
    } = this.props;

    if ((Object.keys(prevProps.dashboard).length === 0) && (Object.keys(dashboard).length > 0) && dashboard.playlists.length > 0) {
      dispatch(getDashboardPlaylistTracks(dashboard.playlists[0].id))
      // the labels on the dashboard genres pie chart does not display correctly unless it is the last chart
      // element rendered. Calling setTimeout forces the code to run async, so that it waits for the current
      // execution block to be completed before firing
      setTimeout(() => dispatch(getDashboardPlaylist(dashboard.playlists[0].id)), 0)
    }
  }

  setHeader(header){
    this.setState({header:header})
  }

  createPdf() {
    const {
      dashboard
    } = this.props

    const input = document.getElementById('dashboard__report-data');

    this.setState({
      pdfMode: true
    },
    () => {
      setTimeout(() => {html2canvas(input)
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF('l', 'mm', 'a4');
          const imgWidth = canvas.width;
          const imgHeight = canvas.height;
          const imgRatio = imgWidth/imgHeight;
          const pageWidth = pdf.internal.pageSize.getWidth();
          const pageHeight = pdf.internal.pageSize.getHeight();
          const pageRatio = pageWidth/pageHeight;

          // Adds the canvas object to the dom, userful for testing
          // document.querySelector('.dashboard').append(canvas);

          const wc = imgWidth / pageWidth;
          if (imgRatio >= pageRatio) {
            pdf.addImage(imgData, 'JPEG', 0, 0, pageWidth, imgHeight / wc, null, 'NONE');
          }
          else {
            const pi = pageRatio / imgRatio;
            pdf.addImage(imgData, 'JPEG', 0, 0, pageWidth / pi, (imgHeight / pi) / wc, null, 'NONE');
          }

          pdf.save(`open-ear-report-${dashboard.businessName}__${this.getReportDate()}.pdf`);

          this.setState({
            pdfMode: false
          });
        })}, 5000);
    });
  }

  formatTrackBreakdown(data) {
    let totals = 0;
    const trackBreakdown = Object.entries(data).map((playlistData) => {
      const date = new Date(playlistData[0]);  // 2009-11-10
      const month = date.toLocaleString('default', { month: 'short' });
      const data = {
        name:month,
        Existing: playlistData[1].total,
        New: playlistData[1].new
      }

      totals = totals + playlistData[1].total + playlistData[1].new

      return data
    })

    // if we have no data at all, return an empty array
    if (totals === 0) {
      return []
    }

    // Reverse array order to make it chronological and return
    return trackBreakdown.reverse()
  }

  getReportDate () {
    const dateObj = new Date();
    const month = dateObj.getMonth() + 1; //months from 1-12
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();

    return `${day}/${month}/${year}`;
  }

  formatTimeTotal(time) {
    if (!time) return;
    const timeArray = time.split(":");
    return `${timeArray[0] === '0' ? '' : `${timeArray[0]}d`}  ${timeArray[1]}h ${timeArray[2]}m ${timeArray[3]}s`;
  }

  selectPlaylist(id) {
    const {
      dispatch
    } = this.props

    dispatch(getDashboardPlaylistTracks(id))
    // the labels on the dashboard genres pie chart does not display correctly unless it is the last chart
    // element rendered. Calling setTimeout forces the code to run async, so that it waits for the current
    // execution block to be completed before firing
    setTimeout(() => dispatch(getDashboardPlaylist(id)), 0)
  }

  render(){
    const {
      dashboard,
      dashboardPlaylist,
      dashboardPlaylistTracks,
      loading,
      playlistLoading,
      playlistTracksLoading,
      userGroup
    } = this.props;

    const {
      pdfMode
    } = this.state;

    return (
      <Container classname={classname} maxHeight maxWidth column>
        {pdfMode && (
            <div className='dashboard__pdf-loader-container'>
              <div className='dashboard__loader'>
                <Loader/>
              </div>
            </div>
        )}
        {loading ? (
            <div className='dashboard__loader'>
              {/* Removing for the time being due to a lack of exciting messages */}
              {/*<p className='dashboard__loader-message'>Exciting message explaining functionality goes here</p>*/}
              <Loader/>
            </div>
        ) : (
          <div className='dashboard'>
            <h2>{dashboard.businessName}</h2>
            {dashboard.playlistCount === 0 ? (
              <div className='dashboard__no-data'>
                <p><Link to='/playlists?tab=main'>Subscribe to a playlist</Link> to show dashboard data</p>
              </div>
            ) : (
              <Fragment>
                <button
                  className='dashboard__report-data-btn'
                  type='button'
                  onClick={() => { this.createPdf() }}
                  data-html2canvas-ignore='true'
                >
                  Download PDF
                </button>
                <div className='dashboard__row'>
                  <div
                    id='dashboard__report-data'
                    className={pdfMode ? 'dashboard__main dashboard__main--pdf' : 'dashboard__main'}
                  >
                    {pdfMode && (
                      <Fragment>
                        <h2>
                          {dashboard.businessName} - {this.getReportDate()}
                        </h2>
                        <img
                          src='/logo-black.png' alt='Open Ear Music'
                          className='dashboard__oe-pdf-logo'
                        />
                      </Fragment>
                    )}
                    <div className='dashboard__row'>
                      <DashboardUnit
                        header='Total tracks'
                        width='col2'
                      >
                        <div className="dashboard__data">
                          {dashboard.totalTracks}
                        </div>
                      </DashboardUnit>
                      <DashboardUnit
                        header='Total duration'
                        width='col2'
                      >
                        <div className="dashboard__data">
                          {this.formatTimeTotal(dashboard.timeTotal)}
                        </div>
                      </DashboardUnit>
                      <DashboardUnit
                        header='New tracks this month'
                        width='col2'
                      >
                        <div className="dashboard__data">
                          {dashboard.new}
                        </div>
                      </DashboardUnit>
                      <DashboardUnit
                        header='Average track energy'
                        width='col2'
                      >
                        <div className="dashboard__data">
                          {dashboard.avgEnergy}
                        </div>
                      </DashboardUnit>
                    </div>
                    <div className='dashboard__row'>
                      <DashboardUnit
                        header='Genres'
                        width='col3'
                      >
                        {dashboard.genres && (
                          <div>
                            <DashboardPieChart
                              data={dashboard.genres}
                              animate={false}
                            />
                          </div>
                        )}
                      </DashboardUnit>
                      <DashboardUnit
                        header='Tracks per month'
                        width='col5'
                      >
                        {dashboard.breakdown && (this.formatTrackBreakdown(dashboard.breakdown).length > 0)  ? (
                          <DashboardBarChart
                            data={this.formatTrackBreakdown(dashboard.breakdown)}
                          />
                        ) : (
                          <p className='dashboard-unit__no-data-message'>Sorry, no data available for this period</p>
                        )}
                      </DashboardUnit>
                    </div>
                    {!pdfMode && dashboard.playlists && (
                      userGroup === 9 ?
                      null :
                      <div className='dashboard__row'>
                        <DashboardUnit
                          header='Playlists'
                          width='col7'
                        >
                          <div className='dashboard__playlists-form'>
                            <FormSelect
                              action={e=>this.selectPlaylist(e.target.value)}
                              options={dashboard.playlists.map((playlist) => {
                                return ({
                                  'display': playlist.name,
                                  'value': playlist.id
                                })
                              })}
                            />
                          </div>
                          <div className='dashboard__playlists'>
                            <div className='dashboard__playlists-data'>
                              <div className='dashboard__data'>
                                <h6>Total tracks</h6>
                                {dashboardPlaylist.count}
                              </div>
                              <div className='dashboard__data'>
                                <h6>Total playlist duration</h6>
                                {this.formatTimeTotal(dashboardPlaylist.total_length)}
                              </div>
                              <div className='dashboard__data'>
                                <h6>Average track energy</h6>
                                {dashboardPlaylist.energy}
                              </div>
                            </div>
                            <div className='dashboard__playlists-genres'>
                              <h6>Genres</h6>
                              <div className='dashboard__playlists-genres-pie-chart'>
                                {playlistLoading ? (
                                  <Loader />
                                ) : (
                                  <Fragment>
                                    {dashboardPlaylist.genres && Object.keys(dashboardPlaylist.genres).length > 1 ? (
                                      <div className='dashboard__playlists-genres-pie-chart-wrapper'>
                                        <DashboardPieChart
                                          data={dashboardPlaylist.genres}
                                          animate={true}
                                        />
                                      </div>
                                    ) : (
                                      <p>Sorry, no data available for this playlist</p>
                                    )}
                                  </Fragment>
                                )}
                              </div>
                            </div>
                            <div className='dashboard__playlists-breakdown'>
                              <h6>Track breakdown</h6>
                              {playlistTracksLoading ? (
                                <Loader />
                              ) : (
                                <Fragment>
                                  {dashboardPlaylistTracks && this.formatTrackBreakdown(dashboardPlaylistTracks).length > 0 ? (
                                    <div className='dashboard__playlists-breakdown-wrapper'>
                                      <DashboardBarChart
                                        data={this.formatTrackBreakdown(dashboardPlaylistTracks)}
                                      />
                                    </div>
                                  ) : (
                                    <p>Sorry, no data available for this playlist</p>
                                  )}
                                </Fragment>
                              )}
                            </div>
                          </div>
                        </DashboardUnit>
                      </div>
                    )}
                  </div>
                  {/*Disabling during temp removal of album and playlist of the month*/}
                  {/*{!pdfMode && (*/}
                  {/*  <div className='dashboard__secondary'>*/}
                  {/*    <DashboardUnit*/}
                  {/*        header='Album of the month'*/}
                  {/*        width='col2'*/}
                  {/*    >*/}
                  {/*      <img*/}
                  {/*        className='dashboard__image'*/}
                  {/*        src="/albumCovers/124835-2.jpg"*/}
                  {/*      />*/}
                  {/*    </DashboardUnit>*/}
                  {/*    <DashboardUnit*/}
                  {/*        header='Playlist of the month'*/}
                  {/*        width='col2'*/}
                  {/*    >*/}
                  {/*      <img*/}
                  {/*        className='dashboard__image'*/}
                  {/*        src="/albumCovers/mono.jpg"*/}
                  {/*      />*/}
                  {/*    </DashboardUnit>*/}
                  {/*    <DashboardUnit*/}
                  {/*        header='Playlist of the month'*/}
                  {/*        width='col2'*/}
                  {/*    >*/}
                  {/*      <img*/}
                  {/*        className='dashboard__image'*/}
                  {/*        src="/albumCovers/126713-1.jpg"*/}
                  {/*      />*/}
                  {/*    </DashboardUnit>*/}
                  {/*  </div>*/}
                  {/*)}*/}
                </div>
              </Fragment>
            )}

          </div>
        )}
      </Container>
    )
  }
}

function mapStateToProps(store){
  return {
    dashboard: store.dashboard.dashboard,
    dashboardPlaylist: store.dashboard.dashboardPlaylist,
    dashboardPlaylistTracks: store.dashboard.dashboardPlaylistTracks,
    loading: store.dashboard.loading,
    playlistLoading: store.dashboard.playlistLoading,
    playlistTracksLoading: store.dashboard.playlistTracksLoading,
    userGroup:store.auth.userGroup
  }
}
export default connect(mapStateToProps)(DashboardContainer)
