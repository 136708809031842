import React, {Component, Fragment} from 'react'
import { connect } from 'react-redux'
import { Tooltip } from "recharts"

import { getPlaylistsOverlay, addTrackToPlaylistOwnedMulti } from 'store/actions/playlistAction'

import Container from 'ui/Container'
import Icon from "ui/Icon"

const classname = 'overlayPlaylistsLibrary'

class OverlayMultiPlaylistsLibrary extends Component{

  constructor(props){
    super(props)

    this.state = {
      alreadyHasTracks: [],
      addedPlaylists:[]
    }
  }

  componentDidMount(){
    //send track id so we can set assigned playlists
    this.props.dispatch(getPlaylistsOverlay(this.props.data[0].id))
  }

  assignPlaylists(playlist){
    const {
      data,
      dispatch
    } = this.props

    data.map((track) => {
      dispatch(addTrackToPlaylistOwnedMulti(
        track,
        playlist
      ))
      this.setState({
        addedPlaylists:[
          ...this.state.addedPlaylists,
          playlist
        ]
      })
    })
  }

  getPlaylists() {
    const {
      playlists,
      curr_playlist_id
    } = this.props

    const {
      addedPlaylists
    } = this.state

    return playlists
      .filter(playlist => curr_playlist_id !== playlist.id)
      .map((playlist,index)=>{
        return (
          <Fragment key={index}>
            <div className='playlists-overlay'
              onClick={()=>this.assignPlaylists(playlist)}>
              {playlist.name}
              {
                addedPlaylists.find(addedPlaylist => addedPlaylist.id === playlist.id) ?
                <Icon name="checkmark-circle"/> :
                <Icon name="add-circle">
                  <Tooltip text="Add tracks to playlist" pos='left'/>
                </Icon>
              }
            </div>
          </Fragment>
        )
    })
  }

  render(){

    return (
      <Container classname={classname} maxWidth height="100%" column>
        <h1>Add tracks to...</h1>
        <div className="playlists-overlay-wrapper">
          {this.getPlaylists()}
        </div>
      </Container>
    )
  }
}
function mapStateToProps(store){
  return {
    overlay:store.overlay,
    playlists:store.playlists.data,
    loading:store.overlay.loading,
    curr_playlist_id:store.playlist.details.id
  }
}
export default connect(mapStateToProps)(OverlayMultiPlaylistsLibrary)
