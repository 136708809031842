import axios from 'axios'

export function getPlaylists(type,limit,offset){

  const headers = {
    'Limit':limit,
    'Offset':offset
  }
  const urlMap = {
    owned:'playlists/owner',
    subscribed:'playlists/subscribed',
    recent:'playlists/recent',
    main:'playlists/main',
    dashboard:'playlists/dashboard',
    assigned:'playlists/assigned',
  }
  return (dispatch) => {
    return dispatch({
      type:type === 'dashboard' ? 'FETCH_PLAYLISTS_DASHBOARD' : 'FETCH_PLAYLISTS',
      payload:axios.get(urlMap[type],{headers:headers}),
      meta:type
    })
  }
}

export function getPlaylistsDashboard(){

}

export function getPlaylistQuick(id){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_PLAYLIST_QUICK',
      payload:axios.get(`playlist/quick/${id}`)
    })
  }
}

export function getPlaylistsOverlay(trackID){

  return (dispatch) => {
    return dispatch({
      type:'FETCH_PLAYLISTS_OVERLAY',
      payload:axios.get(`playlists/overlay/owned/${trackID}`),
      meta:trackID
    })
  }

}

export function clearPlaylists(){
  return (dispatch) => {
    return dispatch({
      type:'CLEAR_PLAYLISTS',
      payload:null
    })
  }
}

export function clearPlaylist(){
  return (dispatch) => {
    return dispatch({
      type:'CLEAR_PLAYLIST',
      payload:null
    })
  }
}

export function getPlaylist(id){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_PLAYLIST',
      payload:axios.get(`playlist/${id}`)
    })
  }
}

export function getPlaylistDetails(id){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_PLAYLIST_DETAILS',
      payload:axios.get(`playlist/details/owner/${id}`)
    })
  }
}

export function getPlaylistTracks(id){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_PLAYLIST_TRACKS',
      payload:axios.get(`playlist/tracks/${id}`)
    })
  }
}

export function getPlaylistOrdered(id){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_PLAYLIST_TRACKS_ORDERED',
      payload:axios.get(`playlist/ordered/${id}`)
    })
  }
}

export function orderPlaylist(newIndex,track){
  return (dispatch) => {
    return dispatch({
      type:'ORDER_PLAYLIST_TRACKS',
      payload:{newIndex:newIndex,track:track},
      meta:track
    })
  }
}

export function updatePlaylistOrder(tracks,playlistID){
  return (dispatch) => {
    const data = tracks.map(track => {
      const data = {trackID:track.id,index:track.index}
      return data
    })
    return dispatch({
      type:'UPDATE_PLAYLIST_ORDER',
      payload:axios.post(`playlist/order/${playlistID}`,data)
    })
  }
}

export function getPlaylistVenues(id){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_PLAYLIST_VENUES',
      payload:axios.get(`playlist/venues/${id}`)
    })
  }
}

export function pinPlaylist(id){
  return (dispatch) => {
    return dispatch({
      type:'PIN_PLAYLIST',
      payload:axios.put(`playlists/pin/${id}`)
    })
  }
}
export function unpinPlaylist(playlist){
  return (dispatch) => {
    return dispatch({
      type:'UNPIN_PLAYLIST',
      payload:axios.delete(`playlists/pin/${playlist.pinned_id}`),
      meta:playlist.id
    })
  }
}
export function filterPlaylistTracks(term){
  return (dispatch) => {
    return dispatch({
      type:'FILTER_PLAYLIST_TRACKS',
      payload:term
    })
  }
}
export function playlistTypeaheadAll(term){
  return (dispatch) => {
    return dispatch({
      type:'PLAYLISTS_TYPEAHEAD_ALL',
      payload:axios.post(`playlists/typeahead/all`,{term:term})
    })
  }
}
export function clearPlaylistTypeahead(){
  return (dispatch) => {
    return dispatch({
      type:'CLEAR_PLAYLIST_TYPEAHEAD',
      payload:null
    })
  }
}
export function getAllUserPlaylistsQuick(){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_PLAYLIST_USER_QUICK',
      payload:axios.get(`playlist/user/all/quick`)
    })
  }
}
export function createPlaylist(data){
  return (dispatch) => {
    return dispatch({
      type:'CREATE_PLAYLIST',
      payload:axios.post(`playlist/create`,data)
    })
  }
}
export function updatePlaylistDetails(playlist_id, data){
  return (dispatch) => {
    return dispatch({
      type:'UPDATE_PLAYLIST_DETAILS',
      payload:axios.put(`playlist/details/${playlist_id}`,data),
      meta:data
    })
  }
}
export function removeTrackFromPlaylist(track,playlist_id){
  return (dispatch) => {
    return dispatch({
      type:'DELETE_PLAYLIST_TRACK',
      payload:axios.delete(`playlist/track/${track.pivot_id}/${playlist_id}`),
      meta:track
    })
  }
}

export function removeTrackFromPlaylistLibrary(track,playlist){
  return (dispatch) => {
    return dispatch({
      type:'DELETE_PLAYLIST_TRACK_LIBRARY',
      payload:axios.delete(`playlist/track/${track.id}/${playlist.id}`),
      meta:playlist
    })
  }
}

export function removeTrackFromPlaylistOwned(track,playlistID){
  return (dispatch) => {
    return dispatch({
      type:'DELETE_PLAYLIST_TRACK_OWNED',
      payload:axios.delete(`playlist/track/${track}/${playlistID}`),
      meta:track
    })
  }
}

export function removeTrackFromPlaylistOwnedUsingPivot(track,playlist_id){
  return (dispatch) => {
    return dispatch({
      type:'DELETE_PLAYLIST_TRACK_OWNED',
      payload:axios.delete(`playlist/track/pivot/${track.pivot_id}/${playlist_id}`),
      meta:track
    })
  }
}

export function addTrackToPlaylist(track,playlist){
  return (dispatch) => {
    return dispatch({
      type:'ADD_PLAYLIST_TRACK',
      payload:axios.put(`playlist/track/${track.id}/${playlist.id}`),
      meta:playlist
    })
  }
}

export function addTrackToPlaylistOwned(track,playlist){
  return (dispatch) => {
    return dispatch({
      type:'ADD_PLAYLIST_TRACK_OWNED',
      payload:axios.put(`playlist/track/${track.id}/${playlist.id}`),
      meta:track
    })
  }
}
export function addTrackToPlaylistOwnedMulti(track,playlist){
  return (dispatch) => {
    return dispatch({
      type:'ADD_PLAYLIST_TRACK_OWNED_MULTI',
      payload:axios.put(`playlist/track/${track.id}/${playlist.id}`)
    })
  }
}

export function searchPlaylistsQuick(term){
  return (dispatch) => {
    return dispatch({
      type:'SEARCH_PLAYLISTS_QUICK',
      payload:axios.get(`playlists/search/quick/${term}`)
    })
  }
}

export function filterPlaylistsTypeahead(term){
  return (dispatch) => {
    return dispatch({
      type:'TYPEAHEAD_PLAYLISTS',
      meta: term
    })
  }
}

export function subscribePlaylist(playlistID){
  return (dispatch) => {
    return dispatch({
      type:'PUT_SUBSCRIBE_PLAYLIST',
      payload:axios.put(`playlist/subscribe/${playlistID}`),
      meta:playlistID
    })
  }
}

export function unsubscribePlaylist(playlist, isMain){
  return (dispatch) => {
    return dispatch({
      type:'PUT_UNSUBSCRIBE_PLAYLIST',
      payload:axios.put(`playlist/unsubscribe/${playlist.id}`),
      meta: {
        playlistID: playlist.id,
        isMain: isMain
      }
    })
  }
}

export function subscribePlaylistDetails(playlistID){
  return (dispatch) => {
    return dispatch({
      type:'PUT_SUBSCRIBE_PLAYLIST_DETAILS',
      payload:axios.put(`playlist/subscribe/${playlistID}`),
      meta:playlistID
    })
  }
}

export function unsubscribePlaylistDetails(playlist, isMain){
  return (dispatch) => {
    return dispatch({
      type:'PUT_UNSUBSCRIBE_PLAYLIST_DETAILS',
      payload:axios.put(`playlist/unsubscribe/${playlist.id}`),
      meta: {
        playlistID: playlist.id,
        isMain: isMain
      }
    })
  }
}

export function addVenueToPlaylist(playlist_id,venue){
  return (dispatch) => {
    return dispatch({
      type:'ADD_VENUE_TO_PLAYLIST',
      payload:axios.put(`playlist/venues/${playlist_id}/${venue.id}`),
      meta: venue
    })
  }
}

export function removeVenueFromPlaylist(venueID,playlistID){
  return (dispatch) => {
    return dispatch({
      type:'REMOVE_VENUE_FROM_PLAYLIST',
      payload:axios.delete(`venue/playlist/remove/${venueID}/${playlistID}`),
      meta: {
        playlistID: playlistID,
        venueID: venueID
      }
    })
  }
}

export function deletePlaylist(playlist) {
  return (dispatch) => {
    return dispatch({
      type:'DELETE_PLAYLIST',
      payload:axios.delete(`playlist/delete/${playlist.id}`),
      meta: {
        playlistID: playlist.id
      }
    })
  }
}

export function copyPlaylist(dt) {
  const data = {
    playlist_id:dt.playlist_id,
    playlist_type:dt.playlist_type,
    playlist_name:dt.playlist_name
    //settings:dt.settings
  }
  return (dispatch) => {
    
    return dispatch({
      type:'COPY_PLAYLIST',
      payload:axios.post(`playlist/copy`,data)
    })
  }
}
export function removeDuplicates(playlist_id){
  return (dispatch) => {
    return dispatch({
      type:'REMOVE_DUPLICATES',
      payload:axios.put(`playlist/tracks/remove-duplicates`,{
        playlist_id:playlist_id
      })
    })
  }
}