const FormModel = {
  venueDetails:{
    form:[
      {elem:'legend',label:'Venue details'},
      {elem:'input',type:'text',name:'name',label:'Venue name',field:'name',required:true},
      {elem:'input',type:'text',name:'contact_name',label:'Contact name',field:'contact_name',required:true},
      {elem:'input',type:'text',name:'contact_number',label:'Contact number',field:'contact_number',required:true},
      {elem:'input',type:'text',name:'address_line_1',label:'Address Line 1',field:'address_line_1',required:true},
      {elem:'input',type:'text',name:'address_line_2',label:'Address Line 2',field:'address_line_2'},
      {elem:'input',type:'text',name:'town',label:'Town',field:'town',required:true},
      {elem:'input',type:'text',name:'postcode',label:'Postcode',field:'postcode',required:true}
    ],
    submitLabel:'Save venue details'
  },
  venueSettings:{
    form:[
      {
        elem:'legend',
        label:'Venue settings'
      },
      {
        elem:'select',
        label:'File size',
        name:'file_size',
        field:'file_size',
        optionsProp:'fileSizes'
      }
    ],
    submitLabel:'Save venue settings'
  },
  venueUser:{
    form:[
      {elem:'legend',label:'Venue user'},
      {elem:'input',type:'text',name:'loginHash',label:'Login hash',field:'loginHash',disabled:true},
      {elem:'input',type:'text',name:'username',label:'Venue user',field:'username',required:true},
      {
        elem:'checkbox',
        name:'changePw',
        label:'Change password?',
        field:'changePw'
      },
      {
        elem:'input',
        type:'password',
        name:'password',
        label:'Password',
        field:'password',
        isPassword:true,
        confirm:'passwordConf',
        disabled:true,
        enabledBy:'changePw'
      },
      {
        elem:'input',
        type:'password',
        name:'passwordConf',
        label:'Confirm Password',
        field:'passwordConf',
        disabled:true,
        enabledBy:'changePw'
      }
    ],
    submitLabel:'Save venue details'
  },
  playlistDetails:{
    form:[
      {elem:'legend',label:'Playlist details'},
      {elem:'input',type:'text',name:'name',label:'Name',field:'name'},
      {elem:'textarea',name:'style',label:'Description',field:'style'}
    ],
    submitLabel:'Save playlist details'
  },
  playlistDetailsOwned:{
    form:[
      {elem:'legend',label:'Playlist details'},
      {elem:'input',type:'text',name:'name',label:'Name',field:'name'},
      {elem:'textarea',name:'style',label:'Description',field:'style'}
    ],
    submitLabel:'Save playlist details'
  },
  auth:{
    form:[
      {elem:'input',type:'text',name:'username',label:'Username'},
      {elem:'input',type:'password',name:'password',label:'Password'}
    ],
    submitLabel:'Login'
  },
  fitnessClasses:{
    form:[
      {elem:'legend',label:'Create a class'},
      {elem:'input',type:'text',name:'className',label:'Class Name'}
    ],
    submitLabel:'Create class'
  },
  playlists:{
    form:[
      {elem:'legend',label:'Create a playlist'},
      {elem:'input',type:'text',name:'name',label:'Playlist Name',required:true}
    ],
    submitLabel:'Create playlist'
  },
  venues:{
    form:[
      {elem:'legend',label:'Create a venue'},
      {elem:'input',type:'text',name:'venueName',label:'Venue Name',required:true,errorMessage:'Please enter the venue name'},
      {elem:'input',type:'text',name:'contactName',label:'Contact Name',required:true,errorMessage:'Please enter the contact name'},
      {elem:'input',type:'text',name:'contactNumber',label:'Contact number',required:true,errorMessage:'Please enter a valid phone number'},
      {elem:'input',type:'text',name:'address1',label:'Address Line 1',required:true,errorMessage:'Please enter the first line of the address'},
      {elem:'input',type:'text',name:'address2',label:'Address Line 2'},
      {elem:'input',type:'text',name:'town',label:'Town'},
      {elem:'input',type:'text',name:'postcode',label:'Postcode',required:true,errorMessage:'Please enter the postcode'},
      {elem:'legend',label:'Venue user account'},
      {elem:'input',type:'text',name:'username',label:'Venue username',required:true,errorMessage:'Please enter a username for the venue'},
      {elem:'input',type:'password',name:'password',label:'Password',required:true, isPassword: true, confirm: 'confirmPassword',errorMessage:'Please enter a password'},
      {elem:'input',type:'password',name:'confirmPassword',label:'Confirm password',required:true,isConfirm:true,password:'password',errorMessage:'Please ensure passwords match'},
    ],
    submitLabel:'Create venue'
  },
  overlayVenueCopy:{
    form:[
      {elem:'legend',label:'Venue details'},
      {elem:'input',type:'text',name:'venueName',label:'Venue Name',required:true,errorMessage:'Please enter the venue name'},
      {elem:'input',type:'text',name:'contactName',label:'Contact Name',required:true,errorMessage:'Please enter the contact name'},
      {elem:'input',type:'text',name:'contactNumber',label:'Contact number',required:true,errorMessage:'Please enter a valid phone number'},
      {elem:'input',type:'text',name:'address1',label:'Address Line 1',required:true,errorMessage:'Please enter the first line of the address'},
      {elem:'input',type:'text',name:'address2',label:'Address Line 2'},
      {elem:'input',type:'text',name:'town',label:'Town',required:true,errorMessage:'Please enter the town or city'},
      {elem:'input',type:'text',name:'postcode',label:'Postcode',required:true,errorMessage:'Please enter the postcode'},
      {elem:'legend',label:'Venue user account'},
      {elem:'input',type:'text',name:'username',label:'Venue username',required:true,errorMessage:'Please enter a username for the venue'},
      {elem:'input',type:'password',name:'password',label:'Password',required:true, isPassword: true, confirm: 'confirmPassword',errorMessage:'Please enter a password'},
      {elem:'input',type:'password',name:'confirmPassword',label:'Confirm password',required:true,isConfirm:true,password:'password',errorMessage:'Please ensure passwords match'},
    ],
    submitLabel:'Copy venue'
  },
  createPlaylistAddVenues:{
    form:[
      {elem:'legend',label:'Add venues'},
      {elem:'checklist',name:'venues'}
    ],
    skipLabel:'Skip this step',
    submitLabel:'Add venues'
  }
}

export default FormModel
