import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Icon from 'ui/Icon'

import {
  getTrackInfo,
  getTrackUrl,
  fetchQueue,
  deleteFromQueue,
  deleteAllFromQueue,
  toggleQueueLoop,
  toggleQueueOverlay
} from 'store/actions/playerAction'

import { toggleOverlay } from 'store/actions/overlayAction'

import { debounce } from 'helpers/Debounce'

import QueueOverlayItem from './QueueOverlayItem'

class QueueOverlay extends Component{

  constructor(props){
    super(props)

    this.getTrackUrlDebounce = debounce(this.getTrackUrlDebounce, 500)
  }

  componentDidMount() {
    const {
      dispatch
    } = this.props

    dispatch(fetchQueue())
  }

  componentDidUpdate(prevProps) {
    const {
      showQueueOverlay,
      dispatch
    } = this.props

    if(showQueueOverlay && (showQueueOverlay !== prevProps.showQueueOverlay)) {
      dispatch(fetchQueue())
    }
  }

  clearQueue() {
    const {
      dispatch
    } = this.props

    dispatch(deleteAllFromQueue())
  }

  getTrackUrlDebounce(track, trackType) {
    const {
      dispatch
    } = this.props

    dispatch(getTrackUrl(track, trackType))
  }

  getTrack(track, trackType) {
    const {
      dispatch
    } = this.props

    dispatch(getTrackInfo(track, trackType))
    this.getTrackUrlDebounce(track, trackType)
  }

  render(){
    const {
      loopQueue,
      showQueueOverlay,
      dispatch,
      queue,
      playingTrack
    } = this.props

    return (
      <Fragment>
        {showQueueOverlay && (
          <div className='queue-overlay__wrapper'>
            <div
              className='queue-overlay'
              onClick={(e) => {e.stopPropagation()}}
            >
              {queue && (queue.length > 0) ? (
                <Fragment>
                  <div
                    className='queue-overlay__header'>
                    <div className={`queue-overlay__loop-btn ${loopQueue ? 'queue-overlay__loop-btn--active' : ''}`}>
                      <Icon
                        name="ios-refresh-circle"
                        action={() => { dispatch(toggleQueueLoop(!loopQueue)) }}
                      />
                    </div>
                    <button
                      className='queue-overlay__submit-btn'
                      onClick={() => {
                        dispatch(toggleOverlay(true, queue, 'multiPlaylistsAdd'))
                        dispatch(toggleQueueOverlay(false))
                      }}
                    >
                      Add all to playlist
                    </button>
                    <button
                      className='queue-overlay__delete-btn'
                      onClick={() => { this.clearQueue()}}
                    >
                      Clear all
                    </button>
                  </div>
                  <ul className='queue-overlay__tracks'>
                    {queue && queue.map((item, index) =>
                      <QueueOverlayItem
                        key={index}
                        track={item}
                        playTrack={
                          (track)=>{this.getTrack(track, 'queue')}
                        }
                        removeFromQueue={
                          (track)=>{dispatch(deleteFromQueue(track))}
                        }
                        playingTrack={playingTrack}
                      />
                    )}
                  </ul>
                </Fragment>
              ) : (
                <p className='queue-overlay__empty-message'>Queue tracks will be shown here</p>
              )}

            </div>
          </div>
        )}
      </Fragment>
    )
  }
}

function mapStateToProps(store){
  return {
    loopQueue: store.player.loopQueue,
    showQueueOverlay: store.player.showQueueOverlay,
    queue: store.player.queue,
    playingTrack: store.player.track
  }
}

export default withRouter(connect(mapStateToProps)(QueueOverlay))
