import { applyMiddleware, createStore } from 'redux'

import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'
import promise from 'redux-promise-middleware'

import reducers from './reducers'

import { routerMiddleware } from 'react-router-redux'
import { createBrowserHistory } from 'history'

const history = createBrowserHistory()
const routerMw = routerMiddleware(history)
const middleware = applyMiddleware(routerMw, thunk, promise, createLogger())

export default createStore(reducers,middleware)

