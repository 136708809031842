import React, {Component, Fragment} from 'react'
import {connect} from "react-redux";

import { getPlaylist } from 'store/actions/playlistAction'

import {
  getTrackInfo,
  getTrackUrl
} from 'store/actions/playerAction'

import PlaylistsCarousel from 'components/PlaylistsCarousel'
import Loader from 'ui/Loader'
import Container from 'ui/Container'

class PlaylistsDashboard extends Component {

  constructor(props){
    super(props)

    this.state = {
      activePreview: null,
      currentlyPlaying: null
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      dispatch,
      playlist,
      tracks
    } = this.props

    const {
      activePreview
    } = this.state

    if (!activePreview && (tracks.length > 0) && (tracks !== prevProps.tracks) && (prevProps.playlist.id !== playlist.id)) {
      dispatch(getTrackInfo(tracks[0], 'playlist'))
      dispatch(getTrackUrl(tracks[0], 'playlist'))
    }
  }

  playPlaylist(id){
    const {
      dispatch
    } = this.props

    this.setState({
      currentlyPlaying: id
    })

    dispatch(getPlaylist(id))
  }

  showPlaylistPreview(playlistID) {
    this.setState({
      activePreview: playlistID
    })
  }

  getCarousel(carouselData) {
    const {
      activePreview,
      currentlyPlaying
    } = this.state

    return (
      <PlaylistsCarousel
        carouselData={carouselData}
        playPlaylist={(playlistID)=>this.playPlaylist(playlistID)}
        showPlaylistPreview={(playlistID)=>this.showPlaylistPreview(playlistID)}
        activePreview={activePreview}
        currentlyPlaying={currentlyPlaying}
      />
    )
  }

  render() {
    const {
      picks,
      featured,
      topical
    } = this.props

    if(picks.length === 0) {
      return <Loader/>
    } else {
      return (
        <Container classname="playlist-wrapper" column maxHeight>
          <div className="playlists-dashboard">
            <h2 className="playlists-dashboard__header">My Picks</h2>
            {this.getCarousel(picks)}
            {featured.length > 0 && (
              <Fragment>
                <h2 className="playlists-dashboard__header">Featured Playlists</h2>
                {this.getCarousel(featured)}
              </Fragment>
            )}
            {topical.length > 0 &&   (
              <Fragment>
                <h2 className="playlists-dashboard__header">Topical Playlists</h2>
                {this.getCarousel(topical)}
              </Fragment>
            )}
          </div>
        </Container>
      )
    }

  }
}

function mapStateToProps(store){
  return {
    tracks:store.playlist.tracks,
    playlist: store.playlist.details
  }
}

export default connect(mapStateToProps)(PlaylistsDashboard)
