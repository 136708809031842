import React, { Component } from 'react'
import Icon from 'ui/Icon'

import TypeaheadModel from 'models/TypeaheadModel'

class PlaylistsMainTypeahead extends Component{
  constructor(props){
    super(props)
    this.typeaheadInput = React.createRef()
    this.state = {
      typeaheadInput:'',
      term:''
    }
  }

  componentDidMount(){
    this.typeaheadInput.current.focus()
  }

  handleOnChange(e){
    const {
      typeaheadAction
    } = this.props

    const { value } = e.target

    this.setState({term:value},()=>{
      typeaheadAction(value)
    })
  }

  clearTypeaheadInput() {
    const {
      typeaheadAction
    } = this.props

    this.typeaheadInput.current.value = ''
    typeaheadAction('')
  }

  render(){
    const { classname } = this.props

    return (
      <div className={`typeahead-wrapper typeahead-wrapper-${classname}`}>
        <Icon name="ios-search" classname="typeahead"/>
        <input
          ref={this.typeaheadInput}
          className={`typeahead-input typeahead-input-${classname}`}
          onChange={(e)=>this.handleOnChange(e)}
          placeholder={TypeaheadModel[classname].placeholder}
        />
        <Icon
          name="ios-close"
          action={()=>this.clearTypeaheadInput()}
          classname={`typeahead-clear ${this.typeaheadInput.current && this.typeaheadInput.current.value.length > 0 ? 'active' : ''}`}/>
      </div>
    )
  }
}

export default PlaylistsMainTypeahead
