import React from 'react'

import { Link } from 'react-router-dom'
import TableModel from 'models/TableModel'

const TableCellLink = (props) => {
  const header = TableModel[props.classname].headers.find(header => header.field === props.field)
  return (
    <td className={`table-cell table-cell-${props.classname} table-cell-size-${props.size} table-cell-link`}>
      <Link to={`${header.link}${encodeURI(props.datum).toLowerCase()}`}>{props.datum}</Link>
    </td>
  )
}

export default TableCellLink