import React, {Component} from 'react'
import { connect } from 'react-redux'

import {
  getPlaylistVenues,
  removeVenueFromPlaylist,
  addVenueToPlaylist
} from 'store/actions/playlistAction'

import {
  searchVenuesTypeahead,
  clearTypeahead
} from 'store/actions/venueAction'

import PlaylistsVenuesTableActions from './playlist-venues/PlaylistsVenuesTableActions'

import Container from 'ui/Container'
import Loader from 'ui/Loader'
import TableWrapper from 'ui/TableWrapper'
import TableTypeahead from 'ui/TableTypeahead'

const classname = 'playlistVenues'

class PlaylistVenues extends Component {

  constructor(props){
    super(props)
    this.state = {
      filter:'',
      venuesOutput: []
    }
  }

  componentDidMount(){
    const {
      dispatch
    } = this.props

    dispatch(getPlaylistVenues(this.props.id))
  }

  componentDidUpdate(prevProps) {
    const {
      venues
    } = this.props

    if (prevProps.venues !== venues) {
      this.setState({
        venuesOutput: venues
      })
    }
  }

  getTypeaheadData(){

    const {
      typeahead,
      venues,
      userVenues
    } = this.props
    let results = []
    //filter out venues that we already have from the typeahead
    if(this.props.typeahead){
      if(venues.length > 0){
        results = typeahead.filter(ta => venues.find(venue => venue.id !== ta.id))
      } else {
        results = [...typeahead]
      }

      if(userVenues && userVenues.length > 0){
        results = results.filter(venue => userVenues.find(userVenue => venue.id === userVenue.id))
      }
    }
    return results

  }

  clearTypeahead(){
    this.props.dispatch(clearTypeahead())
  }

  addVenue(venue){
    this.props.dispatch(addVenueToPlaylist(this.props.id,venue))
  }

  searchVenuesTypeahead(term){
    const {
      userVenues,
      venues
    } = this.props
    this.props.dispatch(searchVenuesTypeahead(term,userVenues,venues))
  }

  removeVenue(venueID, playlistID) {
    const {
      dispatch
    } = this.props

    dispatch(removeVenueFromPlaylist(venueID, playlistID))
  }

  render(){
    const {
      id,
      loading,
      typeaheadLoading,
      venues,
      userVenues
    } = this.props

    let _venues = [...venues]
    //manager login
    if(userVenues && userVenues.length > 0){
      _venues = _venues.filter(venue => userVenues.find(userVenue => venue.id === userVenue.id))
    }

    if(loading){
      return <Loader/>
    } else {
      return (
        <Container classname={`${classname}`}>
          <TableWrapper
            classname={classname}
            data={_venues}
            clearData={()=>this.clearVenues()}
            getData={(limit,offset)=>this.getPlaylists(limit,offset)}
            loading={loading}
            rowActions={<PlaylistsVenuesTableActions
              more={(data)=>this.moreActions(data)}
              removeVenue={(venueID, playlistID)=>this.removeVenue(venueID, playlistID)}
              classname={classname}
              playlistID={id}/>
            }
            tableAction={<TableTypeahead
              classname={classname}
              typeaheadSearch={(value)=>this.searchVenuesTypeahead(value)}
              typeaheadAction={(venue)=>this.addVenue(venue)}
              typeaheadLoading={typeaheadLoading}
              clear={()=>this.clearTypeahead()}
              data={this.getTypeaheadData()}/>
            }/>
        </Container>
      )
    }
  }
}
function mapStateToProps(store){
  return {
    venues:store.playlist.venues,
    typeahead:store.venues.typeahead,
    typeaheadLoading:store.venues.typeaheadLoading,
    loading:store.playlist.loading,
    userVenues:store.auth.venues
  }
}

export default connect(mapStateToProps)(PlaylistVenues)
