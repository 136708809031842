import React, {Component} from 'react'
import { connect } from 'react-redux'

import {
  getPlaylistDetails,
  updatePlaylistDetails
} from 'store/actions/playlistAction'

import Container from 'ui/Container'
import Loader from 'ui/Loader'
import Form from 'ui/Form'

const classname = 'playlistDetailsOwned'

class PlaylistDetailsOwned extends Component {
  constructor(props){
    super(props)
    this.state = {
      displayName:null
    }
  }

  componentDidMount(){
    this.props.dispatch(getPlaylistDetails(this.props.id))
  }

  onSubmit(form){
    this.props.dispatch(updatePlaylistDetails(this.props.id,form))
  }

  getPlaylistTypes(){
    if(this.props.details.allTypes){
      return this.props.details.allTypes.map((type)=>{
        const res = {display:type.type,value:type.id}
        return res
      })
    } else {
      return []
    }
  }

  render(){
    if(this.props.loading){
      return <Loader/>
    } else {
      return (
        <Container classname="playlist-details" maxHeight maxWidth column>
          <Form
            classname={classname}
            submit={(e)=>this.onSubmit(e)}
            messages={this.state.message}
            data={this.props.details}
            playlistTypes={this.getPlaylistTypes()}/>
        </Container>
      )
    }
  }
}

function mapStateToProps(store){
  return {
    details:store.playlist.details,
    loading:store.playlist.details.loading
  }
}

export default connect(mapStateToProps)(PlaylistDetailsOwned)
