import React from 'react'
import {Link} from 'react-router-dom'

import Icon from 'ui/Icon'
import Tooltip from 'ui/Tooltip'
import TableModel from 'models/TableModel'

const PlaylistsVenuesTableActions = (props) => {
  const {classname,venue, removeVenue, playlistID} = props
  return (
    <div>
      <Link to={`${TableModel[classname].viewLink}/${venue.id}?tab=details`}>
        <Icon name="eye">
          <Tooltip pos="left" text="View venue"/>
        </Icon>
      </Link>
      <Icon name="remove-circle" action={()=>removeVenue(venue.id, playlistID)}>
        <Tooltip pos="left" text="Remove venue from playlist"/>
      </Icon>
    </div>
  )
}

export default PlaylistsVenuesTableActions
