import React from 'react'

import TabsModel from 'models/TabsModel'
import Icon from 'ui/Icon'

const Tabs = (props) => {
  const {select, active, header, classname, add, closeTab, tabActions} = props

  let exclusions = props.exclusions || []
  let tabs = TabsModel[classname];

  if(add && add.length > 0){
    tabs = tabs.concat(add)
  }

  let mappedTabs = tabs.map((tab,index)=>{
    //exclude tab if set
    if(!exclusions.includes(tab.link)){
      return (
        <div
          key={index}
          onClick={()=>{select(tab.link)}}
          className={`${tab.link === active ? 'active' : ''} tab`}>
            {tab.name} {tab.dismiss ? <Icon name="close-circle" stopPropagation="true" action={()=>closeTab(tab)}/> : null}
        </div>
      )
    }

    return false
  })
  //set header if set
  const headerElem = header ? <div className="tabs-header">{header}</div> : null
  return (
  <div className="tabs-wrapper">
    <div className="tabs-left">
      {mappedTabs}
    </div>
    <div className="tabs-right">
      {headerElem}
      {tabActions}
    </div>
  </div>
  )
}
export default Tabs
