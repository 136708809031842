const initalState = {
  isActive:false,
  data:null,
  type:null,
  loading:false
}

export default function reducer(state = initalState,action){
  switch (action.type){
    case 'TOGGLE_OVERLAY':{
      state = {
        ...state,
        isActive:action.payload.isActive,
        data:action.payload.data,
        type:action.payload.type
      }
      break
    }
    case 'COPY_PLAYLIST_PENDING':
    case 'COPY_VENUE_PENDING':
    case 'DELETE_VENUE_PENDING':
    case 'COPY_VENUE_SCHEDULES_PENDING':
    case 'COPY_VENUE_INTERLEAVES_PENDING':
    case 'COPY_CALENDAR_TO_VENUES_PENDING':{
      state = {
        ...state,
        loading:true
      }
      break
    }
    case 'COPY_PLAYLIST_FULFILLED':
    case 'COPY_VENUE_FULFILLED':
    case 'DELETE_VENUE_FULFILLED':
    case 'COPY_VENUE_SCHEDULES_FULFILLED':
    case 'COPY_VENUE_INTERLEAVES_FULFILLED':
    case 'COPY_CALENDAR_TO_VENUES_FULFILLED':{
      state = {
        ...state,
        loading:false,
        isActive:false
      }
      break
    }
    case 'COPY_VENUE_REJECTED':{
      state = {
        ...state,
        loading:false,
        isActive:true
      }
      break
    }
    default: //no default case
  }
  return state
}
