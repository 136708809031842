import Filter from 'ui/Filter'

const PlaylistTableAction = ({
  classname,
  placeholder,
  action
}) => {

  return(
    <div className={`table-actions table-actions-${classname}`}>
      <div className="table-actions-left">
        <Filter placeholder={placeholder} action={(e)=>action(e)}/>
      </div>
    </div>
  )
}

export default PlaylistTableAction