import axios from 'axios'
import Websockets from 'lib/Websockets'
const CancelToken = axios.CancelToken
let cancel
let ws

export function getVenues(limit,offset){

  const headers = {
    'Limit':limit,
    'Offset':offset
  }
  return (dispatch) => {
    return dispatch({
      type:'FETCH_VENUES',
      payload:axios.get('venues',{headers:headers})
    })
  }
}

export function searchVenuesTypeahead(term){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_VENUES_TYPEAHEAD',
      payload:axios.get(`venues/typeahead/${term}`)
    })
  }
}

export function clearVenues(){
  return (dispatch) => {
    return dispatch({
      type:'CLEAR_VENUES',
      payload:null
    })
  }
}

export function searchVenues(term){
  return (dispatch) => {
    return dispatch({
      type:'SEARCH_VENUES',
      payload:axios.get(`venues/search/${term}`)
    })
  }
}

export function clearTypeahead(){
  return (dispatch) => {
    return dispatch({
      type:'CLEAR_TYPEAHEAD'
    })
  }
}

export function clearVenue(){
  return (dispatch) => {
    return dispatch({
      type:'CLEAR_VENUE',
      payload:null
    })
  }
}
export function getVenueDetails(id){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_VENUE_DETAILS',
      payload:axios.get(`venue/details/${id}`)
    })
  }
}
export function getVenueUser(id){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_VENUE_USER',
      payload:axios.get(`venue/user/${id}`)
    })
  }
}
export function getVenueSettings(id){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_VENUE_SETTINGS',
      payload:axios.get(`venue/settings/${id}`)
    })
  }
}
export function updateVenueDetails(id,data){
  return (dispatch) => {
    return dispatch({
      type:'UPDATE_VENUE_DETAILS',
      payload:axios.put(`venue/details/${id}`,data),
      meta:data
    })
  }
}
export function updateVenueUser(id,data){
  return (dispatch) => {
    return dispatch({
      type:'UPDATE_VENUE_USER',
      payload:axios.put(`venue/user/${id}`,data),
      meta:data
    })
  }
}
export function updateVenueSettings(id,data){
  return (dispatch) => {
    return dispatch({
      type:'UPDATE_VENUE_SETTINGS',
      payload:axios.put(`venue/settings/${id}`,data),
      meta:data
    })
  }
}
export function getVenuePlaylists(id){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_VENUE_PLAYLISTS',
      payload:axios.get(`venue/playlists/${id}`)
    })
  }
}
export function getVenueSchedule(id){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_VENUE_SCHEDULE',
      payload:axios.get(`venue/schedule/${id}`)
    })
  }
}
export function createVenueSchedule(playlistData, playlistIndex){
  return (dispatch) => {
    return dispatch({
      type:'CREATE_VENUE_SCHEDULE',
      payload:axios.post(`venue/schedule/create`, playlistData),
      meta: playlistIndex
    })
  }
}
export function updateVenueSchedule(id, playlistData){
  return (dispatch) => {
    return dispatch({
      type:'UPDATE_VENUE_SCHEDULE',
      payload:axios.post(`venue/schedule/update/${id}`, playlistData)
    })
  }
}
export function deleteVenueSchedule(id){
  return (dispatch) => {
    return dispatch({
      type:'DELETE_VENUE_SCHEDULE',
      payload:axios.delete(`venue/schedule/${id}`)
    })
  }
}
export function addInterleave(data, index){
  return (dispatch) => {
    return dispatch({
      type:'CREATE_VENUE_INTERLEAVE',
      payload:axios.post(`venue/schedule/interleave`, data),
      meta:index
    })
  }
}
export function addInterleaves(data){
  return (dispatch) => {
    return dispatch({
      type:'CREATE_VENUE_INTERLEAVES',
      payload:axios.post(`venue/schedule/interleaves`, data)
    })
  }
}
export function deleteInterleave(id){
  return (dispatch) => {
    return dispatch({
      type:'DELETE_VENUE_INTERLEAVE',
      payload:axios.delete(`venue/schedule/interleave/${id}`)
    })
  }
}
export function updateInterleaveRatios(data){
  return (dispatch) => {
    return dispatch({
      type:'UPDATE_VENUE_INTERLEAVE_RATIOS',
      payload:axios.post(`venue/schedule/interleave/update/ratio`,data)
    })
  }
}
export function toggleInterleaveRatioChanger(isOpen){
  return (dispatch) => {
    return dispatch({
      type:'TOGGLE_VENUE_INTERLEAVE_RATIO_CHANGER',
      payload:isOpen
    })
  }
}
export function copyInterleaveToVenues(data){
  return (dispatch) => {
    return dispatch({
      type:'COPY_VENUE_INTERLEAVES',
      payload:axios.post(`venue/schedule/interleave/copy`,data)
    })
  }
}
export function copySchedulesToVenues(data){
  return (dispatch) => {
    return dispatch({
      type:'COPY_VENUE_SCHEDULES',
      payload:axios.post(`venue/schedule/copy`,data)
    })
  }
}
export function copyCalendarToVenues(data){
  return (dispatch) => {
    return dispatch({
      type: 'COPY_CALENDAR_TO_VENUES',
      payload: axios.post(`/venue/schedule/calendar/copy`,data)
    })
  }
}
export function toggleScheduleCreator(isOpen){
  return (dispatch) => {
    dispatch({type:'TOGGLE_SCHEDULE_CREATOR',payload:isOpen})
  }
}
export function setPlaylistInfo(isOpen){
  return (dispatch) => {
    dispatch({type:'SET_PLAYLIST_INFO',payload:isOpen})
  }
}
export function getVenueCalendar(id, date){
  return (dispatch) => {
    cancel && cancel('FETCH_VENUE_CALENDAR request cancelled')

    const request = axios.get(`venue/schedule/${id}/calendar/${date}`,
      {cancelToken: new CancelToken(function executor(c) {
          cancel = c
        })
      }).catch((cancel) => {
      console.log(cancel)
      return 'cancelled'
    })
    return dispatch({
      type:'FETCH_VENUE_CALENDAR',
      payload: request
    })
  }
}
export function createVenueCalendar(playlistData, playlistIndex){
  return (dispatch) => {
    return dispatch({
      type:'CREATE_VENUE_CALENDAR',
      payload:axios.post(`venue/schedule/calendar/create`, playlistData),
      meta: playlistIndex
    })
  }
}
export function updateVenueCalendar(id, playlistData){
  return (dispatch) => {
    return dispatch({
      type:'UPDATE_VENUE_CALENDAR',
      payload:axios.post(`venue/schedule/calendar/update/${id}`, playlistData)
    })
  }
}
export function deleteVenueCalendar(id){
  return (dispatch) => {
    return dispatch({
      type:'DELETE_VENUE_CALENDAR',
      payload:axios.delete(`venue/schedule/calendar/${id}`)
    })
  }
}
export function addCalendarInterleave(data, index,businessID){
  return (dispatch) => {
    return dispatch({
      type: 'CREATE_CALENDAR_INTERLEAVE',
      payload: axios.post(`venue/schedule/calendar/interleave`, data),
      meta: index
    })
  }
}
export function addCalendarInterleaves(data){
  return (dispatch) => {
    return dispatch({
      type: 'CREATE_CALENDAR_INTERLEAVES',
      payload: axios.post(`venue/schedule/calendar/interleaves`, data)
    })
  }
}
export function deleteCalendarInterleave(id){
  return (dispatch) => {
    return dispatch({
      type: 'DELETE_CALENDAR_INTERLEAVE',
      payload: axios.delete(`venue/schedule/calendar/interleave/${id}`)
    })
  }
}
export function updateCalendarInterleaveRatios(data){
  return (dispatch) => {
    return dispatch({
      type: 'UPDATE_CALENDAR_INTERLEAVE_RATIOS',
      payload: axios.post(`venue/schedule/calendar/interleave/update/ratio`,data)
    })
  }
}
export function getVenueAnnouncements(id){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_VENUE_ANNOUNCEMENTS',
      payload:axios.get(`venue/announcements/${id}`)
    })
  }
}
export function createVenue(data){
  const venueData = {
    name: data.venueName,
    contact_name: data.contactName,
    contact_number: data.contactNumber,
    address_line_1: data.address1,
    address_line_2: data.address2 || '',
    town: data.town || '',
    postcode: data.postcode,
    username: data.username,
    password: data.password
  }

  const storedVenueData = data
  delete storedVenueData['password']

  return (dispatch) => {
    return dispatch({
      type:'CREATE_VENUE',
      payload:axios.post(`venue/create`, venueData),
      meta: storedVenueData
    })
  }
}
export function deleteVenue(id){
  return (dispatch) => {
    return dispatch({
      type:'DELETE_VENUE',
      payload:axios.delete(`venue/${id}`),
      meta:id
    })
  }
}
export function copyVenue(id,data){
  const venueData = {
    name: data.venueName,
    contact_name: data.contactName,
    contact_number: data.contactNumber,
    address_line_1: data.address1,
    address_line_2: data.address2 || '',
    town: data.town || '',
    postcode: data.postcode,
    username: data.username,
    password: data.password,
    confirm_password: data.confirmPassword
  }

  const storedVenueData = data
  delete storedVenueData['password']
  delete storedVenueData['confirmPassword']

  return (dispatch) => {
    return dispatch({
      type:'COPY_VENUE',
      payload:axios.post(`venue/copy/${id}`,venueData),
      meta: storedVenueData
    })
  }
}
export function addPlaylistToVenue(venueID,playlist){
  return (dispatch) => {
    return dispatch({
      type:'ADD_PLAYLIST_TO_VENUE',
      payload:axios.put(`venue/playlist/add/${venueID}/${playlist.id}`),
      meta: {
        playlist: playlist,
        venueID: venueID
      }
    })
  }
}
export function removePlaylistFromVenue(venueID,playlist){
  return (dispatch) => {
    return dispatch({
      type:'REMOVE_PLAYLIST_FROM_VENUE',
      payload:axios.delete(`venue/playlist/remove/${venueID}/${playlist.id}`),
      meta: {
        playlist: playlist,
        venueID: venueID
      }
    })
  }
}
export function getVenueControls(id){
  return (dispatch) => {
    if(ws){
      ws.endConnection()
    }
    return dispatch({
      type:'FETCH_VENUE_CONTROLS',
      payload:axios.get(`/manager/venue/${id}`)
    })
  }
}
export function openManagerWs(){
  return (dispatch) => {
    ws = new Websockets(dispatch)
    ws.init()
    dispatch({type:'WS_MANAGER_INIT',payload:{init:true}})
  }
}

export function openVenueWS(token){
  return (dispatch) => {
    ws = new Websockets(dispatch)
    ws.init(token)
    dispatch({type:'WS_INIT',payload:{init:true}})
  }
}
export function connectVenueWS(){
  return () => {
    ws.connect()
  }
}
export function connectManagerWS(){
  return () => {
    ws.connect()
  }
}
export function closeWSConnection(){
  return (dispatch) => {
    if (!ws) return null
    ws.endConnection()
    dispatch({type:'WS_RESET',payload:null})
  }
}
export function toggleEnergySlider(isActive){
  ws.send('energyMan',{status:isActive,level:5})
  return {
    type:'TOGGLE_ENERGY_SLIDER',
    payload:isActive
  }
}

export function updateVenueEnergy(energy){
  ws.send('energyMan',{status:true,level:energy})
  return {
    type:'UPDATE_VENUE_ENERGY',
    payload:energy
  }
}

export function setVolume(vol){
  ws.send('volume',vol)
  return {
    type:'WS_SET_VOLUME',
    payload:vol
  }
}

export function getVenueOnline(token){
  return () => {
    ws.sendManager('checkPlOnlineMM',token,null)
  }
}

export function skipBack(){
  return () => {
    ws.send('skipBack',null)
  }
}

export function skipForward(){
  return () => {
    ws.send('skipForward',null)
  }
}

export function playPause(isPlaying){
  ws.send('playPause',null)
  return {
    type:'VENUE_ISPLAYING',
    payload:isPlaying
  }
}

export function toggleFullscreen(isFullscreen){
  return {
    type:'VENUE_TOGGLE_FULLSCREEN',
    payload:isFullscreen
  }
}

export function selectPtp(data){
  return () => {
    ws.send('pushToPlay',data)
  }
}

export function cancelPtp(){
  return () => {
    ws.send('cancelPushToPlay',null)
  }
}

export function restartPlayer(){
  return () => {
    ws.send('restartPlayer',null)
  }
}

export function blockTrack(venueID, trackID){
  return (dispatch) => {
    return dispatch({
      type: 'BLOCK_TRACK',
      payload: axios.put(`/player/block-track/${venueID}/${trackID}`),
      meta: trackID
    })
  }
}

export function getBlockedTracks(venue_id){
  return (dispatch) => {
    return dispatch({
      type: 'FETCH_BLOCKED_TRACKS',
      payload: axios.get(`/venue/blocked/${venue_id}`)
    })
  }
}

export function removeBlockedTrack(blocked_id){
  return (dispatch) => {
    return dispatch({
      type: 'DELETE_BLOCKED_TRACKS',
      payload: axios.delete(`/venue/blocked/${blocked_id}`),
      meta:blocked_id
    })
  }
}
