import React from 'react'

import Typeahead from 'ui/Typeahead'

const TableTypeahead = (props) => {
  const {
    classname,
    typeaheadSearch,
    search,
    typeaheadAction,
    typeaheadLoading,
    clear,
    data
  } = props

  return (
    <div className={`table-typeahead table-typeahead-${classname}`}>
      <Typeahead
        search={(value) => search(value)}
        typeaheadSearch={(value)=>typeaheadSearch(value)}
        typeaheadAction={(data)=>typeaheadAction(data)}
        typeaheadLoading={typeaheadLoading}
        clear={()=>clear()}
        data={data}
        classname={classname}/>
    </div>
  )
}

export default TableTypeahead
