import React from 'react'

const Badge = (props) => {
  if(props.action){
    return <span onClick={()=>props.action()} className={`badge badge-action badge-${props.type ? props.type : 'basic'}`}>{props.data}</span>
  } else {
    return <span className={`badge badge-${props.type ? props.type : 'basic'}`}>{props.data}</span>
  }
}

export default Badge