import React from 'react'

export const Select = (props) => {
  const { classname, options, action, selected, data} = props
  
  return (
    <select className={`select select-${classname}`} onChange={(e)=>action({event:e,data:data})} value={selected}>
      {options.map((option, index) => <option key={index} value={option.value}>{option.display}</option>)}
    </select>
  )
}

export default Select
