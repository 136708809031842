import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import queryString from 'qs'

import {
  getPlaylistDetails,
  getPlaylistQuick
} from 'store/actions/playlistAction'

import PlaylistDetails from './playlist/PlaylistDetails'
import PlaylistDetailsOwned from './playlist/PlaylistDetailsOwned'
import PlaylistTracks from './playlist/PlaylistTracks'
import PlaylistLibrary from './playlist/PlaylistLibrary'
import PlaylistOrder from './playlist/PlaylistOrder'
import PlaylistVenues from './playlist/PlaylistVenues'

import Tabs from 'ui/Tabs'
import Container from 'ui/Container'
import Loader from "ui/Loader"

const classname = 'playlist'

class PlaylistContainer extends Component {

  constructor(props){
    super(props)
    this.state = {
      tab:'',
      header:null
    }
  }

  componentDidMount(){
    this.getTabQuery()
    this.props.dispatch(getPlaylistQuick(this.props.match.params.id))
    this.props.dispatch(getPlaylistDetails(this.props.match.params.id))
  }

  componentDidUpdate(prevProps){

    const {
      playlist,
      copyPlaylist,
      history
    } = this.props

    if (playlist.id !== prevProps.playlist.id || this.props.location !== prevProps.location){
      this.setHeader()
    }

    if(copyPlaylist && prevProps.copyPlaylist !== copyPlaylist){
      history.push(`/playlist/${playlist.id}?tab=details`)
    }

    //  handle forward and back browser button usage
    if ((this.props.location !== prevProps.location) && this.props.history.action === 'POP') {
      this.getTabQuery()
    }
  }

  getTabQuery(){
    const query = queryString.parse(this.props.location.search.slice(1))

    if(typeof query.tab !== 'undefined') {
      // catch library search queries and display the library tab
      if(query.tab.match(/search|all/)) {
        this.setState({tab: 'library'})
      } else {
        this.setState({tab: query.tab})
      }
    }
  }

  selectTab(tab){
    this.props.history.push({
      search: `?tab=${tab}`
    })
    this.setState({tab:tab})
  }

  getTabExlusions(){
    const {
      isOwned,
      playlist
    } = this.props
    let exclusions = []
    if(!isOwned){
      exclusions.push('order','library')
    }
    if(!playlist.subscribed){
      exclusions.push('venues')
    }
    return exclusions
  }

  setHeader(){
    const {
      location,
      playlist
    } = this.props

    const query = queryString.parse(location.search.slice(1))

    const header = query.tab === 'details' ?
      '' :
      `${playlist.displayName || playlist.name} - ${playlist.trackCount} tracks`

    this.setState({header:header})
  }

  getComponent(){
    const {
      history,
      isOwned,
      location,
      playlist,
      playingTrack,
    } = this.props

    const id = this.props.match.params.id
    switch(this.state.tab){
      case 'details':
        if(typeof isOwned === 'undefined'){
          return null
        } else {
          if(isOwned){
            return <PlaylistDetailsOwned id={id}/>
          } else {
            return <PlaylistDetails id={id}/>
          }
        }
      case 'tracks':
        return <PlaylistTracks id={id} playingTrack={playingTrack} />
      case 'library':
        return (
          <PlaylistLibrary
            history={history}
            id={id}
            location={location}
            playlist={playlist}
            playingTrack={playingTrack}
          />
        )
      case 'order':
        return <PlaylistOrder id={id}  playingTrack={playingTrack} />
      case 'venues':
        return <PlaylistVenues id={id}/>
      default: //no default case
    }
  }

  render(){
    const {
      playlistDetailsLoading
    } = this.props
    return (
      <Container classname={classname} height="100%" column>
        <Container classname={`tabs ${this.state.tab}`}>
          <Tabs
            match={this.props.match}
            select={(tab)=>this.selectTab(tab)}
            active={this.state.tab}
            classname={classname}
            exclusions={this.getTabExlusions()}
            header={playlistDetailsLoading ? <Loader/> : this.state.header}
            />
        </Container>
        <Container height="100%" classname={`${classname} container-tabview`}>
          {this.getComponent()}
        </Container>
      </Container>
    )
  }
}

function mapStateToProps(store){
  return {
    playlist:store.playlist.details,
    isOwned:store.playlist.isOwned,
    tracks:store.playlist.tracks,
    loading:store.playlist.loading,
    playingTrack: store.player.track,
    copyPlaylist:store.playlist.copyPlaylist,
    playlistDetailsLoading:store.playlist.playlistDetailsLoading
  }
}

export default withRouter(connect(mapStateToProps)(PlaylistContainer))
