import React from 'react'

import FormLabel from 'ui/FormLabel'

const FormCheckbox = (props) => {
  const {
    change,
    checked,
    name,
    label,
    classname
  } = props

  const wrapperClasses = `form-input-wrapper input-wrapper-${classname}`
  const labelClasses = `input-label input-label-${classname}`
  const inputClasses = `input checkbox input-${classname}`

  return (
    <div className={wrapperClasses}>
      <FormLabel label={label} className={labelClasses}/>
        <input
        type="checkbox"
        className={inputClasses}
        name={name}
        checked={checked}
        onChange={(e)=>change(e)}
      />
    </div>
  )
}

export default FormCheckbox
