import { formatDate } from 'helpers/DateHelper'
const initialState = {
  details:{
    id:undefined,
    name:undefined,
    displayName:undefined,
    trackCount:undefined,
    owner:undefined,
    style:undefined,
    type:undefined,
    allTypes:[],
    genres:undefined,
    allGenres:[],
    tags:undefined,
    allTags:[],
    loading:false,
    subscribed: false
  },
  tracks:[],
  venues:[],
  isOwned:undefined,
  filter:'',
  metadata:{},
  count:null,
  loading:false,
  loaded:false,
  subscriptionLoading:false,
  playlistTracksLoading:false,
  addTrackToOwnedPlaylistLoading:false,
  fullPlaylistDataLoading: false,
  playlistCreated: null,
  copyPlaylist:false,
  error:null,
  removeDuplicatesLoading:false
}
export default function reducer(state = initialState, action){
  switch (action.type){
    //playlist
    case 'CLEAR_PLAYLIST':{
      state = {...state,tracks:[],venues:[]}
      break
    }
    case 'FETCH_PLAYLIST_TRACKS_PENDING':{
      state = {...state,playlistTracksLoading:true,tracks:[]}
      break
    }
    case 'FETCH_PLAYLIST_TRACKS_REJECTED':{
      state = {...state,playlistTracksLoading:false,error:action.payload}
      break
    }
    case 'FETCH_PLAYLIST_TRACKS_FULFILLED':{
      const tracks = action.payload.data.tracks.map(track => {
        const data = {
          id:track.id,
          title:track.title,
          artist:track.artist,
          album:track.album,
          genres:track.genres.map((genre)=>genre.genre),
          length:track.total_length,
          energy:track.energy,
          bpm:track.bpm,
          added:formatDate(track.pivot.created_at),
          addedMysql:track.pivot.created_at,
          filter:`${track.title} ${track.artist} ${track.album} ${track.genres.map((genre)=>genre.genre).join(' ')}`,
          pivot_id:track.pivot.id,
          duplicate:track.duplicate
        }
        return data
      })

      state =  {
        ...state,
        playlistTracksLoading:false,
        tracks:state.tracks.concat(tracks),
        metadata:action.payload.data.metadata
      }
      break
    }
    case 'FETCH_PLAYLIST_PENDING':{
      state = {
        ...state,
        loading:true,
        fullPlaylistDataLoading: true,
        tracks:[]
      }
      break
    }
    case 'FETCH_PLAYLIST_REJECTED':{
      state = {
        ...state,
        loading:false,
        fullPlaylistDataLoading: false,
        error:action.payload
      }
      break
    }
    case 'FETCH_PLAYLIST_FULFILLED':{
      const {playlist, metadata} = action.payload.data

      const tracks = playlist.tracks.map(track => {
        const data = {
          id:track.id,
          title:track.title,
          artist:track.artist,
          album:track.album,
          genres:track.genres.map((genre)=>genre.genre),
          length:track.total_length,
          energy:track.energy,
          bpm:track.bpm,
          filter:`${track.title} ${track.artist} ${track.album}`,
          added:formatDate(track.pivot.created_at),
          addedMysql:track.pivot.created_at,
          pivot_id:track.pivot.id,
          new:track.new
        }
        return data
      })

      state =  {
        ...state,
        loading:false,
        loaded:true,
        fullPlaylistDataLoading: false,
        details:{
          ...state.details,
          id:playlist.id,
          name:playlist.name,
          genres:playlist.genres.map((genre)=>genre.genre),
          tags:playlist.tags.map((tag)=>tag.name),
          displayName:playlist.display_name,
          trackCount:tracks.length,
          owner:playlist.owner.username,
          playlistImage: playlist.playlist_image,
          style:playlist.style,
          subscribed:playlist.subscribed
        },
        tracks:state.tracks.concat(tracks),
        metadata:metadata,
        copyPlaylist:false,
        playlistCreated:false
      }
      break
    }
    case 'FETCH_PLAYLIST_TRACKS_ORDERED_PENDING':{
      state = {...state,loading:true,tracks:[]}
      break
    }
    case 'FETCH_PLAYLIST_TRACKS_ORDERED_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_PLAYLIST_TRACKS_ORDERED_FULFILLED':{
      const tracks = [].concat(action.payload.data.tracks)
        .sort((a,b)=>{
          if(a.ordered[0] && b.ordered[0]){
            return a.ordered[0].pivot.index - b.ordered[0].pivot.index
          }
          return false
        })
        .map(track => {
          const data = {
            id:track.id,
            title:track.title,
            artist:track.artist,
            album:track.album,
            genres:track.genres.map((genre)=>genre.genre),
            length:track.total_length,
            energy:track.energy,
            bpm:track.bpm,
            added:formatDate(track.pivot.created_at),
            addedMysql:track.pivot.created_at,
            filter:`${track.title} ${track.artist} ${track.album}`,
            pivot_id:track.pivot.id
          }
          return data
        })

      state =  {
        ...state,
        loading:false,
        loaded:true,
        tracks:state.tracks.concat(tracks),
        metadata:action.payload.data.metadata
      }
      break
    }
    case 'FETCH_PLAYLIST_QUICK_PENDING':{
      state = {
        ...state,
        loading:true,
        isOwned:undefined,
        trackCount:null,
        tracks:[]
      }
      break
    }
    case 'FETCH_PLAYLIST_QUICK_REJECTED':{
      state = {
        ...state,
        loading:false,
        error:action.payload
      }
      break
    }
    case 'FETCH_PLAYLIST_QUICK_FULFILLED':{
      state = {
        ...state,
        loading:false,
        isOwned:action.payload.data.isOwned,
        trackCount:action.payload.data.tracks_count,
        copyPlaylist:false,
        playlistCreated:false
      }
      break
    }
    case 'FILTER_PLAYLIST_TRACKS':{
      state = {...state,filter:action.payload}
      break
    }
    case 'ORDER_PLAYLIST_TRACKS':{
      //create new array
      let tracks = state.tracks.slice()
      //remove old position of old track
      tracks.splice(action.payload.track.index,1)
      //set moved track
      tracks.splice(parseInt(action.payload.newIndex),0,action.payload.track)

      //set index property for each track
      tracks.forEach((track, index) => {
        track.index = index
      })

      state = {
        ...state,
        tracks:tracks
      }
      break
    }
    case 'DELETE_PLAYLIST_TRACK_PENDING':{
      state = {...state,tracks:state.tracks.filter(track => track.id !== action.meta.id)}
      break
    }
    case 'ADD_PLAYLIST_TRACK_OWNED_PENDING':{
      state = {
        ...state,
        addTrackToOwnedPlaylistLoading: action.meta.id
      }
      break
    }
    case 'ADD_PLAYLIST_TRACK_OWNED_REJECTED':{
      state = {
        ...state,
        addTrackToOwnedPlaylistLoading: false
      }
      break
    }
    case 'ADD_PLAYLIST_TRACK_OWNED_FULFILLED':{
      const track = {
        ...action.meta,
        pivot_id:action.payload.data.pivot_id
      }
      const tracks = state.tracks.concat(track)
      state = {
        ...state,
        tracks: tracks,
        addTrackToOwnedPlaylistLoading: false
      }
      break
    }
    case 'ADD_PLAYLIST_TRACK_OWNED_MULTI_PENDING':{
      state = {
        ...state,
        addTrackToOwnedPlaylistLoading: true
      }
      break
    }
    case 'ADD_PLAYLIST_TRACK_OWNED_MULTI_REJECTED':{
      state = {
        ...state,
        addTrackToOwnedPlaylistLoading: false
      }
      break
    }
    case 'ADD_PLAYLIST_TRACK_OWNED_MULTI_FULFILLED':{
      state = {
        ...state,
        addTrackToOwnedPlaylistLoading: false
      }
      break
    }
    case 'ADD_VENUE_TO_PLAYLIST_PENDING':{
      state = {
        ...state
      }
      break
    }
    case 'ADD_VENUE_TO_PLAYLIST_REJECTED':{
      state = {
        ...state,
        error:action.payload
      }
      break
    }
    case 'ADD_VENUE_TO_PLAYLIST_FULFILLED':{
      const venue = {
        name:action.meta.name,
        created_at:action.meta.created_at.standard
      }
      state = {
        ...state,
        venues:state.venues.concat(venue)
      }
      break
    }
    case 'DELETE_PLAYLIST_TRACK_OWNED_PENDING':{
      state = {
        ...state,
        addTrackToOwnedPlaylistLoading: action.meta
      }
      break
    }
    case 'DELETE_PLAYLIST_TRACK_OWNED_REJECTED':{
      state = {
        ...state,
        addTrackToOwnedPlaylistLoading: false
      }
      break
    }
    case 'DELETE_PLAYLIST_TRACK_OWNED_FULFILLED':{
      const tracks = state.tracks.filter(track => track.id !== action.meta.id)
      state = {
        ...state,
        tracks:tracks,
        addTrackToOwnedPlaylistLoading: false
      }
      break
    }
    case 'FETCH_PLAYLIST_VENUES_PENDING':{
      state = {...state,loading:true,venues:[]}
      break
    }
    case 'FETCH_PLAYLIST_VENUES_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_PLAYLIST_VENUES_FULFILLED':{
      const venues = action.payload.data.venues.map(venue => {
        return {
          id:venue.id,
          name:venue.name,
          created_at:venue.created_at.standard
        }
      })
      state =  {
        ...state,
        loading:false,
        loaded:true,
        venues:state.venues.concat(venues)
      }
      break
    }
    case 'FETCH_PLAYLIST_DETAILS_PENDING':{
      state = {
        ...state,
        details:{
          ...state.details,
          loading:true
        }
      }
      break
    }
    case 'FETCH_PLAYLIST_DETAILS_REJECTED':{
      state = {
        ...state,
        details:{
          ...state.details,
          loading:false
        },
        error:action.payload
      }
      break
    }
    case 'FETCH_PLAYLIST_DETAILS_FULFILLED':{
      const {data} = action.payload
      state =  {
        ...state,
        loading:false,
        loaded:true,
        details:{
          ...state.details,
          id:data.id,
          name:data.name,
          displayName:data.display_name,
          trackCount:data.tracks_count,
          owner:data.owner.username,
          style:data.style,
          type:data.playlist_types.id,
          allTypes:data.allTypes,
          genres:data.genres,
          allGenres:data.allGenres,
          tags:data.tags,
          allTags:data.allTags,
          loading:false
        },
        playlistCreated: false
      }
      break
    }
    case 'UPDATE_PLAYLIST_DETAILS_PENDING':{
      state = {
        ...state,
        details:{
          ...state.details,
          loading:true
        }
      }
      break
    }
    case 'UPDATE_PLAYLIST_DETAILS_REJECTED':{
      state = {
        ...state,
        details:{
          ...state.details,
          loading:false,
          error:action.payload
        }
      }
      break
    }
    case 'UPDATE_PLAYLIST_DETAILS_FULFILLED':{
      state = {
        ...state,
        details:{
          ...state.details,
          name:action.meta.name,
          style:action.meta.style,
          loading:false
        }
      }
      break
    }
    case 'CREATE_PLAYLIST_FULFILLED':{
      state =  {
        ...state,
        details:{
          ...state.details,
          id:action.payload.data.res.data.id
        },
        playlistCreated: true
      }
      break
    }
    case 'COPY_PLAYLIST_FULFILLED':{
      state =  {
        ...state,
        details:{
          ...state.details,
          id:action.payload.data.res.data.id
        },
        isOwned:true,
        copyPlaylist: true
      }
      break
    }
    case 'PUT_SUBSCRIBE_PLAYLIST_DETAILS_PENDING':{
      state = {
        ...state,
        subscriptionLoading: true
      }
      break
    }
    case 'PUT_SUBSCRIBE_PLAYLIST_DETAILS_FULFILLED':{
      state = {
        ...state,
        details:{
          ...state.details,
          subscribed: true
        },
        subscriptionLoading: false
      }
      break
    }
    case 'PUT_UNSUBSCRIBE_PLAYLIST_DETAILS_PENDING':{
      state = {
        ...state,
        subscriptionLoading: true
      }
      break
    }
    case 'PUT_UNSUBSCRIBE_PLAYLIST_DETAILS_FULFILLED':{
      state = {
        ...state,
        details:{
          ...state.details,
          subscribed: false
        },
        subscriptionLoading: false
      }
      break
    }
    case 'REMOVE_VENUE_FROM_PLAYLIST_FULFILLED':{
      const venues = state.venues.filter((venue) => {
        return venue.id !== action.meta.venueID
      })

      state = {
        ...state,
        venues: venues
      }
      break
    }
    case 'REMOVE_DUPLICATES_PENDING':{
      state = {
        ...state,
        playlistDetailsLoading:true,
        removeDuplicatesLoading:true
      }
      break
    }
    case 'REMOVE_DUPLICATES_REJECTED':{
      state = {
        ...state,
        playlistDetailsLoading:false,
        removeDuplicatesLoading:false,
        error:action.payload
      }
      break
    }
    case 'REMOVE_DUPLICATES_FULFILLED':{
      state = {
        ...state,
        playlistDetailsLoading:false,
        removeDuplicatesLoading:false,
      }
      break
    }
    case 'LOGOUT':{
      state = {
        details:{
          name:null,
          displayName:null,
          owner:null,
          type:null,
          tags:[],
          genres:[],
          playlistSettings:{},
          trackCount:null
        },
        tracks:[],
        venues:[],
        isOwned:undefined,
        filter:'',
        metadata:{},
        count:null,
        loading:false,
        loaded:false,
        error:null
      }
      break
    }
    default: //no default case
  }
  return state
}
