import React from 'react'

import Icon from 'ui/Icon'
import Tooltip from 'ui/Tooltip'

const PlaylistTracksTableRowActions = (props) => {
  const {
    openPlaylistOverlay,
    track
  } = props

  return (
    <div>
      <Icon name="add-circle" action={()=>openPlaylistOverlay({track: track})}>
        <Tooltip pos="left" text="Add track to another playlist."/>
      </Icon>
    </div>
  )
}

export default PlaylistTracksTableRowActions
