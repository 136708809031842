import React, {Component} from 'react'
import { connect } from 'react-redux'

import { getVenueAnnouncements } from 'store/actions/venueAction'
import { toggleOverlay } from 'store/actions/overlayAction'

import Announcement from './announcements/Announcement'

import Container from 'ui/Container'
import Icon from 'ui/Icon'

const classname = 'venueAnnouncements'

class VenueAnnouncements extends Component{

  componentDidMount(){
    this.props.dispatch(getVenueAnnouncements(this.props.id))
  }

  changeTime(direction){

  }

  addAnnouncements(){
    this.props.dispatch(toggleOverlay('announcements'))
  }

  getAnnouncements(){
    let announcementsMap = []
    //put announcements in array per day
    for(let announcement of this.props.announcements){
      if(!announcementsMap[announcement.pivot.day]){
        announcementsMap[announcement.pivot.day] = []
      }
      announcementsMap[announcement.pivot.day].push(announcement)
      //sort the announcements by time
      announcementsMap[announcement.pivot.day].sort((x,y)=>new Date('1970/01/01 ' + x.start) - new Date('1970/01/01 ' + y.start))
    }

    return announcementsMap.map((dayMap,index)=>{
      return dayMap.map((announcement,index) => {
        const data = announcement.pivot
        return (
          <Announcement
            key={index}
            id={data.id}
            name={announcement.name}
            start={data.start}
            day={data.day}
            order={index}
            changeTime={(direction)=>this.changeTime(direction)}
          />
        )
      })
    })
  }

  render(){
    return (
      <Container classname={classname} height="100%" maxWidth column>
        <div className="announcements-actions">
          <div className="announcements-actions-left">

          </div>
          <div className="announcements-controls">

          </div>
          <div className="announcements-actions-right">
            <Icon name="add-circle" action={()=>this.addAnnouncements()}/>
            <Icon name="copy" action={()=>this.copyToVenue()}/>
            <Icon name="trash" action={()=>this.clearAllSchedules()}/>
          </div>
        </div>
        <div className="announcements-header">
          <div className="announcements-header-day">Monday</div>
          <div className="announcements-header-day">Tuesday</div>
          <div className="announcements-header-day">Wednesday</div>
          <div className="announcements-header-day">Thursday</div>
          <div className="announcements-header-day">Friday</div>
          <div className="announcements-header-day">Saturday</div>
          <div className="announcements-header-day">Sunday</div>
        </div>
        <div className="announcements-wrapper">
          {this.getAnnouncements()}
        </div>
      </Container>
    )
  }
}

function mapStateToProps(store){
  return {
    announcements:store.venue.announcements,
    playlists:store.venue.playlists,
    loading:store.playlist.loading
  }
}

export default connect(mapStateToProps)(VenueAnnouncements)
