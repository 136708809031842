const TypeaheadModel = {
  playlists:[
    {display:'Playlists',field:'playlists'},
    {display:'Tags',field:'tags'},
    {display:'Genres',field:'genres'}
  ],
  venues:{
    field:'name',
    link:'venue',
    placeholder:'Search venues...',
    debounce:300
  },
  venuePlaylists:{
    field:'name',
    action:true,
    placeholder:'Add a playlist...',
    debounce:300
  },
  businesses:{
    field:'name',
    link:'business',
    placeholder:'Search businesses...',
    debounce:300
  },
  library:{
    field:'name',
    link:'business',
    placeholder:'Search library...',
    debounce:300
  },
  playlistsMain:{
    field:'name',
    link:'business',
    placeholder:'Filter playlists by name, genre or tag...',
    debounce:500
  },
  fitnessClasses:{
    field:'name',
    link:'class',
    placeholder:'Search classes...',
    debounce:300
  },
  interleavePlaylists:{
    field:'name',
    action:true,
    placeholder:'Search playlists...',
    debounce:300
  },
  playlistVenues:{
    field:'name',
    action:true,
    placeholder:'Add a venue...',
    debounce:300
  },
  overlayFitnessTimeline:{
    field:'name',
    action:true,
    placeholder:'Search playlists by name, tags, genres...',
    debounce:300
  },
  overlayFitnessVenues:{
    field:'name',
    action:true,
    placeholder:'Search venues...',
    debounce:300
  }
}

export default TypeaheadModel
