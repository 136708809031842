import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  getTracks,
  clearTracks,
  searchLibrary,
  searchLibraryTypeahead,
  addTrackToQueue,
  getGenres,
} from 'store/actions/libraryAction'

import {
  addTrackToPlaylistOwned,
  removeTrackFromPlaylistOwnedUsingPivot
} from 'store/actions/playlistAction'

import {
  getTrackInfo,
  getTrackUrl,
  deleteFromQueue
} from 'store/actions/playerAction'

import { getRowLimit } from 'helpers/TableHelper'
import { getQueue } from 'helpers/QueueHelper'
import { parseQueryString } from 'helpers/StringHelper'

import { toggleOverlay } from 'store/actions/overlayAction'

import Container from 'ui/Container'
import Tabs from 'ui/Tabs'
import TableWrapper from 'ui/TableWrapper'
import LibraryTableActions from './LibraryTableActions'
import LibraryTableRowActions from './LibraryTableRowActions'
import LibrarySearchFilter from './LibrarySearchFilter'
import LibraryTableRow from "./LibraryTableRow"

const classname = 'library'

class LibraryTable extends Component {

  constructor(props){
    super(props)
    this.state = {
      tab:'all',
      url:'library',
      searchTab:[],
      actionActive:false,
      showFilter:false,
      filter:null,
      filterLength:null,
      disableTypeahead: false,
      selectedTracks:[]
    }
    this.selectedTracks = []
  }

  componentDidMount(){
    const params = parseQueryString(this.props.location.search)
    this.props.dispatch(getGenres())

    const {
      settings
    } = this.props

    const restricted = settings && settings.library_isRestricted
    this.setState({
      restricted:restricted
    })
    if(params.type && params.type === 'search' && params.term) {
      this.libraryAction(this.props.location.search)
      this.props.dispatch(searchLibrary(
        params.term,
        getRowLimit(classname),
        0,
        null,
        restricted
      ))
    } else if(typeof params.tab !== 'undefined' && params.tab !== 'all' && params.tab !== 'library'){
      this.libraryAction(this.props.location.search)
      this.props.dispatch(
        getTracks(
          `library/${params.type}/${params.term}`,
          getRowLimit(classname),
          0,
          null,
          null,
          restricted
        )
      )
    } else if(typeof params.tab === 'undefined' || params.tab === 'all' || params.tab === 'library'){
      this.props.dispatch(clearTracks())
      this.props.dispatch(getTracks(
        this.state.url,
        getRowLimit(classname),
        0,
        null,
        null,
        restricted
      ))
    }
  }

  componentDidUpdate(prevProps){

    const params = parseQueryString(this.props.location.search)

    if(prevProps.location.search !== this.props.location.search){

      this.props.dispatch(clearTracks())

      if(params.tab === 'all' || typeof params.tab === 'undefined'){

        this.props.dispatch(getTracks(
          '/library',
          getRowLimit(classname),
          0,
          this.state.filter,
          null,
          this.state.restricted
        ))

      } else if(params.type && params.type === 'search' && params.term){

        this.props.dispatch(searchLibrary(
          params.term,
          getRowLimit(classname),
          0,
          null,
          this.state.restricted
        ))

      } else if(params.type && params.term && params.tab !== 'all'){

        this.props.dispatch(
          getTracks(
            `library/${params.type}/${params.term}`,
            getRowLimit(classname),
            0,
            this.state.filter,
            null,
            this.state.restricted
          )
        )

      } else if(typeof params.tab === 'undefined' || params.tab === 'all' || params.tab === 'library'){
        this.props.dispatch(clearTracks())
        this.props.dispatch(getTracks(
          this.state.url,
          getRowLimit(classname),
          0,
          null,
          null,
          this.state.restricted
        ))
      }
    }

    //  handle forward and back browser button usage
    if ((this.props.location !== prevProps.location) && this.props.history.action === 'POP') {
      this.getTabQuery()
    }
    //set state tracks
    if(prevProps.tracks !== this.props.tracks){
      this.setState({
        tracks:this.props.tracks
      })
    }

  }

  getTabQuery(){
    const {
      location
    } = this.props

    const params = parseQueryString(location.search)

    if (!location.search || params.tab === 'all' || params.tab === 'library' || typeof params === 'undefined') {
      this.setState({tab: 'all'})
    } else {
      this.setState({tab: location.search})
      // if we're loading a tab state from the browser history and the tab does not exist already, create it
      if (!this.state.searchTab.some(tab=>tab.name === `${params.type}: ${params.term}`)) {
        this.setState({
          searchTab: this.state.searchTab.concat(
            {
              name:`${params.type}: ${params.term}`,
              link:location.search,
              dismiss:true
            }
          ),
          url:`library/${params.type}/${params.term}`
        })
      }
    }
  }

  selectTab(tab){
    this.setState({tab:tab},()=>{
      // make sure we don't add the tab query twice
      const strippedTab = tab.replace('?tab=', '')

      this.props.history.push({
        search: `?tab=${strippedTab}`
      })
    })
  }

  clearTracks(){
    this.props.dispatch(clearTracks())
  }

  getTracks(limit,offset,sorter = null){
    const params = parseQueryString(this.state.tab)
    if(params.type === 'search'){
      this.props.dispatch(searchLibrary(
        params.term,
        limit,
        offset,
        sorter,
        this.state.restricted
      ))
    } else {
      this.props.dispatch(getTracks(
        this.state.url,
        limit,
        offset,
        sorter,
        this.state.filter,
        this.state.restricted
      ))
    }
  }

  moreActions(data){
    this.props.dispatch(toggleOverlay(true,data,'venues'))
  }

  searchTracks(term){
    const link = `search&type=search&term=${term}`
    // If the user performs a search, disable the typeahead dropdown
    this.setState({
      searchTab:this.state.searchTab.concat(
        {name:`Search: ${term}`,link:link,dismiss:true}
      ),
      tab:link,
      url:`library/search/${term}`,
      disableTypeahead: true
    },()=>{
      this.props.history.push({
        search: `?tab=${link}`
      })
    })
  }

  searchLibraryTypeahead(value){
    // if the user has entered text in the search field, re-enable the typeahead
    this.setState({
      disableTypeahead: false
    })
    this.state.restricted || this.props.dispatch(searchLibraryTypeahead(value))
  }

  addTrackToPlaylist(data){
    const {
      dispatch,
      playlist
    } = this.props

    if (playlist) {
      dispatch(addTrackToPlaylistOwned(data.track, playlist))
    } else {
      dispatch(toggleOverlay(true,data,'playlistsAddTrack'))
    }
  }

  removeTrackFromPlaylist(data){
    const {
      dispatch,
      playlist,
      playlistTracks
    } = this.props

    const playlistTrack = playlistTracks.find(playlistTrack => playlistTrack.id === data.track.id)
    dispatch(removeTrackFromPlaylistOwnedUsingPivot(playlistTrack, playlist.id))
  }

  addTrackToQueue(data){
    this.props.dispatch(addTrackToQueue(data.track))
    getQueue()
  }

  removeTrackFromQueue(data){
    this.props.dispatch(deleteFromQueue(data.track))
    getQueue()
  }

  checkboxAction(e,data,index){
    const {
      selectedTracks
    } = this.state
    if(e.target.checked){
      data.index = index
      this.setState({
        selectedTracks:[
          ...selectedTracks,
          data
        ]
      })
      this.selectedTracks.push(data)
    } else {
      this.setState({
        selectedTracks:selectedTracks.filter(track => track.id !== data.id)
      })
      this.selectedTracks = this.selectedTracks.filter(track => track.id !== data.id)
    }
    if(selectedTracks.length > 0){
      this.setState({actionActive:true})
    } else {
      this.setState({actionActive:false})
    }
  }

  clickRow(e,data,isChecked,index){
    e.stopPropagation()
    const {
      selectedTracks
    } = this.state
    //cmd + click
    if(e.metaKey){
      if(!isChecked){
        data.index = index
        this.setState({
          selectedTracks:[
            ...this.state.selectedTracks,
            data
          ]
        })
        this.selectedTracks.push(data)
      } else {
        this.setState({
          selectedTracks:this.state.selectedTracks.filter(track => track.id !== data.id)
        })
        this.selectedTracks = this.selectedTracks.filter(track => track.id !== data.id)
      }
      if(this.selectedTracks.length > 0){
        this.setState({actionActive:true})
      } else {
        this.setState({actionActive:false})
      }
    }
    if(e.shiftKey){
      if(selectedTracks.length === 1){
        let tracks = []
        console.log('INDEX',index)
        for (let i = selectedTracks[0].index; i <= index; i++) {
          tracks.push(this.state.tracks[i])
        }
        console.log('TRACKS',selectedTracks)
        console.log('TRACKS',tracks)
        this.setState({
          selectedTracks:tracks
        })
        this.setState({actionActive:true})
      }
    }
  }

  deselectTracks(){
    this.setState({
      selectedTracks:[],
      actionActive:false
    })
  }

  addTracksToPlaylist(){
    this.props.dispatch(toggleOverlay(true,this.selectedTracks,'multiPlaylistsAdd'))
  }

  playTrack(track){
    const {
      dispatch,
      playlistTracks
    } = this.props

    const trackType = playlistTracks && playlistTracks.length > 0 ? 'playlist-library' : 'library'

    dispatch(getTrackInfo(track, trackType))
    dispatch(getTrackUrl(track, trackType))
  }

  pauseTrack(track){
    this.props.dispatch()
  }

  favouriteTrack(track){

  }

  sortLibrary(header){
    this.clearTracks()
    const params = parseQueryString(this.state.tab)
    if(params.type === 'search'){
      this.props.dispatch(searchLibrary(
        params.term,
        getRowLimit(classname),
        0,
        header
      ))
    } else {
      this.props.dispatch(getTracks(
        this.state.url,
        getRowLimit(classname),
        0,
        header,
        this.state.filter,
        this.state.restricted
      ))
    }
  }

  showFilter(){
    this.setState(
      {showFilter:!this.state.showFilter}
    )
  }

  setFilter(filter){
    this.setState({
      filter:filter,
      filterLength:filter.genres.length + filter.excludedGenres.length + filter.energies.length
    },() => {
      this.props.dispatch(clearTracks())
      this.props.dispatch(getTracks(
        '/library',
        getRowLimit(classname),
        0,
        null,
        this.state.filter,
        this.state.restricted
      ))
    })
  }

  clickTypeahead(type,term){
    //this.props.history.push(url)
    const link = `search&type=${type}&term=${term}`
    // If the user has selected a typeahead option, disable the typeahead dropdown
    this.setState({
      searchTab:this.state.searchTab.concat(
        {name:`${type}: ${term}`,link:link,dismiss:true}
      ),
      tab:link,
      url:`library/${type}/${term}`,
      disableTypeahead: true
    },()=>{
      this.props.history.push({
        search: `?tab=${link}`
      })
    })
  }

  closeTypeahead() {
    this.setState({
      disableTypeahead: true
    })
  }

  closeTab(tab){
    const searchTabs = this.state.searchTab.filter(tb => tb !== tab)
    this.setState({
      searchTab:searchTabs,
      tab:'all',
      url:'library'
    },()=>{
      this.props.history.push({
        search: `?tab=all`
      })
    })
  }

  libraryAction(data,e){
    const params = parseQueryString(data)
    if(e && e.metaKey){
      //e.stopPropagation()
      //window.open(data,'_blank')
      return
    } else {
      this.setState({
        searchTab:this.state.searchTab.concat(
          {name:`${params.type}: ${params.term}`,link:data,dismiss:true}
        ),
        tab:data,
        url:`library/${params.type}/${params.term}`
      },()=>{
        this.props.history.push({
          search: data
        })
      })
    }
  }

  render(){
    const {
      playlist,
      playlistTracks,
      addTrackToOwnedPlaylistLoading,
      isLibrary,
      queue
    } = this.props

    return (
      <Container classname={`${isLibrary || 'isPlaylist'}`} column height="100%">
        <Container classname="tabs">
          <Tabs
            match={this.props.match}
            classname={classname}
            select={(tab)=>this.selectTab(tab)}
            active={this.state.tab}
            add={this.state.searchTab}
            closeTab={(tab)=>this.closeTab(tab)}
          />
        </Container>
        <Container height="100%" classname={`
          ${classname} 
          ${isLibrary ? 'container-tabview' : 'isPlaylist'}
          `}
          column>
          <LibrarySearchFilter
            showFilter={this.state.showFilter}
            genres={this.props.genres}
            setFilter={(filter)=>this.setFilter(filter)}
            closeFilter={()=>this.showFilter()}
          />
          <TableWrapper
            controls
            restricted={this.state.restricted}
            classname={classname}
            data={this.props.tracks}
            count={this.props.count}
            clearData={()=>this.clearTracks()}
            getData={(limit,offset,sorter)=>this.getTracks(limit,offset,sorter)}
            loading={this.props.loading}
            typeaheadLoading={this.props.typeaheadLoading}
            playingTrack={this.props.playingTrack}
            sortTable={(head)=>this.sortLibrary(head)}
            rowComp={
              <LibraryTableRow
                play={(track)=>this.playTrack(track)}
                playingTrack={this.props.playingTrack}
                checkboxSelected={this.state.selectedTracks}
                checkboxAction={(e,data,index)=>this.checkboxAction(e,data,index)}
                clickRow={(e,data,isChecked,index)=>this.clickRow(e,data,isChecked,index)}
                libraryAction={(data,e) => this.libraryAction(data,e)}/>
            }
            rowActions={<LibraryTableRowActions
              more={(data)=>this.moreActions(data)}
              addTrackToPlaylist={(data)=>this.addTrackToPlaylist(data)}
              removeTrackFromPlaylist={(data)=>this.removeTrackFromPlaylist(data)}
              addTrackToOwnedPlaylistLoading={addTrackToOwnedPlaylistLoading}
              favouriteTrack={(data)=>this.favouriteTrack(data)}
              addTrackToQueue={(data) => this.addTrackToQueue(data)}
              removeTrackFromQueue={(data) => this.removeTrackFromQueue(data)}
              classname={classname}
              playlist={playlist}
              playlistTracks={playlistTracks}
              queueTracks={queue}/>
            }
            tableAction={<LibraryTableActions
              classname={classname}
              active={this.state.actionActive}
              numSelected={this.state.selectedTracks.length}
              typeaheadAction={(value)=>this.searchLibraryTypeahead(value)}
              typeaheadLoading={this.props.typeaheadLoading}
              searchAction={(value)=>this.searchTracks(value)}
              clear={()=>this.clearTracks()}
              disableTypeahead={this.state.disableTypeahead}
              searchData={this.props.typeahead}
              selectGenres={()=>this.selectGenres()}
              clickTypeahead={(type,term)=>this.clickTypeahead(type,term)}
              closeTypeahead={()=>this.closeTypeahead()}
              showFilter={()=>this.showFilter()}
              filterLength={this.state.filterLength}
              addTracksToPlaylist={()=>this.addTracksToPlaylist()}
              deselectTracks={()=>this.deselectTracks()}/>
            }
          />
        </Container>
      </Container>
    )
  }
}

function mapStateToProps(store){
  return {
    tracks:store.library.tracks,
    typeahead:store.library.typeahead,
    typeaheadLoading:store.library.typeaheadLoading,
    count:store.library.count,
    loading:store.library.loading,
    addTrackToOwnedPlaylistLoading: store.playlist.addTrackToOwnedPlaylistLoading,
    playingTrack: store.player.track,
    genres:store.library.genres,
    queue:store.player.queue,
    settings:store.auth.settings
  }
}

export default withRouter(connect(mapStateToProps)(LibraryTable))
