import React, {Component, Fragment} from 'react'
import { connect } from 'react-redux'

import Container from 'ui/Container'

import {
  createImporterPlaylist,
  createPlaylistAddVenues,
  resetCreatePlaylist
} from 'store/actions/importerAction'

import {
  toggleOverlay
} from 'store/actions/overlayAction'

import {
  getVenues
} from 'store/actions/venueAction'

import Form from 'ui/Form'
import Loader from 'ui/Loader'

const classname = 'overlayPlaylistImporter'

class OverlayPlaylistImporterCreatePlaylist extends Component {
  constructor(props){
    super(props)
    this.state = {
      checklistErrorMessage: ''
    }
  }

  componentDidMount(){
    const {
      dispatch
    } = this.props

    dispatch(resetCreatePlaylist())
    dispatch(getVenues())
    this.setState({ checklistErrorMessage: '' })
  }

  componentDidUpdate(prevProps) {
    const {
      data,
      dispatch,
      playlist,
      playlistCreated,
      playlistCreatedVenuesAdded,
      venues
    } = this.props

    // show an error message if the business has no venues,
    // after the playlist is created, in the add venues step
    if (
      (prevProps.playlistCreated !== playlistCreated) &&
      playlistCreated &&
      venues.length === 0
    ) {
      this.setState({
        checklistErrorMessage: 'This business does not have any venues'
      })
    }

    if (
      (prevProps.playlistCreatedVenuesAdded !== playlistCreatedVenuesAdded) &&
      playlistCreatedVenuesAdded
    ) {
      data.history.push(`/playlist/${playlist.id}?tab=details`)
    }

    if (
      (prevProps.playlistCreatedVenuesAdded !== playlistCreatedVenuesAdded) &&
      playlistCreatedVenuesAdded
    ) {
      dispatch(toggleOverlay(false, [], 'importerCreatePlaylist'))
    }
  }

  createImporterPlaylist(formData) {
    const {
      data,
      dispatch
    } = this.props

    // remove selected tracks from playlistTracks
    // to add them to the tracks to import list
    const tracksToImport = data.playlistTracks.filter(playlistTrack => {
      return !data.tracks.find(track => track.spotifyID === playlistTrack.id)
    })

    const importerPlaylistData ={
      ...formData,
      tracks: data.tracks,
      tracksToImport: tracksToImport
    }

    dispatch(createImporterPlaylist(importerPlaylistData))
  }

  createPlaylistAddVenues(form){
    this.props.dispatch(createPlaylistAddVenues(form, this.props.details.id))
  }

  skipCreatePlaylistAddVenues() {
    const {
      data,
      dispatch,
      playlist
    } = this.props

    data.history.push(`/playlist/${playlist.id}?tab=details`)

    dispatch(toggleOverlay(false, [], 'importerCreatePlaylist'))
  }

  render(){
    const {
      loading,
      playlistCreated,
      venues
    } = this.props

    const {
      checklistErrorMessage,
      message
    } = this.state

    return (
      <Container classname={classname} maxWidth height="100%" column>
        {loading ? (
          <Loader/>
        ) : (
          <Fragment>
            {playlistCreated ? (
              <Form
                scroll
                classname='createPlaylistAddVenues'
                submit={(details)=>this.createPlaylistAddVenues(details)}
                messages={message}
                checklistData={venues}
                checklistErrorMessage={checklistErrorMessage}
                skipFunction={()=>this.skipCreatePlaylistAddVenues()}
                disableEnterToSearch
              />
            ) : (
              <Form
                classname='playlists'
                submit={(formData)=>this.createImporterPlaylist(formData)}
                messages={message}
              />
            )}
          </Fragment>
        )}
      </Container>
    )
  }
}
function mapStateToProps(store){
  return {
    business: store.importer.business,
    details: store.importer.details,
    importerFinalTracklist: store.importer.importerFinalTracklist,
    loading: store.importer.loading,
    playlist: store.importer.details,
    playlistCreated: store.importer.playlistCreated,
    playlistCreatedVenuesAdded: store.importer.playlistCreatedVenuesAdded,
    venues: store.venues.data
  }
}
export default connect(mapStateToProps)(OverlayPlaylistImporterCreatePlaylist)
