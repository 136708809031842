import React from 'react'

import Button from 'ui/Button'
import Icon from 'ui/Icon'
import Image from 'ui/Image'

const PlaylistItem = (props) => {

  const {
    currentlyPlaying,
    playlist,
    isSubscribed,
    subscribe,
    unsubscribe,
    subscriptionLoading,
    subscribingPlaylistId,
    copyPlaylist,
    userGroup
  } = props

  // const getGenres = () => {
  //   if(playlist.genres){
  //     return playlist.genres.map((genre,index)=><span key={index} className="playlist-item-genre">{genre.genre}</span>)
  //   } else {
  //     return null
  //   }
  // }
  //
  // const getTags = () => {
  //   if(playlist.tags){
  //     return playlist.tags.map((tag,index)=><span key={index} className="playlist-item-tag">{tag.name}</span>)
  //   } else {
  //     return null
  //   }
  // }

  return (
    <div className="playlist-item" onClick={()=>props.viewPlaylist(playlist)}>
      <div className="playlist-item-play">
        <Image
          name={playlist.image ? playlist.image.name : null}
          width="200"
          height="200"
        />
        <Icon
          name={currentlyPlaying === playlist.id ? 'ios-volume-high' : 'play-circle'}
          action={()=>props.playPlaylist(playlist.id)}
          stopPropagation
        />
      </div>
      <div className="playlist-item-info">
        <span className="playlist-item-name">{playlist.name}</span>
        <span className="playlist-item-tracks">{playlist.tracksCount} tracks</span>
        {isSubscribed && (
          <span className='playlist-item__subscribed'>SUBSCRIBED</span>
        )}
        <div className="playlist-item-style">{playlist.style}</div>
        <div className="playlist-item-genres">{playlist.tags} {playlist.genres}</div>
      </div>
      <div className="playlist-item-actions">
        {isSubscribed ? (
          <Button
            action={userGroup && userGroup === 9 ? null : ()=>unsubscribe(playlist)}
            type="block"
            name={userGroup && userGroup === 9 ? 'Subscribed' : 'Unsubscribe'}
            loading={subscriptionLoading && subscribingPlaylistId === playlist.id}
            stopPropagation
            warning
            disabled={userGroup && userGroup === 9 ? true : false}
          />
        ) : (
          <Button
            action={()=>subscribe(playlist)}
            type="block"
            name="Subscribe"
            loading={subscriptionLoading && subscribingPlaylistId === playlist.id}
            stopPropagation
          />
        )}
        <Button
          action={()=>copyPlaylist(playlist)}
          type="block"
          name="Copy"
          stopPropagation
        />
      </div>
    </div>
  )
}

export default PlaylistItem
