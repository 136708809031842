import axios from 'axios'

export function getStatus() {
  return (dispatch) => {
    return dispatch({
      type:'GET_STATUS',
      payload:axios.get('onboarding/status')
    })
  }
}

export function getBubbles() {
  return (dispatch) => {
    return dispatch({
      type:'GET_BUBBLES',
      payload:axios.get('onboarding/bubbles')
    })
  }
}

export function setBubbles(data){
  return (dispatch) => {
    return dispatch({
      type:'SET_BUBBLES',
      payload:axios.post(
        `onboarding/bubbles`,
        { data }
      )
    })
  }
}
