import React, {Component} from 'react'
import { connect } from 'react-redux'

import {
  getTrackInfo,
  getTrackUrl
} from 'store/actions/playerAction'
import {
  getPlaylist,
  subscribePlaylistDetails,
  unsubscribePlaylistDetails
} from 'store/actions/playlistAction'
import { toggleOverlay } from 'store/actions/overlayAction'
import { toggleConfirm } from 'store/actions/confirmAction'

import Confirm from 'ui/Confirm'
import Container from 'ui/Container'
import Loader from 'ui/Loader'
import Icon from 'ui/Icon'
import Energy from 'ui/Energy'
import Button from 'ui/Button'
import Badge from 'ui/Badge'
import Image from 'ui/Image'
import Tooltip from 'ui/Tooltip'
import DashboardPieChart from 'components/dashboard/DashboardPieChart'

const classname = 'playlistDetails'

class PlaylistDetails extends Component {
  constructor(props){
    super(props)
    this.state = {
      displayName:null,
      subscribingPlaylistId: null
    }
  }

  componentDidMount(){
    const {
      dispatch,
      id
    } = this.props

    dispatch(getPlaylist(id))
  }

  playTrack(track){
    const {
      dispatch
    } = this.props

    dispatch(getTrackInfo(track, 'playlist'))
    dispatch(getTrackUrl(track, 'playlist'))
  }

  subscribe(playlist){
    const {
      dispatch
    } = this.props

    dispatch(subscribePlaylistDetails(playlist.id))
    dispatch(toggleOverlay(true, playlist ,'subscribedPlaylistToVenues'))
  }

  unsubscribe(playlist){
    const {
      dispatch
    } = this.props

    const confirmData = {
      action: () => {
        dispatch(unsubscribePlaylistDetails(playlist))
      },
      question: 'Unsubscribing from a playlist will remove it from your schedules for all your venues. Are you sure?'
    }

    dispatch(toggleConfirm(true, confirmData))
  }

  getPieChart(){
    const {metadata} = this.props
    if(metadata && metadata.genres){
      return <DashboardPieChart data={metadata.genres}/>
    }
  }

  getSubscribeButton(){
    const {
      details,
      subscriptionLoading,
      userGroup
    } = this.props

    if(details.subscribed){
      return(
        <Button
          action={()=>this.unsubscribe(details)}
          type="block"
          name={userGroup === 9 ? 'Subscribed' : 'Unsubscribe from playlist'}
          loading={subscriptionLoading}
          warning
          disabled={userGroup === 9 && true}
        />
      )
    } else {
      return(
        <Button
          action={()=>this.subscribe(details)}
          type="block"
          name="Subscribe to playlist"
          loading={subscriptionLoading}
        />
      )
    }
  }

  addToVenues(playlist) {
    const {
      dispatch
    } = this.props

    dispatch(toggleOverlay(true, playlist ,'subscribedPlaylistToVenues'))
  }

  addToVenuesButton() {
    const {
      details
    } = this.props

    if (details.subscribed) {
      return (
        <Button
          action={()=>this.addToVenues(details)}
          type="block"
          name="Add to venues"
        />
      )
    }
  }

  addTrackToPlaylistsOverlay(track){
    const {
      dispatch,
    } = this.props

    const data = {
      track: track
    }

    dispatch(toggleOverlay(true, data, 'playlistsAddTrack'))
  }

  copyPlaylist(playlist){
    this.props.dispatch(toggleOverlay(true, playlist ,'copyPlaylist'))
  }

  render(){
    const {
      metadata,
      details,
      tracks,
      playingTrack
    } = this.props

    const image = details.playlistImage ? details.playlistImage.name : 'default.png'

    if(this.props.loading){
      return <Loader/>
    } else {
      return (
        <Container classname={classname} height="100%" maxWidth>
          <div className="details-wrapper">
            <div className="playlist-name">
              {details.displayName ? details.displayName : details.name}
            </div>
            <div className="details-top">
              <Image name={image}/>
              <div className="meta-wrapper">
                <div>
                  Total Tracks <span>{metadata.count}</span>
                </div>
                {metadata.new > 0 ? <div>New Tracks <span>{metadata.new}</span></div> : null}
                <div>Running Time <span>{metadata.total_length}</span></div>
                <div className="energy-level">
                  Highest Energy <Energy energy={metadata.highestEnergy}/>
                </div>
                <div className="energy-level">
                  Lowest Energy <Energy energy={metadata.lowestEnergy}/>
                </div>
                <div className="energy-level">
                  Average Energy <Energy energy={metadata.energy}/>
                </div>
              </div>
            </div>
            {this.getSubscribeButton()}
            {this.addToVenuesButton()}
            <Button
              action={()=>this.copyPlaylist(details)}
              type="block"
              name="Copy playlist"
            />
            <div className="pie-chart">
              {this.getPieChart()}
            </div>
          </div>
          <div className="tracks-wrapper">
            <ul className='tracklist'>
              {tracks.map((track,index)=>{
                const isSelected = playingTrack && (playingTrack.id === track.id)
                const isPlaying = isSelected && playingTrack.isPlaying

                return (
                  <li
                    className={`tracklist-item ${isSelected ? ' tracklist-item--playing' : ''}`}
                    key={index}
                    onDoubleClick={() => this.playTrack(track)}
                  >
                    <Icon
                      classname="play"
                      name={`ios-${isPlaying ? 'volume-high' : 'play'}`}
                      action={() => this.playTrack(track)}
                    />
                    <div className='tracklist-track'>
                      <div className='tracklist-track-name'>{track.title}</div>
                      <div className='tracklist-artist'>{track.artist}</div>
                    </div>
                    <div className='tracklist-new'>{track.new ? <Badge data='new'/> : ''}</div>
                    <div className="tracklist-meta">
                      <div className='tracklist-energy'><Energy energy={track.energy}/></div>
                      <div className='tracklist-duration'>{track.length}</div>
                      <div className='tracklist-add-to-playlist'>
                        <Icon name='add-circle' action={()=>this.addTrackToPlaylistsOverlay(track)}>
                          <Tooltip
                            pos='left'
                            text='Add track to another playlist'
                          />
                        </Icon>
                      </div>
                      </div>
                  </li>
                )
              })}

            </ul>
          </div>
          <Confirm />
        </Container>
      )
    }
  }
}

function mapStateToProps(store){
  return {
    details:store.playlist.details,
    playingTrack: store.player.track,
    tracks:store.playlist.tracks,
    metadata:store.playlist.metadata,
    loading:store.playlist.fullPlaylistDataLoading,
    subscriptionLoading:store.playlist.subscriptionLoading,
    userGroup:store.auth.userGroup
  }
}

export default connect(mapStateToProps)(PlaylistDetails)
