import React from 'react'

import Energy from 'ui/Energy'

const TableCellEnergy = (props) => {
  return (
    <td className={`table-cell table-cell-energy table-cell-${props.classname} table-cell-size-${props.size}`}>
      <Energy energy={props.datum}/>
    </td>
  )
}

export default TableCellEnergy
