import React, { Component } from 'react'

import Container from 'ui/Container'

import LibraryTable from './library/LibraryTable'

const classname = 'library'

class LibraryContainer extends Component {
  render(){
    const {
      history,
      location
    } = this.props

    return (
      <Container classname={`${classname}-wrapper`} height="100%" column maxWidth>
          <LibraryTable
            history={history}
            location={location}
            isLibrary={true}
          />
      </Container>
    )
  }
}



export default LibraryContainer
