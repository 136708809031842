import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Icon from 'ui/Icon'
import Loader from 'ui/Loader'
import { debounce } from 'helpers/Debounce'

import TypeaheadModel from 'models/TypeaheadModel'

class Typeahead extends Component{
  constructor(props){
    super(props)
    //https://blog.revathskumar.com/2016/02/reactjs-using-debounce-in-react-components.html
    this.sendResponse = debounce(this.sendResponse,TypeaheadModel[this.props.classname].debounce)
    this.typeaheadInput = React.createRef()
    this.typeaheadResultsRef = []
    this.state = {
      typeaheadInput:'',
      term:'',
      results:[],
      cursor:-1,
      cursorSelected:null
    }
  }

  componentDidMount() {
    const {
      classname,
      typeaheadAction,
      value
    } = this.props

    this.typeaheadInput.current.focus()

    // If used in a form, check for pre-existing value, in case of server errors elsewhere
    if (TypeaheadModel[classname].form && value && value.name) {
      this.typeaheadInput.current.value = value.name
      typeaheadAction(value)
    }
  }

  componentDidUpdate(prevProps,prevState){
    const {
      data
    } = this.props

    if(data && (data.length > 0) && (prevProps.data !== data)){
      this.displayResults()
    }
    if(data && (data.length === 0) && (prevProps.data !== data)){
      this.setState({
        results: []
      })
    }

    if(prevState.cursor !== this.state.cursor){
      //scroll results to show selected result. Not compatible with all browsers
      //https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoViewIfNeeded
      this.typeaheadResultsRef[this.state.cursorSelected.id].current.scrollIntoViewIfNeeded(false)
      this.displayResults()
    }
  }

  componentWillUnmount() {
    this.props.clear()
  }

  handleOnChange(e){
    const { value } = e.target

    this.setState({term:value},()=>{
      this.sendResponse(value)
    })
  }

  handleKeyDown(e){
    const { cursor, cursorSelected } = this.state
    // arrow up/down button should select next/previous list element
    // up arrow
    if (e.key === 'ArrowUp' && cursor > 0) {
      this.setState( prevState => ({
        cursor: prevState.cursor - 1,
        cursorSelected:this.props.data[prevState.cursor - 1]
      }))
    //down arrow
    } else if (e.key === 'ArrowDown' && cursor < this.props.data.length - 1) {
      this.setState( prevState => ({
        cursor: prevState.cursor + 1,
        cursorSelected:this.props.data[prevState.cursor + 1]
      }))
    //on enter if selected via cursor
    } else if(e.key === 'Enter' && cursorSelected){
      //if link in model
      if(TypeaheadModel[this.props.classname].link){
        this.goToLink(`${TypeaheadModel[this.props.classname].link}/${cursorSelected.id}`)
      //if action on model
      } else if(TypeaheadModel[this.props.classname].action){
        this.sendAction(cursorSelected)
      } else if(TypeaheadModel[this.props.classname].form){
        this.formAction(cursorSelected)
      }
    } else if(e.key === 'Enter' && !this.props.disableEnterToSearch && this.typeaheadInput.current.value.length > 0){
      this.clearTypeahead()
      this.props.search(this.typeaheadInput.current.value)
    } else if(e.key === 'Escape'){
      this.clearTypeahead()
    }
  }

  clearTypeahead(){
    this.setState({results:[]},()=>{
      this.typeaheadInput.current.value = ''
      this.props.clear()
    })
  }

  sendResponse(value){
    if(this.typeaheadInput.current && this.typeaheadInput.current.value.length > 0){
      this.props.typeaheadSearch(value)
    } else {
      this.clearTypeahead()
    }
  }

  goToLink(link){
    this.clearTypeahead()
    this.props.history.push(link)
  }

  sendAction(data){
    this.clearTypeahead()
    this.props.typeaheadAction(data)
  }

  formAction(data){
    this.setState({
      results: []
    },()=>{
      this.typeaheadInput.current.value = data.name
      this.props.typeaheadAction(data)
    })
  }

  displayResults(){
    const {data, classname} = this.props
    if(data && data.length > 0){
      //map data
      const results = data.map((datum,index)=>{
        //set refs
        this.typeaheadResultsRef[datum.id] = React.createRef()
        if(TypeaheadModel[classname].link){
          return <div
            ref={this.typeaheadResultsRef[datum.id]}
            onClick={()=>this.goToLink(`${TypeaheadModel[classname].link}/${datum.id}`)}
            key={index}
            className={`typeahead-result ${index === this.state.cursor ? 'typeahead-active' : ''}`}>
              {datum[TypeaheadModel[classname].field]}
            </div>
        } else if(TypeaheadModel[classname].action){
          return <div
            ref={this.typeaheadResultsRef[datum.id]}
            onClick={()=>this.sendAction(datum)}
            key={index}
            className={`typeahead-result ${index === this.state.cursor ? 'typeahead-active' : ''}`}>
              {datum[TypeaheadModel[classname].field]}
            </div>
        } else if(TypeaheadModel[classname].form){
          return <div
            ref={this.typeaheadResultsRef[datum.id]}
            onClick={()=>this.formAction(datum)}
            key={index}
            className={`typeahead-result ${index === this.state.cursor ? 'typeahead-active' : ''}`}>
            {datum[TypeaheadModel[classname].field]}
          </div>
        }

        return false
      })
      this.setState({results:results})
    } else if ((this.typeaheadInput.current.value !== '') && !this.props.typeaheadLoading) {
      this.setState({
        results: [<div className='typeahead-result typeahead-result--no-result'>No results</div>]
      })
    }
  }

  render(){
    const { classname, hideDropdown, typeaheadLoading } = this.props
    return (
      <div className={`typeahead-wrapper typeahead-wrapper-${classname} ${TypeaheadModel[classname].form ? 'typeahead-wrapper--form' : ''}`}>
        {typeaheadLoading ? (
          <Loader />
        ) : (
          <Icon name="ios-search" classname="typeahead"/>
        )}
        <input
          ref={this.typeaheadInput}
          className={`typeahead-input typeahead-input-${classname}`}
          onChange={(e)=>this.handleOnChange(e)}
          onKeyDown={(e)=>this.handleKeyDown(e)}
          placeholder={TypeaheadModel[classname].placeholder}
        />
        <Icon
          name="ios-close"
          action={()=>this.clearTypeahead()}
          classname={`typeahead-clear active`}
        />
        {
          hideDropdown ||
          <div
            ref={this.typeaheadResults}
            className={`
              typeahead-results 
              typeahead-results-${this.props.classname} 
              ${this.state.results.length > 0 ? 'typeahead-results-active' : ''}
            `}
          >
            {this.state.results}
          </div>
        }
      </div>
    )
  }
}

export default withRouter(Typeahead)
