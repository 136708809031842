import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import TypeaheadModel from 'models/TypeaheadModel'
import Loader from 'ui/Loader'
import Container from 'ui/Container'
import Typeahead from 'ui/Typeahead';

class PlaylistsSearch extends Component {

  constructor(props){
    super(props)
    this.state = {
      typeaheadData:null
    }
  }

  componentDidMount(){
    //console.log('STORE',this.props.store)
    //console.log(this.props.match.params.type)
    //this.props.dispatch(getPlaylists(this.props.playlistType,getRows('playlists'),0,null))
  }

  componentDidUpdate(prevProps){
    if (this.props.playlistType !== prevProps.playlistType) {
      //this.props.dispatch(getPlaylists(this.props.playlistType,0,null))
    }
  }

  receiveTypeAhead(value){
    this.setState({typeaheadData:value})
  }

  render(){
    if(this.props.loading){
      return <Loader/>
    } else {
      return (
        <Container classname="playlist-wrapper">
          <div className="">
            <Typeahead
              classname="playlists"
              action={(value)=>this.receiveTypeAhead(value)}
              data={this.state.typeaheadData}
              model={TypeaheadModel.playlists}
              debounce={200}/>
          </div>
        </Container>
      )
    }

  }
}

function mapStateToProps(store){
  return {
    playlists:store.playlists.data,
    loading:store.playlists.loading
  }
}

export default withRouter(connect(mapStateToProps)(PlaylistsSearch))
