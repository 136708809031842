import React, { Component } from 'react'
import { connect } from 'react-redux'
import queryString from 'qs'

import { getVenueDetails } from 'store/actions/venueAction'

import VenueDetails from 'modules/venue/VenueDetails'
import VenuePlaylists from 'modules/venue/VenuePlaylists'
import VenueSchedule from 'modules/venue/VenueSchedule'
import VenueAnnouncements from 'modules/venue/VenueAnnouncements'
import VenueManager from './venue/VenueManager'
import VenueUser from './venue/VenueUser'

import Tabs from 'ui/Tabs'
import Container from 'ui/Container'
import VenueBlocked from "./venue/VenueBlocked"

const classname = 'venue'

class VenueContainer extends Component {

  constructor(props){
    super(props)
    this.state = {
      tab:'details',
      tabExclusions:[]
    }
  }

  componentDidMount(){
    const id = this.props.match.params.id
    this.props.dispatch(getVenueDetails(id))
    this.getTabQuery()
    this.setTabExclusions()
  }

  componentDidUpdate(prevProps, prevState){
    if(this.props.venue !== prevProps.venue){
      this.setHeader()
    }

    //  handle forward and back browser button usage
    if ((this.props.location !== prevProps.location) && this.props.history.action === 'POP') {
      this.getTabQuery()
    }
  }

  getTabQuery(){
    const query = queryString.parse(this.props.location.search.slice(1))

    if(typeof query.tab !== 'undefined' && query.tab !== this.state.tab){
      this.setState({tab: query.tab})
    } else if (typeof query.tab === 'undefined') {
      // defines the default tab to loaded when no tab query is passed in the URL
      this.setState({tab: 'schedule'})
    }
  }

  setTabExclusions(){
    const {
      settings
    } = this.props
    let exclusions = []
    if(settings && settings.venue_hasControls === 0){
      exclusions.push('control')
    }
    this.setState({
      tabExclusions:exclusions
    })
  }

  selectTabQuery(query){
    this.props.history.push({
      search: `?tab=${query.tab}${query.view ? '&view='+query.view:''}`
    })
    this.setState({tab:query.tab})
  }

  selectTab(tab){
    this.props.history.push({
      search: `?tab=${tab}`
    })
    this.setState({tab:tab})
  }

  setHeader(){
    const header = `${this.props.venue.name}`
    this.setState({header:header})
  }

  getComponent(){
    const id = this.props.match.params.id
    switch(this.state.tab){
      case 'details':
        return <VenueDetails setHeader={(header)=>this.setHeader(header)} id={id}/>
      case 'playlists':
        return <VenuePlaylists id={id}/>
      case 'schedule':
        return <VenueSchedule id={id} selectTab={(tab)=>this.selectTab(tab)} />
      case 'announcements':
        return <VenueAnnouncements id={id}/>
      case 'control':
        return <VenueManager id={id}/>
      case 'user':
        return <VenueUser id={id}/>
      case 'blocked':
        return <VenueBlocked id={id}/>
      default: //no default case
    }
  }

  render(){
    return (
      <Container classname={classname} height="100%">
        <Container classname="tabs">
          <Tabs
            match={this.props.match}
            classname={classname}
            select={(tab)=>this.selectTab(tab)}
            active={this.state.tab}
            header={this.state.header}
            exclusions={this.state.tabExclusions}/>
        </Container>
        <Container height="100%" classname={`${classname} container-tabview`}>
          {this.getComponent()}
        </Container>
      </Container>
    )
  }
}

function mapStateToProps(store){
  return {
    loading:store.venue.loading,
    venue:store.venue.details,
    settings:store.auth.settings,
    userGroup:store.auth.userGroup
  }
}

export default connect(mapStateToProps)(VenueContainer)
