import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import Icon from 'ui/Icon'
import Tooltip from 'ui/Tooltip'
import Online from 'ui/Online'
import TableModel from 'models/TableModel'

class VenuesTableActions extends Component {

  componentDidMount(){
    this.props.checkVenueOnline(this.props.venue.authToken)
  }

  render(){
    const {
      classname,
      copyVenue,
      deleteVenue,
      venue
    } = this.props

    return (
      <div>
        <Online online={venue.online}/>
        <Link to={`${TableModel[classname].viewLink}/${venue.id}?tab=details`}>
          <Icon name="eye">
            <Tooltip pos="left" text="View venue"/>
          </Icon>
        </Link>
        <Icon name="copy" action={()=>copyVenue(venue)}>
          <Tooltip pos="left" text="Copy venue"/>
        </Icon>
        <Icon name="trash" action={()=>deleteVenue(venue.id)}>
          <Tooltip pos="left" text="Delete venue"/>
        </Icon>
      </div>
    )
  }
}

export default VenuesTableActions
