import React, {Fragment} from 'react'

import Icon from 'ui/Icon'
import Loader from 'ui/Loader'
import Tooltip from 'ui/Tooltip'

const OverlayPlaylistAddTrackTableRowActions = (props) => {
  const {
    addPlaylistTrackLoadingIDs,
    toggleAddTrackToPlaylist,
    playlist
  } = props

  const isLoading = addPlaylistTrackLoadingIDs.includes(playlist.id)

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) :(
        <Fragment>
          {playlist.assigned ? (
            <Icon name="remove-circle" action={()=>toggleAddTrackToPlaylist(playlist)}>
              <Tooltip pos="left" text="Remove track from playlist."/>
            </Icon>
          ) : (
            <Icon name="add-circle" action={()=>toggleAddTrackToPlaylist(playlist)}>
              <Tooltip pos="left" text="Add track to playlist."/>
            </Icon>
          )}
        </Fragment>
      )}
    </div>
  )
}

export default OverlayPlaylistAddTrackTableRowActions
