import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

import {
  copyVenue
} from 'store/actions/venueAction'

import Container from 'ui/Container'
import Loader from 'ui/Loader'
import Form from 'ui/Form'

const classname = 'overlayVenueCopy'

class OverlayVenueCopy extends Component{
  componentDidMount(){
    const {
      error
    } = this.props

    if (
      error &&
      error.response.data.res &&
      error.response.data.res.data
    ) {
      const serverErrorScrollPos = document.getElementsByName(error.response.data.res.data)[0].parentNode.offsetTop
      document.getElementsByClassName('form-overlayVenueCopy')[0].scrollTo(0, serverErrorScrollPos)
    }
  }


  onSubmit(form){
    const {
      data,
      dispatch
    } = this.props

    dispatch(copyVenue(data.venueID, form))
  }

  render(){
    const {
      data,
      error,
      storedVenueData,
      loading
    } = this.props

    return (
      <Container
        classname='overlay-venue-copy'
        maxWidth
        column
      >
        {loading ?
          <Loader/> :
          <Fragment>
            <h3>Copy {data.venueName}</h3>
            <Form
              classname={classname}
              data={storedVenueData}
              submit={(e)=>this.onSubmit(e)}
              serverError={error && error.response.data.res}
              scroll
              isOverlay
            />
          </Fragment>
        }
      </Container>
    )
  }
}

function mapStateToProps(store){
  return {
    loading: store.venue.loading,
    storedVenueData: store.venues.storedVenueData,
    error: store.venues.error
  }
}

export default connect(mapStateToProps)(OverlayVenueCopy)
