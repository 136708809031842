const initialState = {
  data:[],
  typeahead:[],
  count:null,
  loading:false,
  loaded:false,
  error:null
}
export default function reducer(state = initialState, action){
  switch (action.type){
    case 'CLEAR_BUSINESSES':{
      state = {...state,data:[]}
      break
    }
    case 'FETCH_BUSINESSES_PENDING':{
      state = {...state,loading:true}
      break
    }
    case 'FETCH_BUSINESSES_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_BUSINESSES_FULFILLED':{
      const businesses = action.payload.data.businesses.map((business)=>{
        const data = {
          id:business.id,
          name:business.name,
          contact:business.contact_email,
          created_at:business.created_at.standard,
          venues_count:business.venues_count,
          state:business.status.status,
          lastLogin:business.users[0].last_login.standard
        }
        return data
      })
      state =  {
        ...state,
        loading:false,
        loaded:true,
        data:state.data.concat(businesses),
        count:action.payload.data.count
      }
      break
    }
    case 'SEARCH_BUSINESSES_PENDING':{
      state = {...state,typeahead:[]}
      break
    }
    case 'SEARCH_BUSINESSES_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'SEARCH_BUSINESSES_FULFILLED':{
      state =  {
        ...state,
        loading:false,
        loaded:true,
        typeahead:state.typeahead.concat(action.payload.data),
      }
      break
    }
    case 'CLEAR_TYPEAHEAD':{
      state = {...state,typeahead:[]}
      break
    }
    default: //no default case
  }
  return state
}
