import React, { Component, Fragment } from 'react'

import Icon from 'ui/Icon'

class ProgressTracker extends Component {

  render(){
    const {
      stepsArray,
      activeStep
    } = this.props

    return (
      <div className='progress-tracker'>
        {stepsArray.map((step, i) => {
          const stepNumber = i + 1
          return (
            <Fragment key={step}>
              <div
                className={`
                  progress-tracker__step 
                  ${activeStep === i ? 'progress-tracker__step--active' : ''} 
                  ${activeStep > i ? 'progress-tracker__step--complete' : ''}
                  ${i === (stepsArray.length -1) ? 'progress-tracker__step--final' : ''}
                `}
              >
                <div className='progress-tracker__step-number'>
                  <div>
                    {activeStep > i ? (
                      <Icon name='checkmark' />
                    ) : (
                      stepNumber
                    )}
                  </div>
                </div>
                <div className='progress-tracker__step-text'>{step}</div>
              </div>
              {i < (stepsArray.length -1) && (
                <div className={`progress-tracker__divider ${activeStep > i ? 'progress-tracker__divider--active' : ''}`} />
              )}
            </Fragment>
          )
        })}
      </div>
    )
  }
}

export default ProgressTracker
