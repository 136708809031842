import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { millisToMinutesAndSeconds } from 'helpers/TimeHelper'

import {
  addToFinalTracklist,
  getSpotifyPlaylists,
  getSpotifyPlaylistTracks,
  getSpotifyPlaylistTracksNext,
  getImporterMatches
} from 'store/actions/importerAction'

import { toggleOverlay } from 'store/actions/overlayAction'

import {
  generateRandomString,
  serialize
} from 'helpers/StringHelper'

import Container from 'ui/Container'
import Button from 'ui/Button'
import TableWrapper from 'ui/TableWrapper'
import ImporterPlaylists from './importer/ImporterPlaylists'
import ImporterTableRowActions from './importer/ImporterTableRowActions'

const classname = 'importer'

class ImporterContainer extends Component {

  constructor(props){
    super(props)
    this.state = {
      screen:'init',
      accessToken:null,
      disabled:true
    }
  }

  componentDidMount(){
    const hashParams = this.getHashParams()
    if (hashParams.access_token) {
      this.props.dispatch(getSpotifyPlaylists(hashParams.access_token))
      this.setState({
        accessToken:hashParams.access_token,
        screen:'playlists'
      })
    }
  }

  componentDidUpdate(prevProps, prevState){
    const {
      dispatch,
      importerMatches,
      playlistTracks,
      playlistTracksNext
    } = this.props

    const {
      accessToken
    } = this.state

    if (prevProps.playlistTracks !== playlistTracks){
      this.setState({
        screen:'tracks'
      })

      //if next is defined
      if (prevProps.playlistTracksNext !== playlistTracksNext && playlistTracksNext){
        dispatch(getSpotifyPlaylistTracksNext(accessToken,playlistTracksNext))
      }
    }

    // add exact matches to the final tracklist
    if (importerMatches && (importerMatches !== prevProps.importerMatches)) {
      const newMatch = importerMatches
          .filter(x => !prevProps.importerMatches.includes(x))
          .concat(prevProps.importerMatches.filter(x => !importerMatches.includes(x)))

      if (newMatch[0] && newMatch[0].tracks && newMatch[0].tracks.length === 1 && newMatch[0].match_type === 'exact') {
        dispatch(addToFinalTracklist(newMatch[0].tracks[0]))
      }
    }
  }

  getHashParams() {
    var hashParams = {};
    var e, r = /([^&;=]+)=?([^&;]*)/g,
        q = window.location.hash.substring(1);
    e = r.exec(q)
    while (e) {
      hashParams[e[1]] = decodeURIComponent(e[2]);
      e = r.exec(q);
    }
    return hashParams;
  }

  goToSpotify(){
    const state = generateRandomString(16)
    const href = 'https://accounts.spotify.com/authorize?'
    const scopes = ['playlist-read-private','playlist-read-collaborative']
    const locationURL = window.location.href.match(/localhost/g) ?
      'http://localhost:3000/importer' :
      'https://dashboard.openearmusic.com/importer'

    const options = {
      response_type: 'token',
      client_id: '2dd750d1c05a492bbb147ceb0386c094',
      scope:scopes.join(' '),
      redirect_uri: locationURL,
      state: state
    };

    const serializedUrl = href+serialize(options)
    window.location = serializedUrl
  }

  selectPlaylist(playlist){
    this.setState({selected:playlist,disabled:false})
  }

  getSpotifyPlaylists(){
    return this.props.playlists.map((playlist,index)=>{
      return (
        <div
          key={index}
          onClick={()=>this.selectPlaylist(playlist)}
          className={`playlist ${this.state.selected === playlist ? 'active' : ''}`}
        >
          {playlist.name} <em>{playlist.tracks.total} tracks</em>
        </div>
      )
    })
  }

  importPlaylist(){
    const {
      accessToken,
      selected
    } = this.state

    this.props.dispatch(getSpotifyPlaylistTracks(accessToken,selected,0))
  }

  getImporterTracks() {
    const {
      importerMatchPerformed,
      importerMatches,
      playlistTracks
    } = this.props

    return playlistTracks.map((track) => {
      const trackFound = importerMatches.filter(match=>match.spotifyID === track.id)

      return {
        ...track,
        importerMatchPerformed: importerMatchPerformed,
        total_length: millisToMinutesAndSeconds(track.total_length),
        tracks: trackFound[0] ? trackFound[0].tracks : []
      }
    })
  }

  async scanForMatches(tracks) {
    const {
      dispatch
    } = this.props

    for(const track of tracks) {
      const data = {
        artist: track.artist,
        title: track.title,
        spotifyID: track.id
      }

      await dispatch(getImporterMatches(data))
    }
  }

  getScanButtonText() {
    const {
      importerPlaylist,
      importerMatchPerformed,
      importerMatchesLoaded,
      playlistTracks,
    } = this.props

    if(importerMatchPerformed && (playlistTracks.length > importerMatchesLoaded.length)) {
      return `Scanning: ${importerMatchesLoaded.length}/${playlistTracks.length}`
    } else {
      return `Scan for matches`
    }
  }

  getScanButton() {
    const {
      importerFinalTracklist,
      importerMatchPerformed,
      importerMatchesLoaded,
      playlistTracks
    } = this.props

    if (importerMatchPerformed && (playlistTracks.length === importerMatchesLoaded.length)) {
      return (
          <Button
              action={()=>{
                this.toggleCreatePlaylistOverlay(importerFinalTracklist)
              }}
              name={`${importerFinalTracklist.length}/${playlistTracks.length} found - create playlist?`}
          />
      )
    } else {
      return (
          <Button
              action={importerMatchPerformed && (playlistTracks.length > importerMatchesLoaded.length) ? (
                  ()=>{return false}
              ) : (
                  ()=>{this.scanForMatches(playlistTracks)}
              )}
              name={this.getScanButtonText()}
          />
      )
    }
  }

  toggleCreatePlaylistOverlay(tracks) {
    const {
      dispatch,
      history,
      playlistTracks
    } = this.props

    const overlayData = {
      playlistTracks: playlistTracks,
      tracks: tracks,
      history: history
    }

    dispatch(toggleOverlay(true, overlayData, 'importerCreatePlaylist'))
  }

  toggleImportedTracksOverlay(data) {
    const {
      dispatch
    } = this.props

    dispatch(toggleOverlay(true, data, 'importer'))
  }

  loginScreen() {
    const {
      spotifyTokenValid
    } = this.props

    const {
      accessToken
    } = this.state

    return (
      <div className="importer-login">
        {accessToken && spotifyTokenValid === false ? (
          <p>Spotify access token has expired, login to your Spotify account to continue.</p>
        ) : (
          <p>Welcome to the Open Ear playlist importer. Login to your Spotify account to continue.</p>
        )}
        <Button action={()=>this.goToSpotify()} name="Login to Spotify"/>
      </div>
    )
  }

  getScreen(){
    const {
      importerFinalTracklist,
      importerMatchesLoaded,
      spotifyTokenValid
    } = this.props

    const {
      screen,
      disabled
    } = this.state

    if (!spotifyTokenValid) {
      return this.loginScreen()
    }

    switch(screen){
      case 'init':
        return this.loginScreen()
      case 'playlists':
        return (
          <ImporterPlaylists
            getSpotifyPlaylists={()=>this.getSpotifyPlaylists()}
            importPlaylist={()=>this.importPlaylist()}
            disabled={disabled}
          />
        )
      case 'tracks':
        return (
          <div className="importer-tracks">
            {this.getScanButton()}
            <TableWrapper
              classname={classname}
              data={this.getImporterTracks()}
              rowActions={(
                <ImporterTableRowActions
                  classname={classname}
                  toggleImportedTracksOverlay={(tracks)=>this.toggleImportedTracksOverlay(tracks)}
                  importerFinalTracklist={importerFinalTracklist}
                  importerMatchesLoaded={importerMatchesLoaded}
                />
              )}
            />
          </div>
        )
      default: //no default case
    }
  }

  render(){
    return (
      <Container classname={classname} height="100%" column maxWidth>
        {this.getScreen()}
      </Container>
    )
  }
}

function mapStateToProps(store){
  return {
    importerFinalTracklist: store.importer.importerFinalTracklist,
    importerMatchPerformed: store.importer.importerMatchPerformed,
    importerMatches: store.importer.importerMatches,
    importerMatchesLoaded: store.importer.importerMatchesLoaded,
    playlists: store.importer.playlists,
    playlist: store.importer.playlist,
    playlistTracks: store.importer.playlistTracks,
    playlistTotal: store.importer.playlistTotal,
    playlistTracksNext: store.importer.playlistTracksNext,
    spotifyTokenValid: store.importer.spotifyTokenValid,
  }
}

export default withRouter(connect(mapStateToProps)(ImporterContainer))
