import React from 'react'

import FormLabel from 'ui/FormLabel'

const FormInput = (props) => {
  const {
    type,
    name,
    value,
    change,
    label,
    classname,
    size,
    required,
    hasError,
    errorMessage,
    hasServerError,
    serverError,
    disabled
  } = props

  const inputClasses = `input input-${classname} ${size ? size : ''} ${hasError ? 'has-error' : ''}`
  const wrapperClasses = `form-input-wrapper input-wrapper-${classname}`
  const labelClasses = `input-label input-label-${classname}`
  return (
    <div className={wrapperClasses}>
      <FormLabel label={label} className={labelClasses} required={required}/>
      {serverError && (serverError.data === name) && (
        <p className='form-input__server-error-message'>{serverError.message}</p>
      )}
      <input
        type={type}
        name={name}
        className={inputClasses}
        value={value}
        onChange={(e)=>change(e)}
        autoComplete="new-password"
        disabled={disabled}
      />
      {hasServerError && !disabled && (
        <p className='form-input__error-message'>{serverError.message}</p>
      )}
      {hasError && errorMessage && !disabled && (
        <p className='form-input__error-message'>{errorMessage}</p>
      )}
      <span className="focus-border"></span>
    </div>
  )
}

export default FormInput
