const initialState = {
  data:[]
}

export default function reducer(state = initialState, action){
  switch (action.type){
    case 'SEARCH_PLAYLISTS_QUICK_PENDING':{
      state = {
        ...state,
        data:[]
      }
      break
    }
    case 'SEARCH_PLAYLISTS_QUICK_FULFILLED':{
      state = {
        ...state,
        data:action.payload.data.playlists
      }
      break
    }
    case 'ADD_PLAYLIST_TO_VENUE_FULFILLED':{
      state = {
        ...state,
        data:[]
      }
      break
    }
    default: //no default case
  }
  return state
}
