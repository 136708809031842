import {
  setLocalStorage
} from '../../helpers/LocalStorage'
const initalState = {
  isAuthenticated:false,
  isSetup:false,
  loading:false,
  loaded:false,
  error:null,
  message:null,
  settings:null,
  businessSettings:null,
  venues:null,
  resetPwLoading:false,
  resetPwMsg:null,
  pwTokenIsValid:false,
  showPwReset:false,
  pwResetLoading:false,
  pwChangeSuccess:false,
  pwChangeLoading:false,
  pwChangeIsValid:false,
  pwChangeMsg:null
}
export default function reducer(state = initalState, action){
  switch (action.type){
    case 'FETCH_AUTH_PENDING':{
      state =  {...state,loading:true,loaded:false}
      break
    }
    case 'FETCH_AUTH_REJECTED':{
      state = {...state,loading:false,loaded:false,error:action.payload.err}
      break
    }
    case 'FETCH_AUTH_WRONG':{
      state = {...state,loading:false,loaded:false,message:action.payload}
      break
    }
    case 'FETCH_AUTH_FULFILLED':{
      const {
        business
      } = action.payload
      const businessSettings = business[0].settings && business[0].settings
      businessSettings && setLocalStorage('playback_option',businessSettings.playback_option)
      state =  {
        ...state,
        loading:false,
        loaded:true,
        isAuthenticated:true,
        settings:action.payload.curator_settings,
        venues:action.payload.venue_manager,
        userGroup:action.payload.group_id,
        businessSettings:businessSettings,
        isSetup:action.payload.setup === 1 ? true : false
      }
      break
    }
    case 'IS_AUTH_PENDING':{
      state = {
        ...state,
        loading:true,
        isAuthenticated:false
      }
      break
    }
    case 'IS_AUTH_FULFILLED':{
      const {
        data
      } = action.payload
      const businessSettings = data.user.business[0].settings && data.user.business[0].settings
      businessSettings && setLocalStorage('playback_option',businessSettings.playback_option)
      state = {
        ...state,
        loading:false,
        loaded:true,
        isAuthenticated:action.payload ? action.payload.data.auth : false,
        isSetup: action.payload ? action.payload.data.setup : false,
        settings: action.payload && action.payload.data.settings,
        businessSettings:businessSettings,
        venues:action.payload.data.venues,
        user:action.payload.data.user,
        userGroup:action.payload.data.userGroup
      }
      break
    }
    case 'IS_AUTH_REJECTED':{
      state = {
        ...state,
        isAuthenticated:false,
        loading:false,
        loaded:false,
        error:null,
        venue:{},
        authToken:null
      }
      break
    }
    case 'LOGOUT':{
      state = {
        isAuthenticated:false,
        isSetup: false,
        loading:false,
        loaded:false,
        error:action.payload,
        user:{}
      }
      break
    }
    case 'RESET_PASSWORD_PENDING':{
      state = {
        ...state,
        resetPwLoading:true
      }
      break
    }
    case 'RESET_PASSWORD_REJECTED':{
      break
    }
    case 'RESET_PASSWORD_FULFILLED':{
      state = {
        ...state,
        resetPwLoading:false,
        resetPwMsg:action.payload.data.message
      }
      break
    }
    case 'CHECK_RESET_TOKEN_PENDING':{
      state = {
        ...state,
        showPwReset:true,
        pwResetLoading:true
      }
      break
    }
    case 'CHECK_RESET_TOKEN_REJECTED':{
      break
    }
    case 'CHECK_RESET_TOKEN_FULFILLED':{
      state = {
        ...state,
        pwTokenIsValid:action.payload.data.isValid,
        pwResetLoading:false
      }
      break
    }
    case 'CHANGE_PASSWORD_PENDING':{
      state = {
        ...state,
        pwChangeLoading:true,
      }
      break
    }
    case 'CHANGE_PASSWORD_REJECTED':{
      break
    }
    case 'CHANGE_PASSWORD_FULFILLED':{
      state = {
        ...state,
        pwChangeLoading:false,
        pwChangeIsValid:action.payload.data.isValid,
        pwChangeMsg:action.payload.data.message
      }
      break
    }
    case 'RESET_PW_INTERFACE':{
      state = initalState
    }
    default: //no default caseplayerAction.js
  }
  return state
}
