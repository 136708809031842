import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class Card extends Component {

  componentDidMount(){
    this.props.checkVenueOnline(this.props.venue.authToken)
  }

  render(){
    return (
      <Link className="card-container" to={`venue/${this.props.venue.id}`}>{this.props.children}</Link>
    )
  }
}

export default Card
