export function toggleConfirm(isActive, data=null) {
  return (dispatch) => {
    return dispatch({
      type: 'TOGGLE_CONFIRM',
      payload: {
        confirmActive: isActive,
        data: data
      }
    })
  }
}

