import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

import {
  getBubbles,
  setBubbles
} from 'store/actions/onboardingAction'

import BubbleChart from 'components/BubbleChart';
import AnimateOnLoad from 'ui/AnimateOnLoad'
import Loader from 'ui/Loader'

class OnboardingBubbles extends Component {

  constructor(props){
    super(props)
    this.state = {
      selectedBubbles: 0,
      selBubbles: []
    }

    this.selectBubble = this.selectBubble.bind(this);
  }

  componentDidMount() {
    const {
      dispatch
    } = this.props

    dispatch(getBubbles())
  }

  shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }

    return array
  }

  prepBubbles() {
    const {
      bubbles
    } = this.props

    let bubblesArray = []

    this.shuffleArray(bubbles.bubbles).map((bubble) => {
      const bubbleObject = {
        label: bubble.genre || bubble.name,
        value: (bubble.weight + 2) * 9,
        id: bubble.id,
        type: bubble.type
      }

      return (
        bubblesArray.push(bubbleObject)
      )
    })

    return bubblesArray
  }

  selectBubble(selNode, selLabel, selID, selType) {
    const {
      selectedBubbles,
      selBubbles
    } = this.state

    const currentClass = selNode.attr('class')

    let selBubblesArray = [...selBubbles]

    if (currentClass === 'bubble-chart__bubble') {
      if (selectedBubbles === 6) {
        return
      }
      selBubblesArray.push({
        label: selLabel,
        id: selID,
        type: selType
      })
      selNode.attr('class', 'bubble-chart__bubble bubble-chart__bubble--selected')
      this.setState({
        selectedBubbles: selectedBubbles + 1,
        selBubbles: selBubblesArray
      })
    } else {
      selBubblesArray = selBubblesArray.filter(item => item.id !== selID)
      selNode.attr('class', 'bubble-chart__bubble')
      this.setState({
        selectedBubbles: selectedBubbles - 1,
        selBubbles: selBubblesArray
      })
    }
  }

  submitBubbles() {
    const {
      dispatch
    } = this.props

    const {
      selBubbles
    } = this.state

    let bubbleIDArray = []

    selBubbles.forEach((bubble) => {
      bubbleIDArray.push({
        id: bubble.id,
        type: bubble.type
      })
    })

    dispatch(setBubbles(bubbleIDArray))
  }

  render(){
    const {
      bubbles,
      bubblesLoading
    } = this.props

    const {
      selBubbles
    } = this.state

    return (
      <Fragment>
        {bubblesLoading ? (
          <Loader />
        ) : (
          <AnimateOnLoad>
            <div className='onboarding-bubbles'>
              {selBubbles.length > 0 ? (
                <h2 className='onboarding__header'>
                  You have selected:
                  {selBubbles.map((bubble, i) => {
                    const bubbleLabel = bubble.label
                    if (i === 0) {
                      return ` ${bubbleLabel}`
                    }
                    if (i === selBubbles.length - 1) {
                      return ` and ${bubbleLabel}`
                    }
                    return `, ${bubbleLabel}`
                  })}
                  <button
                    className='button onboarding-bubbles__button'
                    type='button'
                    onClick={() => {this.submitBubbles()}}
                  >
                    Submit
                  </button>
                </h2>
              ) : (
                <h2 className='onboarding__header'>Select up to 6 of your favourite genres or styles</h2>
              )}
              {bubbles.bubbles ? (
                <BubbleChart
                  bubbleFunc={this.selectBubble}
                  bubbleScale={1.5}
                  height={500}
                  padding={10}
                  data={this.prepBubbles()}
                  width={1400}
                />
              ) : (
                <Loader />
              )}
            </div>
          </AnimateOnLoad>
        )}
      </Fragment>
    )
  }
}

function mapStateToProps(store){
  return {
    bubbles: store.onboarding.bubbles,
    bubblesLoading: store.onboarding.bubblesLoading
  }
}
export default connect(mapStateToProps)(OnboardingBubbles)
