import React, {Component, Fragment} from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import queryString from 'qs'
import { tableSorter } from 'helpers/TableHelper'

import {
  getPlaylists,
  clearPlaylists,
  deletePlaylist,
  pinPlaylist,
  unpinPlaylist,
  createPlaylist,
  unsubscribePlaylist
} from 'store/actions/playlistAction'
import { getRowLimit } from 'helpers/TableHelper'
import { toggleOverlay } from 'store/actions/overlayAction'
import { toggleConfirm } from 'store/actions/confirmAction'
import { debounce } from 'helpers/Debounce'

import PlaylistsDashboard from './playlists/PlaylistsDashboard'
import PlaylistsSearch from './playlists/PlaylistsSearch'

import Loader from 'ui/Loader'
import Tabs from 'ui/Tabs'
import Container from 'ui/Container'
import Confirm from 'ui/Confirm'
import TableWrapper from 'ui/TableWrapper'
import PlaylistsImporter from './playlists/PlaylistsImporter'
import PlaylistsTableRowActions from './playlists/PlaylistsTableRowActions'
import PlaylistsSubscribedTableActions from './playlists/PlaylistsSubscribedTableActions'
import PlaylistsAssignedTableRowActions from './playlists/PlaylistsAssignedTableRowActions'
import PlaylistsMain from './playlists/PlaylistsMain'
import Form from 'ui/Form'
import PlaylistTableAction from "./playlists/PlaylistTableAction"

const classname = 'playlists'

class PlaylistsContainer extends Component {

  constructor(props){
    super(props)
    this.state = {
      tab:'dashboard',
      tabExclusions:[],
      subscribingPlaylistId: null,
      deletingPlaylistId: null,
      sorter:{
        field:'name',
        direction:'asc'
      },
      filter:''
    }
  }

  //have to clean this mess up
  componentDidMount(){
    const query = queryString.parse(this.props.location.search.slice(1))

    if (typeof query.tab === 'undefined' || query.tab === 'dashboard'){
      this.props.dispatch(clearPlaylists())
      this.props.dispatch(getPlaylists(this.state.tab,getRowLimit(classname),0))
    }

    this.getTabQuery()
    this.setTabExclusions()
  }

  componentDidUpdate(prevProps, prevState){
    const {
      dispatch,
      history,
      playlist,
      playlistCreated,
      copyPlaylist
    } = this.props

    const {
      tab
    } = this.state

    if (
      prevState.tab !== tab
      && tab
      && tab !== 'create'
    ) {
      dispatch(clearPlaylists())
      dispatch(getPlaylists(tab,getRowLimit(classname),0))
    }

    if (playlistCreated && prevProps.playlistCreated !== playlistCreated) {
      history.push(`/playlist/${playlist.id}?tab=details`)
    }

    if (copyPlaylist && prevProps.copyPlaylist !== copyPlaylist) {
      history.push(`/playlist/${playlist.id}?tab=details`)
    }

    //  handle forward and back browser button usage
    if ((this.props.location !== prevProps.location) && this.props.history.action === 'POP') {
      this.getTabQuery()
    }
  }

  setTabExclusions(){
    const {
      settings
    } = this.props
    let exclusions = []
    let defaultTab = null
    if(settings && settings.playlists_hasDashboard === 0){
      exclusions.push('dashboard')
      defaultTab = 'main'
    }
    if(settings && settings.playlists_hasOEPlaylists === 0){
      exclusions.push('main')
      defaultTab = 'owned'
    }
    if(settings && settings.playlists_hasSubscribed === 0){
      exclusions.push('subscribed')
      defaultTab = 'owned'
    }
    if(settings && settings.playlists_hasAssigned === 0){
      exclusions.push('assigned')
      defaultTab = 'owned'
    }
    if(defaultTab){
      this.selectTab(defaultTab)
    }
    this.setState({
      tabExclusions:exclusions
    })
  }

  getTabQuery(){
    const query = queryString.parse(this.props.location.search.slice(1))

    if(typeof query.tab !== 'undefined' && query.tab !== this.state.tab){
      this.setState({tab: query.tab})
    } else if(typeof query.tab === 'undefined'){
      this.setState({tab: 'dashboard'})
    }
  }

  selectTab(tab){
    this.props.history.push({
      search: `?tab=${tab}`
    })
    this.setState({tab:tab})
  }

  clearPlaylists(){
    this.props.dispatch(clearPlaylists())
  }

  getPlaylists(limit,offset){
    this.props.dispatch(getPlaylists(this.state.tab,limit,offset))
  }

  moreActions(data){
    this.props.dispatch(toggleOverlay(true,data,'playlistsMore'))
  }

  searchPlaylists(value){
    console.log(value)
  }

  pinPlaylist(data){
    this.props.dispatch(pinPlaylist(data.id))
  }

  unpinPlaylist(data){
    const playlist = this.props.playlists.find(playlist => playlist.id === data.id)
    this.props.dispatch(unpinPlaylist(playlist))
  }

  createPlaylist(form){
    this.props.dispatch(createPlaylist(form))
  }

  addPlaylistToVenues(playlist){
    const {
      dispatch
    } = this.props

    dispatch(toggleOverlay(true, playlist ,'subscribedPlaylistToVenues'))
  }

  deleteMyPlaylist(playlist) {
    const {
      dispatch
    } = this.props

    const confirmData = {
      action: () => {
        this.setState({
          deletingPlaylistId: playlist.id
        })
        dispatch(deletePlaylist(playlist))
      },
      question: 'Are you sure you want to permanently delete this playlist?'
    }

    dispatch(toggleConfirm(true, confirmData))
  }

  unsubscribePlaylist(playlist){
    const {
      dispatch
    } = this.props

    this.setState({
      subscribingPlaylistId: playlist.id
    })
    dispatch(unsubscribePlaylist(playlist))
  }

  //Due to the way that playlists are fetched, it is possible for the data from multiple
  //tabs to be loaded simultaneously eg click on the open ear tab and then switch
  //to subscribed before the data has finished loading - both open ear and subscribed
  //playlists will be loaded into the subscribed view. Should probably find a cleaner way to
  //handle this, but this filtering function should do the trick for now.
  filterPlaylists() {
    const {
      playlists
    } = this.props

    const {
      tab,
      filter
    } = this.state

    let filteredPlaylists = []

    playlists.forEach((playlist) => {
      if (playlist.type === tab) {
        filteredPlaylists.push(playlist)
      }
    })

    return filteredPlaylists.sort((x, y) => this.sortType(x, y))
      .filter(playlist => {
        if (playlist.name && playlist.name.toLowerCase().includes(filter.toLowerCase())) {
          return playlist
        }
        return false
      })
  }

  setPlaylistsFilter(e){
    this.setState({
      filter:e.target.value
    })
  }

  copyPlaylist(playlist){
    this.props.dispatch(toggleOverlay(true, playlist ,'copyPlaylist'))
  }

  sortTable(head){
    this.setState({
      sorter:head
    })
  }

  sortType(x,y){
    const {field, direction} = this.state.sorter
    // if no direction is provided, due to the sorting icon being reset, use default values
    if (direction === null) {
      return tableSorter(x,y,'name','asc')
    }

    return tableSorter(x,y,field,direction)
  }

  getComponent(){
    const {
      dashboard,
      loading,
      playlistDeleting,
      subscriptionLoading,
      userGroup
    } = this.props

    const {
      deletingPlaylistId,
      subscribingPlaylistId,
      tab
    } = this.state

    switch(tab){
      case 'dashboard':
        return <PlaylistsDashboard
          picks={dashboard.picks}
          featured={dashboard.featured}
          topical={dashboard.topical}/>
      case 'search':
        return <PlaylistsSearch/>
      case 'owned':
        return <TableWrapper
          classname={classname}
          data={this.filterPlaylists()}
          count={this.props.count}
          clearData={()=>this.clearPlaylists()}
          getData={(limit,offset)=>this.getPlaylists(limit,offset)}
          loading={this.props.loading}
          sortTable={(head)=>this.sortTable(head)}
          rowActions={<PlaylistsTableRowActions
            classname={classname}
            addPlaylistToVenues={(playlist)=>this.addPlaylistToVenues(playlist)}
            deleteMyPlaylist={(playlist)=>this.deleteMyPlaylist(playlist)}
            playlistDeleting={playlistDeleting}
            deletingPlaylistId={deletingPlaylistId}
            copyPlaylist={(playlist) => this.copyPlaylist(playlist)}/>
          }
          tableAction={
            <PlaylistTableAction
            classname={classname}
            placeholder="Filter playlists..."
            action={(e)=>this.setPlaylistsFilter(e)}/>
          }/>
      case 'subscribed':
        return <TableWrapper
          classname={classname}
          data={this.filterPlaylists()}
          count={this.props.count}
          clearData={()=>this.clearPlaylists()}
          getData={(limit,offset)=>this.getPlaylists(limit,offset)}
          loading={this.props.loading}
          sortTable={(head)=>this.sortTable(head)}
          rowActions={<PlaylistsSubscribedTableActions
            classname={classname}
            addPlaylistToVenues={(playlist)=>this.addPlaylistToVenues(playlist)}
            more={(data)=>this.moreActions(data)}
            unsubscribePlaylist={(data)=>this.unsubscribePlaylist(data)}
            subscriptionLoading={subscriptionLoading}
            subscribingPlaylistId={subscribingPlaylistId}
            copyPlaylist={(playlist) => this.copyPlaylist(playlist)}
            userGroup={userGroup}/>
          }/>
      case 'assigned':
        return <TableWrapper
          classname={classname}
          data={this.filterPlaylists()}
          count={this.props.count}
          clearData={()=>this.clearPlaylists()}
          getData={(limit,offset)=>this.getPlaylists(limit,offset)}
          loading={this.props.loading}
          sortTable={(head)=>this.sortTable(head)}
          rowActions={<PlaylistsAssignedTableRowActions
            classname={classname}
            addPlaylistToVenues={(playlist)=>this.addPlaylistToVenues(playlist)}
            deleteMyPlaylist={(playlist)=>this.deleteMyPlaylist(playlist)}
            playlistDeleting={playlistDeleting}
            deletingPlaylistId={deletingPlaylistId}
            copyPlaylist={(playlist) => this.copyPlaylist(playlist)}/>
          }/>
      case 'importer':
        return <PlaylistsImporter/>
      case 'create':
        return (
          <Fragment>
            {loading ? (
              <Loader/>
            ) : (
              <Form
                classname={classname}
                submit={(e)=>this.createPlaylist(e)}
                messages={this.state.message}
              />
            )}
          </Fragment>
        )
      case 'main':
        return <PlaylistsMain/>
      default: //no default case
    }
  }

  render(){
    return (
      <Container classname={`${classname}-wrapper`} height="100%" column maxWidth>
        <Container classname="tabs">
          <Tabs
            match={this.props.match}
            select={(tab)=>this.selectTab(tab)}
            active={this.state.tab}
            classname={classname}
            exclusions={this.state.tabExclusions}/>
        </Container>
        <Container height="100%" classname={`${classname} container-tabview`} column>
          {this.getComponent()}
        </Container>
        <Confirm />
      </Container>
    )
  }
}
function mapStateToProps(store,myProps){
  return {
    playlists:store.playlists.data,
    playlist:store.playlist.details,
    playlistCreated:store.playlist.playlistCreated,
    copyPlaylist:store.playlist.copyPlaylist,
    count:store.playlists.count,
    loading:store.playlists.loading,
    playlistDeleting:store.playlists.playlistDeleting,
    subscriptionLoading:store.playlists.subscriptionLoading,
    dashboard:store.playlists.dashboard,
    tab:myProps.location.search,
    settings:store.auth.settings,
    userGroup:store.auth.userGroup
  }
}

export default withRouter(connect(mapStateToProps)(PlaylistsContainer))
