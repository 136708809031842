import axios from 'axios'

export function getDashboard(){
  return (dispatch) => {
    return dispatch({
      type:'FETCH_DASHBOARD',
      payload:axios.get('/report/dashboard')
    })
  }
}

export function getDashboardPlaylist(id) {
  return (dispatch) => {
    return dispatch({
      type:'FETCH_DASHBOARD_PLAYLIST',
      payload:axios.get(`/playlist/metadata/${id}`)
    })
  }
}

export function getDashboardPlaylistTracks(id) {
  return (dispatch) => {
    return dispatch({
      type:'FETCH_DASHBOARD_PLAYLIST_TRACKS',
      payload:axios.get(`/playlist/breakdown/${id}`)
    })
  }
}
