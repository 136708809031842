import React, {Component} from 'react'

import { connect } from 'react-redux'

import Container from 'ui/Container'
import Button from 'ui/Button'

class OverlayPlaylists extends Component{
  splitPlaylist(){

  }

  copyPlaylist(){

  }

  mergePlaylist(){

  }

  deletePlaylist(){

  }

  render(){
    return (
      <Container classname="overlay-venues" maxWidth height="100%" column>
        <h2>Playlist Actions for {this.props.data.name}</h2>
        <Button action={()=>this.splitPlaylist()} name="Split playlist"/>
        <Button action={()=>this.copyPlaylist()} name="Copy playlist"/>
        <Button action={()=>this.mergePlaylist()} name="Merge playlist"/>
        <Button action={()=>this.deletePlaylist()} name="Delete playlist"/>
      </Container>
    )
  }
}

export default connect()(OverlayPlaylists)