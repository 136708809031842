import React from 'react'
import {Link} from 'react-router-dom'

import Icon from 'ui/Icon'
import Tooltip from 'ui/Tooltip'
import Loader from 'ui/Loader'
import TableModel from 'models/TableModel'

const PlaylistsSubscribedTableActions = (props) => {
  const {
    classname,
    playlist,
    addPlaylistToVenues,
    unsubscribePlaylist,
    subscriptionLoading,
    subscribingPlaylistId,
    copyPlaylist,
    userGroup
  } = props
  return (
    <div>
      <Link to={`${TableModel[classname].viewLink}/${playlist.id}?tab=details`}>
        <Icon name="eye">
          <Tooltip pos="left" text="View playlist."/>
        </Icon>
      </Link>
      <Icon name="copy" action={() => copyPlaylist(playlist)}>
        <Tooltip pos="left" text="Copy playlist."/>
      </Icon>
      <Icon
        name='add-circle-outline'
        action={()=>addPlaylistToVenues(playlist)}
        stopPropagation
      >
        <Tooltip pos="left" text='Add/remove playlist to/from venues' />
      </Icon>
      {
        userGroup && userGroup === 9 ?
        null :
        subscriptionLoading && subscribingPlaylistId === playlist.id ? (
          <Loader />
        ) : (
          <Icon name="trash" action={()=>unsubscribePlaylist(playlist)}>
            <Tooltip pos="left" text="Unsubscribe from playlist."/>
          </Icon>
        )}
    </div>
  )
}

export default PlaylistsSubscribedTableActions
