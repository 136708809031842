import axios from 'axios'
const CancelToken = axios.CancelToken
let cancel

export function getTracks(url,limit,offset,sort = null,filter = null,restricted = false){

  let headers = {
    'Limit':limit,
    'Offset':offset
  }

  if(sort && sort.direction){
    headers['Sort'] = true
    headers['Field'] = sort.field
    headers['Direction'] = sort.direction
  }

  return (dispatch) => {
    return dispatch({
      type:'FETCH_TRACKS',
      payload:axios.post(`${url}${restricted ? '/restricted' : ''}`,filter,{headers:headers})
    })
  }
}

export function getGenres(){
  return (dispatch) => {
    return dispatch({
      type:'GET_GENRES',
      payload:axios.get('library/genres')
    })
  }
}

export function clearTracks(){
  return (dispatch) => {
    return dispatch({
      type:'CLEAR_TRACKS',
      payload:null
    })
  }
}

export function searchLibrary(term, limit, offset, sort = null, restricted = false){

  let headers = {
    'Limit':limit,
    'Offset':offset
  }

  if(sort && sort.direction){
    headers['Sort'] = true
    headers['Field'] = sort.field
    headers['Direction'] = sort.direction
  }

  return (dispatch) => {
    cancel && cancel('TYPEAHEAD_TRACKS request cancelled by SEARCH_TRACKS')

    const request = axios.post(`library/search${restricted ? '/restricted' : ''}`,
      {term:term},
      {headers:headers},
      {cancelToken: new CancelToken(function executor(c) {
          cancel = c
        })
      }).catch((cancel) => {
      console.log(cancel)
    })

    return dispatch({
      type:'SEARCH_TRACKS',
      payload:request
    })
  }
}


export function searchLibraryTypeahead(term){
  return (dispatch) => {
    cancel && cancel('TYPEAHEAD_TRACKS request cancelled')

    const request = axios.post('library/typeahead',
      {term:term},
      {cancelToken: new CancelToken(function executor(c) {
          cancel = c
        })
      }).catch((cancel) => {
      console.log(cancel)
    })

    return dispatch({
      type:'TYPEAHEAD_TRACKS',
      payload:request
    })
  }
}

export function favouriteTrack(track){
  return (dispatch) => {
    return dispatch({
      type:'FAVOURITE_TRACK',
      payload:axios.put('')
    })
  }
}

export function addTrackToQueue(track){
  return (dispatch) => {
    return dispatch({
      type:'ADD_TRACK_TO_QUEUE',
      payload:track
    })
  }
}
