const TableModel = {
  playlists:{
    results:{
      limit:50,
      options:[
        {display:50,value:50},
        {display:75,value:75},
        {display:100,value:100},
        {display:150,value:150},
        {display:200,value:200}
      ]
    },
    actionSize:3,
    viewLink:'/playlist',
    headers:[
      {title:'Name',field:'name',size:'large',sortable:true},
      {title:'Venues',field:'venuesCount',size:'small',sortable:true},
      //{title:'Type',field:'type',size:'normal'},
      {title:'Tracks',field:'tracksCount',size:'small',sortable:true},
      //{title:'Created',field:'createdAt',size:'normal'}
    ]
  },
  overlayPlaylistsLibrary:{
    results:{
      limit:50,
      options:[
        {display:50,value:50},
        {display:75,value:75},
        {display:100,value:100},
        {display:150,value:150},
        {display:200,value:200}
      ]
    },
    actionSize:3,
    viewLink:'/playlist',
    headers:[
      {title:'Name',field:'name',size:'large'},
      {title:'Venues',field:'venuesCount',size:'small'},
      {title:'Type',field:'type',size:'normal'},
      {title:'Children',field:'children',size:'small'},
      {title:'Tracks',field:'tracksCount',size:'small'}
    ]
  },
  overlaySubscribedPlaylistToVenues:{
    actionSize:1,
    viewLink:'/playlist',
    headers:[
      {title:'Name',field:'name',size:'normal'},
      {title:'Playlists',field:'playlists_count',size:'small'},
      {title:'Tracks',field:'total_tracks',size:'small'}
    ]
  },
  playlistTracks:{
    headers:[
      {title:'Play',field:'play',size:'small'},
      {title:'Title',field:'title',size:'normal',sortable:true},
      {title:'Artist',field:'artist',size:'normal',sortable:true},
      {title:'Album',field:'album',size:'normal',sortable:true},
      {title:'Genres',field:'genres',size:'normal'},
      {title:'Length',field:'length',size:'small',sortable:true},
      {title:'Energy',field:'energy',size:'small',sortable:true},
      {title:'BPM',field:'bpm',size:'small',sortable:true},
      {title:'Added',field:'added',size:'normal',sortable:true}
    ],
    play:true,
    checkbox:true,
    selectAll:true
  },
  overlayPlaylistsAddTrack:{
    actionSize:3,
    headers:[
      {title:'Playlist name',field:'name',size:'large'},
      {title:'Venues',field:'venuesCount',size:'small'},
      {title:'Tracks',field:'tracksCount',size:'small'},
    ]
  },
  fitnessClasses:{
    headers:[
      {title:'Name',field:'name',size:'normal'},
      {title:'Added',field:'created_at',size:'normal'},
      {title:'Sections',field:'sections_count',size:'normal'},
      {title:'Venues',field:'venues_count',size:'normal'}
    ],
    viewLink:'/class',
  },
  fitnessClassVenues:{
    headers:[
      {title:'Name',field:'name',size:'normal'},
      {title:'Added',field:'created_at',size:'normal'}
    ],
    viewLink:'/venues',
  },
  overlayFitnessVenues:{
    headers:[
      {title:'Name',field:'name',size:'large'}
    ],
    viewLink:'/venues'
  },
  playlistOrder:{
    headers:[
      {title:'Play',field:'play',size:'small'},
      {title:'Title',field:'title',size:'normal',sorter:true},
      {title:'Artist',field:'artist',size:'normal',sorter:true},
      {title:'Album',field:'album',size:'normal',sorter:true},
      {title:'Genres',field:'genres',size:'normal'},
      {title:'Length',field:'length',size:'small',sorter:true},
      {title:'Energy',field:'energy',size:'small',sorter:true},
      {title:'BPM',field:'bpm',size:'small',sorter:true},
      {title:'Added',field:'added',size:'normal'}
    ]
  },
  playlistVenues:{
    headers:[
      {title:'Name',field:'name',size:'normal'},
      {title:'Created',field:'created_at',size:'normal'}
    ],
    viewLink:'/venue',
  },
  venues:{
    results:{
      limit:50,
      options:[
        {display:50,value:50},
        {display:75,value:75},
        {display:100,value:100},
        {display:150,value:150},
        {display:200,value:200}
      ]
    },
    actionSize:2,
    viewLink:'/venue',
    headers:[
      {title:'Name',field:'name',size:'normal'},
      {title:'Venue user',field:'venue_user',size:'normal'},
      {title:'Playlists',field:'playlists_count',size:'small'},
      {title:'Tracks',field:'total_tracks',size:'small'},
      {title:'Blocked',field:'blocked_count',size:'small'},
      {title:'Created',field:'created_at',size:'normal'},
      {title:'Last login',field:'last_login',size:'normal'}
    ]
  },
  venuePlaylists:{
    headers:[
      {title:'Name',field:'name',size:'large',sortable:true},
      {title:'Tracks',field:'tracks_count',size:'small',sortable:true }
    ],
    viewLink:'/playlist',
  },
  venueBlocked:{
    headers:[
      {title:'Title',field:'title',size:'normal',sortable:true},
      {title:'Artist',field:'artist',size:'normal',sortable:true},
      {title:'Album',field:'album',size:'normal',sortable:true},
      {title:'Length',field:'total_length',size:'small',sortable:true},
      {title:'Energy',field:'energy',size:'small',sortable:true},
      {title:'BPM',field:'bpm',size:'small',sortable:true},
      {title:'Blocked',field:'added',size:'normal',sortable:true}
    ],
    play:true
  },
  businesses:{
    results:{
      limit:50,
      options:[
        {display:50,value:50},
        {display:75,value:75},
        {display:100,value:100},
        {display:150,value:150},
        {display:200,value:200}
      ]
    },
    actionSize:2,
    viewLink:'/business',
    headers:[
      {title:'Name',field:'name',size:'normal'},
      {title:'Contact name',field:'contact',size:'normal'},
      {title:'Signed up',field:'created_at',size:'normal'},
      {title:'State',field:'state',size:'small'},
      {title:'Venues',field:'venues_count',size:'small'},
      {title:'Last login',field:'lastLogin',size:'normal'}
    ]
  },
  library:{
    results:{
      limit:50,
      options:[
        {display:50,value:50},
        {display:75,value:75},
        {display:100,value:100},
        {display:150,value:150},
        {display:200,value:200}
      ]
    },
    actionSize:3,
    viewLink:'/library',
    headers:[
      {title:'Play',field:'play',size:'small'},
      {title:'Title',field:'title',size:'large',sortable:true},
      {title:'Artist',field:'artist',size:'large',sortable:true,libraryAction:'?tab=search&type=artist&term='},
      {title:'Album',field:'album',size:'large',sortable:true,libraryAction:'?tab=search&type=album&term='},
      {title:'Genres',field:'genres',size:'normal'},
      {title:'Length',field:'total_length',size:'small',sortable:true},
      {title:'Energy',field:'energy',size:'small',sortable:true},
      {title:'BPM',field:'bpm',size:'small',sortable:true},
      {title:'Added',field:'created_at',size:'small',sortable:true}
    ],
    play:true,
    checkbox:true
  },
  importer:{
    headers:[
      {title:'Title',field:'title',size:'normal'},
      {title:'Artist',field:'artist',size:'normal'},
      {title:'Album',field:'album',size:'normal'}
    ]
  },
  overlayImporter:{
    headers:[
      {title:'Title',field:'title',size:'normal'},
      {title:'Artist',field:'artist',size:'normal'},
      {title:'Album',field:'album',size:'normal'},
      {title:'Length',field:'total_length',size:'small'},
      {title:'BPM',field:'bpm',size:'small'},
    ],
    play:true
  },
}
export default TableModel
