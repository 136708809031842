export function indexOfArrayObject(arr, obj, prop) {
  var index = -1;
  arr.some((val, ind) => {
      if(val[prop] === obj[prop]) {
          index = ind;
          return true;
      }
      return false
  });
  return index;
}

export function moveInArray(arr,newIndex,item){
  const ret = arr.slice(0);
  ret[newIndex] = item;
  return ret;
}
