import React, { Component } from 'react'
import { connect } from 'react-redux'

import { getTrackUrl } from 'store/actions/playerAction'
import { getQueue } from 'helpers/QueueHelper'

import Container from 'ui/Container'
import Tabs from 'ui/Tabs'
import QueueItem from './queue/QueueItem'

const classname = 'queue'

class QueueContainer extends Component{

  constructor(props){
    super(props)
    this.state = {
      tab:'queue'
    }
  }

  componentDidUpdate(prevProps){
    console.log('update')
  }

  selectTab(tab){
    this.setState({tab:tab},()=>{
      this.props.history.push({
        search: `?tab=${tab}`
      })
    })
  }

  playTrack(track){
    this.props.dispatch(getTrackUrl(track))
  }

  getQueue(){
    //get queue from local storage
    const lsQueue = getQueue()
    if(lsQueue){
      return lsQueue.map((item,index) =>
        <QueueItem
          key={index}
          data={item}
          playTrack={(track)=>{this.playTrack(track)}}
        />
      )
    } else {
      return null
    }
  }

  getComponent(){
    switch(this.state.tab){
      case 'queue':
        return this.getQueue()
      case 'recommendations':
        return null
      default: //no default case
    }
  }

  render(){
    return (
      <Container classname={`${classname}-wrapper`} height="100%" column maxWidth>
        <Container classname="tabs">
          <Tabs
            match={this.props.match}
            classname={classname}
            select={(tab)=>this.selectTab(tab)}
            active={this.state.tab}/>
        </Container>
        <Container height="100%" classname={classname} maxWidth column>
          {this.getComponent()}
        </Container>
      </Container>
    )
  }
}

function mapStateToProps(store){
  return {
    queue:store.player.queue
  }
}

export default connect(mapStateToProps)(QueueContainer)
