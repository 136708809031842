import axios from 'axios'

export function getBusinesses(limit,offset){
  const headers = {
    'Limit':limit,
    'Offset':offset
  }
  return (dispatch) => {
    return dispatch({
      type:'FETCH_BUSINESSES',
      payload:axios.get('businesses',{headers:headers})
    })
  }
}

export function clearBusinesses(){
  return (dispatch) => {
    return dispatch({
      type:'CLEAR_BUSINESSES',
      payload:null
    })
  }
}

export function searchBusinesses(term){
  return (dispatch) => {
    return dispatch({
      type:'SEARCH_BUSINESSES',
      payload:axios.get(`businesses/search/${term}`)
    })
  }
}
