const initialState = {
  tracks:[],
  typeahead:[],
  genres:[],
  count:null,
  loading:false,
  loaded:false,
  typeaheadLoading:false,
  error:null
}
export default function reducer(state = initialState, action){
  switch (action.type){
    case 'CLEAR_TRACKS':{
      state = {...state,tracks:[]}
      break
    }
    case 'FETCH_TRACKS_PENDING':{
      state = {...state,loading:true}
      break
    }
    case 'FETCH_TRACKS_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_TRACKS_FULFILLED':{
      const tracks = action.payload.data.tracks.map((track)=>{
        const data = {
          id:track.id,
          title:track.title,
          artist:track.artist,
          album:track.album,
          genres:track.genres.map((genre)=>genre.genre),
          total_length:track.total_length,
          energy:track.energy,
          bpm:track.bpm,
          created_at:track.created_at.standard,
        }
        return data
      })
      state =  {
        ...state,
        loading:false,
        loaded:true,
        tracks:state.tracks ? state.tracks.concat(tracks) : [],
        count:action.payload.data.count
      }
      break
    }
    case 'GET_GENRES_FULFILLED':{
      state = {...state,genres:action.payload.data}
      break
    }
    case 'SEARCH_TRACKS_PENDING':{
      state = {...state,loading:true,tracks:[]}
      break
    }
    case 'SEARCH_TRACKS_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'SEARCH_TRACKS_FULFILLED':{
      if(action.payload.data.tracks.length > 0){
        const tracks = action.payload.data.tracks.map((track)=>{
          const data = {
            id:track.id,
            title:track.title,
            artist:track.artist,
            album:track.album,
            genres:track.genres.map((genre)=>genre.genre),
            total_length:track.total_length,
            energy:track.energy,
            bpm:track.bpm,
            added:track.created_at.standard,
          }
          return data
        })
        state =  {
          ...state,
          loading:false,
          loaded:true,
          tracks:tracks,
          count:action.payload.data.count
        }
      }
      break
    }
    case 'FETCH_TRACK_FULFILLED':{
      let tracks = []
      if(state.tracks){
        tracks = state.tracks.map((track)=>{
          if(track.id === action.meta.trackId){
            track.isPlaying = true
          } else {
            track.isPlaying = false
          }
          return track
        })
      }
      state =  {
        ...state,
        loading:false,
        loaded:true,
        tracks:tracks ? tracks : []
      }
      break
    }
    case 'TYPEAHEAD_TRACKS_PENDING':{
      state = {...state,typeaheadLoading:true,typeahead:[]}
      break
    }
    case 'TYPEAHEAD_TRACKS_REJECTED':{
      state = {...state,typeaheadLoading:false,error:action.payload}
      break
    }
    case 'TYPEAHEAD_TRACKS_FULFILLED':{
      state =  {
        ...state,
        typeaheadLoading: !action.payload,
        typeahead:action.payload ? state.typeahead.concat(action.payload.data) : []
      }
      break
    }
    case 'CLEAR_TYPEAHEAD_TRACKS':{
      state = {
        ...state,
        typeahead:[]
      }
      break
    }
    default: //no default case
  }
  return state
}
