import React, { Component } from 'react'
import { withRouter, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import queryString from 'qs'

import { activate, isAuthenticated, login, logout, resetPassword } from 'store/actions/authAction'
import { appBodyClick } from 'store/actions/appAction'
import AuthContainer from 'modules/AuthContainer'
import OnboardingContainer from 'modules/OnboardingContainer'
import DashboardContainer from 'modules/DashboardContainer'
import PlaylistsContainer from 'modules/PlaylistsContainer'
import PlaylistContainer from 'modules/PlaylistContainer'
import LibraryContainer from 'modules/LibraryContainer'
import VenuesContainer from 'modules/VenuesContainer'
import VenueContainer from 'modules/VenueContainer'
import FitnessClassesContainer from 'modules/FitnessClassesContainer'
import FitnessClassContainer from 'modules/FitnessClassContainer'
import BusinessesContainer from 'modules/BusinessesContainer'
import QueueContainer from 'modules/QueueContainer'
import ImporterContainer from 'modules/ImporterContainer'

import Nav from 'components/Nav'
import Player from 'components/Player'
import Overlay from 'components/Overlay'
import MessageOverlay from 'components/MessageOverlay'

import Container from 'ui/Container'

class App extends Component {

  constructor(props){
    super(props)
    this.state = {
      isAuthenticated:false
    }
  }

  componentDidMount(){
    const {
      dispatch
    } = this.props

    const query = queryString.parse(this.props.location.search.slice(1))

    if(typeof query.activate !== 'undefined') {
      dispatch(activate(query.activate))
    } else {
      dispatch(isAuthenticated())
    }
  }

  componentDidUpdate(prevProps){
    //check route change
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged()
    }
    //load something on auth
    /*if(
      this.props.auth.isAuthenticated &&
      !this.props.venues.loading &&
      !this.props.venues.loaded
    ){
      //this.props.dispatch(getVenues())
    }*/
  }

  onRouteChanged(){
    /*if(this.state.menuActive){
      this.closeMenu()
    }
    for(let menu of MenuModel.left){
      if(this.props.location.pathname === menu.url){
        this.setHeader(menu)
        break
      }
    }*/
  }

  login(data){
    this.props.dispatch(login(data.username,data.password))
  }

  logout(){
    this.props.dispatch(logout())
  }

  appClick(e){
    this.props.dispatch(appBodyClick())
  }

  resetPassword(email){
    this.props.dispatch(resetPassword(email))
  }

  render() {
    const {
      auth,
      onboardStatus
    } = this.props

    if(auth.isAuthenticated){
      if(!auth.isSetup && (onboardStatus !== 'completed')) {
        return (
          <Container classname="app" height="100%" action={(e) => this.appClick(e)}>
            <OnboardingContainer/>
          </Container>
        )
      }

      return (
        <Container classname="app" height="100%" action={(e)=>this.appClick(e)}>
          <Nav/>
          <Route path="/" component={DashboardContainer} exact={true} />
          <Route path="/playlists" component={PlaylistsContainer} />
          <Route path="/playlist/:id" component={PlaylistContainer} />
          <Route path="/library" component={LibraryContainer} />
          <Route path="/venues" component={VenuesContainer} />
          <Route path="/venue/:id" component={VenueContainer} />
          <Route path="/classes" component={FitnessClassesContainer} />
          <Route path="/class/:id" component={FitnessClassContainer} />
          <Route path="/businesses" component={BusinessesContainer} />
          <Route path="/queue" component={QueueContainer} />
          <Route path="/importer" component={ImporterContainer} />
          <Player />
          <Overlay/>
          <MessageOverlay/>
        </Container>
      )
    } else {
      return (
        <AuthContainer
          loading={auth.loading}
          message={auth.message}
          login={(data)=>this.login(data)}
          resetPassword={(email)=>this.resetPassword(email)}/>
      )
    }
  }
}

function mapStateToProps(store){
  return {
    auth:store.auth,
    saved:store.saved,
    onboardStatus: store.onboarding.status
  }
}
export default withRouter(connect(mapStateToProps)(App))
