import React, {Component, Fragment} from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import queryString from 'qs'

import {
  getVenues,
  searchVenues,
  clearVenues,
  createVenue,
  deleteVenue,
  openManagerWs,
  getVenueOnline,
  closeWSConnection
} from 'store/actions/venueAction'

import { getRowLimit } from 'helpers/TableHelper'

import { toggleOverlay } from 'store/actions/overlayAction'
import { toggleConfirm } from 'store/actions/confirmAction'

import Tabs from 'ui/Tabs'
import Container from 'ui/Container'
import TableWrapper from 'ui/TableWrapper'
import Filter from 'ui/Filter'
import Form from 'ui/Form'
import Card from 'ui/Card'
import Online from 'ui/Online'
import Confirm from 'ui/Confirm'
import Loader from 'ui/Loader'
import Icon from 'ui/Icon'
import Tooltip from 'ui/Tooltip'

import VenuesTableActions from './venues/VenuesTableActions'

const classname = 'venues'

class VenuesContainer extends Component {

  constructor(props){
    super(props)
    this.state = {
      tab:'all',
      filter:'',
      view:'grid'
    }
  }

  componentDidMount(){
    this.props.dispatch(getVenues(getRowLimit(classname),0))
    this.props.dispatch(openManagerWs())
    this.getTabQuery()
  }

  componentDidUpdate(prevProps){
    const {
      error,
      history,
      copiedVenueID,
      createdVenueID
    } = this.props

    if(createdVenueID && prevProps.createdVenueID !== createdVenueID){
      history.push(`/venue/${createdVenueID}`)
    }

    if(copiedVenueID && prevProps.copiedVenueID !== copiedVenueID){
      history.push(`/venue/${copiedVenueID}`)
    }

    //if (venueCreated && prevProps.venueCreated !== venueCreated) {
    //  this.props.dispatch(getVenues(getRowLimit(classname),0))
    //}

    if (
      error &&
      (prevProps.error !== error) &&
      error.response.data.res &&
      error.response.data.res.data &&
      this.state.tab === 'create'
    ) {
      const serverErrorScrollPos = document.getElementsByName(error.response.data.res.data)[0].offsetTop + 200
      document.getElementsByClassName('container-venues')[0].scrollTo(0, serverErrorScrollPos)
    }

    //  handle forward and back browser button usage
    if ((this.props.location !== prevProps.location) && this.props.history.action === 'POP') {
      this.getTabQuery()
    }
  }

  componentWillUnmount(){
    this.props.dispatch(closeWSConnection())
  }

  getTabQuery(){
    const query = queryString.parse(this.props.location.search.slice(1))

    if(typeof query.tab !== 'undefined' && query.tab !== this.state.tab){
      this.setState({tab: query.tab})
    } else if (typeof query.tab === 'undefined') {
      // defines the default tab to loaded when no tab query is passed in the URL
      this.setState({tab: 'all'})
    }
  }

  selectTab(tab){
    this.props.history.push({
      search: `?tab=${tab}`
    })
    this.setState({tab:tab})
  }

  clearVenues(){
    this.props.dispatch(clearVenues())
  }

  getVenues(limit,offset){
    this.props.dispatch(getVenues(limit,offset))
  }

  moreActions(data){
    this.props.dispatch(toggleOverlay(true,data,'venues'))
  }

  searchVenues(value){
    this.props.dispatch(searchVenues(value))
  }

  copyVenue(venue){
    const {
      dispatch
    } = this.props

    const overlayData = {
      venueID: venue.id,
      venueName: venue.name
    }

    dispatch(toggleOverlay(true, overlayData, 'venueCopy'))
  }

  deleteVenue(id){
    const confirmData = {
      action: () => {
        this.props.dispatch(deleteVenue(id))
      },
      question: 'Are you sure you want to delete this venue?'
    }

    this.props.dispatch(toggleConfirm(true, confirmData))
  }

  createVenue(data){
    const {
      dispatch
    } = this.props

    dispatch(createVenue(data))
  }

  filterVenues(e){
    const text = e.target.value.toLowerCase()
    this.setState({filter:text})
  }

  checkVenueOnline(authToken){
    if(authToken && this.props.ws.init && this.props.ws.open){
      this.props.dispatch(getVenueOnline(authToken))
    }
  }

  getView(){
    if(this.state.view === 'grid') {
      return this.getCards()
    } else {
      return this.getTable()
    }
  }

  changeView(view){
    this.setState({
      view:view
    })
  }

  getTabs(){
    const {
      error,
      storedVenueData
    } = this.props

    switch(this.state.tab){
      case 'all':
        return (
          <Container height="100%" classname={`${classname} container-tabview ${this.state.view}-view`} maxHeight column>
            {this.getView()}
          </Container>
        )
      case 'create':
        return (
          <Container height="100%" classname={`${classname} container-tabview`} maxHeight column>
            <Form
              classname={classname}
              submit={(data)=>this.createVenue(data)}
              messages={this.state.message}
              formData={storedVenueData}
              serverError={error && error.response.data.res}
            />
          </Container>
        )
      default: //no default case
    }
  }

  getTable() {
    const {
      venues,
      userVenues
    } = this.props

    let _venues = [...venues]
    if(userVenues && userVenues.length > 0){
      _venues = venues.filter(venue => userVenues.find(userVenue => venue.id === userVenue.id))
    }
    return <TableWrapper
      classname={classname}
      data={_venues}
      count={_venues.length}
      clearData={()=>this.clearVenues()}
      getData={(limit,offset)=>this.getVenues(limit,offset)}
      loading={this.props.loading}
      rowActions={<VenuesTableActions
        checkVenueOnline={(authToken) => this.checkVenueOnline(authToken)}
        copyVenue={(venue)=>this.copyVenue(venue)}
        deleteVenue={(id)=>this.deleteVenue(id)}
        classname={classname}/>
      }/>
  }

  getCards(){
    const {
      venues,
      userVenues
    } = this.props

    let _venues = [...venues]
    if(userVenues && userVenues.length > 0){
      _venues = venues.filter(venue => userVenues.find(userVenue => venue.id === userVenue.id))
    }
    return _venues.map((venue,index) => {
      if(venue.name.toLowerCase().includes(this.state.filter)){
        return (
          <Card
            key={index}
            venue={venue}
            checkVenueOnline={(authToken) => this.checkVenueOnline(authToken)}>
            <div className="venue-name">{venue.name}</div>
            <div className="venue-tracks">{venue.total_tracks} available tracks</div>
            <div className="venue-blocked">{venue.blocked_count} blocked tracks</div>
            <div className="venue-online">
              {venue.online ? 'online' : 'offline'}
            </div>
            <Online online={venue.online}/>
          </Card>
        )
      }
      return false
    })
  }

  render(){
    const {
      loading,
      match
    } = this.props

    return (
      <Container classname={`${classname}-wrapper`} height="100%" column maxWidth>
        <Container classname="tabs">
          <Tabs
            match={match}
            classname={classname}
            select={(tab)=>this.selectTab(tab)}
            active={this.state.tab}/>
        </Container>
        {loading ? (
          <Loader/>
        ) : (
          <Fragment>
            {this.state.tab === 'create' || (
              <Container classname='filter-wrapper'>
                <Filter action={(e)=>this.filterVenues(e)} placeholder="Filter venues..."/>
                <Icon
                  name="ios-grid"
                  classname={this.state.view === 'grid' ? 'active' : ''}
                  action={()=>this.changeView('grid')}>
                  <Tooltip text="Grid view" pos="left"/>
                </Icon>
                <Icon
                  name="ios-list"
                  classname={this.state.view === 'table' ? 'active' : ''}
                  action={()=>this.changeView('table')}>
                  <Tooltip text="Table view" pos="left"/>
                </Icon>
              </Container>
            )}
            {this.getTabs()}
          </Fragment>
        )}
        <Confirm />
      </Container>
    )
  }
}

function mapStateToProps(store){
  return {
    venues:store.venues.data,
    userVenues:store.auth.venues,
    typeahead:store.venues.typeahead,
    count:store.venues.count,
    loading:store.venues.loading,
    loaded:store.venues.loaded,
    venueCreated:store.venues.venueCreated,
    storedVenueData: store.venues.storedVenueData,
    error: store.venues.error,
    createdVenueID:store.venues.createdVenueID,
    copiedVenueID:store.venues.copiedVenueID,
    ws:store.venueWS.ws
  }
}

export default withRouter(connect(mapStateToProps)(VenuesContainer))
