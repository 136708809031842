const initialState = {
  dashboard:{},
  dashboardPlaylist:{},
  dashboardPlaylistTracks:{},
  loading:false,
  playlistLoading:false,
  playlistTracksLoading:false,
  loaded:false,
  error:null
}
export default function reducer(state = initialState, action){
  switch (action.type){
    case 'FETCH_DASHBOARD_PENDING':{
      state = {...state,loading:true}
      break
    }
    case 'FETCH_DASHBOARD_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_DASHBOARD_FULFILLED':{
      state =  {
        ...state,
        loading:false,
        loaded:false,
        dashboard:action.payload.data
      }
      break
    }
    case 'FETCH_DASHBOARD_PLAYLIST_PENDING':{
      state = {...state,playlistLoading:true}
      break
    }
    case 'FETCH_DASHBOARD_PLAYLIST_REJECTED':{
      state = {...state,playlistLoading:false,error:action.payload}
      break
    }
    case 'FETCH_DASHBOARD_PLAYLIST_FULFILLED':{
      state =  {
        ...state,
        playlistLoading:false,
        dashboardPlaylist:action.payload.data
      }
      break
    }
    case 'FETCH_DASHBOARD_PLAYLIST_TRACKS_PENDING':{
      state = {...state,playlistTracksLoading:true}
      break
    }
    case 'FETCH_DASHBOARD_PLAYLIST_TRACKS_REJECTED':{
      state = {...state,playlistTracksLoading:false,error:action.payload}
      break
    }
    case 'FETCH_DASHBOARD_PLAYLIST_TRACKS_FULFILLED':{
      state =  {
        ...state,
        playlistTracksLoading:false,
        dashboardPlaylistTracks:action.payload.data
      }
      break
    }
    default: //no default case
  }
  return state
}
