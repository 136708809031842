import React from 'react'

import Icon from 'ui/Icon'

const Announcement = (props) => {

  const {day,start,name,order,changeTime} = props

  //14400 = 4 hours from midnight for 4am
  //divide by 2 for 1px = 2minutes
  //if midnight overlap, add 24 hours, 86400 seconds
  const styles = {
    top:order*70,
    left:`${(14.28*day)}%`
  }
  return (
    <div className="announcement" style={styles}>
      <div className="playlist">{name}</div>
      <div className="controls">
        <Icon name="arrow-back" action={()=>changeTime('back')}/>
        {start}
        <Icon name="arrow-forward" action={()=>changeTime('forward')}/>
      </div>
    </div>
  )
}

export default Announcement