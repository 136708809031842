import React, {Component} from 'react'
import { connect } from 'react-redux'
import { tableSorter } from 'helpers/TableHelper'

import {
  getVenuePlaylists,
  addPlaylistToVenue,
  removePlaylistFromVenue,
  clearTypeahead
} from 'store/actions/venueAction'

import { searchPlaylistsQuick } from 'store/actions/playlistAction'

import PlaylistTableRowActions from './playlist/PlaylistTableRowActions'

import Container from 'ui/Container'
import TableWrapper from 'ui/TableWrapper'
import TableTypeahead from 'ui/TableTypeahead'

const classname = 'venuePlaylists'

class VenuePlaylists extends Component {
  constructor(props){
    super(props)
    this.state = {
      displayName:null,
      sorter:{
        field:'name',
        direction:'asc'
      }
    }
  }

  componentDidMount(){
    this.props.dispatch(clearTypeahead())
    this.props.dispatch(getVenuePlaylists(this.props.id))
  }

  searchPlaylists(term){
    this.props.dispatch(searchPlaylistsQuick(term))
  }

  removePlaylist(playlist){
    this.props.dispatch(removePlaylistFromVenue(this.props.id,playlist))
  }

  addPlaylist(playlist){
    this.props.dispatch(addPlaylistToVenue(this.props.id,playlist))
  }

  getTypeaheadData(){

    let results = []
    //filter out playlists that we already have from the typeahead
    for(let ta of this.props.typeahead){
      let found = this.props.playlists.find(pl => pl.id === ta.id)
      if(!found){
        results.push(ta)
      }
    }
    return results

  }

  sortTable(head){
    this.setState({
      sorter:head
    })
  }

  sortType(x,y){
    const {field, direction} = this.state.sorter
    // if no direction is provided, due to the sorting icon being reset, use default values
    if (direction === null) {
      return tableSorter(x,y,'name','asc')
    }

    return tableSorter(x,y,field,direction)
  }

  getPlaylists() {
    const {
      playlists
    } = this.props

    const sortedPlaylists = [...playlists]

    sortedPlaylists.sort((x, y) => this.sortType(x, y))

    return sortedPlaylists
  }

  render(){
    return (
      <Container classname={classname} height="100%" maxHeight maxWidth column>
        <TableWrapper
          classname={classname}
          data={this.getPlaylists()}
          count={this.props.count}
          clearData={()=>this.clearVenues()}
          getData={(limit,offset)=>this.getVenues(limit,offset)}
          loading={this.props.loading}
          sortTable={(head)=>this.sortTable(head)}
          rowActions={<PlaylistTableRowActions
            classname={classname}
            removePlaylist={(playlist)=>this.removePlaylist(playlist)}
            />
          }
          tableAction={<TableTypeahead
            classname={classname}
            typeaheadSearch={(value)=>this.searchPlaylists(value)}
            typeaheadAction={(id)=>this.addPlaylist(id)}
            typeaheadLoading={this.props.typeaheadLoading}
            data={this.getTypeaheadData()}
            clear={()=>this.props.dispatch(clearTypeahead())}
          />
          }
        />
      </Container>
    )
  }
}

function mapStateToProps(store){
  return {
    playlists:store.venue.playlists,
    typeahead:store.playlists.typeahead,
    typeaheadLoading:store.playlists.typeaheadLoading,
    loading:store.venue.loading
  }
}

export default connect(mapStateToProps)(VenuePlaylists)
