import React from 'react'
import {Link} from 'react-router-dom'

import Icon from 'ui/Icon'
import Loader from 'ui/Loader'
import Tooltip from 'ui/Tooltip'
import TableModel from 'models/TableModel'

const PlaylistsTableRowActions = (props) => {
  const {
    classname,
    playlist,
    addPlaylistToVenues,
    deleteMyPlaylist,
    playlistDeleting,
    deletingPlaylistId,
    copyPlaylist
  } = props

  return (
    <div>
      <Link to={`${TableModel[classname].viewLink}/${playlist.id}?tab=tracks`}>
        <Icon name="eye">
          <Tooltip pos="left" text="View playlist."/>
        </Icon>
      </Link>
      <Icon name="copy" action={() => copyPlaylist(playlist)}>
        <Tooltip pos="left" text="Copy playlist."/>
      </Icon>
      <Icon
        name='add-circle-outline'
        action={()=>addPlaylistToVenues(playlist)}
        stopPropagation
      >
        <Tooltip pos="left" text='Add/remove playlist to/from venues' />
      </Icon>
      {playlistDeleting && deletingPlaylistId === playlist.id ? (
        <Loader />
      ) : (
        <Icon
          name='trash'
          action={()=>deleteMyPlaylist(playlist)}
          stopPropagation
        >
          <Tooltip pos="left" text='Delete playlist' />
        </Icon>
      )}
    </div>
  )
}

export default PlaylistsTableRowActions
