import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import queryString from 'qs'

import {
  changePassword,
  checkResetToken,
  resetPwInterface
} from 'store/actions/authAction'

import Logo from 'ui/Logo'
import Loader from 'ui/Loader'
import Form from 'ui/Form'
import Container from 'ui/Container'
import ResetPassword from './auth/ResetPassword'
import PasswordReset from "./auth/PasswordReset"

const classname = 'auth'

class Auth extends Component {

  constructor(props){
    super(props)
    this.state = {
      username:'',
      password:'',
      resetPassword:false,
      passwordReset:false,
      resetPassword:false,
      token:null,
      email:null
    }
    this.timer = null
  }

  componentDidMount(){
    const query = queryString.parse(this.props.location.search.slice(1))

    if(query.reset && query.email){
      this.timer = setTimeout(()=>{
        this.props.dispatch(checkResetToken(
          query.reset,
          query.email
        ))
        clearTimeout(this.timer)
        this.timer = null
        this.setState({
          ...this.state,
          token:query.reset,
          email:query.email
        })
      },500)
    }
  }

  handleChange(e) {
    const name = e.target.name
    this.setState({[name]: e.target.value});
  }

  onSubmit(res){
    this.props.login(res)
  }

  resetPassword(email){
    this.props.resetPassword(email)
  }

  changePassword(pw){
    const data = {
      token:this.state.token,
      email:this.state.email,
      password:pw
    }
    this.props.dispatch(changePassword(data))
  }

  resetPwInterface(){
    this.props.dispatch(resetPwInterface())
    this.props.history.push('/')
  }

  render(){
    const {
      pwChangeIsValid,
      pwChangeLoading,
      pwChangeMsg
    } = this.props
    if(this.props.loading){
      return <Loader/>
    } else if(this.props.showPwReset){
      return <Container classname={classname}>
        <PasswordReset
          resetInterface={()=>this.resetPwInterface()}
          loading={pwChangeLoading}
          isPwValid={pwChangeIsValid}
          message={pwChangeMsg}
          changePassword={(pw)=>this.changePassword(pw)}/>
      </Container>
    } else if(this.state.resetPassword){
      return (
        <Container classname={classname}>
          <ResetPassword
            message={this.props.pwMessage}
            loading={this.props.pwLoading}
            resetPassword={(email)=>this.resetPassword(email)}/>
        </Container>
      )
    } else {
      return (
        <Container classname={classname}>
          <Logo/>
          <Form
            classname={classname}
            submit={(res)=>this.onSubmit(res)}
            message={this.props.message}
            change={(e)=>this.handleChange(e)}
            altInput>
          </Form>
          <div 
            onClick={()=>this.setState({
              resetPassword:true
            })}
            className="forgot-password">Forgot password? Click here.</div>
        </Container>
      )
    }
  }
}

function mapStateToProps(store){
  return{
    pwMessage:store.auth.resetPwMsg,
    pwLoading:store.auth.resetPwLoading,
    pwTokenIsValid:store.auth.pwTokenIsValid,
    showPwReset:store.auth.showPwReset,
    pwResetLoading:store.auth.pwResetLoading,
    pwChangeLoading:store.auth.pwChangeLoading,
    pwChangeIsValid:store.auth.pwChangeIsValid,
    pwChangeMsg:store.auth.pwChangeMsg
  }
}

export default withRouter(connect(mapStateToProps)(Auth))
