import React from 'react'

import Energy from 'ui/Energy'
import Icon from 'ui/Icon'
import Tooltip from 'ui/Tooltip'

const QueueItem = (props) => {
  const {
    track,
    playTrack,
    playingTrack,
    removeFromQueue
  } = props

  const isSelected = playingTrack && (playingTrack.id === track.id)
  const isPlaying = isSelected && playingTrack.isPlaying

  return (
    <li
      className={`queue-overlay-item ${isSelected ? ' queue-overlay-item--playing' : ''}`}
      onDoubleClick={()=>playTrack(track)}
    >
      <Icon
        classname='queue-overlay-item__play'
        name={`ios-${isPlaying ? 'volume-high' : 'play'}`}
        action={()=>playTrack(track)}
      />
      <div className='queue-overlay-item__track'>
        <div className='queue-overlay-item__track-name'>{track.title}</div>
        <div className='queue-overlay-item__artist'>{track.artist}</div>
      </div>

      <div className='queue-overlay-item__meta'>
        <span className='queue-overlay-item__energy'>
          {track.energy && (
            <Energy energy={track.energy} />
          )}
        </span>
        <span className="queue-overlay-item__duration">{track.total_length}</span>
        <Icon
          name="close"
          action={()=>removeFromQueue(track)}
        >
          <Tooltip
            text="Remove from queue"
            pos="left"
          />
        </Icon>
      </div>
    </li>
  )
}

export default QueueItem
