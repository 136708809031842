import React from 'react'

import Icon from 'ui/Icon'
import Tooltip from 'ui/Tooltip'

const QueueItem = (props) => {
  return (
    <div className="queue-item" onDoubleClick={()=>props.playTrack(props.data)}>
      <span className="play">
        <Icon name={`ios-${props.isPlaying ? 'volume-high' : 'play'}`} action={()=>props.playTrack(props.data.id)}/>
      </span>
      <span className="title">{props.data.title}</span>
      <span className="artist">{props.data.artist}</span>
      <span className="album">{props.data.album}</span>
      <span className="length">{props.data.total_length}</span>
      <span className="actions">
        <Icon name="close">
          <Tooltip text="Remove from queue" pos="left"/>
        </Icon>
      </span>
    </div>
  )
}

export default QueueItem