import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Icon from 'ui/Icon'
import Loader from 'ui/Loader'
import { debounce } from 'helpers/Debounce'

import TypeaheadModel from 'models/TypeaheadModel'

class LibraryTypeahead extends Component{
  constructor(props){
    super(props)
    //https://blog.revathskumar.com/2016/02/reactjs-using-debounce-in-react-components.html
    this.sendResponse = debounce(this.sendResponse,TypeaheadModel[this.props.classname].debounce)
    this.typeaheadInput = React.createRef()
    this.state = {
      typeaheadInput:'',
      term:'',
      artists:{
        data:[],
        display:false
      },
      albums:{
        data:[],
        display:false
      },
      titles:{
        data:[],
        display:false
      }

    }
  }

  componentDidMount(){
    this.typeaheadInput.current.focus()
  }

  componentDidUpdate(prevProps){
    if(prevProps.data !== this.props.data){
      this.displayResults()
    }
  }

  handleOnChange(e){
    const { value } = e.target
    this.setState({term:value},()=>{
      this.sendResponse(value)
    })
  }

  handleKeyDown(e){
    const { cursor } = this.state
    // arrow up/down button should select next/previous list element
    // up arrow
    if (e.key === 'ArrowUp' && cursor > 0) {
      this.setState( prevState => ({
        cursor: prevState.cursor - 1
      }))
    //down arrow
    } else if (e.key === 'ArrowDown' && cursor < this.props.data.length - 1) {
      this.setState( prevState => ({
        cursor: prevState.cursor + 1
      }))
    //on enter
    } else if(e.key === 'Enter' && this.typeaheadInput.current.value.length > 0){
      this.props.searchAction(this.state.term)
      this.clearTypeahead()
    }
    //on select

  }

  clearTypeahead(){
    this.setState({
      artists:{
        data:[],
        display:false
      },
      albums:{
        data:[],
        display:false
      },
      titles:{
        data:[],
        display:false
      }
    },()=>{
      this.typeaheadInput.current.value = ''
      this.props.closeTypeahead()
      //this.props.clear()
    })
  }

  sendResponse(value){
    if(this.typeaheadInput.current.value.length > 0){
      this.props.typeaheadAction(value)
    } else {
      this.clearTypeahead()
    }
  }

  displayResults(){
    if(this.props.data && this.props.data.length > 0){
      this.setState({
        artists:{
          data:this.props.data[0].artists,
          display:this.props.data[0].artists.length > 0 ? true : false
        },
        albums:{
          data:this.props.data[0].albums,
          display:this.props.data[0].albums.length > 0 ? true : false
        },
        titles:{
          data:this.props.data[0].titles,
          display:this.props.data[0].titles.length > 0 ? true : false
        }
      })
    }
  }

  clickTypeahead(type,term){
    this.clearTypeahead()
    this.props.clickTypeahead(type,term)
  }

  renderTypeaheads(){
    let display = []
    if(this.state.artists.display){
      display.push(<h2 key="artist">Artists</h2>)
      const data = this.state.artists.data
        .map((artist,index)=>
          <div
            key={index}
            className="typeahead-result"
            onClick={()=>this.clickTypeahead('artist',artist.artist)}>
              {artist.artist}
          </div>
        )
      display.push(data)
    }
    if(this.state.albums.display){
      display.push(<h2 key="albums">Albums</h2>)
      const data = this.state.albums.data
        .map((album,index)=>
          <div
            key={index}
            className="typeahead-result"
            onClick={()=>this.clickTypeahead('album',album.album)}>
              {album.album}
          </div>
        )
      display.push(data)
    }
    if(this.state.titles.display){
      display.push(<h2 key="titles">Tracks</h2>)
      const data = this.state.titles.data
        .map((title,index)=>
          <div
            key={index}
            className="typeahead-result"
            onClick={()=>this.clickTypeahead('title',title.title)}>
              {title.title}
            </div>
          )
      display.push(data)
    }

    return display
  }

  render(){
    const {
      classname,
      disableTypeahead,
      typeaheadLoading
    } = this.props

    let showTypeahead = false

    if(!disableTypeahead && (this.state.artists.display || this.state.albums.display || this.state.titles.display)) {
      showTypeahead = true
    }

    return (
      <div className={`typeahead-wrapper typeahead-wrapper-${classname}`}>
        {typeaheadLoading ? (
          <Loader />
        ) : (
          <Icon name="ios-search" classname="typeahead"/>
        )}
        <input
          ref={this.typeaheadInput}
          className={`typeahead-input typeahead-input-${classname}`}
          onChange={(e)=>this.handleOnChange(e)}
          onKeyDown={(e)=>this.handleKeyDown(e)}
          placeholder={TypeaheadModel[classname].placeholder}
        />
        {(showTypeahead || ((this.typeaheadInput.current && this.typeaheadInput.current.value.length) > 0)) && (
          <Icon
            name="ios-close"
            action={()=>this.clearTypeahead()}
            classname='typeahead-clear'
          />
        )}
        {showTypeahead && (
          <div className={`typeahead-results typeahead-results-${classname}`}>
            {this.renderTypeaheads()}
          </div>
        )}
      </div>
    )
  }
}

export default withRouter(LibraryTypeahead)
