import React, {Component} from 'react'
import { connect } from 'react-redux'

import {
  getPlaylistsOverlay,
  addTrackToPlaylist,
  removeTrackFromPlaylistLibrary
} from 'store/actions/playlistAction'

import OverlayPlaylistAddTrackTableRowActions from './playlists-tracks/OverlayPlaylistsAddTrackTableRowActions'

import Container from 'ui/Container'
import Loader from 'ui/Loader'
import TableWrapper from 'ui/TableWrapper'

const classname = 'overlayPlaylistsAddTrack'

class OverlayPlaylistsAddTrack extends Component{
  componentDidMount(){
    //send track id so we can set assigned playlists
    this.props.dispatch(getPlaylistsOverlay(this.props.data.track.id))
  }

  toggleAddTrackToPlaylist(playlist){
    const {
      dispatch,
      data
    } = this.props

    playlist.assigned = !playlist.assigned

    if(playlist.assigned){
      dispatch(addTrackToPlaylist(data.track, playlist))
    } else {
      dispatch(removeTrackFromPlaylistLibrary(data.track, playlist))
    }
  }

  render(){
    const {
      addPlaylistTrackLoadingIDs,
      playlists,
      loading
    } = this.props

    return (
      <Container classname={classname} maxWidth height="100%" column>
        <h1>Add track {this.props.data.track.title} by {this.props.data.track.artist} to...</h1>
        <div className="playlists-overlay-wrapper">
          {loading ? (
            <Loader />
          ) : (
            <TableWrapper
              classname={classname}
              data={playlists}
              loading={loading}
              rowActions={(
                <OverlayPlaylistAddTrackTableRowActions
                  toggleAddTrackToPlaylist={(playlist)=>this.toggleAddTrackToPlaylist(playlist)}
                  addPlaylistTrackLoadingIDs={addPlaylistTrackLoadingIDs}
                />
              )}
            />
          )}
        </div>
      </Container>
    )
  }
}
function mapStateToProps(store){
  return {
    overlay:store.overlay,
    playlists:store.playlists.data,
    addPlaylistTrackLoadingIDs: store.playlists.addPlaylistTrackLoadingIDs,
    loading:store.playlists.loading
  }
}
export default connect(mapStateToProps)(OverlayPlaylistsAddTrack)
