import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { getBusinesses, searchBusinesses, clearBusinesses } from 'store/actions/businessAction'
import { getRowLimit } from 'helpers/TableHelper'

import { toggleOverlay } from 'store/actions/overlayAction'

import Tabs from 'ui/Tabs'
import Container from 'ui/Container'
import TableWrapper from 'ui/TableWrapper'
import TableTypeahead from 'ui/TableTypeahead'
import VenuesTableActions from './venues/VenuesTableActions'

const classname = 'businesses'

class BusinessesContainer extends Component {

  constructor(props){
    super(props)
    this.state = {
      tab:'all'
    }
  }

  componentDidMount(){
    this.props.dispatch(getBusinesses(getRowLimit(classname),0))
  }

  selectTab(tab){
    this.setState({tab:tab},()=>{
      this.props.history.push({
        search: `?tab=${tab}`
      })
    })
  }

  clearBusinesses(){
    this.props.dispatch(clearBusinesses())
  }

  getBusinesses(limit,offset){
    this.props.dispatch(getBusinesses(limit,offset))
  }

  moreActions(data){
    this.props.dispatch(toggleOverlay(true,data,'venues'))
  }

  searchBusinesses(value){
    this.props.dispatch(searchBusinesses(value))
  }

  getComponent(){
    switch(this.state.tab){
      case 'all':
      return <TableWrapper
        controls
        classname={classname}
        data={this.props.businesses}
        count={this.props.count}
        clearData={()=>this.clearBusinesses()}
        getData={(limit,offset)=>this.getBusinesses(limit,offset)}
        loading={this.props.loading}
        rowActions={<VenuesTableActions
          more={(data)=>this.moreActions(data)}
          classname={classname}/>
        }
        tableAction={<TableTypeahead
          classname={classname}
          action={(value)=>this.searchBusinesses(value)}
          clear={()=>this.clearBusinesses()}
          data={this.props.typeahead}/>
        }
      />
      default: //no default case
    }
  }

  render(){
    return (
      <Container classname={`${classname}-wrapper`} height="100%" column maxWidth>
        <Container classname="tabs">
          <Tabs
            match={this.props.match}
            classname={classname}
            select={(tab)=>this.selectTab(tab)}
            active={this.state.tab}/>
        </Container>
        <Container height="100%" classname={classname} maxWidth column>
          {this.getComponent()}
        </Container>
      </Container>
    )
  }
}

function mapStateToProps(store){
  return {
    businesses:store.businesses.data,
    typeahead:store.businesses.typeahead,
    count:store.businesses.count,
    loading:store.businesses.loading
  }
}

export default withRouter(connect(mapStateToProps)(BusinessesContainer))
