import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import PlaylistItem from './playlists-main/PlaylistItem'

import Confirm from 'ui/Confirm'
import Container from 'ui/Container'
import Loader from 'ui/Loader'
import PlaylistsMainTypeahead from './playlists-main/PlaylistsMainTypeahead'

import {
  getPlaylist,
  filterPlaylistsTypeahead,
  subscribePlaylist,
  unsubscribePlaylist
} from 'store/actions/playlistAction'

import {
  getTrackInfo,
  getTrackUrl
} from 'store/actions/playerAction'

import { toggleOverlay } from 'store/actions/overlayAction'
import { toggleConfirm } from 'store/actions/confirmAction'

const classname = 'playlistsMain'

class PlaylistsMain extends Component{
  constructor(props){
    super(props)

    this.state = {
      currentlyPlaying: null,
      subscribingPlaylistId: null,
      playlistsOutput: []
    }
  }

  componentDidUpdate(prevProps) {
    const {
      dispatch,
      playlists,
      tracks
    } = this.props

    if ((tracks.length > 0) && (tracks !== prevProps.tracks)) {
      //get random track every time playlist launched
      const index = Math.floor(Math.random() * tracks.length)
      dispatch(getTrackInfo(tracks[index], 'playlist'))
      dispatch(getTrackUrl(tracks[index], 'playlist'))
    }

    if (prevProps.playlists !== playlists) {
      this.setState({
        playlistsOutput: playlists
      })
    }
  }

  viewPlaylist(playlist){
    const {
      history
    } = this.props

    history.push(`/playlist/${playlist.id}?tab=details`)
  }

  subscribe(playlist){
    const {
      dispatch
    } = this.props

    this.setState({
      subscribingPlaylistId: playlist.id
    })
    dispatch(subscribePlaylist(playlist.id))
    dispatch(toggleOverlay(true, playlist ,'subscribedPlaylistToVenues'))
  }

  unsubscribe(playlist){
    const {
      dispatch
    } = this.props

    const confirmData = {
      action: () => {
        this.setState({
          subscribingPlaylistId: playlist.id
        })
        dispatch(unsubscribePlaylist(playlist, true))
      },
      question: 'Unsubscribing from a playlist will remove it from your schedules for all your venues. Are you sure?'
    }

    dispatch(toggleConfirm(true, confirmData))
  }

  playPlaylist(id){
    const {
      dispatch
    } = this.props

    this.setState({
      currentlyPlaying: id
    })

    dispatch(getPlaylist(id))
  }

  //Due to the way that playlists are fetched, it is possible for the data from multiple
  //tabs to be loaded simultaneously eg click on the open ear tab and then switch
  //to subscribed before the data has finished loading - both open ear and subscribed
  //playlists will be loaded into the subscribed view. Should probably find a cleaner way to
  //handle this, but this filtering function should do the trick for now.
  processPlaylists() {
    const {
      playlists
    } = this.props

    const {
      tab
    } = this.state

    let filteredPlaylists = []

    playlists.forEach((playlist) => {
      if (playlist.type === tab) {
        filteredPlaylists.push(playlist)
      }
    })

    return filteredPlaylists
  }

  filterPlaylists(value) {
    const {
      dispatch,
      playlists
    } = this.props

    const searchTerm = value.toLowerCase()

    // Filter the playlists by name first
    const resultsByName = playlists.filter((playlist) => {
      return (
        playlist.filterName.toLowerCase().includes(searchTerm)
      )
    })

    // Then filter the remaining results by the filter value
    const resultsByFilter = playlists
      .filter((playlist) => {
        return (
          !playlist.filterName.toLowerCase().includes(searchTerm)
        )
      })
      .filter((playlist) => {
        return (
          playlist.filter.toLowerCase().includes(searchTerm)
        )
      })

    // Combine both arrays
    const results = resultsByName.concat(resultsByFilter)

    let highlighted
    if(searchTerm.length > 0){
      const regex = new RegExp(`(${searchTerm})`, 'gi')
      highlighted = results.map(playlist => {
        playlist.style = playlist.filterStyle
        playlist.name = playlist.filterName
        playlist.tags = playlist.filterTags
        playlist.genres = playlist.filterGenres
        //highlight name
        if(playlist.filterName.toLowerCase().includes(searchTerm)){
          const parts = playlist.filterName.split(regex)
          playlist.name = parts.filter(part => part).map((part, i) => (
              regex.test(part) ? <mark key={i}>{part}</mark> : <span key={i}>{part}</span>
          ))
        }
        if(playlist.style && playlist.filterStyle.toString().toLowerCase().includes(searchTerm)){
          //highlight style
          const parts = playlist.filterStyle.split(regex)
          playlist.style = parts.filter(part => part).map((part, i) => (
              regex.test(part) ? <mark key={i}>{part}</mark> : <span key={i}>{part}</span>
          ))
        }
        if(playlist.filterTags.toString().toLowerCase().includes(searchTerm)){
          //highlight style
          const parts = playlist.filterTags.split(regex)
          playlist.tags = parts.filter(part => part).map((part, i) => (
              regex.test(part) ? <mark key={i}>{part}</mark> : <span key={i}>{part}</span>
          ))
        }
        if(playlist.filterGenres.toString().toLowerCase().includes(searchTerm)){
          //highlight style
          const parts = playlist.filterGenres.split(regex)
          playlist.genres = parts.filter(part => part).map((part, i) => (
              regex.test(part) ? <mark key={i}>{part}</mark> : <span key={i}>{part}</span>
          ))
        }
        return playlist
      })
    } else {
      //reset highlighted data
      highlighted = results.map(playlist => {
        playlist.style = playlist.filterStyle
        playlist.name = playlist.filterName
        playlist.tags = playlist.filterTags
        playlist.genres = playlist.filterGenres
        return playlist
      })
    }

    this.setState({
      playlistsOutput: searchTerm.length > 0 ? highlighted : playlists
    })

    dispatch(filterPlaylistsTypeahead(value))
  }

  copyPlaylist(playlist){
    this.props.dispatch(toggleOverlay(true, playlist ,'copyPlaylist'))
  }

  getList(){
    const {
      businessID,
      searchTerm,
      subscriptionLoading,
      userGroup
    } = this.props

    const {
      subscribingPlaylistId,
      currentlyPlaying,
      playlistsOutput
    } = this.state

    const listArray = (searchTerm && searchTerm.length > 0) ? playlistsOutput : this.processPlaylists()

    if (searchTerm && listArray.length === 0) {
      return <p>No results</p>
    }

    return listArray.map((playlist,index)=>{
      const isSubscribed = playlist.businesses ? playlist.businesses.find((business) => {
        return business.id === businessID
      }) : false
      return <PlaylistItem
        key={index}
        playlist={playlist}
        userGroup={userGroup}
        playPlaylist={(id) => this.playPlaylist(id)}
        viewPlaylist={(playlist) => this.viewPlaylist(playlist)}
        subscribe={(playlist)=>{this.subscribe(playlist); }}
        unsubscribe={(playlist)=>{this.unsubscribe(playlist); }}
        currentlyPlaying={currentlyPlaying}
        subscribingPlaylistId={subscribingPlaylistId}
        isSubscribed={isSubscribed}
        subscriptionLoading={subscriptionLoading}
        copyPlaylist={(playlist) => this.copyPlaylist(playlist)}
      />
    })
  }

  render(){
    const {
      loading
    } = this.props

    if(loading) {
      return <Loader/>
    } else {
      return (
        <Container height="100%" classname={classname} maxHeight maxWidth column>
          <PlaylistsMainTypeahead
            classname={classname}
            typeaheadAction={(value) => {this.filterPlaylists(value)}}
          />
          {this.getList()}
          <Confirm />
        </Container>
      )
    }
  }
}

function mapStateToProps(store,myProps){
  return {
    playlists:store.playlists.data,
    searchTerm:store.playlists.searchTerm,
    tracks:store.playlist.tracks,
    businessID:store.playlists.businessID,
    count:store.playlists.count,
    loading:store.playlists.loading,
    subscriptionLoading:store.playlists.subscriptionLoading,
    tab:myProps.location.search,
    userGroup:store.auth.userGroup
  }
}

export default withRouter(connect(mapStateToProps)(PlaylistsMain))
