import React, { Fragment } from 'react'

import Icon from 'ui/Icon'
import Loader from 'ui/Loader'

const ImporterTableRowActions = (props) => {
  const {
    id,
    artist,
    title,
    total_length,
    importerFinalTracklist,
    importerMatchPerformed,
    importerMatchesLoaded,
    tracks,
    toggleImportedTracksOverlay
  } = props

  const hasLoaded = importerMatchesLoaded.includes(id)

  let hasFinalTrack = false

  tracks.map((track) => {
    if (importerFinalTracklist.includes(track)) {
      hasFinalTrack = true
    }
    return false
  })

  return (
    <div
      onClick={()=>toggleImportedTracksOverlay({id, artist, total_length, title, tracks })}
      style={{cursor: 'pointer'}}
    >
      {!importerMatchPerformed ? (
        <span>&nbsp;</span>
      ) : (
        <Fragment>
          {!hasLoaded ? (
            <Loader />
          ) : (
            <Fragment>
              {hasLoaded && (tracks.length === 0) ? (
                <Icon name='sad' />
              ) : (
                <Fragment>
                  {hasFinalTrack ? (
                    <Icon
                      name='checkmark-circle'
                      success
                    />
                  ) : (
                    <Icon
                      name='help-circle'
                    />
                  )}
                </Fragment>
              )}
            </Fragment>
          )}
        </Fragment>
      )}
    </div>
  )
}

export default ImporterTableRowActions
