const initialState = {
  bubbles:[],
  bubblesLoading: false,
  status: null,
  loading:false,
  setupComplete: false,
  error:null
}

export default function reducer(state = initialState, action){
  switch (action.type){
    case 'GET_STATUS_PENDING':{
      state = {
        ...state,
        loading: true
      }
      break
    }
    case 'GET_STATUS_REJECTED':{
      state = {
        ...state,
        loading:false,
        error:action.payload
      }
      break
    }
    case 'GET_STATUS_FULFILLED':{
      state = {
        ...state,
        status: action.payload.data.status,
        loading: false
      }
      break
    }
    case 'GET_BUBBLES_PENDING':{
      state = {
        ...state,
        bubblesLoading: true
      }
      break
    }
    case 'GET_BUBBLES_REJECTED':{
      state = {
        ...state,
        bubbles: [],
        bubblesLoading:false,
        error:action.payload
      }
      break
    }
    case 'GET_BUBBLES_FULFILLED':{
      state = {
        ...state,
        bubbles: action.payload ? action.payload.data : [],
        bubblesLoading: false
      }
      break
    }
    case 'SET_BUBBLES_PENDING':{
      state = {
        ...state,
        bubblesLoading: true
      }
      break
    }
    case 'SET_BUBBLES_REJECTED':{
      state = {
        ...state,
        bubblesLoading:false,
        error:action.payload
      }
      break
    }
    case 'SET_BUBBLES_FULFILLED':{
      state = {
        ...state,
        setupComplete: true,
        bubblesLoading: false
      }
      break
    }
    default: //no default case
  }
  return state
}
