import React, { Component, Fragment } from 'react'

import Button from '../ui/Button'

class FormChecklist extends Component {
  constructor(props){
    super(props)
    this.state = {}

    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount(prevProps) {
    const {
      checklistAction,
      data
    } = this.props

    //check if checklist items have a checked value and update accordingly
    if (data && data.length > 0) {
      const newValues = {}

      data.forEach((venue) => {
        newValues[venue.id] = venue.checked
      })

      checklistAction(newValues)
    }
  }

  componentDidUpdate(prevProps) {
    const {
      checklistAction,
      data,
      values
    } = this.props

    if ((prevProps.data !== data) && data.length > 0) {
      const newValues = {}

      data.forEach((venue) => {
        newValues[venue.id] = values[venue.id] || false
      })

      checklistAction(newValues)
    }
  }

  handleChange(e) {
    const {
      checklistAction,
      values
    } = this.props

    const newValues = {...values}

    newValues[e.target.id] = !values[e.target.id]
    checklistAction(newValues)
  }

  changeAll(itemValue) {
    const {
      checklistAction,
      values
    } = this.props

    const newValues = {...values}

    Object.keys(newValues).forEach((item, index) => {
      newValues[item] = itemValue
    })

    checklistAction(newValues)
  }

  selectAll(e) {
    e.preventDefault()
    this.changeAll(true)
  }

  deselectAll(e) {
    e.preventDefault()
    this.changeAll(false)
  }

  render() {
    const {
      checklistErrorMessage,
      data,
      values
    } = this.props

    if (data && data.length > 0) {
      return (
        <div className='form-checklist-wrapper'>
          {checklistErrorMessage && (
            <p className='form-checklist__error-message'>
              {checklistErrorMessage}
            </p>
          )}
          <div className='form-checklist__controls'>
            <Button
              name='Select all'
              action={(e)=>this.selectAll(e)}
            />
            <Button
              name='Deselect all'
              action={(e)=>this.deselectAll(e)}
            />
          </div>
            <ul className='form-checklist'>
              {data.map((venue, index) => {
                // double bang used on the checked property to force a false value
                // otherwise React will assume the move from null to checked is an uncontrolled to controlled component error
                // see https://stackoverflow.com/questions/39120007/setting-a-checkbox-check-property-in-react
                return (
                  <li key={index}>
                    <label htmlFor={venue.id}>
                      <input
                        type='checkbox'
                        id={venue.id}
                        name={venue.name}
                        onChange={this.handleChange}
                        checked={!!values[venue.id]}
                      />
                      {venue.name}
                    </label>
                  </li>
                )
              })}
            </ul>
        </div>
      )
    } else if (checklistErrorMessage) {
      return (
        <div className='form-checklist-wrapper'>
          <p className='form-checklist__error'>
            {checklistErrorMessage}
          </p>
        </div>
      )
    }

    return null
  }
}

export default FormChecklist
