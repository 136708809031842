import React, { Component } from 'react'
//https://ionicons.com/

class Icon extends Component {

  constructor(props){
    super(props)
    this.state = {
      hover:false
    }
  }
  hoverOn(){
    this.setState({hover:true})
  }
  hoverOff(){
    this.setState({hover:false})
  }
  render(){
    const {
      size,
      classname,
      name,
      stopPropagation,
      disabled,
      active,
      type,
      success
    } = this.props

    if(this.props.action){
      //clone child tooltip and add mouseevent from icon
      return (
        <span
          className={`
            icon-wrapper 
            ${disabled ? 'icon-disabled' : ''}
            ${active ? 'icon-active' : ''}
          `}
          onMouseEnter={()=>this.hoverOn()}
          onMouseLeave={()=>this.hoverOff()}
          onClick={stopPropagation ? (e)=>{this.props.action(); e.stopPropagation()} : ()=>this.props.action()}>
          <ion-icon
            name={name}
            class={`icon icon-${size} icon-action icon-${classname} icon-${name} icon-${type} hydrated ${success ? 'icon-success' : ''}`}>
          </ion-icon>
          {(()=>{
            if(this.props.children && this.state.hover){
              return this.props.children
            }
          })()}
        </span>
      )
    } else {
      return (
        <span
          className={`
            icon-wrapper 
            ${disabled ? 'icon-disabled' : ''}
            ${active ? 'icon-active' : ''}
          `}
          onMouseEnter={()=>this.hoverOn()}
          onMouseLeave={()=>this.hoverOff()}>
          <ion-icon
            name={name}
            class={`icon icon-${size ? size : 'normal'} icon-${classname} icon-${name} icon-${type} hydrated ${success ? 'icon-success' : ''}`}>
          </ion-icon>
          {(()=>{
            if(this.props.children && this.state.hover){
              return this.props.children
            }
          })()}
        </span>
      )
    }
  }
}

export default Icon
