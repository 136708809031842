const initialState = {
  data:[],
  dashboard:{
    picks:[],
    featured:[],
    topical:[]
  },
  typeahead:[],
  searchTerm:'',
  count:null,
  loading:false,
  loaded:false,
  addPlaylistTrackLoadingIDs: [],
  subscriptionLoading:false,
  playlistDeleting:false,
  error:null,
  createdPlaylistID:null
}
export default function reducer(state = initialState, action){
  switch (action.type){
    //playlists
    case 'CLEAR_PLAYLISTS':{
      state = {...state,data:[]}
      break
    }
    case 'FETCH_PLAYLISTS_PENDING':{
      state = {...state,loading:true,data:[]}
      break
    }
    case 'FETCH_PLAYLISTS_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_PLAYLISTS_FULFILLED':{
      let playlists
      if(action.meta === 'main'){
        playlists = action.payload.data.playlists.map((playlist)=>{
          const tags = playlist.tags.map(tag => tag.name).join(' ')
          const genres = playlist.genres.map(genre => genre.genre).join(' ')
          const data = {
            id:playlist.id,
            name:playlist.name,
            style:playlist.style ? playlist.style : '',
            tracksCount:playlist.tracks_count,
            genres:genres,
            tags:tags,
            businesses: playlist.businesses,
            image:playlist.playlist_image,
            createdAt:playlist.created_at,
            filter:`${tags} ${genres} ${playlist.name} ${playlist.style}`,
            filterName:`${playlist.name}`,
            filterStyle:`${playlist.style}`,
            filterTags:`${tags}`,
            filterGenres:`${genres}`
          }
          return data
        })
      } else {
        playlists = action.payload.data.playlists.map((playlist)=>{
          const data = {
            id:playlist.id,
            name:playlist.name,
            pinned_id:playlist.pinned ? playlist.pinned[0].pivot.id : null,
            venuesCount:playlist.venues.length,
            tracksCount:playlist.tracks_count,
            createdAt:playlist.created_at,
            type: action.meta
          }
          return data
        })
      }
      state =  {
        ...state,
        loading:false,
        loaded:true,
        businessID: action.payload.data.businessID,
        data:state.data.concat(playlists),
        count:action.payload.data.count
      }
      break
    }
    case 'FETCH_PLAYLISTS_OVERLAY_PENDING':{
      state = {...state,loading:true,data:[]}
      break
    }
    case 'FETCH_PLAYLISTS_OVERLAY_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_PLAYLISTS_OVERLAY_FULFILLED':{
      const playlists = action.payload.data.playlists.map((playlist)=>{
        const data = {
          id:playlist.id,
          name:playlist.name,
          tracksCount:playlist.tracks_count,
          venuesCount:playlist.venues_count,
          assigned:playlist.assigned,
          tracks: playlist.tracks
        }
        return data
      })
      state =  {
        ...state,
        loading:false,
        loaded:true,
        data:state.data.concat(playlists),
        count:action.payload.data.count
      }
      break
    }
    case 'ADD_PLAYLIST_TRACK_PENDING':{
      state =  {
        ...state,
        addPlaylistTrackLoadingIDs: state.addPlaylistTrackLoadingIDs.concat(action.meta.id)
      }
      break
    }
    case 'ADD_PLAYLIST_TRACK_REJECTED':{
      state = {
        ...state,
        addPlaylistTrackLoadingIDs: state.addPlaylistTrackLoadingIDs.filter(id=>id !== action.meta.id),
        error:action.payload
      }
      break
    }
    case 'ADD_PLAYLIST_TRACK_FULFILLED':{
      const playlists = state.data.map((playlist)=>{
        const data = {
          id:playlist.id,
          name:playlist.name,
          tracksCount: action.meta.id === playlist.id ? playlist.tracksCount + 1 : playlist.tracksCount,
          venuesCount: playlist.venuesCount,
          assigned:action.meta.id === playlist.id ? action.meta.assigned : playlist.assigned
        }
        return data
      })
      state =  {
        ...state,
        addPlaylistTrackLoadingIDs: state.addPlaylistTrackLoadingIDs.filter(id=>id !== action.meta.id),
        loading:false,
        loaded:true,
        data:playlists
      }
      break
    }
    case 'DELETE_PLAYLIST_TRACK_LIBRARY_PENDING':{
      state =  {
        ...state,
        addPlaylistTrackLoadingIDs: state.addPlaylistTrackLoadingIDs.concat(action.meta.id)
      }
      break
    }
    case 'DELETE_PLAYLIST_TRACK_LIBRARY_REJECTED':{
      state = {
        ...state,
        addPlaylistTrackLoadingIDs: state.addPlaylistTrackLoadingIDs.filter(id=>id !== action.meta.id),
        error:action.payload
      }
      break
    }
    case 'DELETE_PLAYLIST_TRACK_LIBRARY_FULFILLED':{
      const playlists = state.data.map((playlist)=>{
        const data = {
          id:playlist.id,
          name:playlist.name,
          tracksCount:action.meta.id === playlist.id ? playlist.tracksCount - 1 : playlist.tracksCount,
          venuesCount: playlist.venuesCount,
          assigned:action.meta.id === playlist.id ? action.meta.assigned : playlist.assigned
        }
        return data
      })
      state =  {
        ...state,
        addPlaylistTrackLoadingIDs: state.addPlaylistTrackLoadingIDs.filter(id=>id !== action.meta.id),
        loading:false,
        loaded:true,
        data:playlists
      }
      break
    }
    case 'FETCH_PLAYLISTS_DASHBOARD_PENDING':{
      state = {...state,loading:true}
      break
    }
    case 'FETCH_PLAYLISTS_DASHBOARD_REJECTED':{
      state = {...state,loading:false,error:action.payload}
      break
    }
    case 'FETCH_PLAYLISTS_DASHBOARD_FULFILLED':{
      const picks  = action.payload.data.picks.map((playlist)=>{
        const data = {
          id:playlist.id,
          name:playlist.name,
          playlistImage: playlist.playlist_image
        }
        return data
      })
      const featured = action.payload.data.featured.map((playlist)=>{
        const data = {
          id:playlist.id,
          name:playlist.name,
          playlistImage: playlist.playlist_image
        }
        return data
      })
      const topical = action.payload.data.topical.map((playlist)=>{
        const data = {
          id:playlist.id,
          name:playlist.name,
          playlistImage: playlist.playlist_image
        }
        return data
      })
      state =  {
        ...state,
        loading:false,
        loaded:true,
        dashboard:{
          ...state.dashboard,
          picks:state.dashboard.picks.concat(picks),
          featured:state.dashboard.featured.concat(featured),
          topical:state.dashboard.topical.concat(topical)
        },
        count:action.payload.data.count
      }
      break
    }
    case 'FETCH_PLAYLIST_USER_QUICK_PENDING':{
      state = {...state,loading:true,data:[]}
      break
    }
    case 'FETCH_PLAYLIST_USER_QUICK_FULFILLED':{
      state =  {
        ...state,
        loading:false,
        loaded:true,
        data:state.data.concat(action.payload.data.playlists),
        count:action.payload.data.count
      }
      break
    }
    case 'SEARCH_PLAYLISTS_QUICK_PENDING':{
      state = {
        ...state,
        typeahead:[],
        typeaheadLoading: true
      }
      break
    }
    case 'SEARCH_PLAYLISTS_QUICK_FULFILLED':{

      const playlists = action.payload.data.playlists.map((playlist)=>{
        const data = {
          id:playlist.id,
          name:playlist.name,
          tracksCount:playlist.tracks_count,
          createdAt:playlist.created_at
        }
        return data
      })

      state = {
        ...state,
        typeahead:playlists,
        typeaheadLoading: false
      }
      break
    }
    case 'CLEAR_TYPEAHEAD':{
      state = {
        ...state,
        typeahead:[]
      }
      break
    }
    case 'TYPEAHEAD_PLAYLISTS':{
      state = {
        ...state,
        searchTerm: action.meta
      }
      break
    }
    case 'ADD_PLAYLIST_TO_VENUE_FULFILLED':{
      state = {
        ...state,
        typeahead:[]
      }
      break
    }
    case 'UNPIN_PLAYLIST_FULFILLED':{
      state = {...state,data:state.data.filter(pl => pl.id !== action.meta)}
      break
    }
    case 'PUT_SUBSCRIBE_PLAYLIST_PENDING':{
      state = {...state,subscriptionLoading:true}
      break
    }
    case 'PUT_SUBSCRIBE_PLAYLIST_REJECTED':{
      state = {...state,subscriptionLoading:false,error:action.payload}
      break
    }
    case 'PUT_SUBSCRIBE_PLAYLIST_FULFILLED':{
      const playlists = state.data
      const playlistIndex = playlists.findIndex((playlist) => {
        return playlist.id === action.meta
      })

      playlists[playlistIndex].businesses.push({
        id: state.businessID
      })

      state = {
        ...state,
        subscriptionLoading: false,
        data: playlists
      }
      break
    }
    case 'PUT_UNSUBSCRIBE_PLAYLIST_PENDING':{
      state = {...state,subscriptionLoading:true}
      break
    }
    case 'PUT_UNSUBSCRIBE_PLAYLIST_REJECTED':{
      state = {...state,subscriptionLoading:false,error:action.payload}
      break
    }
    case 'PUT_UNSUBSCRIBE_PLAYLIST_FULFILLED':{
      let playlists
      if (action.meta.isMain) {
        playlists = state.data
        const playlistIndex = playlists.findIndex((playlist) => {
          return playlist.id === action.meta.playlistID
        })

        playlists[playlistIndex].businesses = playlists[playlistIndex].businesses.filter((business) => {
          return business.id !== state.businessID
        })
      } else {
        playlists = state.data.filter(playlist => playlist.id !== action.meta.playlistID)
      }

      state = {
        ...state,
        subscriptionLoading: false,
        data: playlists
      }

      break
    }
    case 'CREATE_PLAYLIST_PENDING':{
      state =  {
        ...state,
        loading: true,
        createdPlaylistID:null
      }
      break
    }
    case 'CREATE_PLAYLIST_REJECTED':{
      state =  {
        ...state,
        loading: false
      }
      break
    }
    case 'CREATE_PLAYLIST_FULFILLED':{
      state =  {
        ...state,
        loading: false,
        createdPlaylistID:action.payload.data.res.data.id
      }
      break
    }
    case 'DELETE_PLAYLIST_PENDING':{
      state = {
        ...state,
        playlistDeleting: true
      }
      break
    }
    case 'DELETE_PLAYLIST_REJECTED':{
      state = {
        ...state,
        playlistDeleting: false
      }
      break
    }
    case 'DELETE_PLAYLIST_FULFILLED':{
      const playlists = state.data.filter(playlist => playlist.id !== action.meta.playlistID)
      state = {
        ...state,
        data: playlists,
        playlistDeleting: false
      }
      break
    }
    case 'PLAYLISTS_TYPEAHEAD_ALL_PENDING':{
      state = {
        ...state,
        typeahead:[],
        typeaheadLoading: true
      }
      break
    }
    case 'PLAYLISTS_TYPEAHEAD_ALL_FULFILLED':{

      const playlists = action.payload.data.playlists.map((playlist)=>{
        const data = {
          id:playlist.id,
          name:`${playlist.name} -- ${playlist.owner ? playlist.owner.username : 'NO USER'}`,
          tracksCount:playlist.tracks_count,
          createdAt:playlist.created_at
        }
        return data
      })

      state = {
        ...state,
        typeahead:playlists,
        typeaheadLoading: false
      }
      break
    }
    case 'LOGOUT':{
      state = {
        data:[],
        dashboard:{
          picks:[],
          featured:[],
          topical:[]
        },
        typeahead:[],
        count:null,
        loading:false,
        loaded:false,
        addPlaylistTrackLoadingIDs: [],
        subscriptionLoading: false,
        playlistDeleting: false,
        error:null
      }
      break
    }
    default: //no default case
  }
  return state
}
