import React, {Component} from 'react'
import { connect } from 'react-redux'

import {
  getVenues,
  addPlaylistToVenue,
  removePlaylistFromVenue
} from 'store/actions/venueAction'

import {
  clearPlaylists,
  getPlaylists
} from 'store/actions/playlistAction'

import Container from 'ui/Container'
import Loader from 'ui/Loader'
import TableWrapper from 'ui/TableWrapper'

import OverlaySubscribedPlaylistToVenuesTableRowActions from './playlists-subcribed/OverlaySubscribedPlaylistToVenuesTableRowActions'

const classname = 'overlaySubscribedPlaylistToVenues'

class OverlaySubscribedPlaylistToVenues extends Component {

  componentDidMount(){
    this.props.dispatch(getVenues())
  }

  componentWillUnmount() {
    const {
      data,
      dispatch
    } = this.props

    // fetch the playlists after closing the overlay to get the updated playlist data
    if (typeof data.type === 'string' && data.type.match(/owned|subscribed|assigned/)) {
      dispatch(clearPlaylists())
      dispatch(getPlaylists(data.type,50,0))
    }
  }


  render(){
    const {
      data,
      dispatch,
      venues,
      loading,
      updatingVenueIDs,
      updating,
      userVenues
    } = this.props

    let _venues = [...venues]
    //manager login
    if(userVenues && userVenues.length > 0){
      _venues = _venues.filter(venue => userVenues.find(userVenue => venue.id === userVenue.id))
    }
    return (
      <Container classname={classname} maxWidth height="100%" column>
        <h1>Add {data.name} to...</h1>
        {loading ? (
          <Loader />
        ) : (
          <div className="playlists-overlay-wrapper">
            <TableWrapper
              classname={classname}
              data={_venues}
              loading={loading}
              rowActions={
                <OverlaySubscribedPlaylistToVenuesTableRowActions
                  addPlaylist={(venueID, playlist)=>dispatch(addPlaylistToVenue(venueID,playlist))}
                  removePlaylist={(venueID, playlist)=>dispatch(removePlaylistFromVenue(venueID,playlist))}
                  playlist={data}
                  classname={classname}
                  assignedVenues={venues}
                  loading={updating}
                  updatingVenueIDs={updatingVenueIDs}
                />
              }
            />
          </div>
        )}
      </Container>
    )
  }
}
function mapStateToProps(store){
  return {
    overlay:store.overlay,
    playlists:store.venue.playlists,
    venues:store.venues.data,
    updatingVenueIDs:store.venues.updatingVenueIDs,
    loading:store.venues.loading,
    updating:store.venues.updating,
    userVenues:store.auth.venues,
    userGroup:store.auth.userGroup
  }
}
export default connect(mapStateToProps)(OverlaySubscribedPlaylistToVenues)
