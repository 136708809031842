import React, {Component} from 'react'

import { debounce } from 'helpers/Debounce'
import {indexOfArrayObject} from 'helpers/ArrayHelper'
import Icon from 'ui/Icon'

class FormTagger extends Component{

  constructor(props){
    super(props)
    this.sendResponse = debounce(this.sendResponse,300)
    this.taggerInput = React.createRef()
    this.state = {
      filter:'',
      term:'',
      results:[]
    }
  }

  componentDidMount(){
    this.removeTagsFromData()
  }

  handleKeyDown(e){
    const { cursor } = this.state
    // arrow up/down button should select next/previous list element
    // up arrow
    if (e.key === 'ArrowUp' && cursor > 0) {
      this.setState( prevState => ({
        cursor: prevState.cursor - 1
      }))
    //down arrow
    } else if (e.key === 'ArrowDown' && cursor < this.props.data.length - 1) {
      this.setState( prevState => ({
        cursor: prevState.cursor + 1
      }))
    //on enter
    } else if(e.key === 'Enter' && this.taggerInput.current.value.length > 0){
      console.log('enter')
    }
    //on select

  }

  removeTagsFromData(){
    if(this.props.allData && this.props.allData.length > 0){
      let filteredData = this.props.allData
        .filter((elem)=> {
          if(indexOfArrayObject(this.props.data,elem,'id') === -1){
            return elem
          }
          return false
        })
      this.setState({filteredAllData:filteredData})
    }
  }

  filterResults(e){
    const { value } = e.target
    if(value !== this.state.filter){
      //set filter value and pass in filter function
      this.setState({filter:value},()=>{
        let results
        if(this.state.filteredAllData && this.state.filteredAllData.length > 0 && this.state.filter.length > 0){
          results = this.state.filteredAllData
            .filter((datum)=>{
              if(datum[this.props.display] && datum[this.props.display].toLowerCase().includes(value)){
                return datum
              }
              return false
            })
            .map((datum,index)=>{
              return <div
                onClick={() => this.addTag()}
                key={index}
                className="form-tagger-input-result">
                  {datum[this.props.display]}
                </div>
            })
        } else {
          results = []
        }
        this.setState({results:results})
      })
    }
  }

  clearTaggerInput(){
    this.setState({results:[]},()=>{
      this.taggerInput.current.value = ''
    })
  }

  addTag(){

  }

  deleteTag(){

  }

  getTags(){
    if(this.props.data){
      return this.props.data
        .map((datum,index)=>{
          return (
            <div key={index} className={`form-tag form-tag-${this.props.classname}`}>
              {datum[this.props.display]}<Icon name="close" action={()=>this.deleteTag()}/>
            </div>
          )
        })
    } else {
      return []
    }
  }

  render(){
    const {classname, placeholder} = this.props
    return (
      <div className={`form-tagger-wrapper form-tagger-wrapper-${classname}`}>
        <input
          ref={this.taggerInput}
          className={`form-tagger-input form-tagger-input-${classname}`}
          onChange={(e)=>this.filterResults(e)}
          onKeyDown={(e)=>this.handleKeyDown(e)}
          placeholder={placeholder}
          />
          <Icon
          name="ios-close"
          action={()=>this.clearTaggerInput()}
          classname={`form-tagger-input-clear ${this.taggerInput.current && this.taggerInput.current.value.length > 0 ? 'active' : ''}`}/>
          <div className={`
            form-tagger-input-results 
            form-tagger-input-results-${this.props.classname} 
            ${this.state.results.length > 0 ? 'form-tagger-input-results-active' : ''}`}>
            {this.state.results}
          </div>
          <div className={`form-tagger-tags form-tagger-tags-${classname}`}>
            {this.getTags()}
          </div>
      </div>
    )
  }
}

export default FormTagger
