import React, { Component, Fragment } from 'react'
import {connect} from 'react-redux'

import {
  createVenue
} from 'store/actions/venueAction'

import {
  getStatus
} from 'store/actions/onboardingAction'

import OnboardingBubbles from './onboarding/OnboardingBubbles';
import Container from 'ui/Container'
import Form from 'ui/Form'
import Icon from 'ui/Icon'
import Logo from 'ui/Logo'
import ProgressTracker from 'ui/ProgressTracker'
import Button from 'ui/Button'
import AnimateOnLoad from 'ui/AnimateOnLoad'
import Loader from 'ui/Loader'

const classname = 'onboarding';

class OnboardingContainer extends Component {

  constructor(props){
    super(props)
    this.state = {
      showVenueForm: false,
      selectedBubbles: 0,
      selBubbles: []
    }
  }

  componentDidMount() {
    const {
      dispatch
    } = this.props

    dispatch(getStatus())

    if (!document.getElementById('chatScript')) {
      // you'd normally just include the Small Chat script in the footer,
      // but as we're loading the code on componentMount, rather than onLoad,
      // we include the content of that script here
      // see https://embed.small.chat/T2RBF6LN4C04V7TXJEN9.js

      window.Smallchat = {
        config: {
          'slackTeamId':'T2RBF6LN4',
          'scChannelId':'-NRCd6DKE3ObIExoCoZl',
          'slackChannelId':'C04V7TXJEN9',
          'uid':'-MXBntDT7U99Li6DUL8x',
          'planId':'06',
          'accountCreated':1617270117325
        },
        appearance: {
          'brand_color':'#4ba0b3',
          'class_name': 'tony',
          'contact_dismissible':false,
          'contact_enabled':true,
          'contact_field_label':'Business email',
          'contact_immediately':false,
          'contact_prompt':'Add your name and email to make sure you see our reply:',
          'contact_reply':'Thanks {{name}}! You\'ll get a response here or we\'ll contact you at {{contact}}.',
          'visible': false,
          'hide_logo':true,
          'hide_team_icon':true,
          'launcher_pos':'right',
          'launcher_prompt':'Live chat',
          'launcher_type':'button',
          'messenger_blank':'Send a message, and we\'ll reply as soon as we can.',
          'messenger_entry':'Send a message...',
          'messenger_prompt':'How can we help you?',
          'name_field_label':'Name',
          'offline_greeting':'We’re offline right now but we\'ll contact you as soon as possible.',
          'text_color':'#ffffff'
        },
        behavior: {
          'avatar_config':1,
          'friday':{
            'from':'8:00',
            'to':'20:00'
          },
          'monday':{
            'from':'8:00','to':'20:00'
          },
          'operating_hours':true,
          'saturday':{
            'disabled':true
          },
          'sunday':{
            'disabled':true
          },
          'thursday':{
            'from':'8:00',
            'to':'20:00'
          },
          'timezone':'Europe/London',
          'tuesday':{
            'from':'8:00',
            'to':'20:00'
          },
          'wednesday':{
            'from':'8:00',
            'to':'20:00'
          }}
      }

      const styles = document.createElement('link')
      styles.rel = 'stylesheet'
      styles.href = 'https://static.small.chat/messenger.css'
      document.head.appendChild(styles)

      const chatScript = document.createElement('script')
      chatScript.async = true
      chatScript.src = 'https://static.small.chat/messenger.js'
      chatScript.id = 'chatScript'
      document.body.append(chatScript)

      window.addEventListener('smallchat:ready', () => {
        document.getElementById('Smallchat').classList.remove('nav-chat')
      })
    } else {
      // just in case the user has somehow been logged into dashboard proper
      // before entering the onboarding process
      // (mostly added to avoid hassle during the testing process tbh)
      document.getElementById('Smallchat').classList.remove('nav-chat')

      if (typeof(window.Smallchat) === 'function') {
        window.Smallchat('show')
      }
    }
  }

  setActiveStep() {
    const {
      status,
      setupComplete,
      venueCreated
    } = this.props

    const {
      showVenueForm
    } = this.state

    if (setupComplete) {
      return 3
    }

    if ((status === 'tags_required') || venueCreated) {
      return 2
    }

    if (status === 'venue_required' && showVenueForm) {
      return 1
    }

    if (status === 'venue_required' && !showVenueForm) {
      return 0
    }
  }

  showVenueForm() {
    this.setState({
      showVenueForm: true
    })
  }

  createVenue(data){
    const {
      dispatch
    } = this.props

    dispatch(createVenue(data))
  }

  render(){
    const {
      loading,
      setupComplete,
      status,
      storedVenueData,
      venueCreated,
      venueErrors
    } = this.props

    const {
      showVenueForm
    } = this.state

    return (
      <Container classname={classname} height="100%" maxWidth column>
        {loading ? (
          <Loader />
        ) : (
          <div className='onboarding'>
            <div className='onboarding__logo'>
              <Logo />
            </div>
            <ProgressTracker
              stepsArray={['Welcome', 'Create a venue', 'Select your favourite genres or styles of music']}
              activeStep={this.setActiveStep()}
            />
            {((status === 'venue_required') && !venueCreated) && (
              <Fragment>
                {showVenueForm && (
                  <AnimateOnLoad>
                    <div className='onboarding__create-venue'>
                      <h1
                        className='onboarding__header'
                      >
                        Create a venue
                      </h1>
                      <Form
                        classname='venues'
                        submit={(data)=>this.createVenue(data)}
                        messages={this.state.message}
                        formData={storedVenueData}
                        serverError={venueErrors && venueErrors.response.data.res}
                      />
                    </div>
                  </AnimateOnLoad>
                )}
                {!showVenueForm && (
                  <AnimateOnLoad>
                    <h1 className='onboarding__header'>Welcome to your Open Ear Dashboard</h1>
                    <p className='onboarding__intro'>From here you can:</p>
                    <div className='onboarding__features'>
                      <div className='onboarding__feature-item'>
                        <Icon name='musical-notes' />
                        <p>Browse the Open Ear music Library</p>
                      </div>
                      <div className='onboarding__feature-item'>
                        <Icon name='ios-list' />
                        <p>View and create playlists</p>
                      </div>
                      <div className='onboarding__feature-item'>
                        <Icon name='business' />
                        <p>Create and manage your venues</p>
                      </div>
                      <div className='onboarding__feature-item'>
                        <Icon name='calendar' />
                        <p>Manage your music schedule</p>
                      </div>
                      <div className='onboarding__feature-item'>
                        <Icon name='analytics' />
                        <p>View statistics</p>
                      </div>
                    </div>
                    <div className='onboarding__button'>
                      <Button
                        action={()=>this.showVenueForm()}
                        name='Get started'
                      />
                    </div>
                  </AnimateOnLoad>
                )}
              </Fragment>
            )}
            {(((status === 'tags_required') || venueCreated) && !setupComplete) && (
              <OnboardingBubbles />
            )}
            {setupComplete && (
              <AnimateOnLoad>
                <div className='onboarding__success'>
                  <div className='onboarding__success-icon'>
                    <Icon name='rocket' />
                  </div>
                  <h1>Success! Now let's go <a href='/playlists'>listen to some music!</a></h1>
                </div>
              </AnimateOnLoad>
            )}
          </div>
        )}
      </Container>
    )
  }
}

function mapStateToProps(store){
  return {
    bubbles: store.onboarding.bubbles,
    loading: store.onboarding.loading,
    bubblesLoading: store.onboarding.bubblesLoading,
    setupComplete: store.onboarding.setupComplete,
    status: store.onboarding.status,
    storedVenueData: store.venues.storedVenueData,
    venueCreated: store.venues.venueCreated,
    venueErrors: store.venues.error
  }
}
export default connect(mapStateToProps)(OnboardingContainer)
