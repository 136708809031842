import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  getPlaylistOrdered,
  updatePlaylistOrder,
  orderPlaylist
} from 'store/actions/playlistAction'

import {
  getTrackInfo,
  getTrackUrl
} from 'store/actions/playerAction'

import Container from 'ui/Container'
import Loader from 'ui/Loader'

import TableWrapper from 'ui/TableWrapper'
import PlaylistOrderTableRowActions from './playlist-order/PlaylistOrderTableRowActions'

import { debounce } from 'helpers/Debounce'

const classname = 'playlistOrder'

class PlaylistOrder extends Component {

  constructor(props){
    super(props)
    this.state = {
      filter:'',
      actionActive:false
    }
    this.filterTracksDispatch = debounce(this.filterTracksDispatch,300)
    this.selectedTracks = []
  }

  componentDidMount(){
    this.props.dispatch(getPlaylistOrdered(this.props.id))
  }

  componentDidUpdate(prevProps){
    //check if tracks have changed and that prev tracks had loaded
    if(prevProps.tracks !== this.props.tracks && prevProps.tracks.length > 0){
      this.props.dispatch(updatePlaylistOrder(this.props.tracks,this.props.id))
    }
  }

  playTrack(track){
    const {
      dispatch
    } = this.props

    dispatch(getTrackInfo(track, 'playlist'))
    dispatch(getTrackUrl(track, 'playlist'))
  }

  changeOrder(newIndex, track){
    this.props.dispatch(orderPlaylist(newIndex, track))
  }

  getTable(){
    return <TableWrapper
      play={(id)=>this.playTrack(id)}
      checkboxAction={(e,id)=>this.checkboxAction(e,id)}
      checkboxSelected={this.selectedTracks}
      classname={classname}
      data={this.props.tracks}
      count={this.props.count}
      clearData={()=>this.clearPlaylists()}
      getData={(limit,offset)=>this.getPlaylists(limit,offset)}
      loading={this.props.loading}
      playingTrack={this.props.playingTrack}
      rowActions={<PlaylistOrderTableRowActions
        favouriteTrack={(data)=>this.favouriteTrack(data)}
        moreActions={(data)=>this.moreActions(data)}
        removeTrack={(data)=>this.removeTrack(data)}
        openPlaylistOverlay={(data)=>this.openPlaylistOverlay(data)}
        classname={classname} />
      }
      changeOrder={(index, track)=>{this.changeOrder(index, track)}}
      sortable
    />
  }

  render(){
    if(this.props.loading){
      return <Loader/>
    } else {
      return (
        <Container
          classname='playlist-tracks'
          height='100%'
          maxHeight
        >
          {this.getTable()}
        </Container>
      )
    }
  }
}
function mapStateToProps(store){
  return {
    tracks:store.playlist.tracks,
    loading:store.playlist.loading
  }
}

export default connect(mapStateToProps)(PlaylistOrder)
