const initalState = {
  data:[],
  loading:false,
  isActive:false
}

export default function reducer(state = initalState,action){
  switch (action.type){
    case 'DELETE_PLAYLIST_TRACK_PENDING':
    case 'PIN_PLAYLIST_PENDING':
    case 'UNPIN_PLAYLIST_PENDING':{
      state = {...state,loading:true}
      break
    }
    // These two are causing errors as the data object is returning an empty string
    // Commenting out for now in case this gets fixed down the line
    // case 'DELETE_PLAYLIST_TRACK_FULFILLED':
    // case 'ADD_PLAYLIST_TRACK_FULFILLED':
    // case 'DELETE_PLAYLIST_TRACK_LIBRARY_FULFILLED':
    case 'PIN_PLAYLIST_FULFILLED':
    case 'UNPIN_PLAYLIST_FULFILLED':
    case 'CREATE_PLAYLIST_FULFILLED':
    case 'UPDATE_PLAYLIST_ORDER_FULFILLED':{
      const data = {
        type:'success',
        icon:'checkmark',
        text:action.payload.data.res.message
      }
      state = {...state,data:state.data.concat(data),loading:false}
      break
    }
    case 'GLOBAL_ERROR':{
      const data = {
        type:'error',
        icon:'ios-warning',
        text:action.payload.stack
      }
      state = {...state,data:state.data.concat(data)}
      break
    }
    case 'APP_BODY_CLICK':{
      state = {...state,isActive:false}
      break
    }
    case 'TOGGLE_MESSAGES':{
      state = {...state,isActive:action.payload}
      break
    }
    default: //no default case
  }
  return state
}
