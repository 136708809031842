import { useState } from "react"
import Button from "ui/Button"
import Input from "ui/Input"
import Logo from "ui/Logo"

import {
  validateEmail
} from '../../helpers/StringHelper'

const ResetPassword = ({
  resetPassword,
  message,
  loading
}) => {

  const [email,setEmail] = useState('')
  const [isValid,setIsValid] = useState(false)
  const [isDirty,setIsDirty] = useState(false)

  const _validateEmail = (email) => {
    if(validateEmail(email)){
      resetPassword(email)
      setIsValid(true)
    } else {
      setIsDirty(true)
    }
  }

  return(
    <div className="reset-password-wrapper">
      <Logo/>
      {
        message ?
        <div className="message">
          {message}
        </div> :
        <>
          <Input
          name="email"
          value={email}
          change={(e)=>setEmail(e.target.value)}
          placeholder="Enter your email address."/>
          <Button 
            name="Reset Password" 
            loading={loading}
            action={()=>_validateEmail(email)}/>
        </>
      }
      
    </div>
  )
}

export default ResetPassword