import React from 'react'

import Icon from 'ui/Icon'
import Loader from 'ui/Loader'
import Tooltip from 'ui/Tooltip'

const LibraryTableRowActions = (props) => {
  const {
    track,
    playlistTracks,
    queueTracks,
    addTrackToPlaylist,
    removeTrackFromPlaylist,
    addTrackToQueue,
    removeTrackFromQueue,
    addTrackToOwnedPlaylistLoading
  } = props

  const isSubscribed = playlistTracks && (
    playlistTracks.find((playlistTrack) => {
      return playlistTrack.id === track.id
    })
  )

  const isQueued = queueTracks && (
    queueTracks.find((queueTrack) => {
      return queueTrack.id === track.id
    })
  )

  return (
    <div>
      {addTrackToOwnedPlaylistLoading === track.id ? (
        <Loader />
      ) : (
        <Icon
          name={isSubscribed ? 'remove-circle' : 'add-circle'}
          action={()=>{
            isSubscribed ? (
              removeTrackFromPlaylist({track:track})
            ) : (
              addTrackToPlaylist({track:track})
            )
          }}
        >
          <Tooltip
            pos="left"
            text={isSubscribed ? 'Remove track from playlist' : 'Add track to playlist'}
          />
        </Icon>
      )}
      <Icon
        name="ios-list"
        classname={isQueued ? 'library-table-row-actions__active-queue' : ''}
        action={()=>{
          isQueued ? (
            removeTrackFromQueue({track:track})
          ) : (
            addTrackToQueue({track:track})
          )
        }}
        active={isQueued}
        stopPropagation
      >
        <Tooltip pos="left" text={isQueued ? 'Remove track from queue' : 'Add track to queue' }/>
      </Icon>
      {/* Temp removal for initial launch */}
      {/*<Icon name="star" action={()=>favouriteTrack({track:track})}>*/}
      {/*  <Tooltip pos="left" text="Favourite track"/>*/}
      {/*</Icon>*/}
    </div>
  )
}

export default LibraryTableRowActions
