import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import { BrowserRouter as Router} from 'react-router-dom'
import { createBrowserHistory } from 'history'

import './index.scss'
import App from './App'
// import registerServiceWorker from './registerServiceWorker'

import store from 'store'
import http from 'store/actions'

const history = createBrowserHistory()
http.setup(store,history)

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.ready.then(registration => {
    registration.unregister();

    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(async (names) => {
        await Promise.all(names.map(name => caches.delete(name)));
      });
    }
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// if(process.env.NODE_ENV === 'production'){
//   registerServiceWorker()
// }

