import React, { Component } from 'react'

import { DragDropContext } from 'react-beautiful-dnd'

import TableModel from 'models/TableModel'
import { getRowLimit, setRowLimit, getRowOptions, getOffset } from 'helpers/TableHelper'

import Container from 'ui/Container'
import Table from 'ui/Table'
import TableHead from 'ui/TableHead'
import TableBody from 'ui/TableBody'
import TableRow from 'ui/TableRow'
import TableRowEmpty from 'ui/TableRowEmpty'
import TableRowLoading from 'ui/TableRowLoading'
import TableControls from 'ui/TableControls'

class TableWrapper extends Component {

  constructor(props){
    super(props)
    this.state = {
      typeaheadData:null,
      pageNo:1,
      totalPages:null,
      sorter:null,
      empty:true
    }

    this.handleScroll = this.handleScroll.bind(this)
    this.onDragEnd = this.onDragEnd.bind(this);
    this.onDragStart = this.onDragStart.bind(this);
    this.tds = []
  }

  componentDidMount(){
    // adding this scroll event breaks react-beautiful-dnd used in PlaylistOrder
    if (!this.props.sortable) {
      window.addEventListener('scroll', this.handleScroll,true)
    }
  }

  componentDidUpdate(){
    const {data} = this.props
    if(this.props.controls){
      this.getTotalPages()
    }
    if(this.state.empty && data && data.length > 0){
      this.setState({empty:false})
    } else if(!this.state.empty && data && data.length === 0){
      this.setState({empty:true})
    }
  }

  componentWillUnmount(){
    window.removeEventListener('scroll', this.handleScroll,true)
  }

  setTableLimit(value){
    this.props.clearData()
    setRowLimit(this.props.classname,value)
    this.props.getData(getRowLimit(this.props.classname),0)
  }

  getTotalPages(){
    const rows = getRowLimit(this.props.classname)
    const totalPages = Math.ceil(this.props.count/rows)
    //stop state update if pages got
    if(totalPages !== this.state.totalPages){
      this.setState({
        totalPages:totalPages,
        pageNo:1
      })
    }
  }

  sortTable(head){
    this.setState({sorter:head,pageNo:1},()=>{
      this.props.sortTable(head)
    })
  }

  selectAll(selected){
    this.props.selectAll(selected)
  }

  paginate(direction){
    let {pageNo, totalPages} = this.state

    //return if at start or end
    if (
      (pageNo === 1 && direction === 'back') ||
      (pageNo === totalPages && direction === 'forward')
    ){
      return
    }

    this.props.clearData()

    if(direction === 'back' && pageNo !== 1){
      pageNo--
      this.setState({pageNo:pageNo},()=>{
        this.props.getData(
          getRowLimit(this.props.classname),
          getOffset(getRowLimit(this.props.classname),pageNo),
          this.state.sorter
        )
      })
    } else if(direction === 'forward' && pageNo !== totalPages){
      pageNo++
      this.setState({pageNo:pageNo},()=>{
        this.props.getData(
          getRowLimit(this.props.classname),
          getOffset(getRowLimit(this.props.classname),pageNo),
          this.state.sorter
        )
      })
    }
  }
  //get the table header
  getTableHeader(){
    return null
  }

  getRowProps(row,index,length){
    //get the props that we need for the certain table actions
    switch(this.props.classname){
      case 'venues':
        return { venue:row }
      case 'playlists':
        return { playlist:row }
      case 'playlistTracks':
        return { track:row,designation:row.designation}
      case 'playlistOrder':
        return { track:row,length:length,index:index}
      case 'playlistVenues':
        return {venue:row}
      case 'library':
        return { track:row }
      case 'overlayPlaylistsAddTrack':
        return { playlist:row }
      case 'fitnessClasses':
        return {id:row.id}
      case 'fitnessClassVenues':
        return {id:row.id}
      case 'venuePlaylists':
        return {id:row.id, playlist:row}
      case 'overlayFitnessVenues':
        return {id:row.id}
      case 'overlaySubscribedPlaylistToVenues':
        return {venueID: row.id, venuePlaylists: row.playlists}
      case 'importer':
        return {
          id: row.id,
          artist: row.artist,
          title: row.title,
          total_length: row.total_length,
          importerMatchPerformed: row.importerMatchPerformed,
          tracks: row.tracks
        }
      case 'overlayImporter':
        return {
          id: row.id,
          track: row
        }
      case 'venueBlocked':
        return { blocked_id: row.blocked_id, track: row }
      default: //no default case
    }
  }
  //return data list
  getRows(){
    const {data, loading, classname, playingTrack, sortable} = this.props
    if(data && data.length > 0){
      return data.map((row,index)=>{
        //add actions to row
        const actions = React.Children.map(this.props.rowActions, child =>
          //send length of array to row
          React.cloneElement(child, this.getRowProps(row,index,data.length))
        )
        //if has row set on props
        if(this.props.rowComp){
          //add default props
          const RowComp = React.cloneElement(this.props.rowComp,{
            key:index,
            index:index,
            data:row,
            classname:classname,
            actionsComp:actions
          })
          return RowComp
        } else {
          //set default row and add actions
          //find selected checkbox and return true/false if found, null if not
          return (
            <TableRow
              play={typeof this.props.play === 'function' ? (id)=>this.props.play(id) : null}
              libraryAction={typeof this.props.libraryAction === 'function' ? (data)=>this.props.libraryAction(data) : null}
              checkboxAction={(e,id)=>this.props.checkboxAction(e,id)}
              selected={!this.props.checkboxSelected || this.props.checkboxSelected.find(elem => elem.id === row.id)}
              key={index}
              index={index}
              data={row}
              classname={this.props.classname}
              playingTrack={playingTrack}
              size={TableModel[this.props.classname]['actionSize']}
              sortable={sortable}
              restricted={this.props.restricted}
            >
              {actions}
            </TableRow>
          )
        }
      })
    } else if(loading){
      return <TableRowLoading classname={this.props.classname}/>
    } else {
      return <TableRowEmpty classname={this.props.classname}/>
    }
  }

  getTableControls(){
    const {
      classname,
      controls,
      loading
    } = this.props

    const {
      pageNo,
      totalPages
    } = this.state

    if(controls){
      return <TableControls
        className={classname}
        rowLimit={getRowLimit(classname)}
        rowOptions={getRowOptions(classname)}
        setTableLimit={(value)=>this.setTableLimit(value)}
        paginate={(direction)=>this.paginate(direction)}
        pageNo={pageNo}
        totalPages={totalPages}
        loading={loading}/>
    }
  }
  //infinite scroll
  handleScroll(e) {
    e.stopPropagation()
    if(this.props.controls &&
      e.srcElement.className.includes('container-table') &&
      !e.srcElement.className.includes('no-controls') &&
      e.srcElement.className.includes(this.props.classname)
    ){
      const elem = e.srcElement
      if(elem.scrollTop + elem.clientHeight >= elem.scrollHeight - 300 && !this.props.loading){
        let {pageNo, totalPages} = this.state
        if(pageNo !== totalPages){
          pageNo++
          this.setState({pageNo:pageNo},()=>{
            this.props.getData(
              getRowLimit(this.props.classname),
              getOffset(getRowLimit(this.props.classname),this.state.pageNo),
              this.state.sorter
            )
          })
        }
      }
    }
  }

  onDragStart(track, bloop, shoop) {
    // Calculate the width of each cell of the row being dragged,
    // to maintain the table layout outwith the table
    // based on another table row.
    // We only need to grab the table cell dimensions on the initial drag
    if (this.tds.length === 0) {
      this.tds = document.getElementsByClassName('table-row')[track.source.index === 0 ? 1 : 0].childNodes
    }

    document.querySelector(`[data-rbd-draggable-id="${track.draggableId}"]`).childNodes.forEach(
      (track, idx) => {
        track.style.width = `${this.tds[idx].offsetWidth}px`
      }
    )
  }

  onDragEnd(track) {
    const {
      changeOrder,
      data
    } = this.props

    // dropped outside the list
    if (!track.destination) {
      return
    }

    data[track.source.index].index = track.source.index
    changeOrder(track.destination.index, data[track.source.index])

    document.querySelector(`[data-rbd-draggable-id="${track.draggableId}"]`).childNodes.forEach(
      (track, idx) => {
        track.style.width = 'auto'
      }
    )
  }

  render(){
    const {
      classname,
      changeOrder,
      data,
      loading,
      sortable,
      tableAction
    } = this.props

    const {
      empty
    } = this.state

    if (sortable) {
      return (
        <DragDropContext onDragEnd={this.onDragEnd} onBeforeDragStart={this.onDragStart}>
          <Table classname={classname}>
            <TableBody
              classname={classname}
              changeOrder={changeOrder}
              data={data}
              sortable={sortable}
            >
              {this.getRows()}
            </TableBody>
          </Table>
        </DragDropContext>
      )
    }

    return (
      <Container classname={`${classname}-table-wrapper`} height='100%' column maxWidth>
        {tableAction}
          <Container
            classname={`table ${loading ? 'loading':''} ${empty ? 'empty' : ''} ${classname}`}
            maxHeight
          >
          <Table classname={classname}>
            <TableHead
              classname={classname}
              sortTable={(head)=>this.sortTable(head)}
              selectAll={(selected)=>this.selectAll(selected)}
              loading={loading}
            />
            <TableBody
              classname={classname}
              changeOrder={changeOrder}
              data={data}
              sortable={sortable}
            >
              {this.getRows()}
            </TableBody>
          </Table>
          {this.getTableControls()}
        </Container>
      </Container>
    )
  }
}
export default TableWrapper
